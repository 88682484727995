import React, { useEffect, useState, FormEvent } from 'react';
import {
  Container, TextField, Button,
  Typography, Box, Paper, CircularProgress, InputAdornment, IconButton
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { User } from '../data/types';
import PasswordValidator from 'password-validator';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti'
import { Visibility, VisibilityOff } from '@mui/icons-material';


type VerifyResetTokenPayload = {
  reset_token: string | null | undefined;
};

const AccountWaitForUser: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <CircularProgress color="secondary" />
    </Box>
  );
};

interface PasswordSetSuccessProps {
  onClick: () => void;
}

const PasswordSetSuccess: React.FC<PasswordSetSuccessProps> = ({ onClick }) => {
  return (
    <Box textAlign="center">
      <Typography variant="h5">
        Your password has been set successfully.
      </Typography>
      <Typography variant="h5">
        You can now login:
      </Typography>
      <Button onClick={onClick} variant="contained" color="primary" sx={{ marginTop: "20px" }}>
        Login
      </Button>
    </Box>
  );
}

interface AccountSetupFormProps {
  user: User | null;
  username: string;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  password2: string;
  setPassword2: React.Dispatch<React.SetStateAction<string>>;
  emailError: string;
  passwordErrors: string[];
  handleLogin: (event: FormEvent<HTMLFormElement>) => Promise<void>;
}

const AccountSetupForm: React.FC<AccountSetupFormProps> = ({
  user,
  username,
  setUsername,
  password,
  setPassword,
  password2,
  setPassword2,
  emailError,
  passwordErrors,
  handleLogin
}) => {
  const [toggle_password, setTogglePassword] = useState<boolean>(false);
  const [toggle_password2, setTogglePassword2] = useState<boolean>(false);
  const togglePasswordHide = () => {
    setTogglePassword(!toggle_password);
  };

  const togglePasswordHide2 = () => {
    setTogglePassword2(!toggle_password2);
  };

  useEffect(() => {
    setUsername(user?.email || '');
  },
    [user, setUsername]
  );

  return (
    <>
      <Box textAlign="center">
        <img src="logo.png" alt="Logo" style={{ maxWidth: '200px', marginBottom: '30px' }} />
        <Typography variant="body1">
          <strong style={{ color: 'purple' }}>Welcome {user?.first_name}</strong>!
        </Typography>
        <Typography variant="body1">
          Please set up your account.
        </Typography>
      </Box>
      <form onSubmit={handleLogin}>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Email Address"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={!!emailError}
            helperText={emailError}
            margin="normal" />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="New Password"
            type={toggle_password ? 'text' : 'password'}
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={passwordErrors.length > 0}
            helperText={passwordErrors.join(' ')}
            margin="normal"
            InputProps={
              {
                endAdornment: (
                  <InputAdornment position="end"> {
                    <IconButton>
                      {toggle_password ? (
                        <Visibility className="cursor_pointer"
                          onClick={
                            togglePasswordHide
                          }
                        />
                      ) : (
                        <VisibilityOff onClick={
                          togglePasswordHide
                        }
                        />
                      )}
                    </IconButton>
                  }
                  </InputAdornment>
                ),
              }
            }
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Confirm Password"
            type={toggle_password2 ? 'text' : 'password'}
            variant="outlined"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            error={passwordErrors.length > 0}
            helperText={passwordErrors.join(' ')}
            margin="normal"
            InputProps={
              {
                endAdornment: (
                  <InputAdornment position="end"> {
                    <IconButton>{
                      toggle_password2 ? (
                        <Visibility className="cursor_pointer"
                          onClick={
                            togglePasswordHide2
                          }
                        />
                      ) : (
                        <VisibilityOff onClick={
                          togglePasswordHide2
                        }
                        />
                      )
                    }
                    </IconButton>
                  }
                  </InputAdornment>
                ),
              }
            }

          />
        </Box>
        <Box mt={4} textAlign="center">
          <Button type="submit" variant="contained" color="primary">
            Set Up Account
          </Button>
        </Box>
      </form>
    </>
  );
};

export type AccountSetupProps = {
  setup_or_forgot_pass: 'setup' | 'forgot',
};

const AccountSetup: React.FC<AccountSetupProps> = ({ setup_or_forgot_pass }) => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [validToken, setValidToken] = useState<boolean>(false);
  const [tokenChecked, setTokenChecked] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [emailError, setEmailError] = useState<string>('');
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [accountPasswordSetSuccess, setAccountPasswordSetSuccess] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState<React.ReactElement>(<AccountWaitForUser />);

  const verifyToken = async (token: string | null): Promise<boolean> => {
    if (!token) return false;

    try {
      const response = await fetch('/api/verify_reset_token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reset_token: token } as VerifyResetTokenPayload),
      });

      const result = await response.json();

      if (response.ok) {
        setUser(result.user);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error verifying token:', error);
      return false;
    }
  };

  useEffect(() => {
    verifyToken(token).then((isValid) => {
      setValidToken(isValid);
      setTokenChecked(true);
    });
  }, [token]);

  useEffect(() => {
    const onLoginClicked = () => {
      navigate("/login");
    }

    const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setEmailError('');
      setPasswordErrors([]);
      setUsername(username.trim().toLowerCase());

      if (!user || user.email !== username) {
        setEmailError('Email does not match our records.');
        return;
      }
      const schema = new PasswordValidator();
      schema
        .is().min(8)
        .is().max(100)
        .has().uppercase()
        .has().lowercase()
        .has().digits(1)
        .has().not().spaces()
        .is().not().oneOf(['Passw0rd', 'Password123']);
      const passwordValidationErrors = schema.validate(password, { list: true }) as string[];
      if (passwordValidationErrors.length > 0) {
        const errorMessages = passwordValidationErrors.map(error => {
          switch (error) {
            case 'min':
              return 'be at least 8 characters long';
            case 'max':
              return 'be at most 100 characters long';
            case 'uppercase':
              return 'have at least one uppercase letter';
            case 'lowercase':
              return 'have at least one lowercase letter';
            case 'digits':
              return 'have at least one digit';
            case 'spaces':
              return 'not have spaces';
            case 'oneOf':
              return 'not be a common password';
            default:
              return 'be valid';
          }
        });

        const consolidatedErrorMessage = `Password must ${errorMessages.join(', ').replace(/, ([^,]*)$/, ', and $1')}.`;
        setPasswordErrors([consolidatedErrorMessage]);
        return;
      }

      if (password !== password2) {
        setPasswordErrors(['Passwords do not match.']);
        return;
      }

      try {
        const response = await fetch('/api/set_password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ reset_token: token, password: password }),
        });

        if (response.ok) {
          setAccountPasswordSetSuccess(true);
        }
      } catch (error) {
        console.error('Error setting up account:', error);
      }
    };

    const onAccountRequestNewTokenClicked = () => {
      navigate('/reset');
    }

    const AccountInvalidToken = () => {
      return (
        <Box textAlign="center">
          <img src="logo.png" alt="Logo" style={{ maxWidth: '200px', marginBottom: '30px' }} />
          <Typography variant="h5">
            {setup_or_forgot_pass === 'setup' ? 'Account creation link expired' : 'Password reset link expired'}
          </Typography>
          <Typography variant="h5">
            Please request a new one
          </Typography>
          <Button onClick={onAccountRequestNewTokenClicked} variant="contained" color="primary" sx={{ marginTop: "40px" }}>
            Request New Link
          </Button>
        </Box>
      );
    }

    if (accountPasswordSetSuccess) {
      setActiveComponent(<PasswordSetSuccess onClick={onLoginClicked} />);
    } else if (tokenChecked && validToken) {
      setActiveComponent(
        <AccountSetupForm
          user={user}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          password2={password2}
          setPassword2={setPassword2}
          emailError={emailError}
          passwordErrors={passwordErrors}
          handleLogin={handleLogin}
        />
      );
    } else if (!tokenChecked) {
      setActiveComponent(<AccountWaitForUser />);
    } else {
      setActiveComponent(<AccountInvalidToken />);
    }
  }, [tokenChecked, validToken, user, username, password, password2, emailError, passwordErrors, token, accountPasswordSetSuccess, navigate, setup_or_forgot_pass]);

  return (
    <>
      <Box style={{ backgroundColor: "#7B1FA2", display: 'flex', flexDirection: 'column', minHeight: '100svh' }}>
        <Container sx={{ height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Paper elevation={3} sx={{ padding: 4, mt: 4, width: '40%' }}>
            {activeComponent}
          </Paper>
        </Container>
      </Box>
      {accountPasswordSetSuccess && (<Confetti width={window.innerWidth} height={window.innerHeight} />)}
    </>
  );
};

export default AccountSetup;
