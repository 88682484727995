import { WordListMappingType } from "../types";

export const NatureWordListMap: WordListMappingType = {
  "Nature": {
    "Crop, Plant & Tree Issues": {
      "Plant Diseases": [
        "Anthracnose",
        "Apple scab",
        "Bacterial canker virus",
        "Bacterial leaf scorch",
        "Banana bunchy top virus",
        "Begomovirus",
        "Blast",
        "Bleeding canker",
        "Blossom end rot",
        "Bolting",
        "Botrytis fabae",
        "Black pod bunt",
        "Brown blight",
        "Brown streak",
        "Canker blight",
        "Chemical damage",
        "Cedar apple rust",
        "Citrus chlorotic dwarf virus",
        "Citrus ringspot virus",
        "Citrus tristeza virus",
        "Club root",
        "Coconut leaf blight",
        "Corn smut",
        "Covered smut",
        "Crown gall",
        "Damping off plant",
        "Diseases",
        "Disorders",
        "Downy mildew",
        "Drought",
        "Early blight",
        "Ergot leaf curl",
        "Foliar disease",
        "Fomes root rot",
        "Foot rot molds",
        "Frost",
        "Frosty pod rot",
        "Fusarium seedling blight",
        "Fusarium wilt",
        "Ganoderma root rot",
        "Girdling roots",
        "Gray mold",
        "Groundnut rosette virus",
        "Honey fungus",
        "Karnal bunt",
        "Leaf spots",
        "Leaf stripe",
        "Loose smut",
        "Microdochium seedling blight",
        "Mosaic virus",
        "Needle rust",
        "Nematodes",
        "Net blotch",
        "Nutrient deficiency",
        "Oxysporum",
        "Parasitic algae",
        "Pollution",
        "Potato scab",
        "Plum pox virus",
        "Ramularia leaf spot",
        "Rhizosphaera needlecast",
        "Root rots",
        "Rusts",
        "Rynchosporium",
        "Seedling blight",
        "Septoria seedling blight",
        "Sheath blight",
        "Soybean stunt virus",
        "Stigmina needle blight",
        "Stigmina needle cast",
        "Sugarcane mosaic",
        "Sun damage",
        "Tarspot",
        "Tree decline",
        "Veinal necrosis virus",
        "Verticillium wilt",
        "Virus complex",
        "Water logging",
        "Wetwood",
        "Wilts",
        "Wind chill",
        "Wind rock",
        "Witches broom",
        "Yellow leaf curl virus"
      ],
      "Pests": [
        "Adelges japonicas",
        "African citrus psyllid",
        "African snails",
        "Agriotes obscurus",
        "Agrioles sputator",
        "Alfalfa caterpillar",
        "Alfalfa looper",
        "Alfalfa weevil",
        "Anoplophora glabripenis",
        "Aphids",
        "Aradus cinnamonmeus",
        "Argyroploce illepidia",
        "Armyworms",
        "Ash whitefly",
        "Asiatic citrus psyllid",
        "Asian citrus psyllid",
        "Asian long horned beetle",
        "Aster leafhopper",
        "Bark borers",
        "Barley stripe",
        "Bean bugs",
        "Beetle borers",
        "Beetles",
        "Black Asian carp",
        "Black head pest",
        "Bladdergall mites",
        "Blister beetles",
        "Blue aphid",
        "Boll weevil",
        "Bollworm",
        "Bont tick",
        "Brown marmorated stink bug",
        "Brown plant hopper",
        "Brown tree snake",
        "Calliteara pudbundla",
        "Caseworm",
        "Cerambyx cerdo",
        "Ceratitis capitata",
        "Circle worms",
        "Citrus fruit borer",
        "Citrus leaf miner",
        "Clover root curcullo",
        "Cocoa pod borer",
        "Corn aphids",
        "Corn borers",
        "Corn cutworms",
        "Cotton red spider mite",
        "Crayfish",
        "Crytomemes spp.",
        "Curl mite",
        "Darkling beetles",
        "Dendroctonus spp.",
        "Diaprepes root weevil",
        "Earworm",
        "Egg parasitoids",
        "Egyptian alfalfa weevil",
        "Electric ants",
        "Emerald fish borer",
        "Eriophyid mites",
        "European house borer",
        "Eutetranychus orientalis",
        "False cinch bug",
        "Feral pigs",
        "Field crickets",
        "Fire ants",
        "Flea beetles",
        "Fruit borers",
        "Fruit fly",
        "Fruit piercing moths",
        "Fruit spotting bug",
        "Gall flies sweet potato weevil",
        "Gandhi bug",
        "Gando bava",
        "Glanders",
        "Globodera rostochiensis",
        "Grape phylloxera",
        "Grape powedery mildew",
        "Grasshopper",
        "Greenbug",
        "Guinea worm",
        "Gypsy moths",
        "Helicoverpa amigera",
        "Hemlock wooly adelgid",
        "Hessian fly",
        "Hylurgops major",
        "Hylurgus ligniperda",
        "Ips typographus",
        "Leaf beetle",
        "Leaf feeding caterpillars",
        "Leaf miner",
        "Leaf mites",
        "Leaf rollers",
        "Lesidosapher newsteadi",
        "Light brown apple moth",
        "Locusts",
        "Looper",
        "Lumantria monacha",
        "Lygus",
        "Lymantria dispar",
        "Mango leaf gall midge",
        "Mango leafhopper",
        "Mango stem hopper",
        "Mango weevil",
        "Meadow spittlebug",
        "Mealy bug",
        "Meditteranian fruit fly",
        "Melioidosis",
        "Melon fly",
        "Melon thripe",
        "Mirids",
        "Mites",
        "Mountain pine beetle",
        "Navel orangeworm",
        "New world screw worm",
        "NDM-1 super bug",
        "Norway rat",
        "Nosema ceranae",
        "Old world screw worm",
        "Oriental fruit fly",
        "Orthotomicus erosus",
        "Paillosa",
        "Panolis flammea",
        "Pea aphid",
        "Pine beetle",
        "Pink bollworm",
        "Pitypgenes charcographus",
        "Potato leafhopper",
        "Python",
        "Range crane fly",
        "Rats",
        "Red banded mango caterpillar",
        "Red beetle",
        "Red palm weevil",
        "Rice leafminer",
        "Rice seed mdges",
        "Root eater worms",
        "Rootworm",
        "Saltmarsh caterpillar",
        "Sarsina violascens",
        "Scales",
        "Scarab beetles",
        "Scolytus intricatus",
        "Silverleaf whitefly",
        "Sirex moctilio",
        "Seedcorn maggot",
        "Spider mites",
        "Sheep scab mite",
        "Stem miner",
        "Stink bug",
        "Spiraling white fly",
        "Spotted alfalfa aphid",
        "South African citrus thripe",
        "Southern red mite",
        "Spindlegall mites",
        "Sucking pests",
        "Sugarbeet root aphid",
        "Tadpole shrimp",
        "Targonia vitis",
        "Termite",
        "Tessaritomidae",
        "Thripes",
        "Tobacco budworm",
        "Tomicus piniperda",
        "Trogoderma granarium",
        "Trunk borer",
        "Two-spotted spider mite",
        "Vegetable leaf miner",
        "Webworm",
        "Western flower stripe",
        "Wireworms",
        "Xyleborus spp.",
        "Yellow crazy ant",
        "Yellow dwarf",
        "Ellow striped armyworm",
        "Zabus tenebrioides",
        "Zebra mussels"
      ],
      "Bacteria & Fungus": [
        {
          title: "Bacteria",
          words: [
            "Burkhodleria mallei",
            "Burkholderia pseudomallei",
            "Clavibacter michigaesis",
            "Corynebacterium",
            "Cowdria ruminantium",
            "Cytoecetes phagocytophillia",
            "Ehrlichia bovis",
            "Ehrlichia (cytoecetes) ondir",
            "Ehrlichia ovina",
            "Erwinia cartovora",
            "Flavescence doree",
            "Liberobacter spp.",
            "M. Mycoides",
            "Mycoplasma capricolum",
            "Pasteurella cholera-gallinarum",
            "Phytoplasma",
            "Piscinickettsia salmoninarum",
            "Pseudomonas",
            "Pseudomonas suringae",
            "Ralstonia solanacearum",
            "Rhizomonas suberifaciens",
            "Rickettsias",
            "Xanthomonas axonopodis",
            "Xanthomonas axonopodis pv. Vasculoru",
            "Xanthomonas campestris"],
        },
        {
          title: "Fungus",
          words: [
            "Aphanomyces astaci",
            "Armillaria spp.",
            "Aspergillus achraceus",
            "Aspergillus flavus",
            "Aspergillus niger",
            "Banana freckle",
            "Bipolaris maydis",
            "Black sigatoka",
            "Botrytis byssoidea",
            "Ceratpcystis autographa",
            "Chrysomyxa deformans",
            "Chrysomyxa himalensis",
            "Citrus scab",
            "Citrus powdery mildew",
            "Collectotrichum sp.",
            "Collectotrichum zeae",
            "Cronartium hialayense",
            "Exserohilum turcicum",
            "Fusarium graminearium",
            "Fusarium proliferatum",
            "Fusarium syblutinans",
            "Fusarium verticilloides",
            "Garioderma spp",
            "Helicobasidium mompa",
            "Heterobasidium spp.",
            "Histoplasma faciminosum",
            "Lachnellula willcommi",
            "Lophodermella sulcigena",
            "Mal secco",
            "Mango malformation disease",
            "Melampsora pinitorqua",
            "Microcyclus ule",
            "Moniliophthra (monillia) rore",
            "Mycosphaerella sojae",
            "Myrtle rust",
            "Ophiostomia spp",
            "Panama disease",
            "Penicillium",
            "Peronosclerospora spp.",
            "Peronosclerospora philippinensis",
            "Peronosclerospora sacchari",
            "Peronospora sp.",
            "Phakopsora pachyrhiza",
            "Phellinus spp.",
            "Phoma terrestris",
            "Physopella zeae",
            "Phytophthora cambivora",
            "Puccinia sorghi",
            "Pucciniastrum areolatum",
            "Pythium spp.",
            "Pythium volutum",
            "Rhisopus stolonifer",
            "Rhizoctoni spp.",
            "Sclerophthora raysiae",
            "Sclerospora spontanea",
            "Sclerotium rolfsi",
            "Sclerotinia sclerotiorium",
            "Septoria maydis",
            "Sterrphylium",
            "Synchytrium dolichi",
            "Synchytrium umbilicatum",
            "Ustilago maydis"
          ]

        }

      ],
      "Fungus": [
        "Aphanomyces astaci",
        "Armillaria spp.",
        "Aspergillus achraceus",
        "Aspergillus flavus",
        "Aspergillus niger",
        "Banana freckle",
        "Bipolaris maydis",
        "Black sigatoka",
        "Botrytis byssoidea",
        "Ceratpcystis autographa",
        "Chrysomyxa deformans",
        "Chrysomyxa himalensis",
        "Citrus scab",
        "Citrus powedery mildew",
        "Collectotrichum sp.",
        "Collectotrichum zeae",
        "Cronartium hialayense",
        "Exserohilum turcicum",
        "Fusarium graminearium",
        "Fusarium proliferatum",
        "Fusarium syblutinans",
        "Fusarium verticilloides",
        "Garioderma spp",
        "Helicobasidium mompa",
        "Heterobasidium spp.",
        "Histoplasma faciminosum",
        "Lachnellula willcommi",
        "Lophodermella sulcigena",
        "Mal secco",
        "Mango malformation disease",
        "Melampsora pinitorqua",
        "Microcyclus ule",
        "Moniliophthra (monillia) rore",
        "Mycosphaerella sojae",
        "Myrtle rust",
        "Ophiostomia spp",
        "Panama disease",
        "Penicillium",
        "Peronosclerospora spp.",
        "Eronosclerospora philippinensis",
        "Peronosclerospora sacchari",
        "Peronospora sp.",
        "Phakopsora pachyrhiza",
        "Phellinus spp.",
        "Phoma terrestris",
        "Physopella zeae",
        "Phytophthora cambivora",
        "Puccinia sorghi",
        "Pucciniastrum areolatum",
        "Pythium spp.",
        "Pythium volutum",
        "Rhisopus stolonifer",
        "Rhizoctoni spp.",
        "Sclerophthora raysiae",
        "Sclerospora spontanea",
        "Sclerotium rolfsi",
        "Sclerotinia sclerotiorium",
        "Septoria maydis",
        "Sterrphylium",
        "Synchytrium dolichi",
        "Synchytrium umbilicatum",
        "Ustilago maydis"
      ],
      "Weeds": [
        "Absinth wormwood", "Abutilon hirtum", "Abutilon indicum", "Acalypha cilata", "Acalypha indica", "Acuate",
        "Aerva lanata", "Aerva tomentosa", "Aeschynomene indica", "African payal", "Ageratum conyzoides", "African rue",
        "Alfombrilla", "Alhagi maurorum", "Alisma", "Alligator flag", "Alligator weed", "Allmania nodiflora", "Alternanthera",
        "Alysicarpus rogosus", "Amaranthus", "Ambulia/limnophila", "American lotus", "American pondweed", "Ammannia baccifera",
        "Anchored water hyacinth", "Andrographis", "Anisomeles malabarica", "Annual bluegrass", "Annual sowthistle",
        "Argemone mexicana", "Aristolochia bractiata", "Armenian blackberry", "Arrowhead", "Aquarum water-moss",
        "Artichoke thistle", "Asystacia gangetica", "Austrian fieldcress", "Austrian peaweed", "Australian wattles",
        "Autumn olive", "Azolla", "Baby pondweed", "Banana passionfruit", "Banana poka", "Banana waterlily", "Bank’s grevillea",
        "Barbed goatgrass", "Barbwire grass", "Barbwire russian thistle", "Barleria montana", "Bearded creeper", "Bergia ammanioides",
        "Bermuda grass", "Besharam", "Biddy biddy", "Bidens pilosa", "Black henbane", "Bladderflower",
        "Black knapweed", "Blepharis", "Black wattle", "Bladder campion", "Bladderwort", "Bloodberry", "Blue burr", "Blue echium",
        "Blue flag", "Blue panicgrass", "Blue-flowering lettuce", "Blueweed", "Boerhaavia", "Bohemian knotweed", "Borreroa josoda",
        "Bouncingbet", "Branched broomrape", "Brazilian elodea", "Bridle wreath", "Brittle naiad", "Broad-leaved-peppergrass",
        "Broomsedge", "Brown knapweed", "Buckhorn plantain", "Buffalobur", "Buffelgrass", "Bull thistle",
        "Bull tongue", "Bulrush", "Bur ragweed", "Burclover", "Burdock cleavers", "Bushy pondweed", "Butterfly", "Buttonbush",
        "Cabomba", "Caesalpinia decapetala", "Calotropis", "Camel thorn", "Canada thistle", "Canadian water weed", "Candleberry myrtle",
        "Cape ivy", "Capeweed", "Cardiospermum halicacabum", "Carolina horsenettle", "Carpet weed", "Cassia", "Cat weed", "Cattail common",
        "Cattail narrow leaved", "Caulerpa", "Celery leaf buttercup", "Celosia argentia", "Cereal rye", "Cereus jamacaru",
        "Chara", "Cheatgrass", "Chenopodium", "Chickweed", "Chicory", "Chinese clematis", "Chinese tallow", "Chinese tamarisk",
        "Chromolaena", "Chrozophora rottleri", "Cleavers", "Cleome", "Climbing hempweed", "Clover broomrape", "Cogongrass",
        "Coast sandbur", "Coccinia grandis", "Coccinia indica", "Coltsfoot", "Comb hyptis", "Commelina benghalensis", "Common baby’s breath",
        "Common mullein", "Common barberry", "Common buckthorn", "Common bugloss", "Common cordgrass", "Common crupina", "Common duckweed",
        "Common frogbit", "Common groundsee", "Common lambsquarters", "Common purslane", "Common reed", "Common russian thistle",
        "Common salvina", "Creeping mesquite", "Common tansy", "Common teasel", "Common thorn apple", "Coontail", "Corn chamomile",
        "Corn spurry", "Cortaderia jubata", "Cow lilly", "Croftonweed", "Crotalaria", "Croton bonplandianum", "Creeping bellflower",
        "Creeping wartcress", "Creeping yellow cress", "Curly-leafed pondweed", "Cutleaf teasel", "Crimson fountaingrass", "Crupina",
        "Curlyleaf pondweed", "Curse ivy", "Cuscuta reflexa", "Cynodondactylor", "Cynotis", "Cypress spurge", "Dalmatian toadflax",
        "Dame’s rocket", "Datura", "Dense-flowered cordgrass", "Dense waterweed", "Desmodium", "Diffuse knapweed", "Digera muricata",
        "Dodder", "Dollar bonnet", "Downy brome", "Downy rosemyrtle", "Downy thorn apple", "Duckweed", "Dudaim melon", "Dyer’s woad",
        "Elephant’s foot", "East indian hygrophila", "Echinochloa colona", "Eclipta prostrata", "Eelgrass", "Egeria", "Eichhornia crassipes",
        "Elephant opus", "Elodea", "English ivy", "Enyda", "Erigeron asteroids", "Eucalyptus", "Eupatory", "Euphorbia", "Euphorbia pulcherima",
        "Eurasian watermilfoil", "European frogbit", "European water chestnut", "European mistletoe", "Evolvulus alsinoides", "False brome",
        "False garlic", "Fanwort", "Feathered mosquito", "Fern", "Field bindweed", "Field sandbur", "Field scabious", "Filamentous algae",
        "Firetree", "Fireweed", "Flaveria trinervia", "Floating heart", "Floating primrose willow", "Floating water hyacinth", "Flowering rush",
        "Formosa lily", "Fountaingrass", "Fragrant water lily", "French broom", "Frogs-bit", "Galensoga", "Garden loose strife", "Garlic mustard",
        "Giant cane", "Giant duckweed", "Giant foxtail", "Giant hogweed", "Giant knotweed", "Giant ragweed", "Giant reed", "Giant salvinia",
        "Giant sensitive plant", "Gisekia pharnaceoides", "Globe-podded hoary cress", "Golden thistle", "Gorse", "Grangea maderaspatana",
        "Grape groundcherry", "Grass-leaved arrowhead", "Great burdock", "Grecian foxglove", "Green foxtail", "Grootklits", "Hairy willowherb",
        "Hairy whitetop", "Hakea spp.", "Halogeton", "Harmel", "Heart-podded hoary cress", "Himalayan knotweed", "Houndstongue", "Heartleaf nightshade",
        "Hempnettle", "Herb robert geranium", "Hemidesmus indicus", "Hibiscus", "Hill raspberry", "Himalayan balsam", "Himalayan blackberry",
        "Hoary alyssum", "Hoary cardaria", "Hoary cress", "Homwort", "Hoorah grass", "Horned pond weed", "Horsenettle", "Horsetail", "Houndstongue",
        "Hydrilla", "Hydrocotyle", "Hygrophila auriculata", "Hygrophila costata", "I. Caerulea", "Illinois pondweed", "Indian jointvetch",
        "Indian shot", "Indoneesiella echioides", "Invading ageratum", "Ipomea i. Alba", "Italian thistle", "Japanese brome", "Japanese dodder",
        "Japanese hops", "Japanese knotweed", "Japanese knotweed hybrid", "Jointed goatgrass", "Japanese knotweed", "Japanese silvergrass",
        "Japonicas siebold", "Johnsongrass", "Jointed goatgrass", "Jubata grass", "Justicia j. Prostrate", "Kahiliflower", "Kahili ginger lily",
        "Kangaroo thorn", "Kiku yugrass", "Kariba weed", "Karnal bunt", "Kat sola", "Iberian starthistle", "Kidney leaf", "King-devil hawkweed",
        "Klamath weed", "Knapweed", "Kochia", "Koster’s curse", "L. Aspera leucas", "Lactuca runcinata", "Ladysthumb", "Lagarosiphon major",
        "Lanceleaf nightshade", "Lantana camara", "Large cockelbur", "Large thorn apple", "Laurel spurge", "Leafy spurge", "Lens-podded hoarycress",
        "Lesser burdock", "Lens-podded hoary cress", "Lens-podded whitetop", "Lesser broom rape", "Lesser celandine", "Limnocharis flava", "Lizards tail",
        "Longleaf ludwigia", "Longleaf primrose willow", "Lotus", "Lucerne dodder", "Ludwigia perennis", "Lyon’s grass", "Madeira vine", "Maidencane",
        "Malachra", "Curly-leafed pondweed", "Malta starthistle", "Marabu", "Marselia quadrifolia", "Marsh plume thistle", "Meadow knapweed",
        "Marsh thistle", "Matgrass", "Mat sandbur", "Maui pamakani", "Mayweed chamomile", "Meadow hawkweed", "Caput-Meadow knapweed", "Mediterranean sage",
        "Meadow sage", "M. Disticha", "Medinilla venosa", "Melastoma", "Medusahead", "Medusahead rye", "Melia azedarach", "Melilotus indicus",
        "Merremia m.", "Mexican ageratum", "Mexican water lily", "Mexican sunflower", "Miconia", "Mile-a-minute weed", "Mimosa pudica", "Misblom",
        "Miscanthus", "Mistflower", "Molucca raspberry", "Monochoria vaginalis", "Moon cactus", "Moon flower", "Morning glory", "Mosquito fern",
        "Moth catcher", "Moth mullein", "Mouse-ear hawkweed", "Mud plantain", "Multiflora rose", "Musk grass", "Musk thistle", "Mustard", "Myriophyllum aquaticum",
        "Myrtle spurge", "Nasella tussock", "Nastridium indicum", "New zealand bur", "Night-flowering catchfly", "Nilkalmi", "Nitella", "Nodding thistle",
        "Nile cabbage", "Oblong spurge", "Ocium o.", "Old man’s beard", "Oldenlandia o.", "Olive hymenachne", "Opuntia spp.", "Orange hawkweed",
        "Orbanche cernua", "Oriental bittersweet", "Ovate goatgrass", "Oxalis o.", "Oxeye daisy", "Oxford ragwort", "Oxyspora paniculata", "Paddy melon",
        "Pale yellow iris", "Pampas grass", "Panicled onion", "Paragrass", "Parody spike", "Parrot’s feather", "Parthenium", "Passiflora foetida",
        "Patterson’s curse", "Pedalium murex", "Pepper-cress", "Pepperwest", "Perennial peavine", "Perennial peppercress", "Perennial pepperweed",
        "Perennial sowthistle", "Pereskia aculeata", "Peruvian apple", "Peruvian primrose", "Phragmites austra", "Phyllanthus p.", "Physalis minima",
        "Pickerelweed", "Pine", "Piripiri", "Planktonic algae", "Plume poppy", "Plumeless thistle", "Poison hemlock", "Policemens helmet", "Pom pom weed",
        "Portulaca oleracea", "Portulaca p.", "Poverty weed", "Prickly florida blackberry", "Prickly pear", "Primrose willow", "Priva cordifolia", "Procambarus fallax",
        "Prosopis juliflora", "Prosopis stephaniana", "Puna", "Punagrass", "Puncturevine", "Purperwinde", "Purple loosestrife", "Purple mustard", "Purple nutsedge",
        "Purple starthistle", "Radish", "Ragweed", "Ravenna grass", "Red ginger lily", "Redhead grass", "Red rice", "Red sunflower", "Red vernus", "Redstem filaree",
        "Red waterfern", "Reed canary grass", "Reeds", "Ricefield bulrush", "Ricinus communis", "Rivina", "Rooiwaterbaring", "Rotala densiflora", "Rough comfrey",
        "Rough jointvetch", "Rubus", "Rungia repens", "Rush skeletonweed", "Russian knapweed", "Russian olive", "Russian salt tree", "Russian thistle", "Sagitaria",
        "Sago pondweed", "Sahara mustard", "Saltcedar", "Saltlover", "Saltmeadow cordgrass", "Salvinia", "Salvinai molesta", "Scented gaura", "Scentless chamomile",
        "Scotch broom", "Scotch thistle", "Sea rush", "Sedges", "Senegal tea", "Sericea lespedeza", "Serrate spurge", "Serrated tussock", "Silver leaf nightshade",
        "Shiny leaf geranium", "Shoebutton ardisia", "Siamweed bitterbrush", "Siberian elm", "Sicilian starthistle", "Sicilian thistle", "Sida sida acuta", "Singapore daisy",
        "Sisymbrium irio", "Skeletonweed", "Slender flower thistle", "Small broomrape", "Small bugloss", "Smallflower tamarisk", "Smartweed", "Smooth cordgrass",
        "Smooth distaff thistle", "Smooth groundcherry", "Southern sandbur", "Soft rush", "Solanum mauritianum", "Solanum robustum", "Sonchus oleraceus", "South american waterweed",
        "Southern naiad", "Southern sandbur", "Southern watergraa", "Spanish broom", "Spanish heath", "Spatterdock", "Spear thistle", "Spiked pepper", "Spiked water milfoil",
        "Spike rush", "Spikeweed", "Spilanthes calva", "Spiny cocklebur", "Spiny emex", "Spiny rush", "Spiny sowthistle", "Spiny tree cactus", "Spongefruit saltbush", "Spotted knapweed",
        "Squarrose knapweed", "Spurred anoda", "Sticky snakeroot", "St. Johnswort", "St. Josephs lily", "Storks bill", "Striga asiatica", "Sulphur cinquefoil", "Swainsonpea",
        "Swamp morning glory", "Sweet resinbush", "Swinecress", "Sword fern", "Synedrella nodiflora", "Syrian beancaper", "Tanglehead", "Tall buttercup", "Tall hawkweed", "Tansy ragwort",
        "Tartary buckwheat", "Taurian thistle", "Texas blueweed", "Thistles", "Thorny sensitive plant", "Three-lobed morningglory", "Three square", "Tibouchina", "Tipton weed",
        "Tocalote", "Torrey’s nightshade", "Tragia plukenetil", "Tree daisy", "Tree of heaven", "Trianthema t", "Tribuluis terrestris", "Trichodesma indicum.", "Triffod weed",
        "Tropical duckweed", "Tropical kudzu", "Tropical soda apple", "Trumpet lily", "Tufted vetch", "Turkeyberry terongan", "Tyrol knapweed", "Umbrella plant", "Variable-leaf milfoil",
        "Variable pondweed", "Velvetleaf", "Venice mallow", "Veronica cinerea", "Vicoaind", "Victorian box", "Viper’s bugloss", "Wall rocket", "Waltheria indica", "Water cabbage",
        "Water caltrop", "Water cattail", "Water chestnut", "Water clover", "Watercress", "Water fern", "Waterleaf", "Water hyacinth", "Water lettuce", "Water lily", "Watermeal",
        "Water mimosa", "Water paspalum", "Water pennywort", "Water pepper", "Waterpes", "Water plantain", "Water primrose", "Water purslane", "Water shield", "Water smartweed",
        "Water soldier", "Water stargrass", "Water-Varing", "Water velvet", "Water-willow", "Wavyleaf gaura", "Wavyleaf thistle", "Wedelia chinensis", "Xanthium", "West indian foxtail",
        "White bryonia", "White cockle", "White ginger lily", "White horsenettle", "White-margined nightshade", "White water lily", "Whitestem distaff thistle", "White top", "Widgeon grass",
        "Wild buckwheat", "Wild caraway", "Wild carrot", "Wild chervil", "Wild garlic", "Wild marigold", "Wild morning glory", "Wild mustard", "Wild oats", "Wild proso millet", "Wild spikenard",
        "Wild tobacco", "Willow", "Wingleaf passionfruit", "Witchweed", "Woolly distaff thistle", "Wooly burdock", "Wooly cupgrass", "Wooly distaff thistle", "Wormleaf salsola", "Yellow archangel",
        "Yellow clematis", "Yellow devil hawkweed", "Yellow flag iris", "Yellow floating heart", "Yellow flower mex poppy", "Yellow himalayan raspberry", "Yellow tuft", "Yellow spinethistle",
        "Yellow starthistle", "Yellow toadflax", "Yellow water lily"
      
      ],
      "Nutrients": [
        "Aluminum",
        "Boron",
        "Cadmium",
        "Calcium",
        "Copper",
        "Iron",
        "Magnesium",
        "Manganese",
        "Molybdenum",
        "Nitrogen",
        "Phosphorus",
        "Potassium",
        "Sulfur",
        "Zinc"


      ]
    },


    "Soil Issues": {
      "Bacteria - Helpful": [
        "Actinobacteria",
        "Bacillus amyloique faciens",
        "Bacillus circulans",
        "Bacillus laterosporus",
        "Bacillus licheniformis",
        "Bacillus magaterium",
        "Bacillus papillae",
        "Bacillus polymyxa",
        "Bacillus pumilus",
        "Bacillus subtillis chitin",
        "Clostridium nitophenolicum",
        "Cyanobacteria (blue green algae)",
        "Escherichia coli",
        "Mycobacterium",
        "Notrobacterium",
        "Notrobacter winogrdsky",
        "Pseudomonas stutzer"


      ],
      "Bacteria - Harmful": [
        "Agrobacterium tumefaciens",
        "Anaerobic bacteria",
        "Ascaris lumbricoides",
        "Aspergillus",
        "Bacillus anthracis",
        "Bacillus cereus",
        "Blastomyces dermatitdus",
        "Blastomycosis",
        "Burkholderia pseudomallei",
        "Campylobacter",
        "Clostridium botulinum",
        "Clostridium perfringens",
        "Clostridium tetani",
        "Coccidioides",
        "Coccidioidomycosis",
        "Escherichia coli",
        "Escheridia coli",
        "Glicladium virens",
        "Histoplasma capsulatum",
        "Legionella pneumophilia",
        "Listeria monocytogenes",
        "Mycobacterium leprae",
        "Paracoccidiodmycosis",
        "Penicillium mameffei",
        "Pseudomonas",
        "Pseudomonas citronella",
        "Rhizobia",
        "Rhodococcus erythropolis",
        "Rhodospirillum rubrum",
        "Rhodopseudomonas palustris",
        "Sporothrix schenckii",
        "Sporotrichosis",
        "Streptomyces griseoviridis",
        "Richoderma harzianum strain T-22",

      ],

    },

    "Water Issues": {
      "Bacteria & Fungus": [
        "Anabaena",
        "Aphanizomenon",
        "Chaetomium",
        "Cholera",
        "Clostridium Botulinum",
        "Coliphage",
        "Copepods",
        "Cyanobacterial Microcysts",
        "Cylindraspemopsis",
        "Cryptosporidium",
        "Dracunculliasis- (Guinea Worm)",
        "Ecoli",
        "Enterococci",
        "Enteroviruses",
        "Giardia Lambilla",
        "Hepatitis",
        "Intestinal Worms",
        "Iron And Manganese Bacteria",
        "Legionella",
        "Malaria",
        "Naegleria Fowleri",
        "Ndm-1",
        "Nitrates",
        "Nostoc",
        "Nodularia",
        "Radionuclides",
        "Radon",
        "Rhizopus Stolonifer",
        "Rotifers",
        "Salmonella Enterica",
        "Schistosomiasis",
        "Super Bacteria",
        "Trachoma",
        "Typhoidumezakia",
        "Vibrio Vulnificus"


      ],

    },

    "Actions": {
      "Soil": [
        "Aerate",
        "Compost",
        "Cover crops",
        "Feed biochar",
        "Fertilize",
        "Manage run-off",
        "Mulch",
        "Organic matter",
        "pH",
        "Manure",
        "Recycle",
        "Rototill/tillage",
        "Soil testing",
        "Change watering",
        "Check/change frequency",
        "Clear DNA",
        "Close monitoring needed",
        "Conserve water use",
        "Dispose of hazardous waste",
        "Dowse the property",
        "Minimize chemical use",
        "Minimize tillage",
        "Pinpoint location",
        "Protect and support bees",
        "Ration water",
        "Reuse water"
      ],
      "Water": [
        "Manage run-off",
        "pH",
        "Recycle",
        "Change watering",
        "Check/change frequency",
        "Clear DNA",
        "Close monitoring needed",
        "Conserve water use",
        "Dispose of hazardous waste",
        "Dowse the property",
        "Minimize chemical use",
        "Minimize tillage",
        "Pinpoint location",
        "Protect and support bees",
        "Ration water",
        "Reuse water"
      ],
      "Crop,Plant,Tree": [
        "Allow bolt",
        "Enhance habitat for bees",
        "Fertilize",
        "Manure",
        "Mulch",
        "Rototill/tillage",
        "Plant native species",
        "Provide shade",
        "Prune",
        "Recycle",
        "Replant/repot",
        "Rotate crop",
        "Change watering",
        "Check/change frequency",
        "Clear DNA",
        "Close monitoring needed",
        "Conserve water use",
        "Dispose of hazardous waste",
        "Dowse the property",
        "Minimize chemical use",
        "Minimize tillage",
        "Pinpoint location",
        "Ration water",
        "Reuse water"
      ],
      "Air": [
        "Minimize chemical use",
        "Check/change frequency",
        "Dowse the property",
        "Change watering",
        "Clear DNA",
        "Close monitoring needed",
        "Conserve water use",
        "Minimize tillage",
        "Pinpoint location",
        "Protect and support bees",
        "Ration water",
        "Reuse water"
      ],


    }




  }
}