import React, { useState } from 'react';
import { Typography, Card, CardContent, CardHeader, Grid, Button } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { Link } from 'react-router-dom';

import PdfDialog from '../PdfDialog/PdfDialog';
const FreedomTextPage: React.FC = () => {
    const [freedomDialogOpen, setFreedomDialogOpen] = useState(false);
    const [freedomWSDialogOpen, setFreedomWSDialogOpen] = useState(false);

    const handleClose = () => {
        setFreedomDialogOpen(false);
        setFreedomWSDialogOpen(false);
    }

    const margin = 20;
    const textVarient = 'h6';
    return (
        <React.Fragment>

            <Grid container sx={{ marginTop: `${margin}px` }} justifyContent="center">
                <Grid item xs={12} md={12} sx={{ mb: '20px' }}>
                    <Card sx={{ mx: '20px' }} >
                        <CardHeader sx={{ paddingBottom: '0px', }} title="Welcome to Freedom Style" />
                        <CardContent>
                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                The Freedom Style Module, a 5-week process within the Trailblazing Communications
                                Whole Life System, requires that you wear the provided pendant the entire time. This is
                                important to set you up to have the best and most effective results. This module allows you
                                to set a personal intention, such as addressing self-sabotage, perfectionism, physical,
                                emotional, spiritual or situational issues. It is designed to tackle deep-rooted, complex
                                issues that have been standing in our way of making change and being the best and
                                happiest versions of ourselves. If you are uncertain what to work or focus on remember
                                that you use the digging in process and ask, “what would be in my highest good right now to
                                work and clear to help me whatever it is that you are seeking?

                            </Typography>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                        <b>Items Needed:</b>
                                        <ul>
                                            <li>Pendant</li>
                                            <li>
                                                Freedom Worksheet
                                                <Button size="small" sx={{ margin: 0, textTransform: 'none' }} onClick={() => { setFreedomWSDialogOpen(true) }}>
                                                    <DescriptionIcon sx={{ margin: 0 }} fontSize="small" />
                                                    view
                                                </Button>
                                            </li>
                                            <li>Statement of Intent
                                                <Button size="small" sx={{ margin: 0, textTransform: 'none' }} onClick={() => { setFreedomDialogOpen(true) }}>
                                                    <DescriptionIcon sx={{ margin: 0 }} fontSize="small" />
                                                    view
                                                </Button>
                                            </li>
                                        </ul>
                                        {/* Statement of Intent: Begin the module by clearly stating your personal intention. */}
                                    </Typography>
                                </Grid> {/* This closing tag was missing */}
                                <Grid item xs={12} md={6}>
                                    <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                        <b>Timeframe:</b>
                                        <ul>
                                            <li>Total Duration: 5 weeks</li>
                                        </ul>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>


                </Grid>
                <Grid item xs={12} md={12} sx={{ mb: '20px' }} >

                    <Card sx={{ mx: '20px' }} >
                        <CardHeader sx={{ paddingBottom: '0px' }} title="Instructions" />
                        <CardContent>


                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                You may go directly into this Module with an intention. Your intention can be anything! You
                                may want to clear self-sabotage, perfectionism, a physical issue, a spiritual issue, or a
                                situation issue. This is the process to get at those sticky layered issues that are a little
                                harder to let go of.
                            </Typography>


                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                To begin, put on your pendant and then print out the two worksheets. You will then need to
                                fill in the first blank as to what the issue or problem is. This line could be the diagnosis or
                                overarching issue. Next you will go to the
                                <Link to="?module=Freedom&item=Resonance%2C+Frequency+%26+Layers">Resonance, Frequency and Layers Chart</Link>.
                                Find the number of layers for this process using the smaller portion of this chart and document your
                                answer in the accompanying line on the worksheet.
                            </Typography>

                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                If you got the answer is 4 layers (but it could be many more), on the next worksheet entry
                                line write this is layer 1 of 4 layers. This will change with each layer (layer 2 of 4 and so on).
                                Next you will move to the digging in process to find the Freedom statement for this layer,
                                (See the video in the video section of the training center). You will finding a new Freedom
                                statement for each layer of this process as you go along.
                            </Typography>

                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                Next you will use this same chart and ask, what is the frequency of this statement as it
                                aligns with my intentions for this session? If you get anything less than 100%, you will need
                                to change and tweak until you get at least a 100%. This will assure that you are getting the
                                most powerful statement to get the best results from this session process. Your freedom
                                statement with change with each layer but your issue or problem with stay the same
                                because that is what you are pulling a part in the layered process.
                            </Typography>
                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                Next you will go to the <Link to="?module=Freedom&item=Incongruence">Incongruence Chart</Link>.This is where you will see what is standing in
                                the way of you being at that 100% resonance with the freedom statement. Document all
                                the answers that come up. Document them in a format that when reading these
                                statements to start your process it will read like a sentence. You may first be taken to
                                beliefs. Write it out as…..the belief that <u><b> whatever answer that you get in that category</b></u>.
                                Then you might be taken to self-love issue. Write that on the worksheet as….self-love issue
                                of <u><b>whatever comes up</b></u> etc.
                                Get all the answers that apply until you get a no to the
                                question, do I need another answer?
                            </Typography>

                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                Next you will go to the <Link to="?module=Freedom&item=Angels+%26+Guides">Angels and Guides Chart</Link> and find all that apply. Document your
                                helpers on the worksheet. Next you will fill in today’s date. Then visit your calendar and find
                                the date that is 5 weeks from today. That will be the day that you do this all over again,
                                finding the next layer of your process. Make sure and mark that date in your calendar as it is
                                easy to forget that you even have this process going as once you start it you do not have to
                                do anything with until the date of the next layer. You can only do one Freedom process at a
                                time. It is very powerful, and you do not want to overload your system.

                            </Typography>
                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                You can now transfer your answers to the 
                                <Button size="large" sx={{ margin: 0, padding: 0.5, textTransform: 'none', textDecoration: 'underline' }} onClick={() => { setFreedomDialogOpen(true) }}>
                                     Statement of Intent Worksheet<DescriptionIcon sx={{ margin: 0 }} fontSize="small" />
                                </Button>. Just follow the
                                prompts below the lines to know what answers go where. When you have completed the
                                transfer, you will then recite the statement starting with I, your name, all the way to for this I
                                am grateful and thank the angels and guides for their unconditional love. Reading this
                                statement activates this process for you or for whomever this process is being done for. If
                                you are doing this process for someone else, they must read the statement for themselves.
                                If you are doing for a minor child, you may read on their behalf and state so. You need not
                                do anything else other than take really good care of yourself during the process. If shifting
                                becomes a little difficult as you are releasing what no longer serves you, you can go to the  <Link to="?module=Freedom&item=Self-Care">Self-Care Chart </Link>
                                 and check in ot see what action may be helpful during this shifting
                                process.
                            </Typography>
                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                After the 5-week period is up, you will go back to this process and print out another set of
                                worksheets. You will then put the same issue or problem in the first line as you did in the
                                first session and then you will again do the digging in process to find your Freedom
                                statement. You will advance the layers on the layers to layer 2 of 4, etc. With each layer you
                                will notice your resonance rising until by the end of the last layer your resonance should
                                now be at 100%, Nice work!!! Each layer is a 5-week process.
                            </Typography>
                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                The <Link to="?module=Freedom&item=Animal+Syncing">Animal Syncing Chart </Link>
                                will be used if you are doing this process for an animal to see
                                who or what energetically, that they are syncing up with. Like in the Trauma Animal Style
                                Program, you will then want to investigate working on the person that is the syncing partner
                                to the animal to remove the entire energy pattern. See the video in the learning center that
                                accompanies this phenomenon.
                            </Typography>
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>

            <PdfDialog open={freedomWSDialogOpen} onClose={handleClose} pdfName="Freedom%20WS.pdf" />
            <PdfDialog open={freedomDialogOpen} onClose={handleClose} pdfName="Freedom.pdf" />
        </React.Fragment>
    );
}
export default FreedomTextPage;
