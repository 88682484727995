import { ChartPagesType } from '../types'
import { NutritionPopUps } from './popups'

export const NutritionCharts: ChartPagesType = {
  "Nutrition": {
    chart: [
      { id: 0, label: 'Body Climate', value: 1, color: '#571B1E' },
      { id: 1, label: 'Measure/Servings', value: 1, color: '#8A2528' },
      { id: 2, label: 'Time Charts', value: 1, color: '#C3102F' },
      { id: 3, label: 'Protein', value: 1, color: '#E51A23' },
      { id: 4, label: 'Vegetables', value: 1, color: '#F25B21' },
      { id: 5, label: 'Fruits', value: 1, color: '#F7971D' },
      { id: 6, label: 'Grains', value: 1, color: '#EFB211' },
      { id: 7, label: 'Dairy', value: 1, color: '#FFE616' },
      { id: 8, label: 'Oils & Additives', value: 1, color: '#C1D72F' },
      { id: 9, label: 'Fats & Essential Fatty Acids', value: 1, color: '#8BC63E' },
      { id: 10, label: 'Sweeteners', value: 1, color: '#46AA42' },
      { id: 11, label: 'Spices & Cooking', value: 1, color: '#357B2B' },
      { id: 12, label: 'Beverages & Condiments', value: 1, color: '#00653A' },
      { id: 13, label: 'Vitamins & Minerals', value: 1, color: '#006b6E' },
      { id: 14, label: 'Herbs', value: 1, color: '#0083A9' },
      { id: 15, label: 'Amino Acids & Supplements', value: 1, color: '#0077C0' },
      { id: 16, label: 'Detox', value: 1, color: '#135CAB' },
      { id: 17, label: 'Medication', value: 1, color: '#263692' },
      { id: 18, label: 'Self Care', value: 1, color: '#8C63AA' },
       { id: 19, label: 'Exercise', value: 1, color: '#692C91' },
      { id: 20, label: 'Eating Triggers', value: 1, color: '#6C1F7D' },
      { id: 21, label: 'Emotions', value: 1, color: '#862074' },
      { id: 22, label: 'Meals', value: 1, color: '#842936' },
      { id: 24, label: 'Cravings', value: 1, color: '#766340' },
    ],

    parent: "All Modules",
    level: 1,
    subPages: {
      "Protein": {
        chart: [
          { id: 0, label: 'Animal Protein', value: 1, color: '#E51A23' },
          { id: 1, label: 'Plant Protein', value: 1, color: '#F8C1C4' }

        ],
        parent: "Nutrition",
        level: 2,
        textSizeOverride: 26,
        subPages: {}
      },
      "Detox": {
        chart: [
          { id: 0, label: 'Colon', value: 1, color: '#135CAB',popupContent: NutritionPopUps.Nutrition.Detoxification.Colon },
          { id: 1, label: 'Liver', value: 1, color: '#C0D3E8' ,popupContent: NutritionPopUps.Nutrition.Detoxification.Liver},
          { id: 2, label: 'Gallbladder', value: 1, color: '#135CAB' ,popupContent: NutritionPopUps.Nutrition.Detoxification.Gallbladder},
          { id: 3, label: 'Kidney', value: 1, color: '#C0D3E8' ,popupContent: NutritionPopUps.Nutrition.Detoxification.Kidney},
          { id: 4, label: 'Lung', value: 1, color: '#135CAB',popupContent: NutritionPopUps.Nutrition.Detoxification.Lung },
          { id: 5, label: 'Skin', value: 1, color: '#C0D3E8',popupContent: NutritionPopUps.Nutrition.Detoxification.Skin },
          { id: 6, label: 'Lymphatic', value: 1, color: '#135CAB',popupContent: NutritionPopUps.Nutrition.Detoxification.Lymphatic },
          { id: 7, label: 'Parasite', value: 1, color: '#C0D3E8' ,popupContent: NutritionPopUps.Nutrition.Detoxification.Parasite},
          { id: 8, label: 'Candida', value: 1, color: '#135CAB',popupContent: NutritionPopUps.Nutrition.Detoxification.Candida },
          { id: 9, label: 'Metal', value: 1, color: '#C0D3E8' ,popupContent: NutritionPopUps.Nutrition.Detoxification.Metal},
          { id: 10, label: 'Master', value: 1, color: '#135CAB' ,popupContent: NutritionPopUps.Nutrition.Detoxification.Master},
          { id: 11, label: 'Juicing', value: 1, color: '#C0D3E8' ,popupContent: NutritionPopUps.Nutrition.Detoxification.Juicing},
          { id: 12, label: 'Raw/Alkaline', value: 1, color: '#135CAB',popupContent: NutritionPopUps.Nutrition.Detoxification.Raw },
          { id: 13, label: 'Fasting', value: 1, color: '#C0D3E8' ,popupContent: NutritionPopUps.Nutrition.Detoxification.Fasting},
          { id: 14, label: 'Homeopathic', value: 1, color: '#135CAB',popupContent: NutritionPopUps.Nutrition.Detoxification.Homeopathic },
          { id: 15, label: 'Intermittent', value: 1, color: '#C0D3E8',popupContent: NutritionPopUps.Nutrition.Detoxification.Intermittent },
          { id: 16, label: 'Sugar', value: 1, color: '#135CAB',popupContent: NutritionPopUps.Nutrition.Detoxification.Sugar },
          { id: 17, label: 'Bentonite Clay', value: 1, color: '#C0D3E8' ,popupContent: NutritionPopUps.Nutrition.Detoxification.Bentonite},
          { id: 18, label: 'Detox Gold', value: 1, color: '#135CAB' ,popupContent: NutritionPopUps.Nutrition.Detoxification.Gold},
          { id: 19, label: 'Other', value: 1, color: '#C0D3E8' ,popupContent: NutritionPopUps.Nutrition.Detoxification.Other},

        ],
        parent: "Nutrition",
        level: 2,
        subPages: {}
      },
      "Exercise": {
        chart: [

        ],
        parent: "Nutrition",
        level: 2,
        subPages: {}
      },
      "Eating Triggers": {
        chart: [
          { id: 0, label: 'Feelings Of Emptiness', value: 1, color: '#6C1F7D',popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo  },
          { id: 1, label: 'Relationships', value: 1, color: '#D7C3DC' ,popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 2, label: 'Boredom', value: 1, color: '#6C1F7D' ,popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 3, label: 'Self-Sabotage', value: 1, color: '#D7C3DC' ,popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 4, label: 'Avoidance', value: 1, color: '#6C1F7D',popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 5, label: 'Worry', value: 1, color: '#D7C3DC' ,popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 6, label: 'Socializing', value: 1, color: '#6C1F7D',popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo },
          { id: 7, label: 'Job', value: 1, color: '#D7C3DC' ,popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 8, label: 'Mood', value: 1, color: '#6C1F7D' ,popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 9, label: 'Rewards', value: 1, color: '#D7C3DC' ,popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 10, label: 'Cravings', value: 1, color: '#6C1F7D',popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo },
          { id: 11, label: 'Emotions', value: 1, color: '#D7C3DC' ,popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 12, label: 'Environments', value: 1, color: '#6C1F7D',popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo },
          { id: 13, label: 'Certain Foods', value: 1, color: '#D7C3DC',popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo },
          { id: 14, label: 'Stress', value: 1, color: '#6C1F7D' ,popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 15, label: 'Habits', value: 1, color: '#D7C3DC',popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo },
          { id: 16, label: 'Friend', value: 1, color: '#6C1F7D',popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo },
          { id: 17, label: 'Spouse', value: 1, color: '#D7C3DC' ,popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 18, label: 'Children', value: 1, color: '#6C1F7D',popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo },
          { id: 19, label: 'Social Influence', value: 1, color: '#D7C3DC' ,popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 20, label: 'Trigger Foods', value: 1, color: '#6C1F7D' ,popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo},
          { id: 21, label: 'Stuffed Feelings', value: 1, color: '#D7C3DC',popupContent: NutritionPopUps.Nutrition.EatingTriggers.NoInfo }
        ],
        parent: "Nutrition",
        level: 2,
        subPages: {}
      },
      "Emotions": {
        chart: [
          { id: 0, label: 'Happy', value: 1, color: '#A14176' },
          { id: 1, label: 'Sad', value: 1, color: '#E7D0DD' },
          { id: 2, label: 'Angry', value: 1, color: '#A14176' },
          { id: 3, label: 'Scared', value: 1, color: '#E7D0DD' },
          { id: 4, label: 'Confused', value: 1, color: '#A14176' },
          { id: 5, label: 'Bored', value: 1, color: '#E7D0DD',popupContent: NutritionPopUps.Nutrition.Emotions.Bored   },
          { id: 6, label: 'Attn. Issue', value: 1, color: '#A14176',popupContent: NutritionPopUps.Nutrition.Emotions.AttnIssue  },
          { id: 7, label: 'Need Space', value: 1, color: '#E7D0DD',popupContent: NutritionPopUps.Nutrition.Emotions.NeedSpace },
          { id: 8, label: 'Self-Esteem', value: 1, color: '#A14176' ,popupContent: NutritionPopUps.Nutrition.Emotions.LSE },
          { id: 9, label: 'Grieving', value: 1, color: '#E7D0DD',popupContent: NutritionPopUps.Nutrition.Emotions.Grieving  },
          { id: 10, label: 'Worried', value: 1, color: '#A14176' ,popupContent: NutritionPopUps.Nutrition.Emotions.Worried },
          { id: 11, label: 'Jealous', value: 1, color: '#E7D0DD' ,popupContent: NutritionPopUps.Nutrition.Emotions.Jealous },
          { id: 12, label: 'TFG', value: 1, color: '#A14176',popupContent: NutritionPopUps.Nutrition.Emotions.TakenForGranted  },
          { id: 13, label: 'Shame', value: 1, color: '#E7D0DD',popupContent: NutritionPopUps.Nutrition.Emotions.Shame  },
          { id: 14, label: 'W/O Dir.', value: 1, color: '#A14176',popupContent: NutritionPopUps.Nutrition.Emotions.WithoutDirection  },
          { id: 15, label: 'Need To Belong', value: 1, color: '#E7D0DD',popupContent: NutritionPopUps.Nutrition.Emotions.NeedToBelong  },
          { id: 16, label: 'Preoccupied', value: 1, color: '#A14176',popupContent: NutritionPopUps.Nutrition.Emotions.Preoccupied  },
          { id: 17, label: 'Overwhelmed/Stressed', value: 1, color: '#E7D0DD',popupContent: NutritionPopUps.Nutrition.Emotions.Overwhelmed  },
          { id: 18, label: 'Emotional Sponging', value: 1, color: '#A14176' ,popupContent: NutritionPopUps.Nutrition.Emotions.EmotionalSponging },
        ],
        parent: "Nutrition",
        level: 2,
        subPages: {}
      },
      "Meals": {
        chart: [
          { id: 0, label: 'Breakfast', value: 1, color: '#842936',popupContent: NutritionPopUps.Nutrition.Meals.NoInfo },
          { id: 1, label: 'A.M. Snack', value: 1, color: '#DEC5C9',popupContent: NutritionPopUps.Nutrition.Meals.NoInfo },
          { id: 2, label: 'Lunch', value: 1, color: '#842936',popupContent: NutritionPopUps.Nutrition.Meals.NoInfo },
          { id: 3, label: 'P.M. Snack', value: 1, color: '#DEC5C9' ,popupContent: NutritionPopUps.Nutrition.Meals.NoInfo},
          { id: 4, label: 'Dinner/Supper', value: 1, color: '#842936' ,popupContent: NutritionPopUps.Nutrition.Meals.NoInfo},
          { id: 5, label: 'Evening Snack', value: 1, color: '#DEC5C9',popupContent: NutritionPopUps.Nutrition.Meals.NoInfo}
        ],
        parent: "Nutrition",
        level: 2,
        subPages: {}
      },

      "Cravings": {
        chart: [
          { id: 0, label: 'Soda/Carbonated', value: 1, color: '#766340',popupContent: NutritionPopUps.Nutrition.Cravings.Soda  },
          { id: 1, label: 'Candy/Sugar', value: 1, color: '#DAD5CC',popupContent: NutritionPopUps.Nutrition.Cravings.Candy  },
          { id: 2, label: 'Bread/Pasta', value: 1, color: '#766340' ,popupContent: NutritionPopUps.Nutrition.Cravings.Bread },
          { id: 3, label: 'Salty', value: 1, color: '#DAD5CC',popupContent: NutritionPopUps.Nutrition.Cravings.Salty  },
          { id: 4, label: 'Crunchy', value: 1, color: '#766340',popupContent: NutritionPopUps.Nutrition.Cravings.Crunchy  },
          { id: 5, label: 'Fatty/Oily', value: 1, color: '#DAD5CC',popupContent: NutritionPopUps.Nutrition.Cravings.Fatty  },
          { id: 6, label: 'Spicy', value: 1, color: '#766340',popupContent: NutritionPopUps.Nutrition.Cravings.Spicey  },
          { id: 7, label: 'Cool Drinks', value: 1, color: '#DAD5CC',popupContent: NutritionPopUps.Nutrition.Cravings.CoolDrinks  },
          { id: 8, label: 'Coffee/Tea', value: 1, color: '#766340',popupContent: NutritionPopUps.Nutrition.Cravings.CoffeeTea  },
          { id: 9, label: 'Chocolate', value: 1, color: '#DAD5CC' ,popupContent: NutritionPopUps.Nutrition.Cravings.Chocolate },
          { id: 10, label: 'Alcohol', value: 1, color: '#766340',popupContent: NutritionPopUps.Nutrition.Cravings.Alcohol  },
          { id: 11, label: 'Burned Food', value: 1, color: '#DAD5CC',popupContent: NutritionPopUps.Nutrition.Cravings.Burned  },
          { id: 12, label: 'Dairy', value: 1, color: '#766340' ,popupContent: NutritionPopUps.Nutrition.Cravings.Dairy },
          { id: 13, label: 'Tobacco', value: 1, color: '#DAD5CC',popupContent: NutritionPopUps.Nutrition.Cravings.Tobacco  },
          { id: 14, label: 'Pref. For Liquids', value: 1, color: '#766340' ,popupContent: NutritionPopUps.Nutrition.Cravings.PrefLiquid },
          { id: 15, label: 'Pref. For Solids', value: 1, color: '#DAD5CC',popupContent: NutritionPopUps.Nutrition.Cravings.PrefSolid  },
          { id: 16, label: 'Premenstrual', value: 1, color: '#766340',popupContent: NutritionPopUps.Nutrition.Cravings.Premenstrual  },
          { id: 17, label: 'General Overreating', value: 1, color: '#DAD5CC' ,popupContent: NutritionPopUps.Nutrition.Cravings.Overeating },
          { id: 18, label: 'Lack Of Appetite', value: 1, color: '#766340' ,popupContent: NutritionPopUps.Nutrition.Cravings.Lack }
        ],
        parent: "Nutrition",
        level: 2,
        subPages: {}
      },

    }
  }
}
