import React from 'react';
import { Typography } from '@mui/material';
import ModuleChart from '../ModuleChart/ModuleChart';
import { ChartPageType } from '../../data/types';
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../utils';
import { Grid, Box } from '@mui/material';

interface TimeChartsProps {
    height: number;
    width: number;
}

const TimeUnitsChartData: ChartPageType = {
    chart: [
        { label: 'Spring', value: 1, id: 0, color: '#C3102F' },
        { label: 'Summer', value: 1, id: 1, color: '#EEBBB0' },
        { label: 'Fall', value: 1, id: 2, color: '#C3102F' },
        { label: 'Winter', value: 1, id: 3, color: '#EEBBB0' },
    ],
    innerChart: [
        { label: 'Minutes', value: 1, id: 4, color: '#C3102F' },
        { label: 'Hours', value: 1, id: 5, color: '#EEBBB0' },
        { label: 'Days', value: 1, id: 6, color: '#C3102F' },
        { label: 'Weeks', value: 1, id: 7, color: '#EEBBB0' },
        { label: 'Months', value: 1, id: 8, color: '#C3102F' },
        { label: 'Years', value: 1, id: 9, color: '#EEBBB0' },
    ],
    innerInnerChart: [
        { label: 'Morning', value: 1, id: 10, color: '#C3102F' },
        { label: 'Afternoon', value: 1, id: 11, color: '#EEBBB0' },
        { label: 'Evening', value: 1, id: 12, color: '#C3102F' },
    ],
    level: 0,
    subPages: {},
    disableClick: true,
    textSizeOverride: 15,
}

const TwelveHourTime: ChartPageType = {
    chart: [
        { label: '12', value: 1, id: 0, color: '#C3102F', disableTextRotation: true },
        { label: '1', value: 1, id: 1, color: '#F0F0F0', disableTextRotation: true },
        { label: '2', value: 1, id: 2, color: '#ECDCDE', disableTextRotation: true },
        { label: '3', value: 1, id: 9, color: '#E8C7CD', disableTextRotation: true },
        { label: '4', value: 1, id: 10, color: '#E4B3BB', disableTextRotation: true },
        { label: '5', value: 1, id: 11, color: '#E09FAA', disableTextRotation: true },
        { label: '6', value: 1, id: 12, color: '#DC8A98', disableTextRotation: true },
        { label: '7', value: 1, id: 13, color: '#D77687', disableTextRotation: true },
        { label: '8', value: 1, id: 14, color: '#D36175', disableTextRotation: true },
        { label: '9', value: 1, id: 15, color: '#CF4D64', disableTextRotation: true },
        { label: '10', value: 1, id: 16, color: '#CB3952', disableTextRotation: true },
        { label: '11', value: 1, id: 17, color: '#C72441', disableTextRotation: true },
    ],
    level: 0,
    subPages: {},
    disableClick: true,
    fullOneEightyChart: true,
    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >AM or PM</Typography>

}

// const AmOrPMData: ChartPageType = {
//     chart: [
//         { label: 'AM', value: 1, id: 0, color: '#C3102F' },
//         { label: 'PM', value: 1, id: 1, color: '#EEBBB0' },
//     ],
//     level: 0,
//     subPages: {},
//     disableClick: true,

// }


const TimeChartsPage: React.FC<TimeChartsProps> = ({ height, width }) => {
    const chart_height = height;
    const chart_width = width / 2;

    return (<Box style={{ height: `${height*.99}px`, justifyContent: 'center', alignItems: 'center' }}>
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <ModuleChart
                    height={chart_height / 3*2}
                    width={chart_width}
                    chartData={TimeUnitsChartData}
                    chartModule={"Nutrition"}
                    item={"Time Charts"}
                    subitem={null} />
            </Grid>
            <Grid item xs={4}>
                <ModuleChart
                    height={chart_height / 2}
                    width={chart_width}
                    chartData={TwelveHourTime}
                    chartModule={"Nutrition"}
                    item={"Time Charts"}
                    subitem={null} />
            </Grid>
        </Grid>

    </Box>);
}
export default TimeChartsPage;