import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export const NoInfoPopUp = () => {
    return (<Typography>No information available</Typography>);
};

export const WordsPopUp = ({ words }: { words: string[] }) => {
    return (
        <div>
            {words.map((word, index) => {
                return <Typography key={index} gutterBottom>{word}</Typography>;;
            })}
        </div>
    );
}

export const NutritionalIssuePopUp = () => {
    return (
        <div>
            <Typography>See &nbsp;
                <Link to="/?module=Animal&item=Nutrition">Nutrition Chart </Link></Typography>
            <Typography> or </Typography>
            <Typography> Consult <Link to="/?module=Nutrition">Nutrition Style Module </Link></Typography>
        </div>
    );

}
