import { NaturePopUps } from './popups';
import { ChartPagesType } from '../types'
import { Typography } from "@mui/material";
import { SUB_TEXT_TYPOGRAPHY_PROPS } from "../../utils";

export const NatureCharts: ChartPagesType = {
  "Nature": {
    chart: [
      { id: 0, label: 'Crop, Plant & Tree Issues', labelWithFormatting: 'Crop, Plant &\n Tree Issues', value: 1, color: '#D52329' },
      { id: 1, label: 'Soil Issues', value: 1, color: '#FF7B27' },
      { id: 2, label: 'Water Issues', value: 1, color: '#FFAE2D' },
      { id: 3, label: 'Air Issues', value: 1, color: '#4BAD43' },
      { id: 4, label: 'Actions', value: 1, color: '#008E9C' },
      { id: 5, label: 'Clearing', value: 1, color: '#0D2D94' },
      { id: 6, label: 'Syncing', value: 1, color: '#0F1D6D' },
      { id: 7, label: 'Time Chart', value: 1, color: '#502795' },


    ],

    parent: "All Modules",
    level: 1,
    subPages: {
      "Crop, Plant & Tree Issues": {
        chart: [

          { id: 0, label: 'Bacteria & Fungus', value: 1, color: '#D52329' },
          { id: 1, label: 'Soil Conservation', value: 1, color: '#F4C8C9', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.NoInfo },
          { id: 2, label: 'Improper Planting', value: 1, color: '#D52329', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.NoInfo },
          { id: 3, label: 'Plant Diseases', value: 1, color: '#F4C8C9' },
          { id: 4, label: 'Fertilizer', value: 1, color: '#D52329', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.NoInfo },
          { id: 5, label: 'Water', value: 1, color: '#F4C8C9', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.Water },
          { id: 6, label: 'Pests', value: 1, color: '#D52329' },
          { id: 7, label: 'Weeds', value: 1, color: '#F4C8C9' },
          { id: 8, label: 'Poor Seeds', value: 1, color: '#D52329', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.NoInfo },
          { id: 9, label: 'Erosion', value: 1, color: '#F4C8C9', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.NoInfo },
          { id: 10, label: 'Stress', value: 1, color: '#D52329', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.Stress },
          { id: 11, label: 'Grnd. Water', value: 1, color: '#F4C8C9', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.GroundWater },
          { id: 12, label: 'Disorders', value: 1, color: '#D52329', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.NoInfo },
          { id: 13, label: 'Nutrients', value: 1, color: '#F4C8C9' },
          { id: 14, label: 'Root Prob.', value: 1, color: '#D52329', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.RootProb },
          { id: 15, label: 'Inv. Species', value: 1, color: '#F4C8C9', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.InvSpecies },
          { id: 16, label: 'Temperature', value: 1, color: '#D52329', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.NoInfo },
          { id: 17, label: 'Land Degradation', value: 1, color: '#F4C8C9', popupContent: NaturePopUps.Nature.CropPlantTreeIssues.NoInfo },
          { id: 18, label: 'Frequency Change', value: 1, color: '#D52329' },
        ],
        parent: "Nature",
        level: 2,
        subPages: {
          "Frequency Change": {
            chart: [
              { id: 0, label: '-100', value: 1, color: '#8A2528' },
              { id: 1, label: '-90', value: 1, color: '#953C3D' },
              { id: 2, label: '-80', value: 1, color: '#A15053' },
              { id: 3, label: '-70', value: 1, color: '#AD6668' },
              { id: 4, label: '-60', value: 1, color: '#B87C7E' },
              { id: 5, label: '-50', value: 1, color: '#C49293' },
              { id: 6, label: '-40', value: 1, color: '#D0A8A9' },
              { id: 7, label: '-30', value: 1, color: '#DCBEBE' },
              { id: 8, label: '-20', value: 1, color: '#E8D4D4' },
              { id: 9, label: '-10', value: 1, color: '#F3E9E9' },
              { id: 10, label: '0', value: 1, color: '#F3E9E9' },
              { id: 11, label: '10', value: 1, color: '#F3E9E9' },
              { id: 12, label: '20', value: 1, color: '#E8D4D4' },
              { id: 13, label: '30', value: 1, color: '#DCBEBE' },
              { id: 14, label: '40', value: 1, color: '#D0A8A9' },
              { id: 15, label: '50', value: 1, color: '#C49293' },
              { id: 16, label: '60', value: 1, color: '#B87C7E' },
              { id: 17, label: '70', value: 1, color: '#AD6668' },
              { id: 18, label: '80', value: 1, color: '#A15053' },
              { id: 19, label: '90', value: 1, color: '#953C3D' },
              { id: 20, label: '100', value: 1, color: '#8A2528' }
            ],
            disableClick: true,
            parent: "Nature",
            level: 2,
            subPages: {}
          },
        }
      },

      "Soil Issues": {
        chart: [
          { id: 0, label: 'Bacteria - Helpful', value: 1, color: '#FF7B27'},
          { id: 1, label: 'Too Wet/Dry', value: 1, color: '#FFDEC9', popupContent: NaturePopUps.Nature.SoilIssues.NoInfo },
          { id: 2, label: 'Erosion', value: 1, color: '#FF7B27', popupContent: NaturePopUps.Nature.SoilIssues.NoInfo },
          { id: 3, label: 'Acidic/Alkaline', labelWithFormatting: 'Soil pH', value: 1, color: '#FFDEC9' },
          { id: 4, label: 'Salt Content', value: 1, color: '#FF7B27', popupContent: NaturePopUps.Nature.SoilIssues.NoInfo },
          { id: 5, label: 'Organic Matter', value: 1, color: '#FFDEC9', popupContent: NaturePopUps.Nature.SoilIssues.NoInfo },
          { id: 6, label: 'Actions', value: 1, color: '#FF7B27', onClickNav: "/?module=Nature&item=Actions" },
          { id: 7, label: 'Compaction', value: 1, color: '#FFDEC9', popupContent: NaturePopUps.Nature.SoilIssues.NoInfo },
          { id: 8, label: 'Wind', value: 1, color: '#FF7B27', popupContent: NaturePopUps.Nature.SoilIssues.NoInfo },
          { id: 9, label: 'Gravity', value: 1, color: '#FFDEC9', popupContent: NaturePopUps.Nature.SoilIssues.NoInfo },
          { id: 10, label: 'Low Quality', value: 1, color: '#FF7B27', popupContent: NaturePopUps.Nature.SoilIssues.NoInfo },
          { id: 11, label: 'Toxin Build-up', value: 1, color: '#FFDEC9', popupContent: NaturePopUps.Nature.SoilIssues.NoInfo },
          { id: 12, label: 'Bacteria - Harmful', value: 1, color: '#FF7B27' }
        ],
        parent: "Nature",
        level: 2,
        subPages: {
          //This is a third level chart in soil - PH
          "Acidic/Alkaline": {
            chart: [
              { id: 0, label: '1 Very Acidic', value: 1, color: '#ff7b27' },
              { id: 1, label: '2', value: 1, color: '#fe8436' },
              { id: 2, label: '3', value: 1, color: '#fd8d46' },
              { id: 3, label: '4', value: 1, color: '#fc9655' },
              { id: 4, label: '5 - Optimal', value: 1, color: '#fa9f65' },
              { id: 5, label: '6 - Optimal', value: 1, color: '#f9a874' },
              { id: 6, label: '7 - Optimal', value: 1, color: '#f8b184'},
              { id: 7, label: '8 - Optimal', value: 1, color: '#f7ba93' },
              { id: 8, label: '9', value: 1, color: '#f6c3a3' },
              { id: 9, label: '10', value: 1, color: '#f5ccb2' },
              { id: 10, label: '11', value: 1, color: '#f3d5c2' },
              { id: 11, label: '12', value: 1, color: '#f2ded1' },
              { id: 12, label: '13', value: 1, color: '#f1e7e1' },
              { id: 13, label: '14 Very Alkaline', value: 1, color: '#f0f0f0'}
            ],
            parent: "Soil Issues",
            level: 3,
            disableClick: true,
            subPages : {}
          },

        },
      },


      "Water Issues": {
        chart: [
          { id: 0, label: 'Not Enough', value: 1, color: '#FFAE2D', popupContent: NaturePopUps.Nature.WaterIssues.NotEnough },
          { id: 1, label: 'Too Much', value: 1, color: '#FEEAC6', popupContent: NaturePopUps.Nature.WaterIssues.TooMuch },
          { id: 2, label: 'Pollution', value: 1, color: '#FFAE2D', popupContent: NaturePopUps.Nature.WaterIssues.Pollution },
          { id: 3, label: 'Run-off', value: 1, color: '#FEEAC6', popupContent: NaturePopUps.Nature.WaterIssues.NoInfo },
          { id: 4, label: 'Hypoxia', value: 1, color: '#FFAE2D', popupContent: NaturePopUps.Nature.WaterIssues.NoInfo },
          { id: 5, label: 'PH Issues', value: 1, color: '#FEEAC6', popupContent: NaturePopUps.Nature.WaterIssues.NoInfo },
          { id: 6, label: 'Weeds & Algae', value: 1, color: '#FFAE2D', onClickNav: "/?module=Nature&item=Crop%2C+Plant+%26+Tree+Issues&subitem=Weeds"},
          { id: 7, label: 'Sedimentation', value: 1, color: '#FEEAC6', popupContent: NaturePopUps.Nature.WaterIssues.NoInfo },
          { id: 8, label: 'Temperature', value: 1, color: '#FFAE2D', popupContent: NaturePopUps.Nature.WaterIssues.NoInfo },
          { id: 9, label: 'Bacteria & Fungus', value: 1, color: '#FEEAC6'},
          { id: 10, label: 'Metals', value: 1, color: '#FFAE2D', popupContent: NaturePopUps.Nature.WaterIssues.NoInfo },
          { id: 11, label: 'Chlorophyl A or B', value: 1, color: '#FEEAC6', popupContent: NaturePopUps.Nature.WaterIssues.ChlorophylAorB }

        ],
        parent: "Nature",
        level: 2,
        subPages: {}
      },

      "Air Issues": {
        chart: [

          { id: 0, label: 'Infectious Agents', value: 1, color: '#4BAD43', popupContent: NaturePopUps.Nature.AirIssues.NoInfo },
          { id: 1, label: 'Climate/Rainfall', value: 1, color: '#D2EAD0', popupContent: NaturePopUps.Nature.AirIssues.NoInfo },
          { id: 2, label: 'Humidity', value: 1, color: '#4BAD43', popupContent: NaturePopUps.Nature.AirIssues.NoInfo },
          { id: 3, label: 'Air Pressure', value: 1, color: '#D2EAD0', popupContent: NaturePopUps.Nature.AirIssues.NoInfo },
          { id: 4, label: 'Wind', value: 1, color: '#4BAD43', popupContent: NaturePopUps.Nature.AirIssues.NoInfo },
          { id: 5, label: 'Terrain', value: 1, color: '#D2EAD0', popupContent: NaturePopUps.Nature.AirIssues.NoInfo },
          { id: 6, label: 'Cloud Cover', value: 1, color: '#4BAD43', popupContent: NaturePopUps.Nature.AirIssues.NoInfo },
          { id: 7, label: 'Temperature', value: 1, color: '#D2EAD0', popupContent: NaturePopUps.Nature.AirIssues.NoInfo },
          { id: 8, label: 'Pollution', value: 1, color: '#4BAD43', popupContent: NaturePopUps.Nature.AirIssues.Pollution },
          { id: 9, label: 'Radiation', value: 1, color: '#D2EAD0', popupContent: NaturePopUps.Nature.AirIssues.NoInfo },
          { id: 10, label: 'Ozone Levels', value: 1, color: '#4BAD43', popupContent: NaturePopUps.Nature.AirIssues.NoInfo },
          { id: 11, label: 'Nitrogen Dioxide', value: 1, color: '#D2EAD0', popupContent: NaturePopUps.Nature.AirIssues.NoInfo },
          { id: 12, label: 'Particulate Matter', value: 1, color: '#4BAD43', popupContent: NaturePopUps.Nature.AirIssues.NoInfo }
        ],
        parent: "Nature",
        level: 2,
        subPages: {}
      },

      "Actions": {
        chart: [
          { id: 0, label: 'Crop,Plant,Tree', value: 1, color: '#008E9C' },
          { id: 1, label: 'Soil', value: 1, color: '#BFE3E6' },
          { id: 2, label: 'Water', value: 1, color: '#008E9C' },
          { id: 3, label: 'Air', value: 1, color: '#BFE3E6' }

        ],
        parent: "Nature",
        level: 2,
        subPages: {}
      },


      "Clearing": {
        chart: [
         
          { id: 2, label: 'Call In An Angel', value: 1, color: '#0D2D94', popupContent: NaturePopUps.Nature.Clearing.Call },
          { id: 3, label: 'Frequency', value: 1, color: '#C2CAE4', popupContent: NaturePopUps.Nature.Clearing.Frequency },
        
          { id: 6, label: 'Spirit Message', value: 1, color: '#0D2D94', popupContent: NaturePopUps.Nature.Clearing.Spirit },
          { id: 7, label: 'Animal Msg.', value: 1, color: '#C2CAE4', popupContent: NaturePopUps.Nature.Clearing.Animal },
          { id: 9, label: 'Detect Location', value: 1, color: '#0D2D94', popupContent: NaturePopUps.Nature.Clearing.Detect },
          { id: 10, label: 'Dowsing the Property', value: 1, color: '#C2CAE4', popupContent: NaturePopUps.Nature.Clearing.Dowsing },
          { id: 11, label: 'Clear Energy', value: 1, color: '#0D2D94', popupContent: NaturePopUps.Nature.Clearing.ClearEnergyField }

        ],
        parent: "Nature",
        level: 2,
        subPages: {}
      },

      "Syncing": {
        chart: [
          { id: 0, label: 'Cons. of Chemicals', value: 1, color: '#0F1D6D', popupContent: NaturePopUps.Nature.Syncing.Chemicals },
          { id: 1, label: 'Stress Frequency', value: 1, color: '#C3C6DA', popupContent: NaturePopUps.Nature.Syncing.SyncNoInfo },
          { id: 2, label: 'Illness Frequency', value: 1, color: '#0F1D6D', popupContent: NaturePopUps.Nature.Syncing.SyncNoInfo },
          { id: 3, label: 'Societal Fear', value: 1, color: '#C3C6DA', popupContent: NaturePopUps.Nature.Syncing.SyncNoInfo },
          { id: 4, label: 'Societal Greed', value: 1, color: '#0F1D6D', popupContent: NaturePopUps.Nature.Syncing.SyncNoInfo },
          { id: 5, label: 'Societal Love', value: 1, color: '#C3C6DA', popupContent: NaturePopUps.Nature.Syncing.SyncNoInfo },
          { id: 6, label: 'Emotion of Humans', value: 1, color: '#0F1D6D', popupContent: NaturePopUps.Nature.Syncing.SyncNoInfo },
          { id: 7, label: 'Cons. of GMOs', value: 1, color: '#C3C6DA', popupContent: NaturePopUps.Nature.Syncing.GMO },
          { id: 8, label: 'Earth Energies', value: 1, color: '#0F1D6D', popupContent: NaturePopUps.Nature.Syncing.SyncNoInfo },
          { id: 9, label: 'Sound', value: 1, color: '#C3C6DA', popupContent: NaturePopUps.Nature.Syncing.SyncNoInfo },
          { id: 10, label: 'Elec. smog', value: 1, color: '#0F1D6D', popupContent: NaturePopUps.Nature.Syncing.Smog },
          { id: 11, label: 'Animals', value: 1, color: '#C3C6DA', popupContent: NaturePopUps.Nature.Syncing.Animals },
          { id: 12, label: 'Pollution', value: 1, color: '#0F1D6D', popupContent: NaturePopUps.Nature.Syncing.Pollution },
          { id: 13, label: 'Farmer', value: 1, color: '#C3C6DA', popupContent: NaturePopUps.Nature.Syncing.Farmer },
          { id: 14, label: 'Plants/Water/Soil', value: 1, color: '#0F1D6D', popupContent: NaturePopUps.Nature.Syncing.SyncNoInfo },
          { id: 15, label: 'Generational D.E.', value: 1, color: '#C3C6DA', popupContent: NaturePopUps.Nature.Syncing.Generational }

        ],
        parent: "Nature",
        level: 2,
        subPages: {}
      },

      "Time Chart": {
        chart: [
          { id: 0, label: '1 Week', value: 1, color: '#C3102F' },
          { id: 1, label: '2 Weeks', value: 1, color: '#F48026' },
          { id: 2, label: '3 Weeks', value: 1, color: '#EFB211' },
          { id: 3, label: '4 Weeks', value: 1, color: '#46AA42' },
          { id: 4, label: '5 Weeks', value: 1, color: '#009290' },
          { id: 5, label: '6 Weeks', value: 1, color: '#263692' },
          { id: 6, label: '7 Weeks', value: 1, color: '#221E72' },
          { id: 7, label: '8 Weeks', value: 1, color: '#692C91' },
          { id: 9, label: '9 Weeks', value: 1, color: '#896531' },
        ],
        innerChart: [
          { id: 10, label: '1', value: 1, color: '#00656E' },
          { id: 11, label: '2', value: 1, color: '#99DED5' },
          { id: 12, label: '3', value: 1, color: '#00656E' },
          { id: 13, label: '4', value: 1, color: '#99DED5' },
          { id: 14, label: '5', value: 1, color: '#00656E' },
          { id: 15, label: '6', value: 1, color: '#99DED5' },
          { id: 16, label: '7', value: 1, color: '#00656E' },
          { id: 17, label: '8', value: 1, color: '#99DED5' },
          { id: 18, label: '9', value: 1, color: '#00656E' },
          { id: 19, label: '10', value: 1, color: '#99DED5' },
        ],
        parent: "Nature",
        level: 2,
        subPages: {},
        disableClick: true,
        textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>Days/Hours</Typography>
      },
    }
  }
}
