import React from 'react';
import { Grid, Box } from '@mui/material';
import WordCardCollection from '../WordCardCollection/WordCardCollection';
import { SoulWordListMap } from '../../data/Soul/wordlist';
import { WordListType } from '../../data/types'

interface RelationshipsProps {
    height: number;
    width: number;
    titleHeight: number;
    wordPageTitleHeight: number;
}

const RelationshipsPage: React.FC<RelationshipsProps> = ({ height, width, titleHeight, wordPageTitleHeight }) => {
    const words = SoulWordListMap["Soul"]["Relationships"] as WordListType[];
    return (
        <>
            <Box style={{ height: `${height}px`, justifyContent: 'center', alignItems: 'center' }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <WordCardCollection height={height} width={width / 3 * 2} wordList={words}  titleHeight={titleHeight} wordPageTitleHeight={wordPageTitleHeight} />
                    </Grid>
                    <Grid item xs={4}>
                        <WordCardCollection height={height} width={width / 3}
                            titleHeight={titleHeight} wordPageTitleHeight={wordPageTitleHeight} wordList={[
                                {
                                    title: "Relationships Between",
                                    words: [
                                        "3rd & 5th dimensions",
                                        "Giving & receiving",
                                        "Good & evil",
                                        "Me & classmates",
                                        "Me & my body",
                                        "Me & my divine blueprint",
                                        "Me & the dark",
                                        "Me & my shadow side",
                                        "Money & the cost of it",
                                        "Thriving vs surviving",
                                        "Where I am/where I want to be",
                                        "Who I am & my soul's remake",
                                        "With two of my humans",
                                        "Young & old",
                                        "My physical self & my spiritual self",
                                        "Something Not Listed",
                                        "Two of my animal companions",
                                        "Two of My children",
                                        "Who people want me to be & who I really am"
                                    ]
                                }
                            ]} />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
export default RelationshipsPage;
