import { OriginPopUps } from './popups';
import { ChartPagesType } from '../types'
import { Typography } from "@mui/material";
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../utils';

export const OriginCharts: ChartPagesType = {
  "Origin": {
    chart: [
      { id: 0, label: 'Toxicity Level', value: 1, color: '#C3102F' },
      { id: 1, label: 'Clearings', value: 1, color: '#F48026' },
      { id: 3, label: 'Clearing Time Chart', value: 1, color: '#692C91' },

    ],

    parent: "All Modules",
    level: 1,
    subPages: {

      "Toxicity Level": {
        chart: [
          { id: 0, label: 'High Toxicity', value: 1, color: '#c3102f', popupContent: OriginPopUps.Origin.Toxicity.ToxicityLevelNoInfo  },
          { id: 1, label: 'Moderately Toxic', value: 1, color: '#f25b21' , popupContent: OriginPopUps.Origin.Toxicity.ToxicityLevelNoInfo },
          { id: 2, label: 'Mildly Toxic', value: 1, color: '#efb211' , popupContent: OriginPopUps.Origin.Toxicity.ToxicityLevelNoInfo },
          { id: 3, label: 'Toxins Present', value: 1, color: '#46aa42', popupContent: OriginPopUps.Origin.Toxicity.ToxicityLevelNoInfo  },
          { id: 4, label: 'Mildly Clear', value: 1, color: '#0077c0' , popupContent: OriginPopUps.Origin.Toxicity.ToxicityLevelNoInfo },
          { id: 5, label: 'Moderately Clear', value: 1, color: '#221e72' , popupContent: OriginPopUps.Origin.Toxicity.ToxicityLevelNoInfo },
          { id: 6, label: 'Mostly Clear', value: 1, color: '#692c91' , popupContent: OriginPopUps.Origin.Toxicity.ToxicityLevelNoInfo },
          { id: 7, label: 'Toxin Free', value: 1, color: '#f0f0f0', popupContent: OriginPopUps.Origin.Toxicity.ToxicityLevelNoInfo  },
        ],
        parent: "Origin",
        level: 2,
        subPages: {}
      },
      "Clearings": {
        chart: [

          { id: 0, label: 'Chemicals', value: 1, color: '#f25b21' , popupContent: OriginPopUps.Origin.Clearings.Clearings },
          { id: 1, label: 'Consc. Programs', value: 1, color: '#fbd6c8', popupContent: OriginPopUps.Origin.Clearings.Clearings },
          { id: 2, label: 'Curses', value: 1, color: '#f25b21', popupContent: OriginPopUps.Origin.Clearings.Clearings },
          { id: 3, label: 'Frequency Of Illness', value: 1, color: '#fbd6c8', popupContent: OriginPopUps.Origin.Clearings.Clearings },
          { id: 4, label: 'Galactic Programs', value: 1, color: '#f25b21' , popupContent: OriginPopUps.Origin.Clearings.Clearings},
          { id: 5, label: 'Numbers Programs', value: 1, color: '#fbd6c8' , popupContent: OriginPopUps.Origin.Clearings.Clearings},
          { id: 6, label: 'Math Programs', value: 1, color: '#f25b21', popupContent: OriginPopUps.Origin.Clearings.Clearings },
          { id: 7, label: 'Currency Programs', value: 1, color: '#fbd6c8' , popupContent: OriginPopUps.Origin.Clearings.Clearings},
          { id: 8, label: 'Triggering Programs', value: 1, color: '#f25b21' , popupContent: OriginPopUps.Origin.Clearings.Clearings},
          { id: 9, label: 'Something Not Listed', value: 1, color: '#fbd6c8' , popupContent: OriginPopUps.Origin.NoInfo.NoInfo},


        ],
        parent: "Origin",
        level: 2,
        subPages: {}
      },

      "Clearing Time Chart": {
        chart: [

          { id: 10, label: '1 Week', value: 1, color: '#C3102F' },
          { id: 11, label: '2 Weeks', value: 1, color: '#F48026' },
          { id: 12, label: '3 Weeks', value: 1, color: '#EFB211' },
          { id: 13, label: '4 Weeks', value: 1, color: '#46AA42' },
          { id: 14, label: '5 Weeks', value: 1, color: '#009290' },
          { id: 15, label: '6 Weeks', value: 1, color: '#263692' },
          { id: 16, label: '7 Weeks', value: 1, color: '#221E72' },
          { id: 17, label: '8 Weeks', value: 1, color: '#692C91' },
          { id: 18, label: '9 Weeks', value: 1, color: '#896531' },
        ],
        innerChart: [
          { id: 19, label: '1', value: 1, color: '#00656E' },
          { id: 20, label: '2', value: 1, color: '#99DED5' },
          { id: 21, label: '3', value: 1, color: '#00656E' },
          { id: 22, label: '4', value: 1, color: '#99DED5' },
          { id: 23, label: '5', value: 1, color: '#00656E' },
          { id: 24, label: '6', value: 1, color: '#99DED5' },
          { id: 25, label: '7', value: 1, color: '#00656E' },
          { id: 26, label: '8', value: 1, color: '#99DED5' },
          { id: 27, label: '9', value: 1, color: '#00656E' },
          { id: 28, label: '10', value: 1, color: '#99DED5' },
        ],
        disableClick: true,
        parent: "Origin",
        level: 2,
        subPages: {},
        textChartInstructions : <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>Days/Hours</Typography>
      },




    }
  }
}