import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { Box,Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

interface PdfDialogProps {
    open: boolean;
    onClose: () => void;
    pdfName: string;
}

const PdfDialog: React.FC<PdfDialogProps> = ({ open, onClose, pdfName }) => {
    const onDownload = () => {
        window.open(pdfName, '_blank');
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close" style={{ paddingRight: 20 }}>
                    <CloseIcon />
                </IconButton>
                <IconButton edge="end" color="inherit" onClick={onDownload} aria-label="download" style={{ marginRight: 20 }}>
                    <Typography variant="body2" fontWeight="normal">
                        Download
                    </Typography>
                    <DownloadIcon />
                </IconButton>
            </Box>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <center style={{width:"75%", height: '100%' }}>
                <iframe  src={pdfName} title={pdfName} style={{ width: '100%', height: '100%', alignContent:'center',border: 'none'  }} />
                </center>
            </div>
        </Dialog>
    );
};

export default PdfDialog;