import React from 'react';
import { Typography, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const SpiritTextPage: React.FC = () => {

    const margin = 20;
    const textVarient = 'h6';
    return (
        <React.Fragment>

            <Grid style={{ flex: '1 0 auto'}} container sx={{ marginTop: `${margin}px` }} justifyContent="center">
                <Grid item xs={12} md={12} sx={{ mb: '20px' }}>
                    <Card sx={{ mx: '20px' }} >
                        <CardHeader sx={{ paddingBottom: '0px' }} title="Welcome to Spirit Style" />
                        <CardContent>

                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                This program is designed to help you get clarity and clearing on what energies may be hiding in
                                your energy field via birth marks external and internal. Birth marks are left over traumatic energy
                                from experiences we have had before, either in this lifetime or others. Birth marks can also happen
                                internally and those we would not be as inclined to know that they are there. With this program those
                                will be uncovered and cleared right along with the ones that we can see. We will also be given guidance
                                on the people and animals that are connected to these learnings and experiences so that we will be able
                                to embrace and honor those individuals for the gifts that they bring to our lives. We will be able to
                                clearly see that there is no such thing as coincidences when it comes to who crosses our paths.
                                People and animals are in our lives for a reason or a season. The lessons and gifts are many.
                            </Typography>



                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={12} md={12} sx={{ mb: '20px' }} >

                    <Card sx={{ mx: '20px' }} >
                        <CardHeader sx={{ paddingBottom: '0px' }} title="Instructions" />
                        <CardContent>

                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                Begin by going to the <Link to="/?module=Spirit&item=Marking+Locations">Marking Locations</Link> tab and finding the answer that you need and then
                                to the <Link to="/?module=Spirit&item=Number+of+Birthmarks">Number of Birthmarks</Link> tab. You will be clearing after these answers and after each
                                answer that you receive in this module. You then will continue to the <Link to="/?module=Spirit&item=Type+of+Marking">Type of Marking</Link> tab
                                and find the answer that pertains to your session. You will continue to each tab succession
                                across the bottom of your screen until complete by clearing whatever answer you receive in
                                the last tab, the <Link to="/?module=Spirit&item=Stages+of+Learning"> Stages of Learning</Link> tab.
                            </Typography>


                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                Once you have completed all the tabs, you will then ask if you need to go to another
                                module? If yes, then proceed to <Link to="/?module=All+Modules">All Modules</Link> tab and find the next module that you will be
                                clearing in. If you get a no, you will then ask if you are utilizing the Trailblazing
                                Communications Whole Life System Trauma genius process and worksheet, do I need to go
                                to the protocol? If yes, continue to the protocol. If you get a no, then you will ask, is this
                                session complete? If yes, you are done with this session.
                            </Typography>

                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                If you are not utilizing the Trailblazing Communications Whole Life system and the Trauma
                                Genius process and worksheet, then you will ask is this session complete? If yes, you are
                                done. If no, you will then ask if another module is needed. Continue with whatever
                                information you receive.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export default SpiritTextPage;
