import React, { useState } from 'react';
import { Typography, Card, CardContent, CardHeader, Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import DescriptionIcon from '@mui/icons-material/Description';
import PdfDialog from '../PdfDialog/PdfDialog';

const OriginTextPage: React.FC = () => {
    const margin = 20;
    const textVarient = 'h6';
    const [originWSDialogOpen, setOriginSDialogOpen] = useState(false);


    const handleClose = () => {
        setOriginSDialogOpen(false);

    }

    return (
        <React.Fragment>

            <Grid container sx={{ marginTop: `${margin}px` }} justifyContent="center">
                <Grid item xs={12} md={12} sx={{ mb: '20px' }}>
                    <Card sx={{ mx: '20px' }} >
                        <CardHeader sx={{ paddingBottom: '0px', }} title="Welcome to Origin Style" />
                        <CardContent>
                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>

                                The Origin Style Module, a process within the Whole Life Wellness System,
                                requires wearing the provided pendant. It's crucial activate and to wear the pendant during the entire time as Determined by the Clearing Time Chart.
                                Once you have your pendant on, activate it by declaring:


                            </Typography>

                            <Typography variant={textVarient} sx={{ margin: `${margin}px`, fontStyle: 'italic' }}>


                                “I now activate my pendant and give the angels and guides permission to help me detoxify, shift, cleanse, and clear all food, beverages,
                                and products that I consume and use. I also authorize them to cleanse and clear any programming that may be restricting my life, health,
                                and the evolution of my soul in any way." Following this, you should print out the provided worksheet to record your responses.



                            </Typography>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                        <b>Items Needed:</b>
                                        <ul>
                                            <li>Pendant</li>
                                            <li>
                                                Origin Worksheet
                                                <Button size="small" sx={{ margin: 0, textTransform: 'none' }} onClick={() => { setOriginSDialogOpen(true) }}>
                                                    <DescriptionIcon sx={{ margin: 0 }} fontSize="small" />
                                                    view
                                                </Button>
                                            </li>

                                        </ul>
                                        {/* Statement of Intent: Begin the module by clearly stating your personal intention. */}
                                    </Typography>
                                </Grid> {/* This closing tag was missing */}
                                <Grid item xs={12} md={6}>
                                    <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                        <b>Timeframe:</b>
                                        <ul>
                                            <li>Determined by <Link to="/?module=Origin&item=Clearing+Time+Chart">Clearing Time Chart</Link></li>
                                        </ul>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>

                <Grid item xs={12} md={12} sx={{ mb: '20px' }}>
                    <Card sx={{ mx: '20px' }} >
                        <CardHeader sx={{ paddingBottom: '0px' }} title="Instructions" />
                        <CardContent>
                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                This process is to be used to clear programming. Programming is everywhere and comes
                                from multiple sources. It is not typically something we are aware of until we find ourselves
                                repeating patterns, thoughts or behaviors. This is something we can clear but know that
                                because we are surrounded by programming, it is something we should check back on
                                from time to time so we can keep things clear.
                            </Typography>
                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                This is a process that wearing your energy infused Trailblazing Communications pendant
                                will help with. It helps to hold your vibration high to help your body with the clearing
                                process. This module is easy to navigate and fairly straight forward. Print out the worksheet
                                for this module. You will then go to the <Link to="/?module=Origin&item=Toxicity+Level">Toxicity Level Chart</Link> and find out the toxicity level for
                                yourself or for whomever you are working on. This chart will represent your toxicity from
                                programming.
                            </Typography>
                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                After finding your toxicity level on the  <Link to="/?module=Origin&item=Toxicity+Level">Toxicity Level Chart</Link>  if you get anything but toxin free,
                                you will continue to the next chart, the <Link to="/?module=Origin&item=Clearings">Clearings Chart</Link>. Find only one answer here as you
                                will only work on one of these at a time. Write down your answer on the worksheet.
                            </Typography>
                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                Then go to the <Link to="/?module=Origin&item=Clearing+Time+Chart">Clearings Time Chart</Link> and find out the time frame needed to clear this layer
                                of your programming. Document what you get on your calendar so that you will be
                                reminded to come in and check again to see if you still have programming to clear. You will
                                then read the last statement on the worksheet to start this process. That is all you need to
                                do.
                            </Typography>
                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                Once your time is up and you get that you still have some kind of programming toxicity, you
                                will do this same process until you get a reading of Toxin Free.
                            </Typography>
                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                Once you get toxin free make sure to check in frequently to see if anything has changed.
                                Repeat the process when necessary.
                            </Typography>

                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                If you are doing this process for others, they too will need to get themselves a pendant to
                                assist with the process and they will also have to read the statement themselves to start
                                the process It is okay if the session is done for a minor person that you can read the
                                statement on their behalf.
                            </Typography>


                        </CardContent>
                    </Card>


                </Grid>

            </Grid>
            <PdfDialog open={originWSDialogOpen} onClose={handleClose} pdfName="Origin%20WS.pdf" />

        </React.Fragment>
    );
}
export default OriginTextPage;
