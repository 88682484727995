import { WordListMappingType } from "../types";

export const AnimalWordListMap: WordListMappingType = {
  "Animal": {
    "Nutrition": {
      "Foods & Treats": [
        "Alfalfa",
        "Barley",
        "Beef",
        "Bison",
        "Chicken",
        "Corn",
        "Dry Food",
        "Duck",
        "Eggs",
        "Fish",
        "Fruit",
        "Grain",
        "Goose",
        "Hay",
        "Lamb",
        "Legumes",
        "Liver",
        "Oats",
        "Processed Food",
        "Rice",
        "Sweet Potato",
        "Turkey",
        "Veal",
        "Vegetable",
        "Wheat"
      ],
      "Vitamins": [
        "Vitamin A",
        "Vitamin B (B2,B3,B6,B12)",
        "Vitamin C",
        "Vitamin D (D3)",
        "Vitamin E1",
        "Vitamin F"
      ],
      "Supplements": [
        "Acidophilus",
        "Algae",
        "Bee Pollen",
        "Black Currant",
        "Blue Green Algae",
        "Bovine Cartilage",
        "Braggs Apple Cider Vinegar",
        "Chlorella",
        "COQ10",
        "Diatomaceous Earth",
        "Evening Primrose Oil",
        "Glucosamine",
        "Probiotics"
      ],
      "Minerals": [
        "Acidophilus",
        "Alfalfa",
        "Algae",
        "Amino Acids",
        "Bee Pollen1",
        "Biotin",
        "Black Current",
        "Blue Green Algae",
        "Borage",
        "Bovine Cartilage2",
        "Calcium",
        "Cereal Grass",
        "Chlorella",
        "Cobalt",
        "Coenzyme Minerals3",
        "Copper",
        "CoQ10",
        "Evening Primrose Oil",
        "Flax",
        "Fluoride4",
        "Garlic",
        "Glucosamine",
        "Greens",
        "Hops",
        "Iron5",
        "Kelp",
        "Lecithin",
        "Magnesium",
        "Manganese",
        "Phosphorus",
        "Potassium",
        "Protein",
        "Selenium",
        "Sodium",
        "Sulfur",
        "Trace Minerals",
        "Wicks Equine Cornerstone Mineral",
        "Zinc"
      ],
      "Herbs": [
        "Alfalfa Herb",
        "Aloe Vera",
        "Angelica Root",
        "Arnica Flower",
        "Aspen Bark",
        "Astragalus Root",
        "Baptisia Root",
        "Barberry Root",
        "Bayberry Root Bark",
        "Bear Berry",
        "Benzoin Gum",
        "Betony Flower",
        "Black Cohosh Root",
        "Black Haw Root",
        "Black Walnut Hull",
        "Blessed Thistle Herb",
        "Bloodroot",
        "Blue Flag",
        "Blue Cohosh",
        "Blue Vervain Herb",
        "Blueberry Leaf",
        "Boneset",
        "Borage",
        "Boswella",
        "Brickells Herb",
        "Buchu Leaf",
        "Bugleweed Herb",
        "Burdock Root",
        "Burdock Seed",
        "Calendula Flower",
        "Cascara Sagrada Root",
        "Catnip Herb",
        "Cats Claw",
        "Cayenne Fruit",
        "Celandine, Greater Herb",
        "Chamomile Flower",
        "Chaparral Leaf",
        "Chastetree Berry",
        "Cinnamon Bark",
        "Clematis Herb",
        "Cleavers Herb",
        "Comfrey Leaf",
        "Corn Silk",
        "Cotton Root Bark",
        "Cole Parsnip Root",
        "Couch Grass",
        "Cow Parsnip Root",
        "Cramp Bark",
        "Damiana Herb",
        "Dandelion Root",
        "Desert Willow Bark",
        "Devils Claw Root",
        "Dill",
        "Dogbane Root",
        "Dong Quai Root",
        "Echinacea Root",
        "Elecampane Root",
        "Elder Flower",
        "Elderberry",
        "Eucalyptus Leaf",
        "Eyebright Herb",
        "False Unicorn Root",
        "Fennel Seed",
        "Feverfew Flower",
        "Fireweed Herb",
        "Fringetree Bark",
        "Garlic Bulb",
        "Gentian Root",
        "Ginger",
        "Gingko Leaf",
        "Goldenseal Root",
        "Gotu Kola Herb",
        "Gravel Root",
        "Grindelia Flower",
        "Guarana Seed",
        "Hawthorne Flower",
        "Hops Strobules",
        "Horehound Herb",
        "Horse Chestnut Bark",
        "Horseradish Root",
        "Horsetail Herb",
        "Immortal Root",
        "Juniper Berry",
        "Kava Kava Root",
        "Kelp Frond",
        "Kola Nut",
        "Lavender Leaf",
        "Licorice Root",
        "Lily of the Valley Root",
        "Lobella Herb",
        "Ha Huang Stern",
        "Maravilla Root",
        "Marshmallow Root",
        "Matarique Root",
        "Milk Thistle Seed",
        "Menta Polep Herb",
        "Mormon Tea",
        "Motherwort Herb",
        "Mullein Leaf",
        "Myrrh Gum",
        "Neem",
        "Nettles Herb",
        "Oat Seed",
        "Ocotillo Stern Bark",
        "Oregon Grape Root",
        "Osha Root",
        "Pansy Herb",
        "Parsley",
        "Partridge Berry",
        "Passion Flower",
        "Pau d'Arco Bark",
        "Pennyroyal Herb",
        "Peppermint",
        "Pipsissewa Herb",
        "Pleurisy Root",
        "Poke Root",
        "Prickly Ash Bark",
        "Prickly Poppy",
        "Propolis Resin",
        "Pulsatilla Flower",
        "Quassia Wood",
        "Raspberry Leaf",
        "Red Clover Flower",
        "Red Root",
        "Rosemary Leaf",
        "Sarsaparilla Root",
        "Saw Palmetto Fruit",
        "Shepherds Purse Herb",
        "Skullcap Herb",
        "Slippery Elm",
        "Southern Wood",
        "Spikenard Root",
        "Stillingia Root",
        "Storksbill Herb",
        "St. John's Wort Flower",
        "Thuja Leaf",
        "Toadflax Herb",
        "Usnea Lichen",
        "Uva Ursi Leaf",
        "Valerian Root",
        "Virginia Snake Root",
        "White Oak Bark",
        "White Sage Leaf",
        "White Willow Bark",
        "Wild Cherry Bark",
        "Wild Ginger Bark",
        "Wild Yam Root",
        "Witch Hazel Leaf",
        "Yarrow Flower",
        "Yellow Dock Root",
        "Yerba Santa Leaf",
        "Yew",
        "Yohimbe Bark",
        "Yucca Root"
      ],
      "Additives": [
        "Aluminum",
        "Animal/Poultry Fat",
        "Artificial Color",
        "BHT/BHA",
        "Ethoxyquin",
        "Corn Meal",
        "Meat By-Products",
        "Molasses",
        "Peanut Hulls",
        "Pesticides",
        "Propylene Glycol",
        "Salt",
        "Sodium Nitrate",
        "Soybean",
        "Sugar/Sweeteners"
      ],
      "Amino Acids": [
        "Amino Acids",
        "Alanine",
        "Arginine",
        "Asparagine",
        "Aspartic Acid",
        "Biotin",
        "Cysteine",
        "Glutamine",
        "Glycine",
        "Histidine",
        "Isoleucine",
        "Leucine",
        "Lysine",
        "Methionine",
        "Phenylalanine",
        "Proline",
        "Serine",
        "Taurine",
        "Threonine",
        "Tryptophan",
        "Tyrosine",
        "Valine"
      ]
    }, 
    "Body Area": {
      "Endocrine": [
        "Adrenals",
        "Ovaries",
        "Pancreas",
        "Pineal Gland",
        "Pituitary Gland",
        "Testes",
        "Thymus",
        "Thyroid"
      ],
      "Organs": [
        "Bladder",
        "Gallbladder",
        "Heart",
        "Kidneys",
        "Large Intestine",
        "Liver",
        "Lung",
        "Small Intestine",
        "Spleen",
        "Stomach"
      ],
    },
    "Emotional":{
      "Scared": [
        "Fearful",
        "Panicky",
        "Afraid",
        "Shocked",
        "Overwhelmed",
        "Intimidated",
        "Desperate",
        "Frantic",
        "Terrified",
        "Vulnerable",
        "Horrified",
        "Petrified",
        "Appalled",
        "Full of Dread",
        "Tormented",
        "Tense",
        "Threatened",
        "Uneasy",
        "Defensive",
        "Insecure",
        "Skeptical",
        "Apprehensive",
        "Suspicious",
        "Alarmed",
        "Shaken",
        "Swamped",
        "Startled",
        "Guarded",
        "Stunned",
        "Awed",
        "Reluctant",
        "Anxious",
        "Impatient",
        "Shy",
        "Nervous",
        "Unsure",
        "Timid",
        "Concerned",
        "Perplexed",
        "Doubtful",
        "Shocked"
      ],
      "Confused": [
        "Bewildered",
        "Trapped",
        "Immobilized",
        "Directionless",
        "Stagnant",
        "Flustered",
        "Baffled",
        "Constricted",
        "Troubled",
        "Ambivalent",
        "Awkward",
        "Puzzled",
        "Disorganized",
        "Foggy",
        "Perplexed",
        "Hesitant",
        "Torn",
        "Misunderstood",
        "Doubtful",
        "Bothered",
        "Undecided",
        "Uncomfortable",
        "Uncertain",
        "Surprised",
        "Unsettled",
        "Unsure",
        "Distracted"
      ],
      "Happy": [
        "Excited",
        "Elated",
        "Exuberant",
        "Ecstatic",
        "Terrific",
        "Jubilant",
        "Energized",
        "Enthusiastic",
        "Loved",
        "Thrilled",
        "Marvelous",
        "Justified",
        "Resolved",
        "Valued",
        "Gratified",
        "Encouraged",
        "Optimistic",
        "Joyful",
        "Proud",
        "Cheerful",
        "Relieved",
        "Assured",
        "Determined",
        "Grateful",
        "Appreciated",
        "Confident",
        "Respected",
        "Admired",
        "Delighted",
        "Alive",
        "Fulfilled",
        "Tranquil",
        "Content",
        "Relaxed",
        "Glad",
        "Good",
        "Satisfied",
        "Peaceful",
        "Hopeful",
        "Fortunate",
        "Pleased",
        "Flattered"
      ],
      "Sad": [
        "Devastated",
        "Hopeless",
        "Sorrowful",
        "Depressed",
        "Wounded",
        "Drained",
        "Defeated",
        "Exhausted",
        "Helpless",
        "Crushed",
        "Worthless",
        "Uncared For",
        "Dejected",
        "Rejected",
        "Humbled",
        "Empty",
        "Miserable",
        "Distraught",
        "Deserted",
        "Grievous",
        "Burdened",
        "Demoralized",
        "Condemned",
        "Terrible",
        "Unwanted",
        "Unloved",
        "Mournful",
        "Pitiful",
        "Discarded",
        "Disgraced",
        "Disheartened",
        "Despised",
        "Disappointed",
        "Upset",
        "Inadequate",
        "Dismal",
        "Unappreciated",
        "Discouraged",
        "Ashamed",
        "Distressed",
        "Distant",
        "Disillusioned",
        "Lonely",
        "Neglected",
        "Isolated",
        "Alienated",
        "Regretful",
        "Islanded",
        "Resigned",
        "Drained"
      ],
      "Angry": [
        "Strangled",
        "Furious",
        "Seething",
        "Enraged",
        "Hostile",
        "Vengeful",
        "Incensed",
        "Abused",
        "Humiliated",
        "Hateful",
        "Sabotaged",
        "Betrayed",
        "Repulsed",
        "Rebellious",
        "Pissed Off",
        "Outraged",
        "Fuming",
        "Exploited",
        "Throttled",
        "Mad",
        "Spiteful",
        "Patronized",
        "Vindictive",
        "Used",
        "Ridiculed",
        "Resentful",
        "Disgusted",
        "Smothered",
        "Frustrated",
        "Stifled",
        "Offended",
        "Displeased",
        "Controlled",
        "Peeved",
        "Annoyed",
        "Agitated",
        "Irritated",
        "Exasperated",
        "Harassed",
        "Anguished",
        "Deceived",
        "Aggravated",
        "Perturbed",
        "Provoked",
        "Dominated",
        "Coerced",
        "Cheated",
        "Uptight",
        "Dismayed"
      ]
    }, 
    "Body Chemistry": {
      "Parasites": [
        "Bloodworms",
        "Bots",
        "Bolts Filarial",
        "Coccidian",
        "Cryptosporidium",
        "Fleas",
        "Giardia",
        "Hairworms",
        "Heartworm",
        "Hookworm",
        "Lice",
        "Lungworms",
        "Mange",
        "Mites",
        "Palisade Worms",
        "Pinworm",
        "Red Worms",
        "Ringworm",
        "Roundworm",
        "Scabies",
        "Stomach Worms",
        "Strongyles",
        "Strangles",
        "Tapeworm",
        "Threadworms",
        "Tics", // Assuming "Ticks"
        "Whipworm"
      ],
      "Fungus": [
        "Aspergillus",
        "Chartarum",
        "Cladosporium",
        "Mold",
        "Penicillium", // Corrected spelling from "Peniciillium"
        "Ringworm", // Noting that ringworm is a condition caused by fungi, not a fungus itself
        "Stachybotrys",
        "Tinea" // Tinea refers to infections caused by a type of fungus, often specified by an additional term (e.g., "tinea pedis" for athlete's foot)
      ],
      "Mold": [
        "Aspergillus",
        "Chartarum",
        "Cladosporium",
        "Mold",
        "Penicillium", // Corrected spelling from "Peniciillium"
        "Ringworm", // Noting that ringworm is a condition caused by fungi, not a fungus itself
        "Stachybotrys",
        "Tinea" // Tinea refers to infections caused by a type of fungus, often specified by an additional term (e.g., "tinea pedis" for athlete's foot)
      ],
      "Bacteria": [
        "Aeromonas",
        "Brucellosis",
        "Campylobacteriosis", // Corrected spelling from "Camphylobacteriosis"
        "Clostridia",
        "Cytobacter", // Noting potential typo or less common name, possibly "Cytophaga"
        "E. coli", // Adjusted for clarity from "Ecoli"
        "Enterobacter",
        "Klebsiella",
        "Leptospirosis",
        "Lyme Disease", // Adjusted from "Lymes" for accuracy
        "Mycobacteria",
        "Pasteurella",
        "Potomac Horse Fever",
        "Proteus",
        "Pseudomonas",
        "Respiratory Infection",
        "Salmonella",
        "Staphylococcal Infection",
        "Serratia marcescens",
        "Strangles",
        "Streptococcal Infection",
        "Tetanus"
      ],
      "Viruses": [
        "Adenovirus",
        "Bordetella - (Kennel Cough)",
        "Distemper",
        "Covid-19", // Added for relevance
        "Equine Encephalomyelitis",
        "Equine Herpes Virus (EHV-1)",
        "Equine Influenza",
        "Feline Calcivirus (FCV)",
        "Feline Immunodeficiency - (FIV)",
        "Feline Infectious Peritonitis - (FIP)",
        "Feline Leukemia - (FeLV)", // Corrected to standard abbreviation
        "Feline Rhinotracheitis, Calicivirus & Panleukopenia - (FRCP)",
        "Feline Urologic Syndrome - (FUS)",
        "Giardia",
        "Hepatitis",
        "H5N1-Avian Flu",
        "Parainfluenza",
        "Parvovirus", // Adjusted for clarity, assuming "Parvo" refers to Canine Parvovirus
        "Rabies",
        "Rhinopneumonitis - (EHV-4)",
        "Respiratory Virus",
        "Ringworm", // Noting that ringworm is a fungal infection, not a virus
        "Streptococcus",
        "West Nile Virus" // Adjusted for specificity   
      ],
      "Toxins": [
        "Antifreeze",
        "Artificial Sweeteners", // Corrected spelling
        "BHA/BHT",
        "Chemtrails", 
        "Cobra Venom", // Noting that this is a specific toxin
        "Crete Venom", // Noting that this is a specific toxin
        "Deuterium",
        "Food Dyes",
        "Gluten",
        "High Fructose Corn Syrup",
        "Medications",
        "MSG",
        "Plastic",
        "Potassium Bromate",
        "Propylene Glycol",
        "Sodium Nitrate/Nitrite",
        "Sodium Sulfite",
        "Sulfur Dioxide", // Corrected spelling
        "Trans Fat",
        "Vaccinations", // Note: Context-specific, as vaccinations are generally beneficial but can be harmful in certain situations or to certain individuals
        "Toxic Human Foods - Etc.", // This seems to introduce the following items
        "Grapes",
        "Raisins",
        "Chocolate",
        "Avocado"
      ],
      "Vaccine": [
        "Bordetella",
        "Canine Influenza",
        "Covid-19", // Added for relevance
        "Crotalid", // Note: This likely refers to antivenom for snake bites (Crotalidae family).
        "Distemper",
        "Eastern Encephalomyelitis",
        "FCV", // Feline Calicivirus
        "FHV", // Feline Herpes Virus
        "FPV", // Feline Panleukopenia Virus
        "Influenza",
        "Leptospirosis",
        "Lyme Disease", // Corrected spelling from "Lymes" for accuracy
        "Polyomavirus",
        "Rabies",
        "Rhinopneumonitis", // Typically refers to Equine Herpesvirus 1 and 4
        "Tetanus Toxoid", // A vaccine, not a disease
        "Western Encephalomyelitis",
        "West Nile Encephalomyelitis" // Assuming "West Nile Virus" causing encephalitis
      ],
      "Additive":
        [
          "Aluminum",
          "Animal/Poultry Fat",
          "Artificial Color",
          "BHT/BHA",
          "Ethoxyquin",
          "Corn Meal",
          "Meat By-Products",
          "Molasses",
          "Peanut Hulls",
          "Pesticides",
          "Propylene Glycol",
          "Salt",
          "Sodium Nitrate",
          "Soybean",
          "Sugar/Sweeteners"
        ],
    }, 
    "Health Intervention":{
      "Essential Oils": [
        "Anise",
        "Basil",
        "Believe",
        "Bergamot",
        "Cassia",
        "Cedarwood",
        "Cinnamon",
        "Cistus",
        "Citronella",
        "Citrus Fresh",
        "Clary Sage",
        "Clove",
        "Copaiba",
        "Coriander",
        "Cypress",
        "Digize",
        "Dorado Azul",
        "Fennel",
        "Frankincense",
        "Geranium",
        "Grounding",
        "Helichrysum",
        "Hyssop",
        "Idaho Balsam Fir",
        "Idaho Tansy",
        "Immupower",
        "Joy",
        "Juniper",
        "Lauris Nobilis",
        "Lavender",
        "Ledum",
        "Lemon",
        "Lemongrass",
        "Marjoram",
        "Melissa",
        "Melrose",
        "Mountain Savory",
        "Myrtle",
        "Myrrh",
        "Nutmeg",
        "Ocotea",
        "Orange",
        "Oregano",
        "Palmarosa",
        "Palo Santo",
        "Pan Away",
        "Patchouli",
        "Peace and Calming",
        "Peppermint",
        "Pine",
        "Purification",
        "Ravintsara",
        "Roman Chamomile",
        "Rose",
        "Rosemary",
        "Ruta Vala",
        "Sage",
        "Sandalwood",
        "Spearmint",
        "Spikenard",
        "Spruce",
        "Tangerine",
        "Tarragon",
        "Tea Tree (Malaleuca Alternifolia)",
        "Thieves",
        "Thyme",
        "Tsuga",
        "Trauma Life",
        "Valerian",
        "Valor",
        "Vetiver",
        "White Angelica",
        "Wintergreen",
        "Ylang Ylang"
      ],
      "Energy Modality": [
        "Acupuncture",
        "Aromatherapy",
        "Biofeedback",
        "Body Code",
        "Body Talk",
        "Cryo Therapy",
        "Cold Laser",
        "Color Therapy",
        "Cranial Sacral",
        "Emotion Code",
        "Emotional Freedom Technique (EFT)",
        "Energy Field Clearing - Entity Removal",
        "Essential Oils",
        "Falconer",
        "Fascial Unwinding",
        "Flower Essences",
        "Healing Touch",
        "Hydrotherapy",
        "Magnetic Therapy",
        "Massage",
        "Medium",
        "Myofascial Therapy",
        "Prayer Chain",
        "Psychic",
        "Psych K",
        "Radionics",
        "Raindrop Therapy",
        "Sound Therapy",
        "Theta Healing",
        "Training",
        "Tuning Forks",
        "Need a therapy not listed"
      ]
    }
  }
}