import React, { useRef } from 'react';
import Tab from '@mui/material/Tab';
import { ChartPages } from '../../data/charts';
import { ChartPageType, ExtendedPieValueType } from '../../data/types';
import { GetItemWordLists } from '../../data/wordlists';
import { useNavigate } from 'react-router-dom';
import { getBrightnessAdjustedColor } from '../../utils';
import { GetCustomPage } from '../../data/CustomPageMap';
import LockedTabs from '../LockedTabs/LockedTabs';


interface BottomTabsProps {
    currentModule: string;
    item: string | null;
    subitem: string | null;
    detail: string | null;
}

const BottomTabs: React.FC<BottomTabsProps> = ({ currentModule, item, subitem, detail }) => {
    const commonSx = {
        borderTopLeftRadius: 8, borderTopRightRadius: 8,
        fontSize: 10, margin: "1px"
    };
    const allModulesRef = useRef(null);

    const navigate = useNavigate();

    const handleTabChange = (_: React.ChangeEvent<{}>, page: string) => {
        navigateToPage(page);
    }

    const navigateToPage = (page: string) => {
        const level = page.split("_")[0];
        page = page.split("_")[1];
        let path = '/';
        let params = new URLSearchParams();

        if (level === "onclick") {
            navigate(page);
            return;
        }
        else if (level === "module") {
            params.append('module', page);
        }
        else if (level === "item") {
            params.append('module', currentModule);
            params.append('item', page);
        }
        else if (level === "subitem") {
            params.append('module', currentModule);
            params.append('item', item as string);
            params.append('subitem', page);
        }
        else if (level === "detail") {
            params.append('module', currentModule);
            params.append('item', item as string);
            params.append('subitem', subitem as string);
            params.append('detail', page);
        }
        path += '?' + params.toString();
        navigate(path);
    }


    // Modify the createTab function to attach the ref to the "All Modules" tab
    const createTab = (label: string, value: string, color: string | undefined, textColor: string, opacity: number = 1) => {
        return (
            <Tab
                ref={label === 'All Modules' ? allModulesRef : null}
                value={value}
                key={label + '_bottom_tab'}
                label={label}
                sx={{ background: color, color: textColor, ...commonSx, opacity }}
            />
        );
    };

    const tabs = [];
    tabs.push(createTab("All Modules", "module_All Modules", 'gray', 'white'));

    var value = `module_${currentModule}`;
    var value_color = 'white';
    if ((currentModule) && (currentModule !== "All Modules")) {
        const allModules = ChartPages["All Modules" as keyof ChartPageType];
        const currentModuleData = allModules.chart.find(module => module.label === currentModule);
        if (currentModuleData) {
            // special case here where there is no chart data for the Karma and Spirit modules
            if (GetCustomPage(currentModule, null, null, null)) {
                tabs.push(createTab("Directions", `module_${currentModule}`, 'black', 'white'));
            }
            else {
                tabs.push(createTab("All Charts", `module_${currentModule}`, 'black', 'white'));
                if (GetCustomPage(currentModule, "Directions", null, null)) {
                    tabs.push(createTab("Directions", `item_Directions`, 'black', 'white'));
                }
            }
        }
        // special case here for the Soul module
        if (currentModule === "Soul") {
            tabs.push(createTab("Relationships", "item_Relationships", 'black', 'white'))
            if (item === "Relationships") {
                value = `item_Relationships`
            }
        }


        const chartData = ChartPages[currentModule as keyof ChartPageType];
        chartData?.chart.forEach((chartSlice: ExtendedPieValueType) => {
            // these should be larger than the other tabs
            if (chartSlice && chartSlice.label) {
                const textColor = getBrightnessAdjustedColor(chartSlice.color);
                let item = `item_${chartSlice.label}`
                if (chartSlice.onClickNav) {
                    item = `onclick_${chartSlice.onClickNav}`;
                }
                tabs.push(createTab(chartSlice.label, item, chartSlice.color, textColor));
            }

            // add subitems
            if ((chartSlice && chartSlice.label) && (chartSlice.label === item)) {

                if (detail) {
                    value = `detail_${detail}`
                }
                else if (subitem) {
                    value = `subitem_${subitem}`
                }
                else {
                    value = `item_${item}`
                }
                if (value === `item_${chartSlice.label}`) {
                    const textColor = getBrightnessAdjustedColor(chartSlice.color);
                    value_color = textColor;
                }

                var isDetailLevel = false;
                var subChartData = chartData.subPages[item as keyof ChartPageType];
                if (subitem && subChartData) {
                    if (subitem in subChartData.subPages) {
                        const textColor = getBrightnessAdjustedColor(chartSlice.color);
                        tabs.push(createTab(subitem, `subitem_${subitem}`, chartSlice.color, textColor, 0.75));
                        subChartData = subChartData.subPages[subitem as keyof ChartPageType];
                        isDetailLevel = true;
                    }
                }


                const subitemURL = subitem
                const itemsWithWords = { ...GetItemWordLists(currentModule, item, subitem), ...subChartData?.subPages };
                subChartData?.chart.forEach((subChartSlice: ExtendedPieValueType) => {
                    if (subChartSlice && subChartSlice.label && (subChartSlice.label in itemsWithWords)) {
                        const textColor = chartSlice?.textColor ? chartSlice.textColor : getBrightnessAdjustedColor(chartSlice.color)
                        let value_pretext = 'subitem'
                        if (isDetailLevel) {
                            value_pretext = 'detail';
                        }

                        if (value === `subitem_${subChartSlice.label}`) {
                            value_color = textColor;
                        }

                        let subitem = `${value_pretext}_${subChartSlice.label}`
                        if (subChartSlice.onClickNav) {
                            subitem = `onclick_${subChartSlice.onClickNav}`;
                        }


                        // very unique case where we have a detail from within a word list
                        if (currentModule === "Freedom" && subitemURL === "Emotion" && subChartSlice.label === "Emotion") {
                            tabs.push(createTab(subChartSlice.label, subitem, chartSlice.color, textColor, 0.75));
                            isDetailLevel = true;
                            const special_tabs = ["Happy", "Sad", "Angry", "Scared", "Confused"]
                            for (let i = 0; i < special_tabs.length; i++) {
                                const textColor = getBrightnessAdjustedColor(chartSlice.color);
                                tabs.push(createTab(special_tabs[i], `detail_${special_tabs[i]}`, chartSlice.color, textColor, 0.5));
                            }
                        }
                        else {
                            tabs.push(createTab(subChartSlice.label, subitem, chartSlice.color, textColor, 0.5));
                        }

                    }
                });
            }
        })
    }
    else {
        currentModule = "All Modules";
        const chartData = ChartPages[currentModule as keyof ChartPageType];
        chartData?.chart.forEach((chartSlice: ExtendedPieValueType) => {

            // these should be larger than the other tabs
            if (chartSlice && chartSlice.label) {
                const textColor = chartSlice?.textColor ? chartSlice.textColor : getBrightnessAdjustedColor(chartSlice.color)
                tabs.push(createTab(chartSlice.label, `module_${chartSlice.label}`, chartSlice.color, textColor));
            }
        });
    }

    if (GetCustomPage(currentModule, "Directions", null, null)) {
        if (item === "Directions") {
            value = `item_Directions`
        }
    }


    return (
        <LockedTabs
            variant="scrollable"
            scrollButtons="auto"
            aria-label="bottom-tabs"
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { backgroundColor: '#636363', height: '4px' } }}
            sx={{
                color: "white",
                "&& .Mui-selected": {
                    color: value_color,
                    fontWeight: 800,
                }
            }}
            value={value}
            scrollLeftOnClick={true}
        >
            {tabs}
        </LockedTabs>
    );
};

export default BottomTabs;