import { KarmaPopUps } from './popups'
import { ChartPagesType } from '../types'
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../utils';

const TrialsText = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>What are the trials that pertain? Allow clearing after each answer.</Typography>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>After getting all trials. Ask for the vagus nerve to be balanced. Allow the pendulum
        to swing for that process, when complete go on to the <Link to="/?module=Karma&item=Lessons+%26+Karma">Lessons & Karma Chart</Link>.</Typography>
    </Box>
  );
}

const LessonsAndTrialsText = () => {

  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>What are the lessons? Allow clearing after each answer.</Typography>
    </Box>
  );
}

const EmotionalLessonsText = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>
        How many pertain? Clear after each answer. When done go to <Link to="/?module=Karma&item=Lifetimes">Lifetimes chart</Link>.
      </Typography>
    </Box>
  );
}

const LifetimesText = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>
        Ask How many lifetimes does this span? Allow clearing after the answer.Then go to <Link to="/?module=Karma&item=Points+of+Light">Points of Light chart</Link>.
      </Typography>
    </Box>
  );
}

const PointsOfLightText = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>
        Obtain starting color and ending color and then allow for the clearing.
        Ask Is there another configuration needed?  If No, go to the <Link to="/?module=Karma&item=Trials">Trials chart</Link>.
      </Typography>
    </Box>
  );
}


export const KarmaCharts: ChartPagesType = {
  "Karma": {
    chart: [
      { id: 0, label: 'Emotional Lessons', value: 1, color: '#F48026' },
      { id: 1, label: 'Lifetimes', value: 1, color: '#FDB02D' },
      { id: 2, label: 'Points of Light', value: 1, color: '#0FAE4D' },
      { id: 3, label: 'Trials', value: 1, color: '#0B349B' },
      { id: 4, label: 'Lessons & Karma', value: 1, color: '#102386' },
    ],

    parent: "All Modules",
    level: 1,
    subPages: {

      "Emotional Lessons": {
        chart: [

          { id: 0, label: 'Attachment', value: 1, color: '#FF7B27', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 1, label: 'Appearance', value: 1, color: '#FFDBC5', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 2, label: 'Fulfillment', value: 1, color: '#FF7B27', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 3, label: 'Duality', value: 1, color: '#FFDBC5', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 4, label: 'Egoism', value: 1, color: '#FF7B27', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 5, label: 'Fame', value: 1, color: '#FFDBC5', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 6, label: 'Fear', value: 1, color: '#FF7B27', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 7, label: 'Anger', value: 1, color: '#FFDBC5', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 8, label: 'Greed', value: 1, color: '#FF7B27', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 9, label: 'Jealousy', value: 1, color: '#FFDBC5', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 10, label: 'Power', value: 1, color: '#FF7B27', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 11, label: 'Pride', value: 1, color: '#FFDBC5', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 12, label: 'wealth', value: 1, color: '#FF7B27', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 13, label: 'Sexuality', value: 1, color: '#FFDBC5', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 14, label: 'Selfishness', value: 1, color: '#FF7B27', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },
          { id: 15, label: 'Complacency', value: 1, color: '#FFDBC5', popupContent: KarmaPopUps.Karma.Emotional.EmotionalNoInfo },

        ],
        parent: "Karma",
        level: 2,
        subPages: {},
        textChartInstructions: <EmotionalLessonsText />

      },
      "Lifetimes": {
        chart: [

          { id: 0, label: '1', value: 1, color: '#FDB02D', popupContent: KarmaPopUps.Karma.Lifetimes.LifetimesNoInfo },
          { id: 1, label: '2', value: 1, color: '#FEEAC6', popupContent: KarmaPopUps.Karma.Lifetimes.LifetimesNoInfo },
          { id: 2, label: '3', value: 1, color: '#FDB02D', popupContent: KarmaPopUps.Karma.Lifetimes.LifetimesNoInfo },
          { id: 3, label: '4', value: 1, color: '#FEEAC6', popupContent: KarmaPopUps.Karma.Lifetimes.LifetimesNoInfo },
          { id: 4, label: '5', value: 1, color: '#FDB02D', popupContent: KarmaPopUps.Karma.Lifetimes.LifetimesNoInfo },
          { id: 5, label: '6', value: 1, color: '#FEEAC6', popupContent: KarmaPopUps.Karma.Lifetimes.LifetimesNoInfo },
          { id: 6, label: '7', value: 1, color: '#FDB02D', popupContent: KarmaPopUps.Karma.Lifetimes.LifetimesNoInfo },
          { id: 7, label: '8', value: 1, color: '#FEEAC6', popupContent: KarmaPopUps.Karma.Lifetimes.LifetimesNoInfo },
          { id: 8, label: '9', value: 1, color: '#FDB02D', popupContent: KarmaPopUps.Karma.Lifetimes.LifetimesNoInfo },
          { id: 9, label: 'More Than 9', value: 1, color: '#FEEAC6', popupContent: KarmaPopUps.Karma.Lifetimes.LifetimesNoInfo },
        ],
        parent: "Karma",
        level: 2,
        subPages: {},
        textChartInstructions: <LifetimesText />
      },
      "Points of Light": {
        chart: [

          { id: 0, label: 'Red', value: 1, color: '#FB2B17', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
          { id: 1, label: 'Orange', value: 1, color: '#FF581D', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
          { id: 2, label: 'Yellow', value: 1, color: '#FDB02D', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
          { id: 3, label: 'Green', value: 1, color: '#0FAE4D', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
          { id: 4, label: 'Blue', value: 1, color: '#0B349B', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
          { id: 5, label: 'Purple', value: 1, color: '#502795', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
          { id: 6, label: 'White', value: 1, color: '#F5F5F5', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
          { id: 7, label: 'Black', value: 1, color: '#000000', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
          { id: 8, label: 'Indigo', value: 1, color: '#102386', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
          { id: 9, label: 'Turquoise', value: 1, color: '#00B4A5', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
          { id: 10, label: 'Rainbow', value: 1, color: '#FF8C49', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
          { id: 11, label: 'Bronze', value: 1, color: '#C96A4D', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
          { id: 12, label: 'Gold', value: 1, color: '#F8C390', popupContent: KarmaPopUps.Karma.Points.PointsNoInfo },
        ],
        parent: "Karma",
        level: 2,
        subPages: {}, 
        textChartInstructions: <PointsOfLightText />
      },
      "Trials": {
        chart: [
          { id: 0, label: 'Spiritual', value: 1, color: '#0D2D94', popupContent: KarmaPopUps.Karma.Trials.TrialsNoInfo },
          { id: 1, label: 'Emotional', value: 1, color: '#BEC6E2', popupContent: KarmaPopUps.Karma.Trials.TrialsNoInfo },
          { id: 2, label: 'Physical', value: 1, color: '#0D2D94', popupContent: KarmaPopUps.Karma.Trials.TrialsNoInfo },
          { id: 3, label: 'Relational', value: 1, color: '#BEC6E2', popupContent: KarmaPopUps.Karma.Trials.TrialsNoInfo },
          { id: 4, label: 'Situational', value: 1, color: '#0D2D94', popupContent: KarmaPopUps.Karma.Trials.TrialsNoInfo },
          { id: 5, label: 'Past Life', value: 1, color: '#BEC6E2', popupContent: KarmaPopUps.Karma.Trials.TrialsNoInfo },
        ],
        parent: "Karma",
        level: 2,
        subPages: {},
        textChartInstructions: <TrialsText />
      },
      "Lessons & Karma": {
        chart: [
          { id: 0, label: 'Spiritual Lessons', value: 1, color: '#003C75', popupContent: KarmaPopUps.Karma.Lessons.LessonsNoInfo },
          { id: 1, label: 'Karmic Lessons', value: 1, color: '#BACADA' },
          { id: 2, label: 'Unresolved Trauma', value: 1, color: '#003C75', popupContent: KarmaPopUps.Karma.Lessons.LessonsNoInfo },
          { id: 3, label: 'Energy Grids', value: 1, color: '#BACADA', popupContent: KarmaPopUps.Karma.Lessons.LessonsNoInfo },
        ],
        parent: "Karma",
        level: 2,
        subPages: {},
        textChartInstructions: <LessonsAndTrialsText />
      },


    }
  }
}