import { ChartPagesType } from './types';
import { AnimalCharts } from './Animal/charts';
import { PeopleCharts } from './People/charts';
import { TraumaAnimalCharts } from './TraumaAnimal/charts';
import { TraumaPeopleCharts } from './TraumaPeople/charts';
import { SoulCharts } from './Soul/charts';
import { FreedomCharts } from './Freedom/charts';
import { OriginCharts } from './Origin/charts';
import { NatureCharts } from './Nature/charts';
import { BusinessCharts } from './Business/charts';
import { HomeCharts } from './Home/charts';
import { NutritionCharts } from './Nutrition/charts';


import { KarmaCharts } from './Karma/charts';
import { SpiritCharts } from './Spirit/charts';

// This is the main data structure that holds all the chart data
export const ChartPages: ChartPagesType = {
  "All Modules": {
    chart: [
      { id: 0, label: 'Animal', value: 1, color: '#88181B' },
      { id: 1, label: 'People', value: 1, color: '#EE1D23' },
      { id: 2, label: 'Trauma Animal', value: 1, color: '#F58229' },
      { id: 3, label: 'Trauma People', value: 1, color: '#F0AF13' },
      { id: 4, label: 'Soul', value: 1, color: '#FFDD17', textColor: "white" },
      { id: 5, label: 'Freedom', value: 1, color: '#8BC63E' },
      { id: 6, label: 'Origin', value: 1, color: '#3AB54A' },
      { id: 7, label: 'Nature', value: 1, color: '#0C6638' },
      { id: 8, label: 'Business', value: 1, color: '#037370' },
      { id: 9, label: 'Home', value: 1, color: '#1074BC' },
      { id: 10, label: 'Nutrition', value: 1, color: '#283890' },
      { id: 11, label: 'Karma', value: 1, color: '#652D91' },
      { id: 12, label: 'Spirit', value: 1, color: '#9E1F62' }
    ],
    parent: null,
    level: 0,
    subPages: {}
  },

  ...AnimalCharts,
  ...PeopleCharts,
  ...TraumaAnimalCharts,
  ...TraumaPeopleCharts,
  ...SoulCharts,
  ...FreedomCharts,
  ...OriginCharts,
  ...NatureCharts,
  ...BusinessCharts,
  ...HomeCharts,
  ...NutritionCharts,
  ...KarmaCharts,
  ...SpiritCharts

}