import * as React from 'react';
import PropTypes from 'prop-types';
import { animated, SpringValue, to } from '@react-spring/web';
import { arc as d3Arc } from 'd3-shape';
import composeClasses from '@mui/utils/composeClasses';
import generateUtilityClass from '@mui/utils/generateUtilityClass';
import { styled } from '@mui/material/styles';
import generateUtilityClasses from '@mui/utils/generateUtilityClasses';

export interface PieArcLabelClasses {
  /** Styles applied to the root element. */
  root: string;
  /** Styles applied to the root element when higlighted. */
  highlighted: string;
  /** Styles applied to the root element when faded. */
  faded: string;
}

export type PieArcLabelClassKey = keyof PieArcLabelClasses;

interface PieArcLabelOwnerState {
  id: string | number;
  color: string;
  isFaded: boolean;
  isHighlighted: boolean;
  classes?: Partial<PieArcLabelClasses>;
}

export function getPieArcLabelUtilityClass(slot: string) {
  return generateUtilityClass('MuiPieArcLabel', slot);
}

export const pieArcLabelClasses: PieArcLabelClasses = generateUtilityClasses('MuiPieArcLabel', [
  'root',
  'highlighted',
  'faded',
]);

const useUtilityClasses = (ownerState: PieArcLabelOwnerState) => {
  const { classes, id, isFaded, isHighlighted } = ownerState;
  const slots = {
    root: ['root', `series-${id}`, isHighlighted && 'highlighted', isFaded && 'faded'],
  };

  return composeClasses(slots, getPieArcLabelUtilityClass, classes);
};

const PieArcLabelRoot = styled(animated.text, {
  name: 'MuiPieArcLabel',
  slot: 'Root',
  overridesResolver: (_, styles) => styles.root,
})(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'middle',
}));

export type PieArcLabelProps = PieArcLabelOwnerState &
  Omit<React.ComponentPropsWithoutRef<'text'>, 'id'> & {
    startAngle: SpringValue<number>;
    endAngle: SpringValue<number>;
    innerRadius: SpringValue<number>;
    outerRadius: SpringValue<number>;
    arcLabelRadius: SpringValue<number>;
    cornerRadius: SpringValue<number>;
    paddingAngle: SpringValue<number>;
    onItemClick?: (label : string) => void;
  } & {
    formattedArcLabel?: string | null;
    disableTextRotation?: boolean;
  };

/**
 * Helper to compute label position.
 * It's not an inline function because we need it in inerpolation.
 */
const getLabelPosition =
  (formattedArcLabel: string | null | undefined, variable: 'x' | 'y') =>
  (
    startAngle: number,
    endAngle: number,
    padAngle: number,
    arcLabelRadius: number,
    cornerRadius: number,
  ) => {
    if (!formattedArcLabel) {
      return 0;
    }
    const [x, y] = d3Arc().cornerRadius(cornerRadius).centroid({
      padAngle,
      startAngle,
      endAngle,
      innerRadius: arcLabelRadius,
      outerRadius: arcLabelRadius,
    })!;
    if (variable === 'x') {
      return x;
    }
    return y;
  };

const radianToDegree = (radian: number) => (radian * 180) / Math.PI;

  const getLabelRotation =
  (formattedArcLabel: string | null | undefined) =>
  (
    startAngle: number,
    endAngle: number,
    padAngle: number,
    arcLabelRadius: number,
    cornerRadius: number,
  ) => {
    // center of the arc
    const diff :number = radianToDegree(startAngle) + (radianToDegree(endAngle)-radianToDegree(startAngle))/2;

    // we need to rotate the label to be parallel to the arc
    // so we need to rotate it by the angle of the arc
    const label_angle = 90 - (180 - 90 - diff);

    // if the angle is negative we need to rotate the label by 90 degrees
    // to make it parallel to the arc
    if (radianToDegree(startAngle) < -1) {
        return label_angle + 90;
    }
    else
    {
        return label_angle - 90;
    }
    
  };

function PieArcLabel(props: PieArcLabelProps) {
  const {
    id,
    classes: innerClasses,
    color,
    startAngle,
    endAngle,
    paddingAngle,
    arcLabelRadius,
    innerRadius,
    outerRadius,
    cornerRadius,
    formattedArcLabel,
    disableTextRotation,
    isHighlighted,
    isFaded,
    style,
    onItemClick, 
    ...other
  } = props;

  const ownerState = {
    id,
    classes: innerClasses,
    color,
    isFaded,
    isHighlighted,
  };
  const classes = useUtilityClasses(ownerState);

  const rotation = to(
    [startAngle, endAngle, paddingAngle, arcLabelRadius, cornerRadius],getLabelRotation(formattedArcLabel));

    const lines = formattedArcLabel ? formattedArcLabel.split('\n') : [];
    const tspanElements = lines.map((line, index) => (
      <tspan x={0} dy={index > 0 ? "1.2em" : 0} textAnchor="middle" key={index}>
        {line}
      </tspan>
    ));

  return (
    <PieArcLabelRoot
      className={classes.root}
      {...other}
      style={{
        cursor: 'pointer', 
        x: to(
          [startAngle, endAngle, paddingAngle, arcLabelRadius, cornerRadius],
          getLabelPosition(formattedArcLabel, 'x'),
        ),
        y: to(
          [startAngle, endAngle, paddingAngle, arcLabelRadius, cornerRadius],
          getLabelPosition(formattedArcLabel, 'y'),
        ),
        transform: disableTextRotation ? undefined : rotation.to(r => `rotate(${r}deg)`),

        ...style,
      }}
      onClick={(event) => {
        if (onItemClick && formattedArcLabel) {
            onItemClick(formattedArcLabel);
        }
      }}
    >
      {tspanElements}
    </PieArcLabelRoot>
  );
}

PieArcLabel.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // | To update them edit the TypeScript types and run "yarn proptypes"  |
  // ----------------------------------------------------------------------
  classes: PropTypes.object,
  formattedArcLabel: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isFaded: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
} as any;

export { PieArcLabel };