import React from 'react';
import { ChartPagesType } from '../types'
import { PeoplePopUps } from './popups'
import Typography from '@mui/material/Typography';
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../utils';

export const NutritionChartLabel = () => {
    return (<Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}> For more comprehensive information consult Nutrition Style Module</Typography>);
};

export const PeopleCharts: ChartPagesType = {
    "People": {
        chart: [
            { id: 0, label: 'Basic Needs', value: 1, color: '#8F241F' },
            { id: 1, label: 'Nutrition', value: 1, color: '#D52329' },
            { id: 2, label: 'Body Area', value: 1, color: '#FF7B27' },
            { id: 3, label: 'Health Status', value: 1, color: '#FDB02D' },
            { id: 4, label: 'Emotional', value: 1, color: '#4BAD43' },
            { id: 5, label: 'Relationships', value: 1, color: '#005D38' },
            { id: 6, label: 'Environment', value: 1, color: '#008b98' },
            { id: 7, label: 'Body Chemistry', value: 1, color: '#263692' },
            { id: 8, label: 'Upward & Onward', value: 1, color: '#0F1D6D' },
            { id: 9, label: 'Traits', value: 1, color: '#662C91' },
            { id: 10, label: 'Out & About', value: 1, color: '#862074' },
            { id: 11, label: 'Health Intervention', value: 1, color: '#896531' },
        ],
        parent: "All Modules",
        level: 1,
        subPages: {
            "Basic Needs": {
                chart: [
                    { id: 0, label: 'Sleep & Rest', value: 1, color: '#8F241F', popupContent: PeoplePopUps.People.BasicNeeds.Sleep },
                    { id: 1, label: 'Climate', value: 1, color: '#E3C8C7', popupContent: PeoplePopUps.People.BasicNeeds.Climate },
                    { id: 2, label: 'Home & Living Space', value: 1, color: '#8F241F', popupContent: PeoplePopUps.People.BasicNeeds.HomeLivingSpace },
                    { id: 3, label: 'Air Quality', value: 1, color: '#E3C8C7', popupContent: PeoplePopUps.People.BasicNeeds.AirQuality },
                    { id: 4, label: 'Food', value: 1, color: '#8F241F', popupContent: PeoplePopUps.People.BasicNeeds.Food },
                    { id: 5, label: 'Drink', value: 1, color: '#E3C8C7', popupContent: PeoplePopUps.People.BasicNeeds.Drink },
                    { id: 6, label: 'Grooming', value: 1, color: '#8F241F', popupContent: PeoplePopUps.People.BasicNeeds.Grooming },
                    { id: 7, label: 'Water', value: 1, color: '#E3C8C7', popupContent: PeoplePopUps.People.BasicNeeds.Water },
                    { id: 8, label: 'Sexual', value: 1, color: '#8F241F', popupContent: PeoplePopUps.People.BasicNeeds.Sexual },
                    { id: 9, label: 'Touch', value: 1, color: '#E3C8C7', popupContent: PeoplePopUps.People.BasicNeeds.Touch },
                    { id: 10, label: 'Love', value: 1, color: '#8F241F', popupContent: PeoplePopUps.People.BasicNeeds.Love },
                ],
                parent: "People",
                level: 2,
                subPages: {}
            },
            "Nutrition": {
                chart: [
                    { id: 0, label: 'Foods & Treats', value: 1, color: '#D52329' },
                    { id: 1, label: 'Allergy & Intolerance', value: 1, color: '#F4C8C9', popupContent: PeoplePopUps.People.Nutrition.AllergyIntolerance },
                    { id: 2, label: 'Cell Balancing Salts', value: 1, color: '#D52329' },
                    { id: 3, label: 'Vitamins', value: 1, color: '#F4C8C9' },
                    { id: 4, label: 'Supplements', value: 1, color: '#D52329' },
                    { id: 5, label: 'Minerals', value: 1, color: '#F4C8C9' },
                    { id: 6, label: 'EFA', value: 1, color: '#D52329', popupContent: PeoplePopUps.People.Nutrition.EFA },
                    { id: 7, label: 'Hydration', value: 1, color: '#F4C8C9', popupContent: PeoplePopUps.People.Nutrition.Hydration },
                    { id: 8, label: 'Herbs', value: 1, color: '#D52329' },
                    { id: 9, label: 'PH Balance', value: 1, color: '#F4C8C9', popupContent: PeoplePopUps.People.Nutrition.PHBalance  },
                    { id: 10, label: 'Additives', value: 1, color: '#D52329' },
                    { id: 11, label: 'Amino Acids', value: 1, color: '#F4C8C9' },
                    { id: 12, label: 'Medications', value: 1, color: '#D52329', popupContent: PeoplePopUps.People.Nutrition.Medications },

                ],
                parent: "People",
                level: 2,
                subPages: {},
                textChartInstructions: <NutritionChartLabel />
            },
            "Body Area": {
                chart: [
                    { id: 0, label: 'Organs', value: 1, color: '#F48026' },
                    { id: 1, label: 'Neurological', value: 1, color: '#FCDFC9', popupContent: PeoplePopUps.People.BodyArea.Neurological },
                    { id: 2, label: 'Endocrine', value: 1, color: '#F48026' },
                    { id: 3, label: 'Eye(s)', value: 1, color: '#FCDFC9', popupContent: PeoplePopUps.People.BodyArea.Eyes },
                    { id: 4, label: 'Ear(s)', value: 1, color: '#F48026', popupContent: PeoplePopUps.People.BodyArea.Ears },
                    { id: 5, label: 'Skin', value: 1, color: '#FCDFC9', popupContent: PeoplePopUps.People.BodyArea.Skin },
                    { id: 6, label: 'Lymph', value: 1, color: '#F48026', popupContent: PeoplePopUps.People.BodyArea.Lymph },
                    { id: 7, label: 'Brain', value: 1, color: '#FCDFC9', popupContent: PeoplePopUps.People.BodyArea.Brain },
                    { id: 8, label: 'Colon', value: 1, color: '#F48026', popupContent: PeoplePopUps.People.BodyArea.Colon },
                    { id: 9, label: 'Hands & Feet', value: 1, color: '#FCDFC9', popupContent: PeoplePopUps.People.BodyArea.HandsFeet },
                    { id: 10, label: 'Fascia', value: 1, color: '#F48026', popupContent: PeoplePopUps.People.BodyArea.Fascia },
                    { id: 11, label: 'Muscles & Tendons', value: 1, color: '#FCDFC9', popupContent: PeoplePopUps.People.BodyArea.Tendons },
                    { id: 12, label: 'Bones', value: 1, color: '#F48026', popupContent: PeoplePopUps.People.BodyArea.Bones },
                    { id: 13, label: 'Circ. System', value: 1, color: '#FCDFC9', popupContent: PeoplePopUps.People.BodyArea.CirculatorySystem },
                    { id: 14, label: 'Body Part', value: 1, color: '#F48026', popupContent: PeoplePopUps.People.BodyArea.BodyPart },
                    { id: 15, label: 'Arm & Leg', value: 1, color: '#FCDFC9', popupContent: PeoplePopUps.People.BodyArea.ArmLeg },
                ],
                parent: "People",
                level: 2,
                subPages: {}
            },
            "Health Status": {
                chart: [
                    { id: 0, label: 'Digestion & Elimination', value: 1, color: '#FDB02D', popupContent: PeoplePopUps.People.HealthStatus.Digestion },
                    { id: 1, label: 'Pain', value: 1, color: '#FFEBCA', popupContent: PeoplePopUps.People.HealthStatus.Pain },
                    { id: 2, label: 'Weight', value: 1, color: '#FDB02D', popupContent: PeoplePopUps.People.HealthStatus.Weight },
                    { id: 3, label: 'Energy ', value: 1, color: '#FFEBCA', popupContent: PeoplePopUps.People.HealthStatus.Energy },
                    { id: 4, label: 'Energy Field', value: 1, color: '#FDB02D', popupContent: PeoplePopUps.People.HealthStatus.EnergyField },
                    { id: 5, label: 'Life Force', value: 1, color: '#FFEBCA', popupContent: PeoplePopUps.People.HealthStatus.LifeForce },
                    { id: 6, label: 'Pregnant', value: 1, color: '#FDB02D', popupContent: PeoplePopUps.People.HealthStatus.Pregnant },
                    { id: 7, label: 'Obstruction', value: 1, color: '#FFEBCA', popupContent: PeoplePopUps.People.HealthStatus.Obstruction },
                    { id: 8, label: 'Allergy & Intolerance', value: 1, color: '#FDB02D', popupContent: PeoplePopUps.People.HealthStatus.Allergy },
                    { id: 9, label: 'Toxicity', value: 1, color: '#FFEBCA', popupContent: PeoplePopUps.People.HealthStatus.Toxicity },
                    { id: 10, label: 'Body/Temp', value: 1, color: '#FDB02D', popupContent: PeoplePopUps.People.HealthStatus.BodyTemp },
                    { id: 11, label: 'Cyst', value: 1, color: '#FFEBCA', popupContent: PeoplePopUps.People.HealthStatus.Cyst },
                    { id: 12, label: 'Tumor', value: 1, color: '#FDB02D', popupContent: PeoplePopUps.People.HealthStatus.Tumor },
                    { id: 13, label: 'Inflam.', value: 1, color: '#FFEBCA', popupContent: PeoplePopUps.People.HealthStatus.Inflam },
                    { id: 14, label: 'Arthritis', value: 1, color: '#FDB02D', popupContent: PeoplePopUps.People.HealthStatus.Arthritis },
                    { id: 15, label: 'Nutritional Issue', value: 1, color: '#FFEBCA', popupContent: PeoplePopUps.People.HealthStatus.NutritionalIssue },
                ],
                parent: "People",
                level: 2,
                subPages: {}
            },
            "Emotional": {
                chart: [
                    { id: 0, label: 'Happy', value: 1, color: '#4BAD43' },
                    { id: 1, label: 'Sad', value: 1, color: '#D2EAD0' },
                    { id: 2, label: 'Angry', value: 1, color: '#4BAD43' },
                    { id: 3, label: 'Scared', value: 1, color: '#D2EAD0' },
                    { id: 4, label: 'Confused', value: 1, color: '#4BAD43' },
                    { id: 5, label: 'Bored', value: 1, color: '#D2EAD0', popupContent: PeoplePopUps.People.Emotional.Bored },
                    { id: 6, label: 'Attn. Issue', value: 1, color: '#4BAD43', popupContent: PeoplePopUps.People.Emotional.AttnIssue },
                    { id: 7, label: 'Need Space', value: 1, color: '#D2EAD0', popupContent: PeoplePopUps.People.Emotional.NeedSpace },
                    { id: 8, label: 'Self Esteem', value: 1, color: '#4BAD43', popupContent: PeoplePopUps.People.Emotional.Esteem },
                    { id: 9, label: 'Grieving', value: 1, color: '#D2EAD0', popupContent: PeoplePopUps.People.Emotional.Grieving },
                    { id: 10, label: 'Worried', value: 1, color: '#4BAD43', popupContent: PeoplePopUps.People.Emotional.Worried },
                    { id: 11, label: 'Jealous', value: 1, color: '#D2EAD0', popupContent: PeoplePopUps.People.Emotional.Jealous },
                    { id: 12, label: 'TFG', value: 1, color: '#4BAD43', popupContent: PeoplePopUps.People.Emotional.TGF },
                    { id: 13, label: 'Shame', value: 1, color: '#D2EAD0', popupContent: PeoplePopUps.People.Emotional.Shame },
                    { id: 14, label: 'W/O Direction', value: 1, color: '#4BAD43', popupContent: PeoplePopUps.People.Emotional.WithoutDirection },
                    { id: 15, label: 'Need to Belong', value: 1, color: '#D2EAD0', popupContent: PeoplePopUps.People.Emotional.NeedToBelong },
                    { id: 16, label: 'Preoccupied', value: 1, color: '#4BAD43', popupContent: PeoplePopUps.People.Emotional.Preoccupied },
                    { id: 17, label: 'Overwhelmed', value: 1, color: '#D2EAD0', popupContent: PeoplePopUps.People.Emotional.Overwhelmed },
                    { id: 18, label: 'Emotional Sponging', value: 1, color: '#4BAD43', popupContent: PeoplePopUps.People.Emotional.EmotionalSponging },
                ],
                parent: "People",
                level: 2,
                subPages: {}
            },
            "Relationships": {
                chart: [
                    { 'id': 0, 'label': 'Inner Circle', 'value': 1, 'color': '#005D38' },
                    { 'id': 1, 'label': 'Outer Circle', 'value': 1, 'color': '#BFD6CD' },
                    { 'id': 2, 'label': 'Professional Circle', 'value': 1, 'color': '#005D38' },
                ],
                parent: "People",
                level: 2,
                subPages: {}
            },
            "Environment": {
                chart: [
                    { 'id': 0, 'label': 'Emotional', 'value': 1, 'color': '#008B98' },
                    { 'id': 1, 'label': 'Physical', 'value': 1, 'color': '#c0e2e5' },
                    { 'id': 2, 'label': 'Spiritual', 'value': 1, 'color': '#008B98' },
                ],
                parent: "People",
                level: 2,
                subPages: {}
            },
            "Body Chemistry": {
                chart: [
                    { "id": 0, "label": "Parasites", "value": 1, "color": "#0D2D94" },
                    { "id": 1, "label": "Bacteria", "value": 1, "color": "#C2CAE4" },
                    { "id": 2, "label": "Viruses", "value": 1, "color": "#0D2D94" },
                    { "id": 3, "label": "Mold & Fungus", "value": 1, "color": "#C2CAE4" },
                    { "id": 4, "label": "Toxins", "value": 1, "color": "#0D2D94" },
                    { "id": 5, "label": "Vaccine", "value": 1, "color": "#C2CAE4" },
                    { "id": 6, "label": "Nutrition", "value": 1, "color": "#0D2D94", popupContent: PeoplePopUps.People.BodyChemistry.Nutrition },
                    { "id": 7, "label": "Heavy Metal", "value": 1, "color": "#C2CAE4", popupContent: PeoplePopUps.People.BodyChemistry.HeavyMetal },
                    { "id": 8, "label": "Additive", "value": 1, "color": "#0D2D94" },
                    { "id": 9, "label": "PH Imbalance", "value": 1, "color": "#C2CAE4", popupContent: PeoplePopUps.People.BodyChemistry.PHImbalance },
                    { "id": 10, "label": "Medication Issue", "value": 1, "color": "#0D2D94", popupContent: PeoplePopUps.People.BodyChemistry.MedicationIssue },
                ],
                parent: "People",
                level: 2,
                subPages: {}
            },
            "Upward & Onward": {
                chart: [
                    { "id": 0, "label": "Life Purpose", "value": 1, "color": "#0F1D6D" },
                    { "id": 1, "label": "Goals", "value": 1, "color": "#C2C6DA" },
                    { "id": 2, "label": "Dreams", "value": 1, "color": "#0F1D6D" },
                    { "id": 3, "label": "Education & Learning", "value": 1, "color": "#C2C6DA" },
                ],
                parent: "People",
                level: 2,
                subPages: {}
            },
            "Traits": {
                chart: [
                    { "id": 0, "label": "Chart A", "value": 1, "color": "#662C91" },
                    { "id": 1, "label": "Chart B", "value": 1, "color": "#D3C8E4" },
                    { "id": 2, "label": "Chart C", "value": 1, "color": "#662C91" },
                    { "id": 3, "label": "Chart D", "value": 1, "color": "#D3C8E4" },
                    { "id": 4, "label": "Chart E", "value": 1, "color": "#662C91" },
                    { "id": 5, "label": "Chart F", "value": 1, "color": "#D3C8E4" },
                    { "id": 6, "label": "Chart G", "value": 1, "color": "#662C91" },
                ],
                parent: "People",
                level: 2,
                subPages: {}
            },
            "Out & About": {
                chart: [
                    { "id": 0, "label": "Activities & Hobbies", "value": 1, "color": "#862074" },
                    { "id": 1, "label": "Equipment", "value": 1, "color": "#e1c7dc" },
                    { "id": 2, "label": "Transportation", "value": 1, "color": "#862074" },

                ],
                parent: "People",
                level: 2,
                subPages: {}
            },
            "Health Intervention": {
                chart: [
                    { "id": 0, "label": "Naturopathic Doctor", "value": 1, "color": "#896531", popupContent: PeoplePopUps.People.HealthIntervention.NaturopathicDoctor },
                    { "id": 1, "label": "Chiropractor", "value": 1, "color": "#E1D8CC", popupContent: PeoplePopUps.People.HealthIntervention.Chiropractor },
                    { "id": 2, "label": "Acupuncture", "value": 1, "color": "#896531", popupContent: PeoplePopUps.People.HealthIntervention.Acupuncture },
                    { "id": 3, "label": "Homeopathy", "value": 1, "color": "#E1D8CC", popupContent: PeoplePopUps.People.HealthIntervention.Homeopathy },
                    { "id": 4, "label": "Nonverbal Comm.", "value": 1, "color": "#896531", popupContent: PeoplePopUps.People.HealthIntervention.NonVerbalComm },
                    { "id": 5, "label": "Medication", "value": 1, "color": "#E1D8CC", popupContent: PeoplePopUps.People.HealthIntervention.Medication },
                    { "id": 6, "label": "Dentist", "value": 1, "color": "#896531", popupContent: PeoplePopUps.People.HealthIntervention.Dentist },
                    { "id": 7, "label": "Flower Essec.", "value": 1, "color": "#E1D8CC", popupContent: PeoplePopUps.People.HealthIntervention.FlowerEssences },
                    { "id": 8, "label": "Dowsing", "value": 1, "color": "#896531", popupContent: PeoplePopUps.People.HealthIntervention.Dowsing },
                    { "id": 9, "label": "Dr. Of Osteo.", "value": 1, "color": "#E1D8CC", popupContent: PeoplePopUps.People.HealthIntervention.Osteo },
                    { "id": 10, "label": "Colonics", "value": 1, "color": "#896531", popupContent: PeoplePopUps.People.HealthIntervention.Colonics },
                    { "id": 11, "label": "Essential Oils", "value": 1, "color": "#E1D8CC"},
                    { "id": 12, "label": "Detox", "value": 1, "color": "#896531", popupContent: PeoplePopUps.People.HealthIntervention.Detox },
                    { "id": 13, "label": "Western MD", "value": 1, "color": "#E1D8CC", popupContent: PeoplePopUps.People.HealthIntervention.WesternMD },
                    { "id": 14, "label": "Energy Modality", "value": 1, "color": "#896531" },
                    { "id": 15, "label": "Energy Field Cleanse", "value": 1, "color": "#E1D8CC", popupContent: PeoplePopUps.People.HealthIntervention.EnergyFeild },
                    { "id": 16, "label": "Emergency Care", "value": 1, "color": "#896531", popupContent: PeoplePopUps.People.HealthIntervention.EmergencyCare },
                ],
                parent: "People",
                level: 2,
                subPages: {}
            }
        }
    }
}