import React from 'react';
import { Typography, Box } from '@mui/material';
import ModuleChart from '../ModuleChart/ModuleChart';
import { ChartPageType } from '../../data/types';
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../utils';

interface BodyClimateProps {
    height: number;
    width: number;
}

const PhBalanceData: ChartPageType = {
    chart: [
        { label: 'Acidic', value: 1, id: 0, color: '#571B1E' },
        { label: 'Neutral', value: 1, id: 1, color: '#D2C2C3', disableTextRotation: true },
        { label: 'Alkaline', value: 1, id: 2, color: '#571B1E' }
    ],
    level: 0,
    subPages: {},
    disableClick: true,
    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >PH Balance</Typography>
}

const HydrationData: ChartPageType = {
    chart: [
        { label: 'Dehydrated', value: 1, id: 0, color: '#571B1E' },
        { label: 'Moderately Hydrated', labelWithFormatting: "Moderately\nHydrated", value: 1, id: 1, color: '#D2C2C3', disableTextRotation: true },
        { label: 'Fully Hydrated', labelWithFormatting: "Fully\nHydrated", value: 1, id: 2, color: '#571B1E' }
    ],
    level: 0,
    subPages: {},
    disableClick: true,
    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >Hydration</Typography>
}


const MusclePctData: ChartPageType = {
    chart: [
        { label: '0 %', value: 1, id: 0, color: '#D2C2C3' },
        { label: '10 %', value: 1, id: 1, color: '#C7B3B4' },
        { label: '20 %', value: 1, id: 2, color: '#BCA4A5' },
        { label: '30 %', value: 1, id: 3, color: '#B09496' },
        { label: '40 %', value: 1, id: 4, color: '#A58587' },
        { label: '50 %', value: 1, id: 5, color: '#9A7678' },
        { label: '60 %', value: 1, id: 6, color: '#8F6769' },
        { label: '70 %', value: 1, id: 7, color: '#84585A' },
        { label: '80 %', value: 1, id: 8, color: '#79494B' },
        { label: '90 %', value: 1, id: 9, color: '#6D393C' },
        { label: '100 %', value: 1, id: 10, color: '#622A2D' },
    ],
    level: 0,
    subPages: {},
    disableClick: true,
    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >Muscle Percentage</Typography>

}

const BodyClimatePage: React.FC<BodyClimateProps> = ({ height, width }) => {
    const top_chart_heights = height / 2;
    const bottom_chart_heights = top_chart_heights;
    const chart_widths = width;
    return (
        <>
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <ModuleChart
                    chartRadius={"60%"}
                    height={top_chart_heights}
                    width={chart_widths}
                    chartData={PhBalanceData}
                    chartModule={"Nutrition"}
                    item={"Body Climate"}
                    subitem={null} />
                <ModuleChart
                    chartRadius={"60%"}
                    height={top_chart_heights}
                    width={chart_widths}
                    chartData={HydrationData}
                    chartModule={"Nutrition"}
                    item={"Body Climate"}
                    subitem={null} />
            </Box>
            <ModuleChart
                height={bottom_chart_heights}
                width={chart_widths}
                chartData={MusclePctData}
                chartModule={"Nutrition"}
                item={"Body Climate"}
                subitem={null} />
        </>
    );
}
export default BodyClimatePage;
