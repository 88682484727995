import { WordListMappingType } from "../types";

export const FreedomWordListMap: WordListMappingType = {
  "Freedom": {
    "Incongruence": {
      "Belief": [
        "I'm not ____ enough.",
        "I need to make others happy so I won't be rejected.",
        "I cannot be happy until ____________ happens.",
        "If people really knew me, they would not like me.",
        "I'll never change.",
        "Things are hard, no pain no gain.",
        "I am being punished; I deserve to be punished.",
        "I do not deserve ____________.",
        "I should know better.",
        "I could never make money doing what I love to do.",
        "Do not get your hopes up or you will be let down.",
        "I am not good enough.",
        "I am bad.",
        "If I am successful, people will not like me.",
        "I have to be perfect and cannot make a mistake.",
        "I am different and nobody understands or believes in me.",
        "I'm too: ______ ",
        "All people are out for themselves.",
        "I am so unlucky.",
        "If I do not achieve ______, then there is no point in starting.",
        "I am helpless to change things.",
        "What would people think?",
        "If I do not do for others or give them what they want, I will be alone.",
        "I do not know what I want.",
        "I cannot be trusted.",
        "I cannot do it.",
        "I do not have time.",
        "I cannot afford what I want.",
        "I am too scared.",
        "I am too damaged.",
        "I am stuck.",
        "I can't.",
        "I should: ______ ",
        "I am overwhelmed.",
        "It is too late.",
        "I have to stay; I cannot do this on my own.",
        "If I am happy when others are not, it means that I do not care.",
        "If they really loved me then they would: ______ .",
        "I need to produce and perform in order to be worthy.",
        "My needs should be last.",
        "I am responsible for other's happiness.",
        "Others are responsible for my happiness.",
        "If I speak my mind, I will be rejected.",
        "I am unlovable.",
        "I will never have enough: ",
        "My issues will always hold me back.",
        "Time is money.",
        "Dreams are not practical.",
        "Work must be a chore.",
        "Marriage makes you dull and boring.",
        "I lack relevant training and experience.",
        "I could never do that.",
        "I do not have confidence to do that.",
        "I would fail if I tried that.",
        "Taking risks always turns out bad for me.",
        "I am comfortable so why shake it up.",
        "It is my parent's fault.",
        "My partner does not support me.",
        "I am a slow learner.",
        "You have to fight your way to the top.",
        "I always attract_______: ",
        "I am no good at: ______",
        "There is no point in trying.",
        "It would never work.",
        "This always happens to me.",
        "I am not good in relating to others.",
        "No one would be attracted to me.",
        "It will not last.",
        "I am lazy.",
        "I will never be enough",
        "You have to fight to be loved"

      ],
      "Habit": [
        "Always doing things the same way irregardless of a different choice.",
        "Going Against Your Intuition",
        "Not trusting yourself.",
        "Not listening.",
        "Comparing yourself to others.",
        "Generalizing.",
        "Doing something ____________ that is too much.",
        "Cancelling things that you have planned.",
        "Doubting yourself and others.",
        "Perfectionism.",
        "Being rigid and inflexible.",
        "Being closed minded.",
        "Judging.",
        "Worrying.",
        "Blaming.",
        "Procrastinating.",
        "Sleeping.",
        "Watching TV.",
        "Overspending.",
        "Overusing medication.",
        "Overeating.",
        "Undereating.",
        "Being critical of self and others.",
        "Negativity.",
        "Being afraid.",
        "Arrogance."
      ],
      "Action": [
        "Always putting others first.",
        "Not setting boundaries.",
        "Saying yes when you mean no.",
        "Being hard on yourself.",
        "Overdoing it.",
        "Compromising your values for others.",
        "People pleasing.",
        "Being rebellious.",
        "Acting cool.",
        "Acting macho.",
        "Being overly independent.",
        "Always being tough.",
        "Needing to be sexy.",
        "Comparing.",
        "Talking over or at others and not to them.",
        "Speaking in jargon that people do not understand.",
        "Not listening."
      ],
      "Behavior": [
        "Holding yourself in high esteem and avoiding self-defeating behaviors.",
        "Being afraid of failure.",
        "Thinking you are indispensable.",
        "Fear of confrontation.",
        "Seeing things as black or white.",
        "Always looking for problems.",
        "Being addicted to a comfort zone.",
        "Thinking that how you feel now is how it is.",
        "Thinking that you know how things work.",
        "Being stuck in envy.",
        "Overthinking.",
        "The fear of appearing stupid keeps me from asking questions or offering my opinion.",
        "Not focusing on what is really important to me.",
        "Wishing I was someone else.",
        "Being irritable or moody.",
        "Acting impulsively.",
        "Wasting time.",
        "Putting things off until the last minute.",
        "Spending time with people who make you feel bad about yourself.",
        "Rejecting change.",
        "Unrealistic expectations.",
        "Procrastinating.",
        "Being abusive to self or others.",
        "Being racist.",
        "Bucking authority.",
        "Perfectionism.",
        "Holding a negative self concept.",
        "Dreaming about putting money away and then spending more than you make regularly.",
        "Procrastinating taking actions that will give you what you desire.",
        "Worrying about what other people think.",
        "You want to be out of debt but do not stop shopping.",
        "Prioritizing your to-do list and then start at the bottom.",
        "Stopping for fast food when you are committed to healthy eating.",
        "Focusing on what other people need to do rather than on what you need to do.",
        "Making a phone call when you wanted to be somewhere else.",
        "Saying no and then shortly thereafter giving in.",
        "Saying you want to quit drinking and then meeting a friend at the bar.",
        "Wanting to do something and then sabotaging yourself.",
        "Continuing to do things that are bad for me.",
        "Continuing to do things that I am not aligned with.",
      ],
      "Self-Love Issue": [
        "Social withdrawal.",
        "Anxiety and emotional turmoil.",
        "Lack of self-confidence.",
        "Lack of social skills.",
        "Eating disorders.",
        "Inability to accept compliments.",
        "Inability to see yourself accurately.",
        "Accentuating your negative traits.",
        "Worrying about what others think.",
        "Self-neglect.",
        "Treating yourself worse than others.",
        "Ruminating over your past behavior.",
        "Avoiding challenges.",
        "Not trusting yourself.",
        "Not expecting enough for yourself.",
        "Defensiveness.",
        "Your relationships are chaotic.",
        "Hypervigilance.",
        "Lack of assertiveness.",
        "Acting passive-aggressive.",
        "Being aggressive.",
        "Perfectionism.",
        "Poor boundaries.",
        "Poor communication.",
        "Promiscuous behavior.",
        "Sexual dysfunction.",
        "Wearing a mask.",
        "Being indecisive.",
        "Faking it.",
        "Difficulty being present.",
        "Telling white lies to avoid conflict.",
        "Chronic physical symptoms of fatigue and headaches etc.",
        "Giving up easily.",
        "Aiming too low.",
        "Robbing yourself of joy.",
        "Living in greed",
        "Needing outside approval",
        "Overindulging"
      ],
      "Angry": [
        "Abused",
        "Aggravated",
        "Agitated",
        "Anguished",
        "Annoyed",
        "Betrayed",
        "Cheated",
        "Coerced",
        "Controlled",
        "Deceived",
        "Dismayed",
        "Disgusted",
        "Displeased",
        "Dominated",
        "Enraged",
        "Exasperated",
        "Exploited",
        "Frustrated",
        "Fuming",
        "Furious",
        "Harrassed",
        "Hateful",
        "Hostile",
        "Humiliated",
        "Incensed",
        "Irritated",
        "Mad",
        "Offended",
        "Outraged",
        "Patronized",
        "Peeved",
        "Perturbed",
        "Pissed Off",
        "Provoked",
        "Rebellious",
        "Repulsed",
        "Repulsed",
        "Resentful",
        "Ridiculed",
        "Sabotaged",
        "Seething",
        "Smothered",
        "Spiteful",
        "Stifled",
        "Strangled",
        "Throttled",
        "Uptight",
        "Used",
        "Vengeful",
        "Vindictive"
      ],
      "Confused": [
        "Ambivalent",
        "Awkward",
        "Baffled",
        "Bewildered",
        "Bothered",
        "Constricted",
        "Directionless",
        "Disorganized",
        "Distracted",
        "Doubtful",
        "Flustered",
        "Foggy",
        "Hesitant",
        "Immobilized",
        "Misunderstood",
        "Perplexed",
        "Puzzled",
        "Stagnant",
        "Surprised",
        "Torn",
        "Trapped",
        "Troubled",
        "Uncertain",
        "Uncomfortable",
        "Undecided",
        "Unsettled",
        "Unsure"
      ],
      "Happy": [
        "Admired",
        "Alive",
        "Appreciated",
        "Assured",
        "Cheerful",
        "Confident",
        "Content",
        "Delighted",
        "Determined",
        "Ecstatic",
        "Elated",
        "Encouraged",
        "Energized",
        "Enthusiastic",
        "Excited",
        "Exuberant",
        "Flattered",
        "Fortunate",
        "Fulfilled",
        "Glad",
        "Good",
        "Grateful",
        "Gratified",
        "Hopeful",
        "Joyful",
        "Jubilant",
        "Justified",
        "Loved",
        "Marvelous",
        "Optimistic",
        "Peaceful",
        "Pleased",
        "Proud",
        "Relaxed",
        "Relieved",
        "Resolved",
        "Respected",
        "Satisfied",
        "Terrific",
        "Thrilled",
        "Tranquil",
        "Valued"
      ],
      "Sad": [
        "Alienated",
        "Ashamed",
        "Burdened",
        "Condemned",
        "Crushed",
        "Defeated",
        "Dejected",
        "Demoralized",
        "Depressed",
        "Deserted",
        "Despised",
        "Devastated",
        "Disappointed",
        "Discarded",
        "Discouraged",
        "Disgraced",
        "Disheartened",
        "Disillusioned",
        "Dismal",
        "Distant",
        "Distraught",
        "Distressed",
        "Drained",
        "Empty",
        "Exhausted",
        "Grievous",
        "Helpless",
        "Hopeless",
        "Humbled",
        "Inadequate",
        "Islanded",
        "Isolated",
        "Lonely",
        "Miserable",
        "Mournful",
        "Neglected",
        "Pitiful",
        "Regretful",
        "Rejected",
        "Resigned",
        "Sorrowful",
        "Terrible",
        "Unappreciated",
        "Uncared for",
        "Unloved",
        "Unwanted",
        "Upset",
        "Worthless",
        "Wounded"
      ],
      "Scared": [
        "Afraid",
        "Alarmed",
        "Anxious",
        "Appalled",
        "Apprehensive",
        "Awed",
        "Concerned",
        "Defensive",
        "Desperate",
        "Doubtful",
        "Fearful",
        "Frantic",
        "Full of dread",
        "Guarded",
        "Horrified",
        "Impatient",
        "Insecure",
        "Intimidated",
        "Nervous",
        "Overwhelmed",
        "Panicky",
        "Perplexed",
        "Petrified",
        "Reluctant",
        "Shaken",
        "Shocked",
        "Stunned",
        "Startled",
        "Skeptical",
        "Shy",
        "Suspicious",
        "Swamped",
        "Terrified",
        "Tense",
        "Threatened",
        "Timid",
        "Tormented",
        "Uneasy",
        "Unsure",
        "Vulnerable"
      ],
      "Emotion": [
        {
          title: "Emotions", words: [
            "Angry - See Chart",
            "Confused - See Chart",
            "Happy - See Chart",
            "Sad - See Chart",
            "Scared - See Chart"], links: ["/?module=Freedom&item=Incongruence&subitem=Emotion&detail=Angry",
              "/?module=Freedom&item=Incongruence&subitem=Emotion&detail=Confused",
              "/?module=Freedom&item=Incongruence&subitem=Emotion&detail=Happy",
              "/?module=Freedom&item=Incongruence&subitem=Emotion&detail=Sad",
              "/?module=Freedom&item=Incongruence&subitem=Emotion&detail=Scared"]
        },
        "Bored",
        "Needing attention",
        "Needing space",
        "Grieving",
        "Worried",
        "Jealous",
        "Feeling taken for granted",
        "Shame",
        "Without direction",
        "Needing to belong",
        "Preoccupied",
        "Overwhelmed",
        "Stressed",
        "Taking on other peoples emotions",
        "Feeling incongruent",
        "Feeling like a burden",
        "Giving up"
      ],
    }
  }
}