// ModuleBreadcrumbs.tsx
import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ModuleBreadcrumbsProps {
    module: string | null;
    item: string | null;
    subItem: string | null;
    detail: string | null;
}

export const ModuleBreadcrumbs: React.FC<ModuleBreadcrumbsProps> = ({ module, item, subItem, detail }) => {
    const navigate = useNavigate();
    let params = new URLSearchParams();

    const handleAllModulesClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        navigate('/');
    }

    const handleModuleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        params.append('module', module as string);
        navigate(`/?${params.toString()}`)
    }
    const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        params.append('module', module as string);
        params.append('item', item as string);
        navigate(`/?${params.toString()}`)
    }
    const handleSubItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        params.append('module', module as string);
        params.append('item', item as string);
        params.append('subitem', subItem as string);
        navigate(`/?${params.toString()}`)
    }
    const handleDetailClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        params.append('module', module as string);
        params.append('item', item as string);
        params.append('subitem', subItem as string);
        params.append('detail', detail as string);
        navigate(`/?${params.toString()}`)
    }

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ marginTop: '20px', marginLeft: '20px' }}>
            <Link underline="hover" color="inherit" onClick={handleAllModulesClick}>All Modules</Link>
            {module && module !== "All Modules" && <Link underline="hover" color="inherit" onClick={handleModuleClick}>{module}</Link>}
            {item && <Link underline="hover" color="inherit" onClick={handleItemClick}>{item}</Link>}
            {subItem && <Link underline="hover" color="inherit" onClick={handleSubItemClick} >{subItem}</Link>}
            {detail && <Link underline="hover" color="inherit" onClick={handleDetailClick} >{detail}</Link>}
        </Breadcrumbs>
    )
};

export default ModuleBreadcrumbs;