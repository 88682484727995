import {  WordsPopUp, } from "../popup"
import { popupIdMappingType } from "../types"
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';


export const OriginationPopUp = () => {
    return (
        <div>
            <Typography>Continue to the &nbsp;
                <Link to="?module=Home">Origination Info</Link> for additional focus. </Typography>
        </div>
    );
}

export const NumberPopUp = () => {
    return (
        <div>
            <Typography>Allow for a clearing.</Typography>
            <Typography>After clearing, continue to the <Link to="/?module=Spirit&item=Type+of+Marking">Type Of Marking Chart</Link>.</Typography>
        </div>
    );
}



export const SpiritPopUps: popupIdMappingType = {
    "Spirit": {
       
        "Number": {
            //They all have the same popup info
            "Number": <NumberPopUp />,

        },
        "Type": {
            //They all have the same popup info
            "Type": <WordsPopUp words={["Allow for a clearing."]} />,
            "Cells" : <WordsPopUp words={["RNA","DNA","Allow for a clearing."]} />,

        },
        "Origination": {
            //all of them have the same popup
            "Origination":<WordsPopUp words={["Continue to the Origination Info chart for additional focus."]} />,
         
        },
        "OriginationInfo" :{
            "OriginationInfo":<WordsPopUp words={["Allow for a clearing."]} />,

        },
        "Life": {
            //all of them have the same popup
            "Life":<WordsPopUp words={["Allow for a clearing."]} />,
        },
        "Stages": {
            //all of them have the same popup
            "Stages":<WordsPopUp words={["Allow for a clearing."]} />,
        },


    }
}