import React from 'react';
import PropTypes from 'prop-types';
import debounce from '@mui/material/utils/debounce';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { ownerWindow } from '@mui/material/utils';

const styles: React.CSSProperties = {
  width: 99,
  height: 99,
  position: 'absolute',
  top: -9999,
  overflow: 'scroll',
};

interface ScrollbarSizeProps {
  onChange: (scrollbarHeight: number) => void;
}

const ScrollbarSize: React.FC<ScrollbarSizeProps> = (props) => {
  const { onChange, ...other } = props;
  const scrollbarHeight = React.useRef<number>(0);
  const nodeRef = React.useRef<HTMLDivElement | null>(null);

  const setMeasurements = () => {
    if (nodeRef.current) {
      scrollbarHeight.current = nodeRef.current.offsetHeight - nodeRef.current.clientHeight;
    }
  };

  useEnhancedEffect(() => {
    const handleResize = debounce(() => {
      const prevHeight = scrollbarHeight.current;
      setMeasurements();
      if (prevHeight !== scrollbarHeight.current) {
        onChange(scrollbarHeight.current);
      }
    });

    const containerWindow = ownerWindow(nodeRef.current!);
    containerWindow.addEventListener('resize', handleResize);

    return () => {
      handleResize.clear();
      containerWindow.removeEventListener('resize', handleResize);
    };
  }, [onChange]);

  React.useEffect(() => {
    setMeasurements();
    onChange(scrollbarHeight.current);
  }, [onChange]);

  return (
    <div
      style={styles}
      ref={nodeRef}
      {...other}
    />
  );
};

ScrollbarSize.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ScrollbarSize;
