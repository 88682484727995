import { WordListMappingType, WordListPageTitle } from "../types";
import Typography from '@mui/material/Typography';

export const SoulWordTitleMap: WordListPageTitle = {
  "Soul": {
    "Relationships": (
                      <Typography variant="h5" style={{ textAlign: 'center', padding: '10px' }}>
                        What relationship would be beneficial to address?
                      </Typography>)
  }
}

export const SoulWordListMap: WordListMappingType = {
  "Soul": {
    "Relationships": [
      "Attachment",
      "Abundance",
      "Addiction",
      "Alcohol",
      "Animal Companion",
      "Approval",
      "Ascension",
      "Authority Figure",
      "Being alone",
      "Being close to others",
      "Being disappointed",
      "Being extremely wealthy",
      "Being famous",
      "Being more open",
      "Being playful/playing",
      "Being/feeling safe",
      "Boyfriend/Girlfriend",
      "Celebrity",
      "Chemicals",
      "Child: Mine/Someone Else's",
      "Christ energy",
      "Client",
      "Co-worker/Boss",
      "Codependency",
      "Complacency",
      "Confidence",
      "Control",
      "Death",
      "Deceased Loved One: Human/Animal",
      "Divine",
      "Emotions",
      "Expressing myself",
      "Fear",
      "Fear of being alone",
      "Feeling I do not belong",
      "Food",
      "Forgiveness",
      "Friend",
      "Greed",
      "Grandparent: Grandmother/Grandfather",
      "Guilt",
      "Happiness",
      "Having things be easy",
      "Immune system",
      "Inner Child",
      "Life's mission",
      "Light & Dark",
      "Limitation",
      "Materialism",
      "Me & my kids",
      "My Age",
      "My Body",
      "My Career/Work",
      "My Future",
      "My Illness",
      "My Situation",
      "My Weight",
      "My own judgment",
      "Neighbor",
      "Obligation",
      "Overcaring",
      "Parent (in-law): Mother/Father",
      "Peer",
      "Peer",
      "Possibilities",
      "Power",
      "Reproductive system",
      "Responsibility",
      "Sexuality",
      "Sibling (in-law): Sister/Brother",
      "Sleep",
      "Spouse/Partner",
      "Stranger",
      "Success",
      "Time",
      "Touching the world",
      "Truth",
      "Wanting to be here",
      "With being seen",
      "World",
      "A Relationship Not Listed",
      "An Item Not Listed",
    ],
    "Issues": {
      "Thought Pattern": [
        "I am not good enough",
        "I am not lovable",
        "I could never do that",
        "I would never do that",
        "I am no good",
        "I am not confident",
        "They are better than me",
        "They are prettier than me",
        "They are more handsome than me",
        "I am not talented",
        "I could never do that",
        "I am not enough",
        "I need to make others happy",
        "I have to earn approval",
        "If people knew me they would not like me",
        "I have to stay in this relationship because I cannot make it on my own",
        "Health problems will always keep me from happiness and success",
        "I cannot do it",
        "Money corrupts",
        "Rich people are greedy",
        "I am a victim",
        "It is their fault",
        "Comparing yourself or your life to others",
        "I need to be right",
        "I am limited",
        "I get abandoned",
        "I am afraid",
        "I am stuck",
        "I am all on my own",
        "I am hopeless",
        "I am helpless",
        "I am useless",
        "I am blameless",
        "I am worthless",
        "There is no where I can meet like-minded people",
        "Everyone is too busy for me",
        "People are just too unfriendly",
        "I am too:____",
        "At my age:____ ",
        "Because of my sexuality:____ ",
        "Because of my race:____ ",
        "Because of my disabilities:____",
        "There is a price for everything",
        "Money is the root of all evil",
        "Money is not important to me",
        "Money is there to spend",
        "The rich get richer and the poor get poorer",
        "It is selfish to want a lot of money",
        "I cannot be happy if others are suffering",
        "I cannot be happy until ______ is different",
        "If they really loved me then:____ ",
        "I need to do more and more to be worthy",
        "I don't/can't trust",
        "I cannot ask for what I want because I might get rejected",
        "I am not able to pursue my dreams because I might fail",
        "I cannot do A because of B",
        "I cannot be my real self or I will be judged",
        "I cannot get close or I will get my heart broken",
        "I do not need to be successful so I am not going to strive for success",
        "It is too late to pursue my dreams",
        "I have no clue who I am",
        "I have too many responsibilities",
        "I have no clue where to start",
        "I am lazy",
        "I do not have enough:____",
        "Love has not worked in the past",
        "I am my story",
        "I do not know what I want",
        "I should not put my needs before others",
        "I will never really change",
        "I am responsible for others' happiness and they are responsible for mine",
        "I do not deserve:____ ",
        "If I pursue my own interests then my relationship will suffer",
        "I do not have time to:____",
        "It is too late for me to:____ ",
        "If I speak my mind then I will be rejected",
        "I should be farther along than I am",
        "I better not be too happy, or I will just have farther to fall",
        "Things will never work out for me",
        "I should not have to ask for what I want",
        "I am a bad person",
        "I need fear to motivate me and keep me in check",
        "I will never make enough money",
        "I will always have to struggle, while others have it easier",
        "Whatever I am doing I should be doing something else"
      ],
      "Belief": [
        "I am not good enough",
        "I am not lovable",
        "I could never do that",
        "I would never do that",
        "I am no good",
        "I am not confident",
        "They are better than me",
        "They are prettier than me",
        "They are more handsome than me",
        "I am not talented",
        "I could never do that",
        "I am not enough",
        "I need to make others happy",
        "I have to earn approval",
        "If people knew me they would not like me",
        "I have to stay in this relationship because I cannot make it on my own",
        "Health problems will always keep me from happiness and success",
        "I cannot do it",
        "Money corrupts",
        "Rich people are greedy",
        "I am a victim",
        "It is their fault",
        "Comparing yourself or your life to others",
        "I need to be right",
        "I am limited",
        "I get abandoned",
        "I am afraid",
        "I am stuck",
        "I am all on my own",
        "I am hopeless",
        "I am helpless",
        "I am useless",
        "I am blameless",
        "I am worthless",
        "There is no where I can meet like-minded people",
        "Everyone is too busy for me",
        "People are just too unfriendly",
        "I am too:____",
        "At my age:____ ",
        "Because of my sexuality:____ ",
        "Because of my race:____ ",
        "Because of my disabilities:____",
        "There is a price for everything",
        "Money is the root of all evil",
        "Money is not important to me",
        "Money is there to spend",
        "The rich get richer and the poor get poorer",
        "It is selfish to want a lot of money",
        "I cannot be happy if others are suffering",
        "I cannot be happy until ______ is different",
        "If they really loved me then:____ ",
        "I need to do more and more to be worthy",
        "I don't/can't trust",
        "I cannot ask for what I want because I might get rejected",
        "I am not able to pursue my dreams because I might fail",
        "I cannot do A because of B",
        "I cannot be my real self or I will be judged",
        "I cannot get close or I will get my heart broken",
        "I do not need to be successful so I am not going to strive for success",
        "It is too late to pursue my dreams",
        "I have no clue who I am",
        "I have too many responsibilities",
        "I have no clue where to start",
        "I am lazy",
        "I do not have enough:____",
        "Love has not worked in the past",
        "I am my story",
        "I do not know what I want",
        "I should not put my needs before others",
        "I will never really change",
        "I am responsible for others' happiness and they are responsible for mine",
        "I do not deserve:____ ",
        "If I pursue my own interests then my relationship will suffer",
        "I do not have time to:____",
        "It is too late for me to:____ ",
        "If I speak my mind then I will be rejected",
        "I should be farther along than I am",
        "I better not be too happy, or I will just have farther to fall",
        "Things will never work out for me",
        "I should not have to ask for what I want",
        "I am a bad person",
        "I need fear to motivate me and keep me in check",
        "I will never make enough money",
        "I will always have to struggle, while others have it easier",
        "Whatever I am doing I should be doing something else"
      ],
      "Emotional": {
        "Happy": [
          "Alive",
          "Appreciated",
          "Cheerful",
          "Confident",
          "Content",
          "Delighted",
          "Determined",
          "Ecstatic",
          "Energized",
          "Encouraged",
          "Enthusiastic",
          "Excited",
          "Exuberant",
          "Fortunate",
          "Fulfilled",
          "Glad",
          "Good",
          "Grateful",
          "Hopeful",
          "Jubilant",
          "Joyful",
          "Justified",
          "Loved",
          "Marvelous",
          "Optimistic",
          "Peaceful",
          "Pleased",
          "Proud",
          "Relaxed",
          "Relieved",
          "Resolved",
          "Respected",
          "Satisfied",
          "Terrific",
          "Thrilled",
          "Tranquil",
          "Valued"
        ],
        "Sad": [

          "Alienated",
          "Ashamed",
          "Burdened",
          "Condemned",
          "Crushed",
          "Dejected",
          "Demoralized",
          "Depressed",
          "Deserted",
          "Despised",
          "Dismal",
          "Disappointed",
          "Discarded",
          "Discouraged",
          "Disgraced",
          "Disheartened",
          "Disillusioned",
          "Disrespected",
          "Distanced",
          "Distraught",
          "Drained",
          "Empty",
          "Exhausted",
          "Grievous",
          "Helpless",
          "Hopeless",
          "Humbled",
          "Inadequate",
          "Islanded",
          "Isolated",
          "Lonely",
          "Miserable",
          "Mournful",
          "Neglected",
          "Pitiful",
          "Regretful",
          "Rejected",
          "Resigned",
          "Sorrowful",
          "Terrible",
          "Uncared for",
          "Unloved",
          "Unwanted",
          "Upset",
          "Worthless",
          "Wounded"
        ],
        "Angry": [
          "Aggravated",
          "Agitated",
          "Anguished",
          "Annoyed",
          "Betrayed",
          "Cheated",
          "Coerced",
          "Controlled",
          "Deceived",
          "Disgusted",
          "Displeased",
          "Dismayed",
          "Dominate",
          "Enraged",
          "Exasperated",
          "Exploited",
          "Frustrated",
          "Fuming",
          "Furious",
          "Harassed",
          "Hateful",
          "Hostile",
          "Humiliated",
          "Irritated",
          "Mad",
          "Outraged",
          "Patronized",
          "Peeved",
          "Perturbed",
          "Pissed off",
          "Provoked",
          "Repulsed",
          "Resentful",
          "Ridiculed",
          "Sabotaged",
          "Seething",
          "Smothered",
          "Spiteful",
          "Stifled",
          "Strangled",
          "Throttled",
          "Uptight",
          "Used",
          "Vengeful",
          "Vindictive"

        ],
        "Scared": [

          "Afraid",
          "Alarmed",
          "Anxious",
          "Appalled",
          "Apprehensive",
          "Awed",
          "Concerned",
          "Defensive",
          "Desperate",
          "Doubtful",
          "Frantic",
          "Full of dread",
          "Guarded",
          "Horrified",
          "Impatient",
          "Insecure",
          "Intimidated",
          "Nervous",
          "Overwhelmed",
          "Panic",
          "Panicky",
          "Petrified",
          "Perplexed",
          "Reluctant",
          "Shaken",
          "Shocked",
          "Shy",
          "Skeptical",
          "Startled",
          "Stunned",
          "Suspicious",
          "Swamped",
          "Terrified",
          "Threatened",
          "Timid",
          "Tense",
          "Tormented",
          "Uneasy",
          "Unsure",
          "Vulnerable"
        ],
        "Confused": [
          "Ambivalent",
          "Awkward",
          "Baffled",
          "Bewildered",
          "Bothered",
          "Constricted",
          "Directionless",
          "Disorganized",
          "Distracted",
          "Doubtful",
          "Flustered",
          "Foggy",
          "Hesitant",
          "Immobilized",
          "Misunderstood",
          "Perplexed",
          "Puzzled",
          "Stagnant",
          "Surprised",
          "Torn",
          "Troubled",
          "Trapped",
          "Uncertain",
          "Undecided",
          "Uncomfortable",
          "Unsettled",
          "Unsure"

        ],
      },
      "Boundary Issue": [
        "<b>Spiritual Boundary</b>: This is how we protect and share our spiritual beliefs. By safe guarding and honoring our spiritual boundaries it helps us define our spiritual beliefs with others and allows for differences to be acknowledged, respected and honored. It says that we can be different and still have a relationship of some sort and not have to think a like.",
        "<b>Physical Boundary</b>: This has to do with the actual physical space around our bodies. We all have a different comfort space that we are comfortable with and can tolerate. There is no set distance for everyone. We do not have to allow people to come in our personal space if we do not want to. We should also ask permission to go into someone else's personal space and not just assume because we are okay with it that they are.",
        "<b>Mental Boundary</b>: This is a boundary around our thoughts and ideas. We do not have to share everything that we are thinking with everyone. We get to decide what thoughts we share and with who. Just because someone may ask what we are thinking, we do not have to share unless we decide to. Our thoughts are our own.",
        "<b>Intellectual Boundary</b>: Each person has an unique capacity for knowledge and understanding, and the right to apply these abilities in his / her own way. Within the limits of the law and ethics, we all have the right to think and do what we want for ourselves, as well as accept the consequences of those choices. Sometimes, we want to be alone with our thoughts in order to clarify them. Intellectual boundaries allow us to do that, and the freedom to choose what we want to think.",
        "<b>Boundary Evasion</b>: An evasion is when someone ignores us even if we ask for something or need something. It could be when we go to hug someone and they do not hug back. It is a purposeful action of evading someone. If a child experiences boundary on a regular basis they will feel shame. It is important to teach children that they have the right to say no to a hug or a kiss if they do not want it. It is also important to teach children that they have the right to say no to an adult if they are uncomfortable with what the adult is asking them to do.",
        "<b>Boundary Invasion</b>: This is when someone comes into our space on any of the above mentioned boundary areas without regard for us. When they plow in so to speak, being unaware or not caring about limits we may have set.",
        "<b>Rigid Boundaries</b>: This is when we have walls up and do not allow anyone to interact with us or come into our lives to any great degree. Rigid boundaries are often used when we feel we need to protect ourselves for some reason or if we do not trust.",
        "<b>Weak Boundaries</b>: This is when someone sets boundary but does not back it up and therefore it will probably not feel or come across as a boundary. It is very hard to take a weak boundary seriously.",
        "<b>Enmeshed Boundaries</b>: This is when we are overly connected to someone else and do not have a clear sense of our own selves. How this will look is that if the person we are enmeshed with is having a bad day then we have to join them and cannot be where we truly are in our own experience and life. Sometimes families and groups will do this as a whole. We need to have our own boundaries and expect that others have their own too.",
        "<b>Wavering Boundaries</b>: This is when someone is wishy washy about the boundaries they set which sends out a very unclear mixed message. They may set one and not the other or they may set a boundary and not maintain it and not honor it themselves. It sends a very strong message that you do not expect to be honored and respected.",
        "<b>Honored/Not honored</b>: This refers to either the selection that my boundaries are being honored or I am honoring someone else's boundaries or they are not being honored or respected.",
        "<b>Set a boundary</b>: This is telling you that you need to set a boundary somewhere in your life with someone or maybe even with yourself.",
        "<b>Learn about boundaries</b>: This is an important topic area for everyone. If you do not know about boundaries get a book and read up on what they are and how to use them. Boundaries are how we teach others how we want to be treated!"


      ],
    },

    Connections: {
      Archetypes: [
        "<b>Victim</b>: Experiences oneself at the mercy of everything outside of itself. They feel like they are working against everyone and everything. Things happen to them. It involves a lot of suffering due to how they see everything outside of their control. They feel helpless to do or change anything. They are stuck in a frozen state of fear, terror, or desperation, with no sense of empowerment. They usually are full of intense powerful emotions. To shift: victims need to ground themselves and get reconnected with the basic simplicity and goodness of life. They need to work at being present and trusting in life's processes and taking responsibility for themselves in all areas of their lives.",

        "<b>Mother</b>: Is the positive and functional side of the victim archetype. This represents our ability to nurture, nourish and look after the life force within us with love, care and attention. As we care for ourselves we are able to extend to others in our lives. This energy is deeply rooted to the earth energy. Being grounded to the earth mother supports and sustains us.",

        "<b>Martyr</b>: This archetype is a bit stronger than the victim but not so much so that they can be empowered or vitalized. Their life is full of suffering. They, like the victim, feel helpless to do anything about their situation. They stay involved and enmeshed with situations that are not good for them, drain them and deplete them. They do not feel entitled to personal happiness and will often suffer for a long time. They will relinquish their claim to happiness in order to maintain peace, stability and balance for others. A deep sense of guilt is also part of this archetype. The suffering gives them a sense of being special. Through sacrifice the martyr hides its inability or willingness to develop its life.",

        "<b>Emperor/Empress</b>: This is the positive and functional side of the martyr archetype. This means leaving behind any attitudes that would limit their happiness, livelihood and or interfere with their pleasure and well-being. This archetype has a good sense of self and self balance. It represents both worldly power and earthly pleasure. It is the archetype of those that love feeling well and who thoroughly enjoy life.",

        "<b>Servant</b>: This one undervalues themselves and does not see or honor its basic self worth. They relinquish their personal power and emotional needs in order to win acknowledgement and approval. Usually maintaining a low profile, it is effacing and knows its place. There will also be poor boundaries and a weak ego.",

        "<b>Warrior</b>: This is the positive and functional side of the Servant. This archetype lets us all be the hero or heroine in our own lives. The warrior lives in a stable, balanced and empowered state in all areas of their lives. This is the strongest of all the personal archetypes. This archetype is highly intelligent and aware archetype. It engages assertively with its life.",

        "<b>Actor/Actress</b>: They play the game but do not show all their cards. A bit passive-aggressive they are unaware and hide their darker feelings for hidden agendas of power and dominance. Acting as if all is fine, they evade their true emotions and steer clear of bonding and intimacy. If anyone gets too close, the actor/actress will sabotage the relationship. The inner pain comes from old hurts and often will use deflecting techniques to keep love away.",

        "<b>Lover</b>: This is the functional side of the actor/actress. This archetype lives it life from a place of love. This person loves all of life, the animals, plants, people and all of life. It accepts itself and as whole and complete and accepts itself as being worthy of love.",

        "<b>Silent Child</b>: This archetype is emotionally shut down and does not reveal its deep internal feelings. This archetype is susceptible to numbing its feelings with addictive behaviors and does not express its creativity thus creating a weakened life force. It refuses to express its pain and holds on tightly to the feelings locked inside.",

        "<b>Communicator</b>: This is the positive side of the silent child. This archetype is a clear and effective communicator. This archetype represents the artists, writers, the creators or anyone who communicates nonverbally. They overcome all levels of negativity by being positive and direct.",

        "<b>Intellectual</b>: This archetype is the person who over intellectualizes at the cost of balancing with the heart. This person over thinks and responds to life from just the left brain. Because of the disconnection with the right brain and emotions, they usually are filled with suppressed emotions.",

        "<b>Intuitive</b>: This is the positive side of the intellectual. This person is connected to their deeper knowing. It has the gift of sight and seeing things within and from a deeper spiritual perspective. This archetype accepts intellect, emotion and balances it with an understanding of the bigger picture in life. They know that things are not always as they appear and understand themselves and others from that perspective.",

        "<b>Egotist</b>: This is the narcissist that is boastful, brash and ungrateful. It is proud, arrogant and full of self boasting. It doesn't recognize anyone or anything that may be higher than itself. Connection with a higher power is not something this archetype is familiar with.",

        "<b>Guru</b>: This is the positive side of the egotist. This is the highest level for responsibility, vitality and empowerment. The realms of possibility are endless from this perspective. It accepts full responsibility for everything that happens in it's life and knows that it can change whatever it wants to. This archetype will turn the dark into light.",


      ]
    },


    "Traits": {
      "Chart A": [
        "Abrupt",
        "Absentminded",
        "Active",
        "Adaptable",
        "Adventurous",
        "Aggressive",
        "Agreeable",
        "Alert",
        "Aloof",
        "Ambitious",
        "Amusing",
        "Angry",
        "Anxious",
        "Appreciative",
        "Argumentative",
        "Arrogant",
        "Artificial",
        "Assertive",
        "Athletic",
        "Attractive",
        "Balanced",
        "Bizarre",  // Corrected spelling
        "Brilliant",
        "Brutal",
        "Busy",
        "Calm",
        "Capable",
        "Careless",
        "Caring",
        "Casual",
        "Cautious",
        "Challenging",
        "Charming",
        "Cheerful",
        "Childish",
        "Clean",
        "Clever",
        "Clumsy",
        "Cold",
        "Colorful",
        "Colorless",
        "Compassionate",
        "Competitive",
        "Complacent",
        "Complex",
        "Compulsive",
        "Conceited",
        "Confident",
        "Confidential",
        "Confused"
      ],
      "Chart B": [
        "Bizarre",  // Corrected spelling
        "Brilliant",
        "Conservative",
        "Considerate",
        "Contradictory",
        "Cooperative",
        "Courageous",
        "Crazy",
        "Creative",
        "Critical",
        "Crude",
        "Cruel",
        "Cute",
        "Cynical",
        "Deceptive",
        "Dedicated",
        "Delicate",
        "Demanding",
        "Dependent",
        "Desperate",
        "Destructive",
        "Determined",
        "Devious",
        "Difficult",
        "Dirty",
        "Disloyal",
        "Disobedient",
        "Disorderly",
        "Disorganized",
        "Disrespectful",
        "Disruptive",
        "Distractible",
        "Disturbing",
        "Dominating",
        "Dramatic",
        "Dreamy",
        "Dull",
        "Easily Discouraged",
        "Educated",
        "Efficient",
        "Emotional",
        "Empathetic",
        "Energetic",
        "Enthusiastic",
        "Envious",
        "Excitable",
        "Exciting",
        "Experimental",
        "Extreme",
        "Faithful"
      ],
      "Chart C": [
        "Fearful",
        "Firm",
        "Flexible",
        "Focused",
        "Foolish",
        "Forgetful",
        "Formal",
        "Friendly",
        "Frightening",
        "Frivolous",
        "Frugal",
        "Fun",
        "Generous",
        "Gentle",
        "Genuine",
        "Glamorous",
        "Gloomy",
        "Greedy",
        "Gullible",
        "Hardworking",
        "Healthy",
        "Helpful",
        "Hesitant",
        "Honest",
        "Hostile",
        "Humble",
        "Humorous",
        "Hurried",
        "Hyperactive",
        "Ignorant",
        "Imaginative",
        "Impatient",
        "Impersonal",
        "Impractical",
        "Impressionable",
        "Impressive",
        "Impulsive",
        "Inconsiderate",
        "Indecisive",
        "Independent",
        "Indulgent",
        "Insecure",
        "Insensitive",
        "Insightful",
        "Insincere",
        "Insulting",
        "Intelligent",
        "Intense",
        "Intolerant",
        "Irrational"
      ],
      "Chart D": [
        "Intuitive",
        "Invisible",
        "Kind",
        "Lazy",
        "Logical",
        "Lovable",
        "Maternal",
        "Mature",
        "Mellow",
        "Messy",
        "Miserable",
        "Miserly",
        "Misguided",
        "Mistaken",
        "Moderate",
        "Money-Minded",
        "Moody",
        "Morbid",
        "Narcissistic",
        "Narrow-Minded",
        "Naïve",
        "Neat",
        "Neglectful",
        "Neurotic",
        "Neutral",
        "Obedient",
        "Objective",
        "Obnoxious",
        "Observant",
        "Obsessive",
        "Obvious",
        "Odd",
        "One-Sided",
        "Open",
        "Opinionated",
        "Opportunistic",
        "Oppressed",
        "Optimistic",
        "Ordinary",
        "Outrageous",
        "Outspoken",
        "Paranoid",
        "Passionate",
        "Passive",
        "Perfectionistic",
        "Personable",
        "Persuasive",
        "Physical",
        "Playful",
        "Procrastinator",
        "Prosper"
      ],
      "Chart E": [
        "Political",
        "Popular",
        "Possessive",
        "Power-Hungry",
        "Practical",
        "Precise",
        "Predictable",
        "Prejudiced",
        "Preoccupied",
        "Private",
        "Procrastinating",
        "Profound",
        "Progressive",
        "Protective",
        "Proud",
        "Punctual",
        "Pure",
        "Questioning",
        "Quiet",
        "Quirky",
        "Rational",
        "Reactionary",
        "Reactive",
        "Realistic",
        "Regretful",
        "Relaxed",
        "Reliable",
        "Religious",
        "Resentful",
        "Reserved",
        "Resourceful",
        "Respectful",
        "Responsible",
        "Restrained",
        "Ridiculous",
        "Rigid",
        "Romantic",
        "Rowdy",
        "Ruined",
        "Sarcastic",
        "Secretive",
        "Secure",
        "Sedentary",
        "Self-conscious",
        "Self-Sufficient",
        "Selfish",
        "Sensitive",
        "Sentimental",
        "Serious",
        "Sexy"
      ],
      "Chart F": [
        "Shallow",
        "Shortsighted",
        "Shy",
        "Silly",
        "Simple",
        "Skeptical",
        "Skillful",
        "Sloppy",
        "Slow",
        "Sober",
        "Soft",
        "Solitary",
        "Sophisticated",
        "Spontaneous",
        "Stable",
        "Steady",
        "Stern",
        "Stiff",
        "Strict",
        "Strong",
        "Strong-Willed",
        "Stubborn",
        "Stupid",
        "Stylish",
        "Submissive",
        "Subtle",
        "Superficial",
        "Superstitious",
        "Surprising",
        "Suspicious",
        "Sweet",
        "Sympathetic",
        "Tactless",
        "Tasteful",
        "Tense",
        "Thorough",
        "Thoughtless",
        "Tidy",
        "Timid",
        "Tolerant",
        "Tough",
        "Transparent",
        "Trendy",
        "Troublesome",
        "Trusting",
        "Unappreciative",
        "Uncaring",
        "Uncooperative",
        "Understanding",
        "Undisciplined"
      ],
      "Chart G": [
        "Unfriendly",
        "Ungrateful",
        "Unhealthy",
        "Unlovable",
        "Unrealistic",
        "Unreliable",
        "Unstable",
        "Vague",
        "Vulnerable",
        "Warm",
        "Weak",
        "Well-read",
        "Well-rounded",
        "Whimsical",
        "Wise",
        "Witty",
        "Wishful",
        "Young"
      ],

    },
  },
}