import React from "react";
import { Link } from 'react-router-dom';
import { Typography } from "@mui/material";
import { WordsPopUp, NoInfoPopUp } from "./popup"

export const AnimalCompPopUp = () => {
    return (
        <div>
            <Typography>Consult &nbsp;
                <Link to="?module=Animal">Animal Style</Link>
            </Typography>
        </div>
    );

}

export const SpiritGuidePopUp = () => {
    return (
        <div>
            <Typography>Animal Consult &nbsp;
                <Link to="?module=Animal">Animal Style</Link>
            </Typography>
            <Typography>Human Consult &nbsp;
                <Link to="?module=People">People Style</Link>
            </Typography>
        </div>
    );

}


export type AnglePopupType = {
    [name: string]: React.ReactElement
};

export const AngelsPopups: AnglePopupType = {
    "ArcturianEmmissionaries": <WordsPopUp words={["Arcturian Emmissionaries of Light",
        "Energy works as an emotional, mental and spiritual healer for humanity.",
    ]} />,

    "Raphael": <WordsPopUp words={["Archangel Raphael", "Green - Healing",
        "Archangel Raphael is the main archangel who oversees healing for living beings on Earth." +
        " He is a well known and incredibly powerful angelic being, who is ready, willing and waiting" +
        " to connect with you directly if you so desire. Through the powerful divine guidance from Archangel Raphael" +
        ", you can learn to activate and use your bodies innate ability to heal itself."
    ]} />,

    "Gabriel": <WordsPopUp words={["Archangel Gabriel", "White - Purity",
        "Gabriel is the Archangel of communication, new beginnings, and strength." +
        " When called upon Gabriel will bring you inspiring messages to assist you in finding your highest calling"
    ]} />,

    "Michael": <WordsPopUp words={["Archangel Michael", "Royal Blue - Protection",
        "Archangel Michael is a powerful Archangel or protection. " +
        "Michael oversees guardian angels, and he will help you to release" +
        " lower energies of fear or worry, so that you can open to experience" +
        " the incredible love and light of the Angelic Realm, and live a fun, fulfilling and passionate life. "
    ]} />,

    "Jophiel": <WordsPopUp words={["Archangel Jophiel", "Yellow - Wisdom",
        "Jophiel is a wonderful ally to call upon when you need help seeing the beauty within and " +
        "around you. She will help you to shift your perspective from focusing on what is wrong and bad" +
        ", into seeing the positives, beauty and magic that is all around you."
    ]} />,

    "Chamuel": <WordsPopUp words={["Archangel Chamuel", "Rose Pink - Love",
        "Archangel Chamuel is a powerful Archangel of protection, comfort, love, peace " +
        "and compassion. "
    ]} />,

    "Uriel": <WordsPopUp words={["Archangel Uriel", "Purple - Peace",
        "Archangel Uriel's name means \"the Light of God,\" and she is often depicted with" +
        " an open hand... holding forward a flame of light that is a gift of illumination" +
        " for all human souls. The open hand of Archangel Uriel represents the openness" +
        " and willingness to receive the love of God and to remain spiritually devoted."
    ]} />,


    "Zadkiel": <WordsPopUp words={["Archangel Zadkiel", "Violet - Freedom",
        "Archangel Zadkiel radiates joy, love, forgiveness, freedom and mercy. Zadkiel" +
        " is connected to the seventh ray, or violet flame, and has such an uplifting vibration" +
        " simply calling upon Archangel Zadkiel will cleanse and illuminate your vibration."
    ]} />,


    "Metatron": <WordsPopUp words={["Archangel Metatron is a Divine record keeper, and a powerful" +
        " spiritual teacher of the light Metatron can help you release what no longer serves you, so that" +
        " you can tap into your inner connection with pure light."]} />,

    "JesusChrist": <WordsPopUp words={[
        "Jesus Christ is the Son of God and the way to peace and happiness."
    ]} />,


    "AnimalCompanion": <AnimalCompPopUp />,
    "SpiritGuide": <SpiritGuidePopUp />,
    "OtherHelper": <NoInfoPopUp />,
}
