import { NoInfoPopUp, WordsPopUp, } from "../popup"
import { popupIdMappingType } from "../types"
import { Typography } from "@mui/material";
import { Link } from 'react-router-dom';

const AnimalPopUp = () => {
    return (
        <div>
            <Typography>Consult your animal companion</Typography>
            <Typography>Consult &nbsp;
                <Link to="/?module=Animal">Animal Style</Link>
            </Typography>
        </div>
    );
}

const ColorChart = () => {
    return (
        <div>
            <Typography>
                Go to the <Link to="/?module=Business&item=Color+Chart">Color Chart</Link>
            </Typography>
        </div>
    );
}

const SelfPopUp = () => {
    return (
        <div>
            <Typography>Relationship with Self</Typography>
            <Typography>Consult <Link to="/?module=People">People Style</Link></Typography>
            <Typography>Consult <Link to="/?module=Animal">Animal Style</Link></Typography>
        </div>
    );
}

const LovePopUp = () => {
    return (
        <div>
            <Typography>Consult <Link to="/?module=Soul">Soul Style</Link></Typography>
        </div>
    );
}

const FamilyPopUp = () => {
    return (
        <div>
            <Typography>Consult <Link to="/?module=People">People Style</Link></Typography>
        </div>
    );
}

const HealthPopUp = () => {
    return (
        <div>
            <Typography>Consult <Link to="/?module=Freedom">Freedom Style</Link></Typography>
            <Typography>Consult <Link to="/?module=Origin">Origin Style</Link></Typography>
        </div>
    );
}

const CareerPopUp = () => {
    return (
        <div>
            <Typography>Consult <Link to="/?module=Business">Business Style</Link></Typography>
        </div>
    );
}

const AnimalDeeperMsgPopUp = () => {
    return (
        <div>
            <Typography>They are natures' messengers to us. Embrace what they are trying to tell you, thank them for their services and they should leave. For more involved messages consult the <Link to="/?module=Animal">Animal Style Module.</Link></Typography>
        </div>
    );
}

export const BusinessPopUps: popupIdMappingType = {
    "Business": {

        "RoomLocation": {
            "NoInfo": <NoInfoPopUp />,
            "TrainingRoom": <WordsPopUp words={["Training Room"]} />,
            "WaitingArea": <WordsPopUp words={["Waiting Area"]} />,
            "ReceptionArea": <WordsPopUp words={["Reception Area"]} />,
            "MeetingRoom": <WordsPopUp words={["Meeting Room"]} />,
            "ConferenceRoom": <WordsPopUp words={["Conference Room"]} />,
        },

        "Compass":
        {
            "West": <WordsPopUp words={["Introspection", "Looking within", "Movement", "Emotion", "Psyche", "Water"]} />,
            "North": <WordsPopUp words={["Knowledge", "Wisdom", "Earth", "Home", "Security", "Fertility"]} />,
            "East": <WordsPopUp words={["Daybreak", "Illumination", "Communication", "New Beginnings", "New Growth", "Sprit", "Air"]} />,
            "South": <WordsPopUp words={["Creativity", "Youth", "Innocence", "Emotion", "Fire", "Energy", "Passion"]} />,
        },


        "Remedies": {

            "Water": <WordsPopUp words={["Add", "Move", "Clean"]} />,
            "NoInfo": <NoInfoPopUp />,
            "Animals": <WordsPopUp words={["Add", "Move", "Remove"]} />,
            "FengShui": <WordsPopUp words={["Feng Shui Workup"]} />,
            "Plants": <WordsPopUp words={["Add", "Move", "Water", "Change"]} />,
            "Windows": <WordsPopUp words={["Open", "Close",]} />,
            "Art": <WordsPopUp words={["Add", "Move", "Change"]} />,
            "AnimalCompanion": <AnimalPopUp />,
        },
        "Actions": {
            "NoInfo": <NoInfoPopUp />,
            "Item": <WordsPopUp words={["Replace", "Reposition",]} />,
            "Paint": <ColorChart />,
            "Climate": <WordsPopUp words={["Humidify", "Dehumidify",]} />,
            "Intention": <WordsPopUp words={["Write out your intention and goals",]} />,

        },

        "BuildingIssues": {
            "NoInfo": <NoInfoPopUp />,
            "HeatCool" : <WordsPopUp words={["Heating or Cooling"]} />
        },

        "Relationships": {
            "NoInfo": <NoInfoPopUp />,
            "Self": <SelfPopUp />,
            "Animal": <AnimalPopUp />,

        },
        "Communication": {
            "NoInfo": <NoInfoPopUp />,
        },
        "Heart": {
            "NoInfo": <NoInfoPopUp />,
        },
        "Values": {
            "NoInfo": <NoInfoPopUp />,
            "Wellness" : <WordsPopUp words={["Physical", "Mental", "Spiritual","Relational"]} />
        },
        "QA": {
            "NoInfo": <NoInfoPopUp />,
            "PerformanceEvals" : <WordsPopUp words={["Performance Evaluations"]} />
        },
        "Strategy": {
            "NoInfo": <NoInfoPopUp />,
            "Advertising": <WordsPopUp words={["TV", "Radio", "Internet"]} />,

        },
        "ProdServ": {
            "NoInfo": <NoInfoPopUp />,
            "Stream": <WordsPopUp words={["Add another stream of income"]} />,
            "Price": <WordsPopUp words={["Increase", "Decrease"]} />,


        },
        "Chakras": {
            "ThirdEye": <WordsPopUp words={["Wisdom & Identity"]} />,
            "Sacral": <WordsPopUp words={["Creativity"]} />,
            "Solar": <WordsPopUp words={["Action & Balance"]} />,
            "Heart": <WordsPopUp words={["Love & Compassion"]} />,
            "Throat": <WordsPopUp words={["Power of the Word"]} />,
            "Root": <WordsPopUp words={["Security & Survival"]} />,
            "Crown": <WordsPopUp words={["Humility & Vastness"]} />,
            "Eighth": <WordsPopUp words={["The Aura"]} />,
            "NoInfo": <NoInfoPopUp />,
        },
        "Bagua": {
            "NoInfo": <NoInfoPopUp />,
            "Love": <LovePopUp />,
            "Family": <FamilyPopUp />,
            "Health": <HealthPopUp />,
            "Career": <CareerPopUp />,
        },
        "Color": {
            "Hue": <WordsPopUp words={["Light Hue", "Medium Hue", "Dark Hue"]} />,
        },

        "Unwanted": {
            "HomeNoInfo": <NoInfoPopUp />,
            "Wasps": <WordsPopUp words={[" Wasps and Hornets: It teaches the lesson of fulfilling one's role and responsibilities, revealing how to construct and nurture our dreams. Protective nourishment and role fulfillment and dreams fulfilled through practical efforts.", "", "Bees: Industry, ActionCommunication,  Ability to consciously choose the results we want in our lives,Examine your own productivity in life (mentally, physically, spiritually), Are you reaping the honey of life by your dreams and opportunities?"]} />,
            "Bed": <WordsPopUp words={[" Negative sleeping arrangements or sleep patterns. A disruption of your Circadian rhythms may be disturbing your sleep and making you act buggy. "]} />,

            "Crickets": <WordsPopUp words={["Crickets symbolize good fortune. Cricket will assist you in finding new vibrational energies, enhanced intuition and psychic abilities. Their chirp can represent a time of finding your own voice. He teaches you to leap over and leave behind difficult and troublesome situations. He will get you where you need to go effortlessly.", "Beetles: They are here to remind you to continue on your life's journey and you will need to be persistent and trust your intuition. Make sure that you follow through on your current projects. He also shows up to let you know that you have chosen the correct path for you."]} />,
            "Birds": <WordsPopUp words={["Each bird species has their own individual message. Google the bird species as an animal totem for specific information or check out Animal Speak or Animal - wise by Ted Andrews."]} />,
            "Bats": <WordsPopUp words={["Spirituality. Psychic gifts and abilities. Clairvoyance and clairaudience. A connection to all worlds. Dreamwork. Finding others of like-mind. Cave magic. Working with liminal worlds and times such as mist, twilight and other thresholds. It indicates initiation, a new beginning that brings promise after the changes."]} />,
            "Ants": <WordsPopUp words={[" The Ant is dutiful, patient, industrious and focused. It is here to show you that you will achieve your goals if you put forward your best effort and hard work. The Ant is a team player and if each player does their assigned task the group will experience success. The ant asks you if you maybe need to invite a team to help you reach your goals?"]} />,
            "Fruit": <WordsPopUp words={["Fruit flies symbolize an ability to survive even in the most hard of environments. Their capacity to make use of their resources, even what is seemingly invaluable or even trash, make them a good symbol for perseverance. They also reflect an ability to see things differently and in many ways. Their eyes are suited to see and sense more than what is ordinarily possible. Fruit flies also symbolize transformation, their growth stages from seemingly unimportant specks and larvae to adult flies reflect the changes that we go through as well."]} />,
            "Mosquitoes": <WordsPopUp words={[" Reminds you to be vigilant of those that are sucking your energy, physically, emotionally or spiritually. Take a closer look at those around you who are taking more from you then they give. Are you currently experiencing a feeling of depletion? Mosquito reminds you to shield and protect your energy from people who suck your energy dry. "]} />,
            "FliesFleas": <WordsPopUp words={[" Fly reminds you to respond and make decisions spontaneously in situations you find painful or adverse to your way of thinking. Fly will help you stay persistent to your goals no matter what others may be think.", "Flea lets you know that irritations and aggravations will increase until we make the necessary changes.They remind us that we must assert ourselves and change what is irritating us or some aspect of our life. "]} />,
            "Moth": <WordsPopUp words={[" Symbolic of metamorphosis and change. Have you been in disguise or hiding for self protection? Or are you being asked to make a change or transformation in your life ? It may mean that you may finally be able to shed some light on a situation that is in front of you. "]} />,
            "Cockroaches": <WordsPopUp words={[" Teach you to go with the flow and adapt to your surroundings to survive. Are you isolating yourself from the light of others. If you have been in the limelight it may be asking you to step back and gain a new perspective within yourself. You may have overlooked some hidden resource. "]} />,
            "Slugs": <WordsPopUp words={[" They are here to remind us to move to the light. They bring awareness that we are increasing our fertility and divination. Are you afraid to come out and express your inner talents? Are you on your own path or someone else's? Do you trust in your own ability to create? "]} />,
            "Centipedes": <WordsPopUp words={[" They are here to show us that there is movement into new psychic connections and relationships. They also offer us protection against psychic deceptions. They also appear to alert us to any possible pitfalls within those relationships. Theirs is the energy of quiet protection in psychic exploration. "]} />,
            "Rabbits": <WordsPopUp words={[" Comes to ask you to have the courage to face whatever difficulties you may be having, instead of running off to a safe burrow. Face your fears and examine your old fight or flight patterns and determine once and for all to stop hiding away. He teaches us to listen carefully and follow our intuition. "]} />,
            "Moles": <WordsPopUp words={[" They bring awareness of our heightened senses(especially touch). They herald luck in endeavors through our own efforts. They ask us do we trust what we feel? Or are you becoming too sensitive? Are you sitting back waiting for things to come to you? "]} />,
            "Deer": <WordsPopUp words={[" A message of sweetness, innocence and gentleness of spirit. Be delicate in your communication with others and yourself. This is not a time for meanness, sarcasm or being critical. Pay attention to your inner thoughts and look for new perspectives in order to growl "]} />,
            "Squirrels": <WordsPopUp words={[" Squirrels: They teach balance within the cycle of gathering and giving out. If we are doing to much of one or the other, squirrel may appear to help us. They are masters at preparing, but they also are reminders that in our quest for our goals, we should always make time to socialize and play. Work and play go hand in hand, or the work will create problems and become more difficult and less fruitful."]} />,
            "Snakes": <WordsPopUp words={[" Different species of snakes have different meanings. Snake in general speaks about rebirth, resurrection, initiation and wisdom. They are symbols of change and healing. You can look for rebirth into new powers of creativity. "]} />,

            "Spiders": <WordsPopUp words={["Represents a connection between past and future to remind you that the web you weave today is the web you will inhabit tomorrow. Are you spinning a balanced future? She teaches through balance creativity is inspired. Spider is also a creator, spinning stories as easily as a web. Does your future spinning include your biggest dreams? "]} />,
            "Lizards": <WordsPopUp words={[" Different species of lizards have their own meanings. Lizard in general refers to subtlety of perception. They are able to feign sleep while basking in the sun and bring to us the ability to help us understand and use this state more effectively."]} />,

            "Skunks": <WordsPopUp words={[" If you are lacking in self esteem, skunk is there to help you get noticed. You are worthy of a happy life and if someone is there making you miserable, look to skunk as a confidence builder. Skunk does not have to spray is reputation precedes him. You do not have to spray either, but you do need to stand your ground calmly and with skunks confidence. Examine your self image-it is within your power to bring our new respect and self esteem for your mind body and spirit. "]} />,
            "Porcupines": <WordsPopUp words={["Represent a renewed sense of wonder. They can show us how to resist the barbs of others. They can teach you how to enjoy life and to maintain a sense of wonder about it, in spite of negative conditions. They can show how to shuffle along, without too much seriousness and still achieve. They can teach you how to protect the inner child from all of life's barbs, and can show you the strength in your vulnerability."]} />,
            "Mice": <WordsPopUp words={["Mice: They can show you how to focus and pay attention to detail. It can show you how to attain the big things by working on the little things. Whenever mouse shows up there are lessons associated with attention.", "Rats: when they show up you may find yourself getting more restless. It may indicate a time to be shrewder in all of your dealings. Are you not handling the pests in your life properly? Do you need to become more adaptable? It may even reflect a time to more aggressively pursue endeavors for success. The behavior of the rat when you see it will help you determine the kind of energy it specifically brings to you."]} />,
            "Gophers": <WordsPopUp words={["He is reminding you that you must set up your boundaries and respect those of others. He can guide you to learn from your dreams, aid you in lucid dreaming and teach you how to tap into your higher consciousness. He may also enter your life to teach you about death and dying."]} />,
            "Animal ": <WordsPopUp words={["They are natures' messengers to us. Embrace what they are trying to tell you, thank them for their services and they should leave.For more involved messages consult the Animal Style Module. ", "Consult Animal Style"]} />,
            "DeeperMsg": <AnimalDeeperMsgPopUp />,
        },
        "SelfLove": {
            "NoInfo": <NoInfoPopUp />,
            "Intuition": <WordsPopUp words={["Follow your intuition"]} />,
            "Job": <WordsPopUp words={["Change Job or Career"]} />,
            "Prof": <WordsPopUp words={["Get professional guidance"]} />,
            "Service": <WordsPopUp words={["Focus on how you can be of service"]} />,
            "Brainstorm": <WordsPopUp words={["Brainstorm with tribe"]} />,
        },

    }
}