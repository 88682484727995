import React from 'react';
import { Typography } from '@mui/material';
import ModuleChart from '../ModuleChart/ModuleChart';
import { ChartPageType } from '../../data/types';
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../utils';

interface MeasurementsServingsProps {
    height: number;
    width: number;
}

const MeasurementsData: ChartPageType = {
    chart: [
        { label: 'Teaspoon', value: 1, id: 0, color: '#8A2528' },
        { label: 'Tablespoon', value: 1, id: 1, color: '#DFC4C5' },
        { label: 'Cup', value: 1, id: 2, color: '#8A2528' },
        { label: 'Pound', value: 1, id: 3, color: '#DFC4C5' },
        { label: 'Ounce', value: 1, id: 4, color: '#8A2528' },
        { label: 'Pints', value: 1, id: 5, color: '#DFC4C5' },
        { label: 'Quarts', value: 1, id: 6, color: '#8A2528' },
        { label: 'Gallon', value: 1, id: 7, color: '#DFC4C5' },
        { label: 'Milliliter', value: 1, id: 8, color: '#8A2528' },
        { label: 'Liter', value: 1, id: 9, color: '#DFC4C5' },
        { label: 'Gram', value: 1, id: 10, color: '#8A2528' },
        { label: 'Kilogram', value: 1, id: 11, color: '#DFC4C5' },
    ],
    level: 0,
    subPages: {},
    disableClick: true,
    textSizeOverride : 12,
    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >Measurements</Typography>
}

const AmountsData: ChartPageType = {
    chart: [
        { label: '1/16', value: 1, id: 0, color: '#8A2528' },
        { label: '1/8', value: 1, id: 1, color: '#DFC4C5' },
        { label: '1/4', value: 1, id: 2, color: '#8A2528' },
        { label: '1/3', value: 1, id: 3, color: '#DFC4C5' },
        { label: '1/2', value: 1, id: 4, color: '#8A2528' },
        { label: '2/3', value: 1, id: 5, color: '#DFC4C5' },
        { label: '3/4', value: 1, id: 6, color: '#8A2528' },
        { label: '1', value: 1, id: 7, color: '#DFC4C5' },
        { label: '2', value: 1, id: 8, color: '#8A2528' },
        { label: '3', value: 1, id: 9, color: '#DFC4C5' },
        { label: '4', value: 1, id: 10, color: '#8A2528' },
        { label: '5', value: 1, id: 11, color: '#DFC4C5' },
        { label: '6', value: 1, id: 12, color: '#8A2528' },
        { label: '7', value: 1, id: 13, color: '#DFC4C5' },
        { label: '8', value: 1, id: 14, color: '#8A2528' },
        { label: '9', value: 1, id: 15, color: '#DFC4C5' },
        { label: '10', value: 1, id: 16, color: '#8A2528' },
        { label: '11', value: 1, id: 17, color: '#DFC4C5' },
        { label: '12', value: 1, id: 18, color: '#8A2528' },
        { label: 'More than 12', value: 1, id: 19, color: '#DFC4C5' },
    ],
    level: 0,
    subPages: {},
    disableClick: true,
    textSizeOverride : 12,
    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >Amounts</Typography>
}



const MeasurementsServingsPage: React.FC<MeasurementsServingsProps> = ({ height, width }) => {
    const top_chart_heights = height / 2;
    const chart_widths = width;
    return (
        <>
                <ModuleChart
                    height={top_chart_heights}
                    width={chart_widths}
                    chartData={MeasurementsData}
                    chartModule={"Nutrition"}
                    item={"Measure/Servings"}
                    subitem={null} />
                <ModuleChart
                    height={top_chart_heights}
                    width={chart_widths}
                    chartData={AmountsData}
                    chartModule={"Nutrition"}
                    item={"Measure/Servings"}
                    subitem={null} />

        </>
    );
}
export default MeasurementsServingsPage;
