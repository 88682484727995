import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { NoInfoPopUp, WordsPopUp, NutritionalIssuePopUp } from "../popup"
import { popupIdMappingType } from "../types"

export const HomeLivingSpacePopUp = () => {
    return (
        <div>
            <Typography>Consult &nbsp;
                <Link to="?module=Home">Home Style Program</Link>
                </Typography>
        </div>
    );

}

export const DowsingPopUp = () => {
    return (
        <div>
            <Typography>Home Dowsing</Typography>
            <Typography>Consult &nbsp;
                <Link to="?module=Home">Home Style Program</Link>
                </Typography>
        </div>
    );

}


export const PeoplePopUps: popupIdMappingType = {
        
    "People": {
        "BasicNeeds": {
            "Sleep": <NoInfoPopUp />,
            "Climate":  <WordsPopUp words={["Emotional","Humidity", "Temperature"]}/>,
            "HomeLivingSpace":  <HomeLivingSpacePopUp />,
            "AirQuality":  <WordsPopUp words={["Quality","Quantity"]}/>,
            "Food": <WordsPopUp words={["More Healthy","Less Healthy", "More Appealing", "Less Appealing"]}/>,
            "Drink": <WordsPopUp words={["Quality","Quantity"]}/>,
            "Grooming":  <WordsPopUp words={["More", "Less","Different"]}/>,
            "Water": <WordsPopUp words={["Quality","Quantity"]}/>,
            "Sexual":  <WordsPopUp words={["Privacy","Relationship Issues"]}/>,
            "Touch": <WordsPopUp words={["More", "Less","Different"]}/>,
            "Love": <NoInfoPopUp />,
        },
        "Nutrition": {
            "Nutrition": <NoInfoPopUp />,
            "AllergyIntolerance": <WordsPopUp words={["Physical", "Environmental", "Emotional", "Chemical"]} />,
            "EFA": <WordsPopUp words={["Essential Fatty Acids", "Add", "Adjust", "Eliminate"]} />,      
            "Hydration": <WordsPopUp words={["Dehydration", "Water Quality"]} />,
            "PHBalance": <WordsPopUp words={["Acidic", "Alkaline"]} />,
            "Medications": <WordsPopUp words={["Add", "Adjust", "Eliminate"]} />,
        },
        "BodyArea": {
            "Skin": <NoInfoPopUp />,
            "Neurological": <NoInfoPopUp />,
            "Eyes": <NoInfoPopUp />,
            "Ears": <NoInfoPopUp />,
            "Lymph": <NoInfoPopUp />,
            "Brain": <NoInfoPopUp />,
            "Colon": <NoInfoPopUp />,
            "HandsFeet": <NoInfoPopUp />,
            "Fascia": <NoInfoPopUp />,
            "Tendons":  <WordsPopUp words={["Muscles", "Tendons"]} />,
            "Bones": <NoInfoPopUp />,
            "CirculatorySystem": <WordsPopUp words={["Circulatory System"]} />,
            "BodyPart": <NoInfoPopUp />,
            "ArmLeg": <NoInfoPopUp />
        },
        "HealthStatus": {
            "Digestion":<NoInfoPopUp/>,
            "Pain": <WordsPopUp words={["Slight", "Intense"]} />,
            "Weight": <WordsPopUp words={["Over", "Under"]} />,
            "Energy": <WordsPopUp words={["High", "Low"]} />,
            "EnergyField": <WordsPopUp words={["Energy Field Compromise"]} />,
            "LifeForce": <WordsPopUp words={["Dying", "Healing"]} />,
            "Pregnant": <NoInfoPopUp />,
            "Obstruction": <WordsPopUp words={["Bowels", "Throat"]} />,
            "Allergy": <WordsPopUp words={["Allergy", "Intolerance"]} />,
            "Toxicity": <WordsPopUp words={["Physical", "Emotional", "Sensory"]} />,
            "BodyTemp": <WordsPopUp words={["High", "Low"]} />,
            "Cyst": <WordsPopUp words={["Actual", "Energetic", "Literal", "None Literal"]} />,
            "Tumor": <NoInfoPopUp />,
            "Inflam": <WordsPopUp words={["Inflammation Issues"]} />,
            "Arthritis": <NoInfoPopUp />,
            "NutritionalIssue": <NutritionalIssuePopUp />,
        },
        "Emotional": {
          
            "Bored": <WordsPopUp words={["Need Activity", "Nothing Going On"]} />,
            "AttnIssue": <WordsPopUp words={["Attention Issue"]} />,
            "NeedSpace": <WordsPopUp words={["Emotional", "Physical"]} />,
            "Esteem": <WordsPopUp words={["Low Self-Esteem"]} />,
            "Grieving": <NoInfoPopUp />,
            "Worried": <NoInfoPopUp />,
            "Jealous": <NoInfoPopUp />,
            "TGF": <WordsPopUp words={["Take for Granted"]} />,
            "Shame": <NoInfoPopUp />,
            "WithoutDirection": <WordsPopUp words={["Without Direction"]} />,
            "NeedToBelong": <NoInfoPopUp />,
            "Preoccupied": <NoInfoPopUp />,
            "Overwhelmed": <WordsPopUp words={["Overwhelmed", "Stressed"]} />,
            "EmotionalSponging": <NoInfoPopUp />
        },
        "Relationships": {
           //no popups in this section
        },
        "Environment": {
            //no popups in this section
         },
        "BodyChemistry": {
            "Nutrition": <WordsPopUp words={["See Nutrition Chart"]} />,//.need to redirect
            "HeavyMetal": <NoInfoPopUp />,
            "PHImbalance": <WordsPopUp words={["Acidic", "Alkaline"]} />,
            "MedicationIssue": <NoInfoPopUp />,
        },
        "UpwardOnward": {
            //no popups in this section
         },
         "Traits": {
            //no popups in this section
         },
         "OutAbout": {
            //no popups in this section
         }, 
        "HealthIntervention" : {
            "NaturopathicDoctor": <NoInfoPopUp />,
            "Chiropractor": <NoInfoPopUp />,
            "Acupuncture": <NoInfoPopUp />,
            "Homeopathy": <NoInfoPopUp />,
            "NonVerbalComm": <WordsPopUp words={["Non-verbal Communicator"]} />,
            "Medication": <WordsPopUp words={["Add","Remove"]} />,
            "Dentist": <NoInfoPopUp />,
            "FlowerEssences":<WordsPopUp words={["Bach Flower"]} />,
            "Dowsing": <DowsingPopUp />,
            "Osteo": <WordsPopUp words={["Doctor Of Osteopath"]} />,
            "Colonics": <NoInfoPopUp />,
            "Detox": <WordsPopUp words={["Detoxification"]}/>,
            "WesternMD": <WordsPopUp words={["Western Medicine Doctor (MD)"]}/>,
            "EnergyFeild": <NoInfoPopUp />,
            "EmergencyCare": <NoInfoPopUp />,
        }
    }
}