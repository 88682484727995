import { ChartPagesType } from '../types'
import { HomePopUps } from './popups';

export const HomeCharts: ChartPagesType = {
  "Home": {
    chart: [
      { id: 0, label: 'Frequency', value: 1, color: '#8A2528' },
      { id: 1, label: 'Room/Location', value: 1, color: '#C3102F' },
      { id: 2, label: 'Compass', value: 1, color: '#F48026' },
      { id: 3, label: 'Actions', value: 1, color: '#EFB211' },
      { id: 4, label: 'Remedies', value: 1, color: '#46AA42' },
      { id: 5, label: 'House Issues', value: 1, color: '#00653A' },
      { id: 6, label: 'Angels/Guides', value: 1, color: '#008B98' },
      { id: 7, label: 'Color', value: 1, color: '#263692' },
      { id: 8, label: 'Chakras', value: 1, color: '#221E72' },
      { id: 9, label: 'Bagua Areas', value: 1, color: '#692C91' },
      { id: 10, label: 'Unwanted Visitors', value: 1, color: '#862074' },
      { id: 11, label: 'Clearing', value: 1, color: '#896531' },
      { id: 12, label: 'Occupants', value: 1, color: '#674E3F' },

    ],

    parent: "All Modules",
    level: 1,
    subPages: {

      "Frequency": {
        chart: [

          { id: 0, label: '-100', value: 1, color: '#8A2528' },
          { id: 1, label: '-90', value: 1, color: '#953C3D' },
          { id: 2, label: '-80', value: 1, color: '#A15053' },
          { id: 3, label: '-70', value: 1, color: '#AD6668' },
          { id: 4, label: '-60', value: 1, color: '#B87C7E' },
          { id: 5, label: '-50', value: 1, color: '#C49293' },
          { id: 6, label: '-40', value: 1, color: '#D0A8A9' },
          { id: 7, label: '-30', value: 1, color: '#DCBEBE' },
          { id: 8, label: '-20', value: 1, color: '#E8D4D4' },
          { id: 9, label: '-10', value: 1, color: '#F3E9E9' },
          { id: 10, label: '0', value: 1, color: '#F3E9E9' },
          { id: 11, label: '10', value: 1, color: '#F3E9E9' },
          { id: 12, label: '20', value: 1, color: '#E8D4D4' },
          { id: 13, label: '30', value: 1, color: '#DCBEBE' },
          { id: 14, label: '40', value: 1, color: '#D0A8A9' },
          { id: 15, label: '50', value: 1, color: '#C49293' },
          { id: 16, label: '60', value: 1, color: '#B87C7E' },
          { id: 17, label: '70', value: 1, color: '#AD6668' },
          { id: 18, label: '80', value: 1, color: '#A15053' },
          { id: 19, label: '90', value: 1, color: '#953C3D' },
          { id: 20, label: '100', value: 1, color: '#8A2528' }
        ],
        parent: "Home",
        level: 2,
        subPages: {},
        disableClick: true
      },

      "Room/Location": {
        chart: [
          { id: 0, label: 'Other Area Inside', value: 1, color: '#C3102F', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 1, label: 'Laundry Room', value: 1, color: '#F0C3CB', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 2, label: 'Family Room', value: 1, color: '#C3102F', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 3, label: 'Rec Room', value: 1, color: '#F0C3CB', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 4, label: 'Sunroom', value: 1, color: '#C3102F', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 5, label: 'Garage', value: 1, color: '#F0C3CB', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 6, label: 'Closet', value: 1, color: '#C3102F', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 7, label: 'Office', value: 1, color: '#F0C3CB', popupContent: HomePopUps.Home.RoomLocation.Office },
          { id: 8, label: 'Deck', value: 1, color: '#C3102F', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 9, label: 'Porch', value: 1, color: '#F0C3CB', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 10, label: 'Yard', value: 1, color: '#C3102F', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 11, label: 'Kitchen', value: 1, color: '#F0C3CB', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 12, label: 'Basement', value: 1, color: '#C3102F', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 13, label: 'Bedroom', value: 1, color: '#F0C3CB', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 14, label: 'Bathroom', value: 1, color: '#C3102F', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 15, label: 'Storage Room', value: 1, color: '#F0C3CB', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 16, label: 'Outside Dwelling', value: 1, color: '#C3102F', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
          { id: 17, label: 'Other Inside Room', value: 1, color: '#F0C3CB', popupContent: HomePopUps.Home.RoomLocation.NoInfo },
        ],
        parent: "Home",
        level: 2,
        subPages: {},
      },
      "Compass": {
        chart: [
          { id: 0, label: 'West', value: 1, color: '#C3102F', popupContent: HomePopUps.Home.Compass.West },
          { id: 1, label: 'North', value: 1, color: '#F0C3CB', popupContent: HomePopUps.Home.Compass.North },
          { id: 2, label: 'East', value: 1, color: '#C3102F', popupContent: HomePopUps.Home.Compass.East },
          { id: 3, label: 'South', value: 1, color: '#F0C3CB', popupContent: HomePopUps.Home.Compass.South },
        ],
        parent: "Home",
        level: 2,
        subPages: {}
      },

      "Actions": {
        chart: [
          { id: 0, label: 'Replace/Reposition Items', labelWithFormatting:"Replace/Reposition \nItems", value: 1, color: '#EFB211' , popupContent: HomePopUps.Home.Actions.Replace},
          { id: 1, label: 'Redecorate/Update', value: 1, color: '#FBEBC4' , popupContent: HomePopUps.Home.Actions.HomeNoInfo},
          { id: 2, label: 'Energetic Radon Remediation', labelWithFormatting: 'Energetic Radon \nRemediation',value: 1, color: '#EFB211' , popupContent: HomePopUps.Home.Actions.HomeNoInfo},
          { id: 3, label: 'Shut Toilet Lids', value: 1, color: '#FBEBC4' , popupContent: HomePopUps.Home.Actions.HomeNoInfo},
          { id: 4, label: 'Asbestos Rem.', value: 1, color: '#EFB211' , popupContent: HomePopUps.Home.Actions.Asbestos},
          { id: 5, label: 'Fluff Pillows', value: 1, color: '#FBEBC4' , popupContent: HomePopUps.Home.Actions.HomeNoInfo},
          { id: 6, label: 'Declutter', value: 1, color: '#EFB211', popupContent: HomePopUps.Home.Actions.HomeNoInfo},
          { id: 7, label: 'Clean', value: 1, color: '#FBEBC4' , popupContent: HomePopUps.Home.Actions.HomeNoInfo},
          { id: 8, label: 'Remodel', value: 1, color: '#EFB211' , popupContent: HomePopUps.Home.Actions.HomeNoInfo},
          { id: 9, label: 'Organize', value: 1, color: '#FBEBC4' , popupContent: HomePopUps.Home.Actions.HomeNoInfo},
          { id: 10, label: 'Humidity', value: 1, color: '#EFB211', popupContent: HomePopUps.Home.Actions.Humidity},
          { id: 11, label: 'Landscape', value: 1, color: '#FBEBC4' , popupContent: HomePopUps.Home.Actions.HomeNoInfo},
          { id: 12, label: 'Mold Clearing', value: 1, color: '#EFB211' , popupContent: HomePopUps.Home.Actions.HomeNoInfo},
          { id: 13, label: 'Climate Change', value: 1, color: '#FBEBC4' , popupContent: HomePopUps.Home.Actions.Climate},
          { id: 14, label: 'Paint/New Color', value: 1, color: '#EFB211' , popupContent: HomePopUps.Home.Actions.Color},
          { id: 15, label: 'Write Out Intentions', value: 1, color: '#FBEBC4' , popupContent: HomePopUps.Home.Actions.HomeNoInfo},
          { id: 16, label: 'Finish House Project', value: 1, color: '#EFB211' , popupContent: HomePopUps.Home.Actions.HomeNoInfo},

        ],
        parent: "Home",
        level: 2,
        subPages: {}
      },

      "Remedies": {
        chart: [
          { id: 0, label: 'Personal Expression', value: 1, color: '#46AA42' , popupContent: HomePopUps.Home.Remedies.HomeNoInfo},
          { id: 1, label: 'Water Feature', value: 1, color: '#D1EAD0' , popupContent: HomePopUps.Home.Remedies.Water},
          { id: 2, label: 'Call in Angels', value: 1, color: '#46AA42', popupContent: HomePopUps.Home.Remedies.Angel},
          { id: 3, label: 'Add Animal/s', value: 1, color: '#D1EAD0' , popupContent: HomePopUps.Home.Remedies.HomeNoInfo},
          { id: 4, label: 'Stage House', value: 1, color: '#46AA42' , popupContent: HomePopUps.Home.Remedies.HomeNoInfo},
          { id: 5, label: 'Lighting', value: 1, color: '#D1EAD0' , popupContent: HomePopUps.Home.Remedies.Lighting},
          { id: 6, label: 'Sage', value: 1, color: '#46AA42' , popupContent: HomePopUps.Home.Remedies.HomeNoInfo},
          { id: 7, label: 'Dust', value: 1, color: '#D1EAD0' , popupContent: HomePopUps.Home.Remedies.HomeNoInfo},
          { id: 8, label: 'Oils', value: 1, color: '#46AA42'},
          { id: 9, label: 'Art', value: 1, color: '#D1EAD0' , popupContent: HomePopUps.Home.Remedies.Art},
          { id: 10, label: 'Music', value: 1, color: '#46AA42' , popupContent: HomePopUps.Home.Remedies.HomeNoInfo},
          { id: 11, label: 'Plants', value: 1, color: '#D1EAD0' , popupContent: HomePopUps.Home.Remedies.HomeNoInfo},
          { id: 12, label: 'Repairs', value: 1, color: '#46AA42', popupContent: HomePopUps.Home.Remedies.HomeNoInfo},
          { id: 13, label: 'Crystals', value: 1, color: '#D1EAD0', popupContent: HomePopUps.Home.Remedies.Crystals},
          { id: 14, label: 'Feng Shui', value: 1, color: '#46AA42' , popupContent: HomePopUps.Home.Remedies.HomeNoInfo},
          { id: 15, label: 'Open Windows', value: 1, color: '#D1EAD0' , popupContent: HomePopUps.Home.Remedies.HomeNoInfo},
          { id: 16, label: 'Take Out Trash', value: 1, color: '#46AA42' , popupContent: HomePopUps.Home.Remedies.HomeNoInfo},
          { id: 17, label: 'Add Angel Image', value: 1, color: '#D1EAD0' , popupContent: HomePopUps.Home.Remedies.Angel},
          { id: 18, label: 'Window Coverings', value: 1, color: '#46AA42' , popupContent: HomePopUps.Home.Remedies.HomeNoInfo},

        ],
        parent: "Home",
        level: 2,
        subPages: {}
      },

      "House Issues": {
        chart: [

          { id: 0, label: 'Foundational/Basement',labelWithFormatting:'Foundational/\nBasement', value: 1, color: '#00653A', popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 1, label: 'Insulation', value: 1, color: '#C0D8CE' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 2, label: 'Fireplace', value: 1, color: '#00653A' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 3, label: 'Entrances', value: 1, color: '#C0D8CE' , popupContent: HomePopUps.Home.HomeIssues.Entrances },
          { id: 4, label: 'Exterior', value: 1, color: '#00653A', popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 5, label: 'Plumbing', value: 1, color: '#C0D8CE' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 6, label: 'Windows', value: 1, color: '#00653A' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 7, label: 'Walls', value: 1, color: '#C0D8CE' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 8, label: 'Roof', value: 1, color: '#00653A' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 9, label: 'Doors', value: 1, color: '#C0D8CE', popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 10, label: 'Ceiling', value: 1, color: '#00653A' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 11, label: 'Lighting', value: 1, color: '#C0D8CE' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 12, label: 'Sump Pump', value: 1, color: '#00653A' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 13, label: 'Electrical', value: 1, color: '#C0D8CE' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 14, label: 'Appliances', value: 1, color: '#00653A' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 15, label: 'Heating/Cooling', value: 1, color: '#C0D8CE' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
          { id: 16, label: 'Sewer/Well/Septic', value: 1, color: '#00653A' , popupContent: HomePopUps.Home.HomeIssues.HomeNoInfo },
        ],
        parent: "Home",
        level: 2,
        subPages: {}
      },



      "Angels/Guides": {
        chart: [
          { id: 0, label: 'Arcturians', value: 1, color: '#008B98'  , popupContent: HomePopUps.Home.Angels.ArcturianEmmissionaries },
          { id: 1, label: 'Raphael', value: 1, color: '#BBE0E3'  , popupContent: HomePopUps.Home.Angels.Raphael },
          { id: 2, label: 'Gabriel', value: 1, color: '#008B98'  , popupContent: HomePopUps.Home.Angels.Gabriel },
          { id: 3, label: 'Michael', value: 1, color: '#BBE0E3'  , popupContent: HomePopUps.Home.Angels.Michael },
          { id: 4, label: 'Jophiel', value: 1, color: '#008B98'  , popupContent: HomePopUps.Home.Angels.Jophiel },
          { id: 5, label: 'Chamuel', value: 1, color: '#BBE0E3'  , popupContent: HomePopUps.Home.Angels.Chamuel },
          { id: 6, label: 'Uriel', value: 1, color: '#008B98' , popupContent: HomePopUps.Home.Angels.Uriel },
          { id: 7, label: 'Zadkiel', value: 1, color: '#BBE0E3'  , popupContent: HomePopUps.Home.Angels.Zadkiel },
          { id: 8, label: 'Spirit Guide', value: 1, color: '#008B98'  , popupContent: HomePopUps.Home.Angels.SpiritGuide },
          { id: 9, label: 'Metatron', value: 1, color: '#BBE0E3'  , popupContent: HomePopUps.Home.Angels.Metatron },
          { id: 10, label: 'Jesus Christ', value: 1, color: '#008B98' , popupContent: HomePopUps.Home.Angels.JesusChrist },
          { id: 11, label: 'Animal Companion', value: 1, color: '#BBE0E3'  , popupContent: HomePopUps.Home.Angels.AnimalCompanion },
          { id: 12, label: 'Other Helper', value: 1, color: '#008B98'  , popupContent: HomePopUps.Home.Angels.OtherHelper },
        ],
        parent: "Home",
        level: 2,
        subPages: {}
      },


      "Color": {
        chart: [
          { id: 0, label: 'Red', value: 1, color: '#FB2B17', popupContent: HomePopUps.Home.Color.Hue },
          { id: 1, label: 'Red-Orange', value: 1, color: '#FF5349', popupContent: HomePopUps.Home.Color.Hue },
          { id: 2, label: 'Orange', value: 1, color: '#FF581D', popupContent: HomePopUps.Home.Color.Hue },
          { id: 3, label: 'Yellow-Orange', value: 1, color: '#FFAE42', popupContent: HomePopUps.Home.Color.Hue },
          { id: 4, label: 'Yellow', value: 1, color: '#FFE044', popupContent: HomePopUps.Home.Color.Hue },
          { id: 5, label: 'Yellow-Green', value: 1, color: '#C9D731', popupContent: HomePopUps.Home.Color.Hue },
          { id: 6, label: 'Green', value: 1, color: '#0FAE4D', popupContent: HomePopUps.Home.Color.Hue },
          { id: 7, label: 'Blue-Green', value: 1, color: '#088F8F', popupContent: HomePopUps.Home.Color.Hue },
          { id: 8, label: 'Blue', value: 1, color: '#0B349B', popupContent: HomePopUps.Home.Color.Hue },
          { id: 9, label: 'Blue-Violet', value: 1, color: '#510AC9', popupContent: HomePopUps.Home.Color.Hue },
          { id: 10, label: 'Violet', value: 1, color: '#5C00A3', popupContent: HomePopUps.Home.Color.Hue },
          { id: 11, label: 'Red-Violet', value: 1, color: '#c71585', popupContent: HomePopUps.Home.Color.Hue },
        ],
        parent: "Home",
        level: 2,
        subPages: {}
      },
      "Chakras": {
        chart: [
          { id: 0, label: 'Third Eye Chakra', value: 1, color: '#263692', popupContent: HomePopUps.Home.Chakras.ThirdEye },
          { id: 1, label: 'Sacral Chakra', value: 1, color: '#C9CDE4', popupContent: HomePopUps.Home.Chakras.Sacral },
          { id: 2, label: 'Solar Plexus', value: 1, color: '#263692', popupContent: HomePopUps.Home.Chakras.Solar },
          { id: 3, label: 'Heart Chakra', value: 1, color: '#C9CDE4', popupContent: HomePopUps.Home.Chakras.Heart },
          { id: 4, label: 'Throat Chakra', value: 1, color: '#263692', popupContent: HomePopUps.Home.Chakras.Throat },
          { id: 5, label: 'Root Chakra', value: 1, color: '#C9CDE4', popupContent: HomePopUps.Home.Chakras.Root },
          { id: 6, label: 'Crown Chakra', value: 1, color: '#263692', popupContent: HomePopUps.Home.Chakras.Crown },
          { id: 7, label: 'Eighth Chakra', value: 1, color: '#C9CDE4', popupContent: HomePopUps.Home.Chakras.Eighth },
          //removed 2 chakras
          { id: 10, label: 'Chakra Not Listed', value: 1, color: '#263692', popupContent: HomePopUps.Home.Chakras.NoInfo },
        ],
        parent: "Home",
        level: 2,
        subPages: {}
      },
      "Bagua Areas": {
        chart: [
          { id: 0, label: 'Bagua Map', value: 1, color: '#D52329' },
          { id: 1, label: 'Wealth & Prosperity', value: 1, color: '#692C91', popupContent: HomePopUps.Home.Bagua.NoInfo },
          { id: 2, label: 'Fame & Reputation', value: 1, color: '#F48026', popupContent: HomePopUps.Home.Bagua.NoInfo },
          { id: 3, label: 'Love & Relation.', value: 1, color: '#F390B0', popupContent: HomePopUps.Home.Bagua.Love },
          { id: 4, label: 'Family & Friends', value: 1, color: '#00653A', popupContent: HomePopUps.Home.Bagua.Family },
          { id: 5, label: 'Health & Well Being', value: 1, color: '#FFE616', popupContent: HomePopUps.Home.Bagua.Health },
          { id: 6, label: 'Chldn. & Creativity', value: 1, color: '#f0f0f0', popupContent: HomePopUps.Home.Bagua.NoInfo },
          { id: 7, label: 'Knowl. & Wisdom', labelWithFormatting: 'Knowledge  & \n Wisdom',value: 1, color: '#263692', popupContent: HomePopUps.Home.Bagua.NoInfo },
          { id: 8, label: 'Career', value: 1, color: '#231F20', popupContent: HomePopUps.Home.Bagua.Career },
          { id: 9, label: 'Travel & Helpful People', labelWithFormatting:'Travel &\nHelpful People', value: 1, color: '#BEBFC2', popupContent: HomePopUps.Home.Bagua.NoInfo }
        ],
        parent: "Home",
        level: 2,
        subPages: {}
      },
      "Unwanted Visitors": {
        chart: [
          { id: 0, label: 'Wasps,Hornets,Bees', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.Wasps },
          { id: 1, label: 'Bed Bugs', value: 1, color: '#E1C7DC', popupContent: HomePopUps.Home.Unwanted.Bed },
          { id: 2, label: 'Crickets/Beetles', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.Crickets },
          { id: 3, label: 'Birds', value: 1, color: '#E1C7DC', popupContent: HomePopUps.Home.Unwanted.Birds },
          { id: 4, label: 'Bats', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.Bats },
          { id: 5, label: 'Ants', value: 1, color: '#E1C7DC', popupContent: HomePopUps.Home.Unwanted.Ants },
          { id: 6, label: 'Fruit Flies', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.Fruit },
          { id: 7, label: 'Mosq.', value: 1, color: '#E1C7DC', popupContent: HomePopUps.Home.Unwanted.Mosquitoes },
          { id: 8, label: 'Flies/Fleas', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.FliesFleas },
          { id: 9, label: 'Moth', value: 1, color: '#E1C7DC', popupContent: HomePopUps.Home.Unwanted.Moth },
          { id: 10, label: 'Cockro.', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.Cockroaches },
          { id: 11, label: 'Slugs', value: 1, color: '#E1C7DC', popupContent: HomePopUps.Home.Unwanted.Slugs },
          { id: 12, label: 'Centi.', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.Centipedes },
          { id: 13, label: 'Rabbits', value: 1, color: '#E1C7DC', popupContent: HomePopUps.Home.Unwanted.Rabbits },
          { id: 14, label: 'Moles/Voles', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.Moles },
          { id: 15, label: 'Deer', value: 1, color: '#E1C7DC', popupContent: HomePopUps.Home.Unwanted.Deer },
          { id: 16, label: 'Squirrels', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.Squirrels },
          { id: 17, label: 'Spiders', value: 1, color: '#E1C7DC', popupContent: HomePopUps.Home.Unwanted.Spiders },
          { id: 18, label: 'Snakes', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.Snakes },
          { id: 19, label: 'Lizards/Reptiles', value: 1, color: '#E1C7DC', popupContent: HomePopUps.Home.Unwanted.Lizards },
          { id: 20, label: 'Skunks', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.Skunks },
          { id: 21, label: 'Porcupines', value: 1, color: '#E1C7DC', popupContent: HomePopUps.Home.Unwanted.Porcupines },
          { id: 22, label: 'Mice/Rats', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.Mice },
          { id: 23, label: 'Gophers/Prairie Dogs', value: 1, color: '#E1C7DC', popupContent: HomePopUps.Home.Unwanted.Gophers },
          { id: 24, label: 'Animal has a deeper message', value: 1, color: '#862074', popupContent: HomePopUps.Home.Unwanted.Animal },
        ],
        parent: "Home",
        level: 2,
        subPages: {}
      },
      "Clearing": {
        chart: [
          { id: 0, label: 'Take Inspired Action', value: 1, color: '#896531' , popupContent: HomePopUps.Home.Clearing.NoInfoPopUp },

          { id: 3, label: 'Call In An Angel', value: 1, color: '#E1D8CC' , popupContent: HomePopUps.Home.Clearing.CallAngel },

          { id: 6, label: 'Animal Msg.', value: 1, color: '#896531' , popupContent: HomePopUps.Home.Clearing.Animal },
          { id: 7, label: 'Spirit Message', value: 1, color: '#E1D8CC' , popupContent: HomePopUps.Home.Clearing.Spirit },
          { id: 9, label: 'Detect Location', value: 1, color: '#896531' , popupContent: HomePopUps.Home.Clearing.NoInfoPopUp },
          { id: 10, label: 'Clean', value: 1, color: '#E1D8CC' , popupContent: HomePopUps.Home.Clearing.NoInfoPopUp },
          { id: 11, label: 'Dowsing Prof.', value: 1, color: '#896531' , popupContent: HomePopUps.Home.Clearing.Dowsing },
          { id: 12, label: 'Add To Space', value: 1, color: '#E1D8CC' , popupContent: HomePopUps.Home.Clearing.NoInfoPopUp },

          { id: 15, label: 'Move From Space', value: 1, color: '#896531' , popupContent: HomePopUps.Home.Clearing.NoInfoPopUp },
          { id: 16, label: 'Clear Energy Field', value: 1, color: '#E1D8CC', popupContent: HomePopUps.Home.Clearing.ClearEnergyField },
        ],
        parent: "Home",
        level: 2,
        subPages: {}
      },
      "Occupants": {
        chart: [
          { id: 0, label: 'Animal Companion', value: 1, color: '#674E3F' , popupContent: HomePopUps.Home.Occupants.HomeNoInfo },
          { id: 1, label: 'Contractor', value: 1, color: '#D9D3CF' , popupContent: HomePopUps.Home.Occupants.HomeNoInfo },
          { id: 2, label: 'Room Mate', value: 1, color: '#674E3F' , popupContent: HomePopUps.Home.Occupants.HomeNoInfo },
          { id: 3, label: 'Boarder', value: 1, color: '#D9D3CF' , popupContent: HomePopUps.Home.Occupants.HomeNoInfo },
          { id: 4, label: 'Parent', value: 1, color: '#674E3F' , popupContent: HomePopUps.Home.Occupants.HomeNoInfo },
          { id: 5, label: 'Self', value: 1, color: '#D9D3CF' , popupContent: HomePopUps.Home.Occupants.HomeNoInfo },
          { id: 6, label: 'Tenant', value: 1, color: '#674E3F' , popupContent: HomePopUps.Home.Occupants.HomeNoInfo },
          { id: 7, label: 'Sibling', value: 1, color: '#D9D3CF', popupContent: HomePopUps.Home.Occupants.HomeNoInfo },
          { id: 8, label: 'Visitor/Guest', value: 1, color: '#674E3F' , popupContent: HomePopUps.Home.Occupants.HomeNoInfo },
          { id: 9, label: 'Spiritual Visitor', value: 1, color: '#D9D3CF' , popupContent: HomePopUps.Home.Occupants.HomeNoInfo },
        ],
        parent: "Home",
        level: 2,
        subPages: {}
      },
    }
  }
}
