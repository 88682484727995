import React, { useState, FormEvent } from 'react';
import { Container, TextField, Button, Typography, Box, Paper, CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';

const AccountReset: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleReset = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const response = await fetch('/api/password_reset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'email': email }),
    });

    if (response.status === 200) {
      setSuccess(true);
      setAlertMessage('Password reset link has been sent to your email.');
    } else {
      const responseJson = await response.json();
      setAlertMessage(responseJson.message || 'Something went wrong. Please try again.');
    }
    setShowAlert(true);
    setLoading(false);
  };

  return (
    <Box style={{ backgroundColor: "#7B1FA2", display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Container sx={{ height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Paper elevation={3} sx={{ padding: 4, mt: 4, width: '40%' }}>
          <Box textAlign="center">
            <img src="logo.png" alt="Logo" style={{ maxWidth: '200px', marginBottom: '30px' }} />
            <Typography variant="h6">
              Reset Your Password
            </Typography>
            <Typography variant="body1">
              Enter your email address to receive a password reset link.
            </Typography>
          </Box>
          <form onSubmit={handleReset}>
            <Box mt={2}>
              <TextField
                fullWidth
                label="Email Address"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                disabled={loading}
              />
            </Box>
            <Box mt={4} textAlign="center">
              <Button type="submit" variant="contained" color="primary" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Send Reset Link'}
              </Button>
            </Box>
          </form>
          {showAlert && (
            <Box mt={2}>
              <Alert severity={success ? "success" : "error"}>
                {alertMessage}
              </Alert>
            </Box>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default AccountReset;
