import { Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import { NoInfoPopUp, WordsPopUp } from "../popup"
import { popupIdMappingType } from "../types"
import { AngelsPopups } from "../AngelsPopups"


export const CallAngel = () => {
    return (
        <div>
            <Typography>You do this just with your intention and thoughts. Make sure that you ask specifically for what you need and then start your pendulum swinging in a clockwise manner and let it swing on it's own until it is done. You will then know that the angels/guides/helpers have completed your request. Make sure to thank them for the help. Gratitude goes a long way!</Typography>
            <Typography>Consult &nbsp;
                <Link to="?module=Soul&item=Angels">Angels</Link>
                </Typography>
        </div>
    );

}
export const SpiritPopUp = () => {
    return (
        <div>
            <Typography>Someone in spirit has something that they need you to know. If you are not a medium, you can refer to the Trailblazing Communications People Style Module to find out what the message is.</Typography>
            <Typography>Consult &nbsp;
                <Link to="?module=People">People Style</Link>
                </Typography>
        </div>
    );

}
export const AnimalPopUp = () => {
    return (
        <div>
            <Typography>This means that whatever animal comes up with this has something that they need you to know beyond the explanations for the unwanted visitor explanations. If you are not a non-verbal communicator you can refer to Trailblazing communications Animal Style Module to find out what this message may be.</Typography>
            <Typography>Consult &nbsp;
                <Link to="?module=Animal"> Animal Style</Link>
                </Typography>
        </div>
    );

}

export const NaturePopUp = () => {
    return (
        <div>
            <Typography>Consult &nbsp;
                <Link to="?module=Nature">Nature Style</Link>
                </Typography>
        </div>
    );

}
export const BusinessPopUp = () => {
    return (
        <div>
            <Typography>Consult &nbsp;
                <Link to="?module=Business">Business Style</Link>
                </Typography>
        </div>
    );

}
export const KarmaPopUp = () => {
    return (
        <div>
            <Typography>Clearing needed in Karma Style.</Typography>
            <Typography>Consult &nbsp;
                <Link to="?module=Karma">Karma Style</Link>
                </Typography>
        </div>
    );

}



export const SoulPopUps: popupIdMappingType = {
    "Soul": {
        "Issues": {
            "SelfWorth": <NoInfoPopUp />,
            "Perception": <NoInfoPopUp />,
            "SelfSabotage": <NoInfoPopUp />,
            // Emotional
            "Bored": <WordsPopUp words={["Need Activity", "Nothing Going On"]} />,
            "AttnIssue": <WordsPopUp words={["Attention Issue"]} />,
            "NeedSpace": <WordsPopUp words={["Emotional","Physical"]} />,
            "LowSelfEsteem": <NoInfoPopUp />,
            "Greiving": <NoInfoPopUp />,
            "Worried": <NoInfoPopUp />,
            "Jealous": <NoInfoPopUp />,
            "TakenForGranded": <WordsPopUp words={["Taken For Granted"]}/>,
            "Shame": <NoInfoPopUp />,
            "WithoutDirection": <WordsPopUp words={["Without Direction"]} />,
            "NeedToBelong":<NoInfoPopUp />,
            "Preoccupied": <NoInfoPopUp />,
            "Overwhelmed": <NoInfoPopUp />,
            "EmotionalSponging": <NoInfoPopUp />,

        },

        "Triggers": {
            "TriggersNoInfo": <NoInfoPopUp />,
          ///All popups except Power have no info
            "Power": <WordsPopUp words={["Mine", "Theirs"]} />,
        },
        
        "Connections": {
            "ConnectionsNoInfo": <NoInfoPopUp />,
          ///All popups except below have no info
            "Nature": <NaturePopUp />,
            //Archetypes Word List
            "Business": <BusinessPopUp />,
            "Generational": <WordsPopUp words={["Maternal","Paternal"]} />,
        },

        "ActionsBehaviors": {
            "ActionsNoInfo": <NoInfoPopUp />,
          ///All popups except weight, hiddenActions have no info
            "Weight": <WordsPopUp words={["Low", "High"]} />,
            "HiddenActions": <WordsPopUp words={["Unable To See Own Actions",]} />,
            "HiddenPatterns": <WordsPopUp words={["Unable To See Own Patterns",]} />,
        },

        "Traits": {
          //no popups, all wordlists
        },


        "Syncing": {
            "SyncNoInfo":  <NoInfoPopUp />,
            "GroupConsciousness": <WordsPopUp words={["Group Consciousness"]} />,
            "Parent": <WordsPopUp words={["Mother", "Father"]} />,
            "Sibling": <WordsPopUp words={["Sister", "Brother"]} />,
            "Deceased":<WordsPopUp words={["Human", "Animal"]} />,
           
        },
        "Clearing": {
            "Frequency": <WordsPopUp words={["Raise", "Lower"]} />,
            "CallAngel": <CallAngel />,
            "Animal": <AnimalPopUp />,
            "Spirit": <SpiritPopUp />,
            "Karma": <KarmaPopUp />,
            "ClearEnergyField": <WordsPopUp words={["With a left spin of your pendulum and an intention to clear any unwanted energies that are not in the highest good of the animal that you are clearing ask for it to be cleared and taken to a dimension where it cannot come back again to bother the animal that it was attached to. When the pendulum stops spinning on its own, ask if the energies were cleared. If you get a yes, then do a right spin and bring in the protective and healing white energies of the Divine and if you get a no, repeat the left spin process intending to clear any unwanted energies until you get a yes. Always end this process with filling the cleared space with the protective and healing white energies of the Divine."]} />,
        },
        "Origination": {
            //all of them have the same popup
            "Origination": <NoInfoPopUp />,
        },
        "Angels": {
            ...AngelsPopups,
        },
   
    }
}