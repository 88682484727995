import React, { useState, FormEvent } from 'react';
import {
  Container, TextField, Button, Typography, Box, Paper,
  IconButton, InputAdornment
} from '@mui/material';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { AddTokenToLocalStorage, TokenObject } from '../components/Auth/Auth';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
type LoginFailedResponse = {
  status: number;
  appCode: string;
  message: string;
};

const Login: React.FC = () => {
  const [toggle_password, setTogglePassword] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [accountNotSetup, setAccountNotSetup] = useState<boolean>(false);
  const [passwordWrong, setPasswordWrong] = useState<boolean>(false);
  const [noActiveSubscription, setNoActiveSubscription] = useState<boolean>(false);

  const navigate = useNavigate();
  const togglePasswordHide = () => {
    setTogglePassword(!toggle_password);
  };

  const setToken = (token: TokenObject) => {
    AddTokenToLocalStorage(token);
    navigate('/');
  };

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const fingerprint = await getFingerprint();
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'username': username, 'password': password, 'fingerprint': fingerprint }),
    });

    if (response.status === 200) {
      const data = await response.json() as TokenObject;
      setShowAlert(false);
      setAccountNotSetup(false);
      setPasswordWrong(false);
      setNoActiveSubscription(false);
      setToken(data);
    } else {
      const responseJson: LoginFailedResponse = await response.json();
      setAlertMessage(responseJson.message);
      setShowAlert(true);
      if (responseJson.appCode === "ACCOUNT_NO_SETUP") {
        setAccountNotSetup(true);
      } else if (responseJson.appCode === "PASSWORD_WRONG") {
        setPasswordWrong(true);
      } else if (responseJson.appCode === "NO_ACTIVE_SUBSCRIPTION") {
        setNoActiveSubscription(true);
      } else {
        setAccountNotSetup(false);
        setPasswordWrong(false);
        setNoActiveSubscription(false);
      }
    }
  };

  return (
    <Box style={{ backgroundColor: "#7B1FA2", display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Container sx={{ height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Paper elevation={3} sx={{ padding: 4, mt: 4, width: '40%' }}>
          <Box textAlign="center">
            <img src="logo.png" alt="Logo" style={{ maxWidth: '200px', marginBottom: '30px' }} />
            <Typography variant="h6">
              👋 Welcome back!
            </Typography>
            <Typography variant="body1">
              Sign in to your account
            </Typography>
          </Box>
          <form onSubmit={handleLogin}>
            <Box mt={2}>
              <TextField
                fullWidth
                label="Email Address"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
              />
            </Box>
            <Box mt={2}>
              <TextField
                fullWidth
                label="Password"
                type={toggle_password ? 'text' : 'password'}
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                error={showAlert}
                helperText={alertMessage}
                InputProps={
                  {
                    endAdornment: (
                      <InputAdornment position="end"> {
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordHide}
                          edge="end"
                        >
                          {toggle_password ? (
                            <Visibility className="cursor_pointer" />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      }
                      </InputAdornment>
                    ),
                  }
                }
              />
            </Box>
            <Box mt={4} textAlign="center">
              <Button type="submit" variant="contained" color="primary">
                {accountNotSetup ? "Finish Setting Up Account" : "Log In"}
              </Button>
            </Box>
          </form>
          {noActiveSubscription ? (
            <Box mt={2} textAlign="center">
              <Button onClick={() => window.location.href='https://www.trailblazingcommunications.com/' } variant="text">
                Renew Subscription?
              </Button>
            </Box>
          ) : (accountNotSetup || passwordWrong) && (
            <Box mt={2} textAlign="center">
              <Button onClick={() => navigate('/reset')} variant="text">
                Reset Password?
              </Button>
            </Box>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
