// ModuleTitle.tsx
import React from 'react';
import { Typography } from '@mui/material';

interface ModuleTitleProps {
    module: string | null;
}

export const ModuleTitle: React.FC<ModuleTitleProps> = ({ module }) => {
    return (
        <Typography variant="h4" sx={{ marginTop: '10px', marginLeft: '20px' }}>
            {module}
        </Typography>
    )
};

export default ModuleTitle;