

import React, { useEffect, useState } from 'react';
import { WordListType, WordListWithTitleType } from '../../data/types';
import WordCard from '../WordCard/WordCard';
import { Box, Pagination } from '@mui/material';

interface WordCardCollectionProps {
    wordList: WordListType[];
    cardSize?: number;
    height?: number;
    width?: number;
    titleHeight?: number;
    wordPageTitleHeight?: number;
    disablePagination?: boolean;
}

function findMaxLength(wordList: (string | WordListWithTitleType)[]): number {
    let maxLength = 0;

    for (let i = 0; i < wordList.length; i++) {
        if (typeof wordList[i] === 'string') {
            if ((wordList[i] as string).length > maxLength) {
                maxLength = (wordList[i] as string).length;
            }
        } else {
            let withTitle = wordList[i] as WordListWithTitleType;

            if (withTitle.title.length * 1.25 > maxLength) {
                maxLength = withTitle.title.length * 1.25;
            }

            for (let j = 0; j < withTitle.words.length; j++) {
                if (withTitle.words[j].length > maxLength) {
                    maxLength = withTitle.words[j].length;
                }
            }
        }
    }

    return maxLength;
}

const lengthToCardSize = (length: number): number => {
    return length * 7.5 + 56;
};

const WordCardCollection: React.FC<WordCardCollectionProps> = ({ wordList, cardSize, height, width, titleHeight, wordPageTitleHeight,disablePagination }) => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [cardsPerPage, setCardsPerPage] = useState(1);
    const [cards, setCards] = useState<JSX.Element[]>([]);
    let cardRef = React.createRef<HTMLDivElement>();
    const padding = 10;
    const containerWidth = (width ?? 0);
    let desiredCardSize = cardSize ?? lengthToCardSize(findMaxLength(wordList));
    let paginationDisabled = disablePagination ?? false;

    if ((containerWidth > 0) && (desiredCardSize > containerWidth)) {
         desiredCardSize = containerWidth - padding*3;
    }

    useEffect(() => {
        setPage(1);
    }, [wordList]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        var newCards = [];

        wordList.sort((a, b) => {
            if (typeof a === 'string' && typeof b === 'string') {
                return a.localeCompare(b);
            } else if (typeof a === 'string') {
                return 1; // push strings to the end
            } else if (typeof b === 'string') {
                return -1; // push strings to the end
            } else {
                // if both a and b have titles, compare the titles
                let aTitle = (a as WordListWithTitleType).title;
                let bTitle = (b as WordListWithTitleType).title;
                return aTitle.localeCompare(bTitle);
            }
        });

        let group: string[] = [];
        for (let i = 0; i < wordList.length; i++) {
            if (typeof wordList[i] === 'string') {
                group.push(wordList[i] as string);
                if (group.length === 5) {
                    newCards.push(<WordCard key={`word_card${i}`} content={group} cardSize={desiredCardSize} />);
                    group = [];
                }
            } else {
                let withTitle = wordList[i] as WordListWithTitleType;
                let sortedWords = withTitle.words.sort();
                for (let j = 0; j < sortedWords.length; j += 5) {
                    newCards.push(<WordCard key={`word_card${i}_${j}`}
                        cardSize={desiredCardSize}
                        content={sortedWords.slice(j, j + 5)}
                        title={withTitle.title}
                        links={withTitle?.links} />);
                }
            }
        }
        if (group.length > 0) {
            newCards.push(<WordCard cardSize={desiredCardSize} key={wordList.length} content={group} />);
        }
        setCardsPerPage(newCards.length);
        setCards(newCards);
    }, [wordList, desiredCardSize]);

    useEffect(() => {
        const calculatePagination = () => {
            if (paginationDisabled) {
                setTotalPages(1);
                setCardsPerPage(cards.length);
                return;
            }
    
            if (cardRef.current !== null && cardRef.current.children.length > 0) {
                let maxCardHeight = 0;
                let maxCardWidth = 0;
    
                for (let i = 0; i < cardRef.current.children.length; i++) {
                    const card = cardRef.current.children[i] as HTMLElement;
                    const cardHeight = card.offsetHeight;
                    const cardWidth = card.offsetWidth;
    
                    if (cardHeight > maxCardHeight) {
                        maxCardHeight = cardHeight;
                    }
    
                    if (cardWidth > maxCardWidth) {
                        maxCardWidth = cardWidth;
                    }
                }
    
                const containerHeight = (height ?? 0) - (wordPageTitleHeight ?? 0);
                const containerWidth = width ?? 0;
    
                const totalPadVertical = (Math.floor(containerHeight / maxCardHeight) + 1) * padding;
                const totalPadHorizontal = (Math.floor(containerWidth / maxCardWidth) + 1) * padding;
                const cardsPerColumn = (Math.floor((containerHeight - totalPadVertical) / maxCardHeight));
                const cardsPerRow = (Math.floor((containerWidth - totalPadHorizontal) / maxCardWidth));
                var newCardsPerPage = cardsPerRow * cardsPerColumn;
                if (newCardsPerPage < 1) {
                    newCardsPerPage = 1;
                }

                const newTotalPages = Math.ceil(cards.length / newCardsPerPage);
    
                setTotalPages(newTotalPages);
                setCardsPerPage(newCardsPerPage);
            }
        };
    
        calculatePagination();
    // eslint-disable-next-line
    }, [cards, height,width]);

    const start = (page - 1) * cardsPerPage;
    const end = start + cardsPerPage;
    const currentCards = cards.slice(start, end);

    return (
        <Box>
            {totalPages > 1 &&  !paginationDisabled ? (
                <Box style={{ height: titleHeight ?? 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {totalPages > 1 && <Pagination variant="outlined" color="primary" count={totalPages} page={page} onChange={handleChange} />}
                </Box>) : <Box style={{ height: titleHeight ?? 0 }}></Box>}
            <Box
                ref={cardRef}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(auto-fill, minmax(${desiredCardSize}px, 1fr))`,
                    gap: '10px',
                    justifyContent: 'start',
                    padding: '10px',
                }}>
                {currentCards}
            </Box>
        </Box>
    );
}
export default WordCardCollection;