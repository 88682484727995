import { FreedomPopUps } from './popups';
import { ChartPagesType } from '../types'
import { Typography } from "@mui/material";
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../utils';

export const FreedomCharts: ChartPagesType = {
  "Freedom": {
    chart: [
      { id: 0, label: 'Resonance, Frequency & Layers', labelWithFormatting : 'Resonance, \nFrequency & Layers', value: 1, color: '#C3102F' },
      { id: 1, label: 'Incongruence', value: 1, color: '#F48026' },
      { id: 2, label: 'Animal Syncing', value: 1, color: '#46AA42' },
      { id: 3, label: 'Angels & Guides', value: 1, color: '#0077C0' },
      { id: 4, label: 'Self-Care', value: 1, color: '#692C91' },

    ],

    parent: "All Modules",
    level: 1,
    subPages: {

      "Resonance, Frequency & Layers": {
        disableClick: true,
        chart: [
          { id: 0, label: '-100', value: 1, color: '#C3102F', },
          { id: 1, label: '-90', value: 1, color: '#C92844', },
          { id: 2, label: '-80', value: 1, color: '#CF3F58', },
          { id: 3, label: '-70', value: 1, color: '#D5586D', },
          { id: 4, label: '-60', value: 1, color: '#DB6F82', },
          { id: 5, label: '-50', value: 1, color: '#E18797', },
          { id: 6, label: '-40', value: 1, color: '#E7A0AC', },
          { id: 7, label: '-30', value: 1, color: '#EDB7C1', },
          { id: 8, label: '-20', value: 1, color: '#F3D0D6', },
          { id: 9, label: '-10', value: 1, color: '#F9E7EA', },
          { id: 10, label: '0', value: 1, color: '#F0F0F0', },
          { id: 11, label: '10', value: 1, color: '#F9E7EA', },
          { id: 12, label: '20', value: 1, color: '#F3D0D6', },
          { id: 13, label: '30', value: 1, color: '#EDB7C1', },
          { id: 14, label: '40', value: 1, color: '#E7A0AC', },
          { id: 15, label: '50', value: 1, color: '#E18797', },
          { id: 16, label: '60', value: 1, color: '#DB6F82', },
          { id: 17, label: '70', value: 1, color: '#D5586D', },
          { id: 18, label: '80', value: 1, color: '#CF3F58', },
          { id: 19, label: '90', value: 1, color: '#C92844', },
          { id: 20, label: '100', value: 1, color: '#C3102F' },
        ],
        innerChart :[
          { id: 21, label: '1', value: 1, color: '#F3D0D6', disableTextRotation: true},
          { id: 22, label: '2', value: 1, color: '#F3D0D6', disableTextRotation: true},
          { id: 23, label: '3', value: 1, color: '#F3D0D6', disableTextRotation: true},
          { id: 24, label: '4', value: 1, color: '#F3D0D6', disableTextRotation: true},
          { id: 25, label: '5', value: 1, color: '#F3D0D6', disableTextRotation: true},
          { id: 26, label: '6', value: 1, color: '#F3D0D6', disableTextRotation: true},
          { id: 27, label: '7', value: 1, color: '#F3D0D6', disableTextRotation: true},
          { id: 28, label: '8', value: 1, color: '#F3D0D6', disableTextRotation: true},
          { id: 29, label: '9', value: 1, color: '#F3D0D6', disableTextRotation: true}
        ],
        parent: "Freedom",
        level: 2,
        subPages: {},
        textChartInstructions : <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>Layer</Typography>
      },

      "Incongruence": {
        chart: [
          { id: 0, label: 'Self-Love Issue', value: 1, color: '#F8AD90' },
          { id: 1, label: 'Past Life Energy', value: 1, color: '#F25B21',popupContent: FreedomPopUps.Freedom.Incongruence.PastLife  },
          { id: 2, label: 'Action', value: 1, color: '#F8AD90' },
          { id: 3, label: 'Behavior', value: 1, color: '#F25B21' },
          { id: 4, label: 'Belief', value: 1, color: '#F8AD90' },
          { id: 5, label: 'Emotion', value: 1, color: '#F25B21' },
          { id: 6, label: 'Habit', value: 1, color: '#F8AD90' },
          { id: 7, label: 'Allergy', value: 1, color: '#F25B21' ,popupContent: FreedomPopUps.Freedom.Incongruence.Allergy},
          { id: 8, label: 'Genetics', value: 1, color: '#F8AD90' ,popupContent: FreedomPopUps.Freedom.Incongruence.Genetics},
          { id: 9, label: 'Karma', value: 1, color: '#F25B21' ,popupContent: FreedomPopUps.Freedom.Incongruence.Karma},
          { id: 10, label: 'Chromo. Abnor.', value: 1, color: '#F8AD90',popupContent: FreedomPopUps.Freedom.Incongruence.Chromo },
          { id: 11, label: 'Envirn. Toxins', value: 1, color: '#F25B21',popupContent: FreedomPopUps.Freedom.Incongruence.Envirn },
          { id: 12, label: 'Vaccs. Meds. Tox.', value: 1, color: '#F8AD90',popupContent: FreedomPopUps.Freedom.Incongruence.Vaccs },
          { id: 13, label: 'GMO Food Products', value: 1, color: '#F25B21' ,popupContent: FreedomPopUps.Freedom.Incongruence.GMO},
          { id: 14, label: 'Group Consc.', value: 1, color: '#F8AD90' ,popupContent: FreedomPopUps.Freedom.Incongruence.Group},
          { id: 15, label: 'Other', value: 1, color: '#F25B21' ,popupContent: FreedomPopUps.Freedom.Incongruence.Other},
        ],
        parent: "Freedom",
        level: 2,
        subPages: {}
      },


      "Animal Syncing": {
        chart: [

          { id: 0, label: 'Roommate', value: 1, color: '#46AA42', popupContent: FreedomPopUps.Freedom.Animal.AnimalSyncNoInfo },
          { id: 1, label: 'Partner', value: 1, color: '#A2D4A0', popupContent: FreedomPopUps.Freedom.Animal.AnimalSyncNoInfo },
          { id: 2, label: 'Parent', value: 1, color: '#46AA42', popupContent: FreedomPopUps.Freedom.Animal.AnimalSyncNoInfo },
          { id: 3, label: 'Child', value: 1, color: '#A2D4A0', popupContent: FreedomPopUps.Freedom.Animal.AnimalSyncNoInfo },
          { id: 4, label: 'Me', value: 1, color: '#46AA42', popupContent: FreedomPopUps.Freedom.Animal.AnimalSyncNoInfo },
          { id: 5, label: 'Tenant', value: 1, color: '#A2D4A0', popupContent: FreedomPopUps.Freedom.Animal.AnimalSyncNoInfo },
          { id: 6, label: 'Other Animal', value: 1, color: '#46AA42', popupContent: FreedomPopUps.Freedom.Animal.AnimalSyncNoInfo },
          { id: 7, label: 'Someone/Something Not Listed', value: 1, color: '#A2D4A0', popupContent: FreedomPopUps.Freedom.Animal.AnimalSyncNoInfo }
        ],
        parent: "Freedom",
        level: 2,
        subPages: {}
      },

      "Angels & Guides": {
        chart: [
          { id: 0, label: 'Arcturians', value: 1, color: '#0077C0', popupContent: FreedomPopUps.Freedom.Angels.ArcturianEmmissionaries },
          { id: 1, label: 'Raphael', value: 1, color: '#BBDAEE', popupContent: FreedomPopUps.Freedom.Angels.Raphael },
          { id: 2, label: 'Gabriel', value: 1, color: '#0077C0', popupContent: FreedomPopUps.Freedom.Angels.Gabriel },
          { id: 3, label: 'Michael', value: 1, color: '#BBDAEE', popupContent: FreedomPopUps.Freedom.Angels.Michael },
          { id: 4, label: 'Jophiel', value: 1, color: '#0077C0', popupContent: FreedomPopUps.Freedom.Angels.Jophiel },
          { id: 5, label: 'Chamuel', value: 1, color: '#BBDAEE', popupContent: FreedomPopUps.Freedom.Angels.Chamuel },
          { id: 6, label: 'Uriel', value: 1, color: '#0077C0', popupContent: FreedomPopUps.Freedom.Angels.Uriel },
          { id: 7, label: 'Zadkiel', value: 1, color: '#BBDAEE', popupContent: FreedomPopUps.Freedom.Angels.Zadkiel },
          { id: 8, label: 'Spirit Guide', value: 1, color: '#0077C0', popupContent: FreedomPopUps.Freedom.Angels.SpiritGuide },
          { id: 9, label: 'Metatron', value: 1, color: '#BBDAEE', popupContent: FreedomPopUps.Freedom.Angels.Metatron },
          { id: 10, label: 'Jesus Christ', value: 1, color: '#0077C0', popupContent: FreedomPopUps.Freedom.Angels.JesusChrist },
          { id: 11, label: 'Animal Companion', value: 1, color: '#BBDAEE', popupContent: FreedomPopUps.Freedom.Angels.AnimalCompanion },
          { id: 12, label: 'Other Helper', value: 1, color: '#0077C0', popupContent: FreedomPopUps.Freedom.Angels.OtherHelper },

        ],
        parent: "Freedom",
        level: 2,
        subPages: {}
      },

      "Self-Care": {
        chart: [
          { id: 0, label: 'Exercise-Walk', value: 1, color: '#692C91', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 1, label: 'Talk To Someone', value: 1, color: '#B495C8', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 2, label: 'Essential Oils', value: 1, color: '#692C91', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 3, label: 'Meditation', value: 1, color: '#B495C8', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 4, label: 'Self Nurture', value: 1, color: '#692C91', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 5, label: 'Journal', value: 1, color: '#B495C8', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 6, label: 'Rest', value: 1, color: '#692C91', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 7, label: 'Music', value: 1, color: '#B495C8', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 8, label: 'Dance', value: 1, color: '#692C91', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 9, label: 'Sing', value: 1, color: '#B495C8', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 10, label: 'Massage', value: 1, color: '#692C91', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 11, label: 'Chiropractic', value: 1, color: '#B495C8', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 12, label: 'Feel Your Feelings', value: 1, color: '#692C91', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },
          { id: 13, label: 'Something Not Listed', value: 1, color: '#B495C8', popupContent: FreedomPopUps.Freedom.SelfCare.SelfCareNoInfo },

        ],
        parent: "Freedom",
        level: 2,
        subPages: {}
      },



    }
  }
}