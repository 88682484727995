import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import './Footer.css';

const Footer: React.FC = () => {
    return (
        <Paper>
            {/* <Typography variant="caption" sx={{ fontSize: '0.55rem' }} align="center" display="block">© {new Date().getFullYear()} - Trailblazing Communications® LLC</Typography> */}
            <Typography variant="caption" sx={{ fontSize: '0.43rem' }} align="center" display="block">
                Disclaimer: The information and processes contained in these materials are intended for personal use and not intended to diagnose, treat, or be used
                for medical treatment or any type of action take on behalf of you or anyone else. This program is intended for communication purposes
                only and the user is responsible to and for any information they obtain by using this program. User accepts all responsibility for
                information and actions taken on behalf of that information.” It is highly recommended that user utilizes professional expertise and advice 
                when considering any actions on behalf of themselves, their children, families, clients, their animals, or any other people they may 
                communicate with using this process or these tools.
               © {new Date().getFullYear()} - Trailblazing Communications® LLC </Typography>
        </Paper>
    );
};

export default Footer;
