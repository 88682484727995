import { WordListMappingType, WordListPageTitle } from "../types";
import Typography from '@mui/material/Typography';

export const TrueOrFalseWordTitle = () => {
  return (
    <Typography variant="h5" style={{ textAlign: 'center', padding: '10px' }}>
      Is this True or False?
    </Typography>);
}



export const PeopleWordListPageTitle: WordListPageTitle = {
  "People": {
    "Relationships": {
      "Inner Circle": <TrueOrFalseWordTitle />,
      "Outer Circle": <TrueOrFalseWordTitle />,
      "Professional Circle": <TrueOrFalseWordTitle />,
    },
    "Environment": {
      "Emotional": <TrueOrFalseWordTitle />,
      "Physical": <TrueOrFalseWordTitle />,
      "Spiritual": <TrueOrFalseWordTitle />,
    }
  }
}

export const PeopleWordListMap: WordListMappingType = {
  "People": {
    "Nutrition": {
      "Foods & Treats": [
        {
          "title": "Fruits",
          "words": ["Apples", "Oranges", "Bananas", "Grapes", "Melons", "Tomato"]
        },
        {
          "title": "Proteins",
          "words": ["Protein", "Beef", "Chicken", "Fish", "Eggs", "Shellfish", "Whey", "Soy"]
        },
        {
          "title": "Dairy",
          "words": ["Cheese", "Eggs", "Milk", "Yogurt", "Ice Cream", "Butter", "Cottage Cheese", "Infant Formula"]
        },
        {
          "title": "Vegetables",
          "words": [
            "Lettuce",
            "Potatoes",
            "Carrots",
            "Peppers",
            "Asparagus",
            "Celery",
            "Broccoli",
            "Peas",
            "Beans",
            "Corn",
            "Pumpkin",
            "Squash",
            "Onion",
            "Garlic"
          ]
        },
        {
          "title": "Grains",
          "words":
            [
              "Amaranth",
              "Barley",
              "Rice",
              "Buckwheat",
              "Bulgur",
              "Flaxseed",
              "Oats",
              "Millet",
              "Oatmeal",
              "Popcorn",
              "Quinoa",
              "Rye",
              "Farro",
              "Corn",
              "Sorghum",
              "Legumes"
            ]
        }
      ],
      "Cell Balancing Salts": [
        "Calc Flour",
        "Calcarea Fluorica",
        "Calcarea Sulphurica",
        "Calcium Sulph",
        "Calcarea Phosphorica",
        "Calc Phos",
        "Ferrum Phosphorium",
        "Ferrum Phos",
        "Kali Phos",
        "Kali Mur",
        "Kali Muriaticum", // Assuming "Kali Mariaticum" is a typo
        // Note: "Kali Mur" is repeated
        "Kali Sulph",
        "Kali Sulphuricum",
        "Natrum Muriaticum",
        "Nat Mur",
        "Magnesia Phosphoric",
        "Mag Phos",
        "Natrium Phosphoricum",
        "Nat Phos",
        "Biochemic Phosphate", // This seems more like a category than a specific remedy
        "Natrum Sulphuricum",
        "Nat Sulph",
        "Bioplasma",
        "Silicea"
      ],
      "Vitamins": [
        "Vitamin A",
        "Vitamin B",
        "Vitamin B2",
        "Vitamin B3",
        "Vitamin B6",
        "Vitamin B12",
        "Vitamin C",
        "Vitamin D",
        "Vitamin D3",
        "Vitamin E",
        "Vitamin F",
        "Vitamin K"
      ],
      "Supplements": [
        "Acidophilus",
        "Calcium",
        "CoQ10",
        "Echinacea",
        "Fish Oil",
        "Gingko",
        "Glucosamine",
        "Green Tea",
        "Magnesium",
        "Melatonin",
        "Milk Thistle",
        "Multivitamin",
        "Niacin",
        "Probiotics",
        "Selenium"
      ],
      "Additives": [
        "Algin",
        "Artificial Colorings",
        "Aspartame",
        "BHT",
        "BHA",
        "Calcium Carbonate",
        "Citric Acid",
        "Erythorbic Acid",
        "Folic Acid",
        "Fumaric Acid",
        "Guar Gum",
        "Iron",
        "Lecithin",
        "Methylcellulose",
        "Mono & Diglycerides",
        "MSG",
        "Pectin",
        "Phosphoric Acid",
        "Potassium Besulfate", // Assuming "Potassium Bisulfate"
        "Sodium Benzoate",
        "Sodium Nitrate",
        "Trans Fat"
      ],
      "Minerals": [
        "Boron",
        "Copper",
        "Calcium",
        "Sulphur", // Note: "Sulfur" is the IUPAC spelling, but "Sulphur" is also commonly used
        "Chromium",
        "Germanium",
        "Molybdenum",
        "Phosphorous", // Note: The correct term for the element is "Phosphorus"
        "Magnesium",
        "Manganese",
        "Potassium",
        "Vanadium",
        "Selenium",
        "Sodium",
        "Lithium",
        "Cesium",
        "Cerium",
        "Iodine",
        "Silver",
        "Silica", // Note: "Silicon" is the element; "Silica" (SiO2) refers to a compound of silicon
        "Zinc",
        "Iron",
        "Tin",
        "Nickel",
        "Cobalt",
        "Barium",
        "Gallium",
        "Bismuth",
        "Bromine",
        "Chlorine",
        "Fluorine",
        "Gold",
        "Beryllium",
        "Hydrogen",
        "Nitrogen",
        "Strontium",
        "Titanium",
        "Zirconium"
      ],
      "Herbs": [
        "Alfalfa Herb", "Aloe Vera", "Angelica Root", "Arnica Flower", "Aspen Bark",
        "Astragalus Root", "Baptisia Root", "Barberry Root", "Bayberry Root Bark", "Bear Berry",
        "Benzoin Gum", "Betony Flower", "Black Cohosh Root", "Black Haw Root", "Black Walnut Hull",
        "Blessed Thistle Herb", "Bloodroot", "Blue Flag", "Blue Cohosh", "Blue Vervain Herb",
        "Blueberry Leaf", "Boneset", "Borage", "Boswella", "Brickells Herb",
        "Buchu Leaf", "Bugleweed Herb", "Burdock Root", "Burdock Seed", "Calendula Flower",
        "Cascara Sagrada Root", "Catnip Herb", "Cats Claw", "Cayenne Fruit", "Celandine, Greater Herb",
        "Chamomile Flower", "Chaparral Leaf", "Chastetree Berry", "Cinnamon Bark", "Clematis Herb",
        "Cleavers Herb", "Comfrey Leaf", "Corn Silk", "Cotton Root Bark", "Cole Parsnip Root",
        "Couch Grass", "Cow Parsnip Root", "Cramp Bark", "Damiana Herb", "Dandelion Root",
        "Desert Willow Bark", "Devils Claw Root", "Dill", "Dogbane Root", "Dong Quai Root",
        "Echinacea Root", "Elecampane Root", "Elder Flower", "Elderberry", "Eucalyptus Leaf",
        "Eyebright Herb", "False Unicorn Root", "Fennel Seed", "Feverfew Flower", "Fireweed Herb",
        "Fringetree Bark", "Garlic Bulb", "Gentian Root", "Ginger", "Gingko Leaf",
        "Goldenseal Root", "Gotu Kola Herb", "Gravel Root", "Grindelia Flower", "Guarana Seed",
        "Hawthorne Flower", "Hops Strobules", "Horehound Herb", "Horse Chestnut Bark", "Horseradish Root",
        "Horsetail Herb", "Immortal Root", "Juniper Berry", "Kava Kava Root", "Kelp Frond",
        "Kola Nut", "Lavender Leaf", "Licorice Root", "Lily Of The Valley Root", "Lobella Herb",
        "Ha Huang Stern", "Maravilla Root", "Marshmallow Root", "Matarique Root", "Milk Thistle Seed",
        "Menta Polep Herb", "Mormon Tea", "Motherwort Herb", "Mullein Leaf", "Myrrh Gum",
        "Neem", "Nettles Herb", "Oat Seed", "Ocotillo Stern Bark", "Oregon Grape Root",
        "Osha Root", "Pansy Herb", "Parsley", "Partridge Berry", "Passion Flower",
        "Pau d'Arco Bark", "Pennyroyal Herb", "Peppermint", "Pipsissewa Herb", "Pleurisy Root",
        "Poke Root", "Prickly Ash Bark", "Prickly Poppy", "Propolis Resin", "Pulsatilla Flower",
        "Quassia Wood", "Raspberry Leaf", "Red Clover Flower", "Red Root", "Rosemary Leaf",
        "Sarsaparilla Root", "Saw Palmetto Fruit", "Shepherds Purse Herb", "Skullcap Herb", "Slippery Elm",
        "Southern Wood", "Spikenard Root", "Stillingia Root", "Storksbill Herb", "St. John's Wort Flower",
        "Thuja Leaf", "Toadflax Herb", "Usnea Lichen", "Uva Ursi Leaf", "Valerian Root",
        "Virginia Snake Root", "White Oak Bark", "White Sage Leaf", "White Willow Bark", "Wild Cherry Bark",
        "Wild Ginger Bark", "Wild Yam Root", "Witch Hazel Leaf", "Yarrow Flower", "Yellow Dock Root",
        "Yerba Santa Leaf", "Yew", "Yohimbe Bark", "Yucca Root"
      ],

      "Amino Acids": [
        "Alanine",
        "Arginine",
        "Asparagine",
        "Aspartic Acid",
        "Biotin",
        "Cysteine",
        "Glutamine",
        "Glycine",
        "Histidine",
        "Isoleucine",
        "Leucine",
        "Lysine",
        "Methionine",
        "Phenylalanine",
        "Proline",
        "Serine",
        "Taurine",
        "Threonine",
        "Tryptophan",
        "Tyrosine",
        "Valine"
      ]
    },
    "Body Area": {
      "Endocrine": [
        "Adrenals",
        "Ovaries",
        "Pancreas",
        "Pineal Gland",
        "Pituitary Gland",
        "Testes",
        "Thymus",
        "Thyroid"
      ],
      "Organs": [
        "Bladder",
        "Gallbladder",
        "Heart",
        "Kidneys",
        "Large Intestine",
        "Liver",
        "Lung",
        "Small Intestine",
        "Spleen",
        "Stomach"
      ]
    },
    "Emotional": {
      "Scared": [
        "Afraid",
        "Alarmed",
        "Anxious",
        "Appalled",
        "Apprehensive",
        "Awed",
        "Concerned",
        "Defensive",
        "Desperate",
        "Doubtful",
        "Fearful",
        "Frantic",
        "Full of dread",
        "Guarded",
        "Horrified",
        "Impatient",
        "Insecure",
        "Intimidated",
        "Nervous",
        "Overwhelmed",
        "Panicky",
        "Perplexed",
        "Petrified",
        "Reluctant",
        "Shaken",
        "Shocked",
        "Stunned",
        "Startled",
        "Skeptical",
        "Shy",
        "Suspicious",
        "Swamped",
        "Terrified",
        "Tense",
        "Threatened",
        "Timid",
        "Tormented",
        "Uneasy",
        "Unsure",
        "Vulnerable"
      ],


      "Confused": [
        "Ambivalent",
        "Awkward",
        "Baffled",
        "Bewildered",
        "Bothered",
        "Constricted",
        "Directionless",
        "Disorganized",
        "Distracted",
        "Doubtful",
        "Flustered",
        "Foggy",
        "Hesitant",
        "Immobilized",
        "Misunderstood",
        "Perplexed",
        "Puzzled",
        "Stagnant",
        "Surprised",
        "Torn",
        "Trapped",
        "Troubled",
        "Uncertain",
        "Uncomfortable",
        "Undecided",
        "Unsettled",
        "Unsure"
      ],
      "Happy": [
        "Admired",
        "Alive",
        "Appreciated",
        "Assured",
        "Cheerful",
        "Confident",
        "Content",
        "Delighted",
        "Determined",
        "Ecstatic",
        "Elated",
        "Encouraged",
        "Energized",
        "Enthusiastic",
        "Excited",
        "Exuberant",
        "Flattered",
        "Fortunate",
        "Fulfilled",
        "Glad",
        "Good",
        "Grateful",
        "Gratified",
        "Hopeful",
        "Joyful",
        "Jubilant",
        "Justified",
        "Loved",
        "Marvelous",
        "Optimistic",
        "Peaceful",
        "Pleased",
        "Proud",
        "Relaxed",
        "Relieved",
        "Resolved",
        "Respected",
        "Satisfied",
        "Terrific",
        "Thrilled",
        "Tranquil",
        "Valued"
      ],
      "Sad": [
        "Alienated",
        "Ashamed",
        "Burdened",
        "Condemned",
        "Crushed",
        "Defeated",
        "Dejected",
        "Demoralized",
        "Depressed",
        "Deserted",
        "Despised",
        "Devastated",
        "Disappointed",
        "Discarded",
        "Discouraged",
        "Disgraced",
        "Disheartened",
        "Disillusioned",
        "Dismal",
        "Distant",
        "Distraught",
        "Distressed",
        "Drained",
        "Empty",
        "Exhausted",
        "Grievous",
        "Helpless",
        "Hopeless",
        "Humbled",
        "Inadequate",
        "Islanded",
        "Isolated",
        "Lonely",
        "Miserable",
        "Mournful",
        "Neglected",
        "Pitiful",
        "Regretful",
        "Rejected",
        "Resigned",
        "Sorrowful",
        "Terrible",
        "Unappreciated",
        "Uncared for",
        "Unloved",
        "Unwanted",
        "Upset",
        "Worthless",
        "Wounded"
      ],
      "Angry": [
        "Abused",
        "Aggravated",
        "Agitated",
        "Anguished",
        "Annoyed",
        "Betrayed",
        "Cheated",
        "Coerced",
        "Controlled",
        "Deceived",
        "Dismayed",
        "Disgusted",
        "Displeased",
        "Dominated",
        "Enraged",
        "Exasperated",
        "Exploited",
        "Frustrated",
        "Fuming",
        "Furious",
        "Harrassed",
        "Hateful",
        "Hostile",
        "Humiliated",
        "Incensed",
        "Irritated",
        "Mad",
        "Offended",
        "Outraged",
        "Patronized",
        "Peeved",
        "Perturbed",
        "Pissed Off",
        "Provoked",
        "Rebellious",
        "Repulsed",
        "Repulsed",
        "Resentful",
        "Ridiculed",
        "Sabotaged",
        "Seething",
        "Smothered",
        "Spiteful",
        "Stifled",
        "Strangled",
        "Throttled",
        "Uptight",
        "Used",
        "Vengeful",
        "Vindictive"
      ],

    },
    "Relationships": {
      "Inner Circle": [
        "Animal (Pet)",
        "Boyfriend/Girlfriend",
        "Direct Service Staff Person",
        "Family As A Whole",
        "Parents - Mom/Dad",
        "Relative",
        "Service Animal",
        "Siblings - Brother/Sister",
        "Spouse/Partner"
      ],
      "Outer Circle": [
        "I enjoy and get along with my co-workers.",
        "I enjoy and get along with my roommates.",
        "I get to have friends.",
        "I get to participate in community activities.",
        "I get to relate with others in my community.",
        "I have a support network.",
        "I want/need to extend and expand more into the community.",
        "My teachers are a good fit.",
        "I am satisfied with my education system or plan.",
        " I am too sensitive to be in large groups.",
        "Relationship with life"
      ],
      "Professional Circle": [
        "I am satisfied with my dentist.",
        "I am satisfied with my doctor.",
        "I am satisfied with my mental health counselor.",
        "I am satisfied with my occupational therapist.",
        "I am satisfied with my physical therapist.",
        "I am satisfied with my service providers.",
        "I am satisfied with my speech/language therapist.",
        "I am satisfied with people that are the staff that work for me."
      ],
    },
    "Environment": {
      "Emotional": [
        "I am experiencing abuse or neglect.",
        "I am lonesome for someone or something.",
        "I feel like a priority.",
        "I feel loved.",
        "I feel respected.",
        "I feel taken advantage of.",
        "I get overwhelmed and/or over stimulated by activities and my environment.",
        "I get overwhelmed and/or over stimulated by the people around me.",
        "I get to decide when to share my personal information.",
        "I get to exercise my personal rights.",
        "I need a venue for having my ideas heard and understood.",
        "My feelings are acknowledged and respected."
      ],
      "Physical": [
        "Choosing music type.",
        "Environment meets physical needs or not.",
        "Feeling safe.",
        "Having daily balance.",
        "Having daily structure.",
        "Having guests in living space.",
        "Issues with others' boundaries.",
        "Learning environment is fun.",
        "Living in integrated environment.",
        "Making environmental choices.",
        "Physically comfortable.",
        "Satisfied with dentist.",
        "Satisfied with doctor.",
        "Satisfied with mental health counselor.",
        "Satisfied with occupational therapist.",
        "Satisfied with physical therapist.",
        "Treated fairly or not.",
        "Wanting better boundaries.",
        "Wanting different home/space.",
        "Wanting different roommate."

      ],
      "Spiritual": [
        "Access to spiritual activities of interest.",
        "Allowed to exercise beliefs.",
        "Allowed to explore spiritual beliefs/practices.",
        "Spiritual beliefs honored and respected.",
        "Wanting to expand spiritual beliefs/practices."
      ],
    },
    "Body Chemistry": {
      "Parasites": [
        "Amoeba",
        "Balantidium",
        "Bedbug",
        "Bots",
        "Cryptosporidium",
        "Deuterium",
        "Flea",
        "Giardia",
        "Isospora",
        "Leishmania",
        "Lice",
        "Malaria",
        "Metals",
        "Pinworms",
        "Plasmodium",
        "Ringworm",
        "Roundworms",
        "Scabies",
        "Tapeworm",
        "Threadworms",
        "Ticks",
        "Toxoplasmosis",
        "Trichomonas",
        "Trypanosome",
        "Trypanosome Cruzi"
      ],
      "Mold & Fungus": [
        "Acremonium",
        "Alternaria",
        "Aspergillus",
        "Chartarum",
        "Cladosporium",
        "Dreschslera",
        "Penicillium",  // Corrected spelling
        "Ringworm",  // Note: Ringworm is a condition caused by fungi, not a fungus itself
        "Stachybotrys",
        "Tinea",
        "Trichoderma"
      ],
      "Bacteria": [
        "Aeromonas",
        "Borrelia",
        "Brucellosis",
        "Campylobacteriosis",
        "Clostridia",
        "Cystitis",
        "E. coli",
        "Enterobacter",
        "H. pylori",
        "Influenza",
        "Klebsiella",
        "Leptospirosis",
        "Lyme Disease",
        "Meningitis",
        "Mycobacterium",
        "Pasteurella",
        "Pertussis",
        "Proteus",
        "Respiratory Bacteria",
        "Salmonella",
        "Staph",
        "Strep",
        "Syphilis",
        "TB",
        "Tetanus",
        "Typhus"
      ],
      "Viruses": [
        "AIDS",
        "Adenovirus",
        "Chicken Pox",
        "Corona Virus",  // Note: Added for current relevance
        "Ebola",
        "Epstein Barr",
        "H1N1",
        "HPV",
        "Hepatitis",
        "Herpes",
        "Influenza",
        "Measles",
        "Mononucleosis",
        "Mumps",
        "Rabies",
        "Rhinovirus",
        "Rubella",
        "Strep"
      ],
      "Toxins": [
        "Acetaldehyde",
        "Acetone",
        "Artificial Sweeteners",
        "BHA/BHT",
        "Carbon Monoxide",
        "Chlorine",
        "Chloroform",
        "Dioxins",
        "Ethanol",
        "Food Dyes",
        "Formaldehyde",
        "Glycol Ethers",
        "High Fructose Corn Syrup",
        "MSG",
        "Medications",  // Note: Beneficial in many contexts but can have harmful aspects.
        "Pesticides",
        "Potassium Bromate",
        "Propylene Glycol",
        "Sodium Nitrate",
        "Sodium Sulfite",
        "Styrene",
        "Sulfur Dioxide",
        "Tetracycline",  // An antibiotic, considered in specific contexts.
        "Trans Fat",
        "Vaccinations",  // Generally beneficial, included due to specific concerns.
        "Xylene"
      ],
      "Vaccine": [
        "Anthrax",
        "Chicken Pox",
        "Covid",
        "Diphtheria",
        "Encephalitis",
        "HIB",
        "HPV",
        "Hepatitis",
        "Influenza",
        "Lyme Disease",
        "MMR",
        "Measles",
        "Mumps",
        "Pertussis",
        "Pneumococcal",
        "Polio",
        "Rabies",
        "Rotavirus",
        "Rubella",
        "Shingles",
        "Smallpox",
        "Tetanus",
        "Tuberculosis",
        "Typhoid",
        "Yellow Fever"
      ],
      "Additive": [
        "Aluminum",
        "Animal/Poultry Fat",
        "Artificial Color",
        "BHT/BHA",
        "Corn Meal",
        "Ethoxyquin",
        "Meat By-Products",
        "Molasses",
        "Peanut Hulls",
        "Pesticides",
        "Propylene Glycol",
        "Salt",
        "Sodium Nitrate",
        "Soybean",
        "Sugar/Sweeteners"
      ],
    },
    "Upward & Onward": {
      "Life Purpose": [
        "Aligning with life's purpose.",
        "Desiring to make a difference.",
        "Having ideas to share.",
        "Seeking employment.",
        "Seeking new/different employment.",
        "Wanting to serve."
      ],
      "Goals": [
        "Caring for clothing and belongings.",
        "Caring for nuclear family.",
        "Choosing clothing.",
        "Choosing own goals.",
        "Expressing and caring for personal needs.",
        "Managing personal finances.",
        "Preparing and choosing food.",
        "Selecting and managing household."
      ],
      "Dreams": [
        "Choosing and developing a career.",
        "Desiring to marry.",
        "Having a dream job in mind.",
        "Seeking a relationship.",
        "Wanting to create/design.",
        "Wanting to travel."
      ],
      "Education & Learning": [
        "Choosing music preferences.",
        "Opportunities to expand knowledge.",
        "Opportunity for accelerated classes.",
        "Opportunity for extra-curricular activities.",
        "Opportunity to graduate.",
        "Participating in extended high school.",
        "Participating in preferred classes.",
        "Venue to share information/ideas."
      ],
    },
    "Traits": {
      "Chart A": [
        "Abrupt",
        "Absentminded",
        "Active",
        "Adaptable",
        "Adventurous",
        "Aggressive",
        "Agreeable",
        "Alert",
        "Aloof",
        "Ambitious",
        "Amusing",
        "Angry",
        "Anxious",
        "Appreciative",
        "Argumentative",
        "Arrogant",
        "Artificial",
        "Assertive",
        "Athletic",
        "Attractive",
        "Balanced",
        "Bizarre",  // Corrected spelling
        "Brilliant",
        "Brutal",
        "Busy",
        "Calm",
        "Capable",
        "Careless",
        "Caring",
        "Casual",
        "Cautious",
        "Challenging",
        "Charming",
        "Cheerful",
        "Childish",
        "Clean",
        "Clever",
        "Clumsy",
        "Cold",
        "Colorful",
        "Colorless",
        "Compassionate",
        "Competitive",
        "Complacent",
        "Complex",
        "Compulsive",
        "Conceited",
        "Confident",
        "Confidential",
        "Confused"
      ],
      "Chart B": [
        "Bizarre",  // Corrected spelling
        "Brilliant",
        "Conservative",
        "Considerate",
        "Contradictory",
        "Cooperative",
        "Courageous",
        "Crazy",
        "Creative",
        "Critical",
        "Crude",
        "Cruel",
        "Cute",
        "Cynical",
        "Deceptive",
        "Dedicated",
        "Delicate",
        "Demanding",
        "Dependent",
        "Desperate",
        "Destructive",
        "Determined",
        "Devious",
        "Difficult",
        "Dirty",
        "Disloyal",
        "Disobedient",
        "Disorderly",
        "Disorganized",
        "Disrespectful",
        "Disruptive",
        "Distractible",
        "Disturbing",
        "Dominating",
        "Dramatic",
        "Dreamy",
        "Dull",
        "Easily Discouraged",
        "Educated",
        "Efficient",
        "Emotional",
        "Empathetic",
        "Energetic",
        "Enthusiastic",
        "Envious",
        "Excitable",
        "Exciting",
        "Experimental",
        "Extreme",
        "Faithful"
      ],
      "Chart C": [
        "Fearful",
        "Firm",
        "Flexible",
        "Focused",
        "Foolish",
        "Forgetful",
        "Formal",
        "Friendly",
        "Frightening",
        "Frivolous",
        "Frugal",
        "Fun",
        "Generous",
        "Gentle",
        "Genuine",
        "Glamorous",
        "Gloomy",
        "Greedy",
        "Gullible",
        "Hardworking",
        "Healthy",
        "Helpful",
        "Hesitant",
        "Honest",
        "Hostile",
        "Humble",
        "Humorous",
        "Hurried",
        "Hyperactive",
        "Ignorant",
        "Imaginative",
        "Impatient",
        "Impersonal",
        "Impractical",
        "Impressionable",
        "Impressive",
        "Impulsive",
        "Inconsiderate",
        "Indecisive",
        "Independent",
        "Indulgent",
        "Insecure",
        "Insensitive",
        "Insightful",
        "Insincere",
        "Insulting",
        "Intelligent",
        "Intense",
        "Intolerant",
        "Irrational"
      ],
      "Chart D": [
        "Intuitive",
        "Invisible",
        "Kind",
        "Lazy",
        "Logical",
        "Lovable",
        "Maternal",
        "Mature",
        "Mellow",
        "Messy",
        "Miserable",
        "Miserly",
        "Misguided",
        "Mistaken",
        "Moderate",
        "Money-Minded",
        "Moody",
        "Morbid",
        "Narcissistic",
        "Narrow-Minded",
        "Naïve",
        "Neat",
        "Neglectful",
        "Neurotic",
        "Neutral",
        "Obedient",
        "Objective",
        "Obnoxious",
        "Observant",
        "Obsessive",
        "Obvious",
        "Odd",
        "One-Sided",
        "Open",
        "Opinionated",
        "Opportunistic",
        "Oppressed",
        "Optimistic",
        "Ordinary",
        "Outrageous",
        "Outspoken",
        "Paranoid",
        "Passionate",
        "Passive",
        "Perfectionistic",
        "Personable",
        "Persuasive",
        "Physical",
        "Playful"
      ],
      "Chart E": [
        "Political",
        "Popular",
        "Possessive",
        "Power-Hungry",
        "Practical",
        "Precise",
        "Predictable",
        "Prejudiced",
        "Preoccupied",
        "Private",
        "Procrastinating",
        "Profound",
        "Progressive",
        "Protective",
        "Proud",
        "Punctual",
        "Pure",
        "Questioning",
        "Quiet",
        "Quirky",
        "Rational",
        "Reactionary",
        "Reactive",
        "Realistic",
        "Regretful",
        "Relaxed",
        "Reliable",
        "Religious",
        "Resentful",
        "Reserved",
        "Resourceful",
        "Respectful",
        "Responsible",
        "Restrained",
        "Ridiculous",
        "Rigid",
        "Romantic",
        "Rowdy",
        "Ruined",
        "Sarcastic",
        "Secretive",
        "Secure",
        "Sedentary",
        "Self-conscious",
        "Self-Sufficient",
        "Selfish",
        "Sensitive",
        "Sentimental",
        "Serious",
        "Sexy"
      ],
      "Chart F": [
        "Shallow",
        "Shortsighted",
        "Shy",
        "Silly",
        "Simple",
        "Skeptical",
        "Skillful",
        "Sloppy",
        "Slow",
        "Sober",
        "Soft",
        "Solitary",
        "Sophisticated",
        "Spontaneous",
        "Stable",
        "Steady",
        "Stern",
        "Stiff",
        "Strict",
        "Strong",
        "Strong-Willed",
        "Stubborn",
        "Stupid",
        "Stylish",
        "Submissive",
        "Subtle",
        "Superficial",
        "Superstitious",
        "Surprising",
        "Suspicious",
        "Sweet",
        "Sympathetic",
        "Tactless",
        "Tasteful",
        "Tense",
        "Thorough",
        "Thoughtless",
        "Tidy",
        "Timid",
        "Tolerant",
        "Tough",
        "Transparent",
        "Trendy",
        "Troublesome",
        "Trusting",
        "Unappreciative",
        "Uncaring",
        "Uncooperative",
        "Understanding",
        "Undisciplined"
      ],
      "Chart G": [
        "Unfriendly",
        "Ungrateful",
        "Unhealthy",
        "Unlovable",
        "Unrealistic",
        "Unreliable",
        "Unstable",
        "Vague",
        "Vulnerable",
        "Warm",
        "Weak",
        "Well-read",
        "Well-rounded",
        "Whimsical",
        "Wise",
        "Witty",
        "Wishful",
        "Young"
      ],

    },
    "Out & About": {
      "Activities & Hobbies": [
        "I get to choose what activities I participate in.",
        "I feel like I get to participate in enough activities.",
        "I get to perform different social roles.",
        "I get to play when and how I want to.",
        "I get enough exercise.",
        "I get to choose what adventures I want to have.",
        "I get to participate in activities with friends.",
        "I get to choose the type of music I like to listen to.",
        "I get to choose how I want to exercise.",
        "I feel like I fit in."
      ],
      "Equipment": [
        {
          "title": "Mobility Group",
          "words": ["Gait Trainer", "Stander", "Walker", "Braces", "Canes", "Wheelchairs"]
        },
        {
          "title": "Computer Access",
          "words": ["Key Guard", "Mini Mouse", "Touch Screen", "Trackball", "Switch Interface",
            "Text To Speech Software", "Speech To Text Software", "Picture Software"]
        },
        {
          "title": "Daily Living",
          "words": [
            "Adapted Eating And Drinking Utensils", "Adapted Personal Hygiene Tools", "Switch Operated Cooking Devices",
            "Picture Task Analysis For Chores", "Switch Adapted Toys", "Environmental Control Units", "Adapted Swings",
            "Adapted Tricycle"]
        },
        {
          "title": "Hearing",
          "words": [
            "Classroom Amplification System",
            "Personal Amplification System",
            "TTY"
          ]
        },
        {
          "title": "Communication",
          "words": ["Eye Gaze Board", "Object Communication Boards", "Single Message Voice Output Device",
            "Multi-Message Voice Output Device",
            "Voice Output With Dynamic Display",
            "Voice Output With Multiple Levels",
            "iPad",
          ]
        },
        {
          "title": "Seating/Positioning",
          "words": [
            "Non-Slip On Chair To Prevent Slipping",
            "Bolster, Rolled Towel, Blocks For Feet",
            "Adapted/Alternate Char, Side Lyer, Stander",
            "Custom Fitted Wheelchair Insert"
          ]
        },

      ],
      "Transportation": [
        "I am traveling, I feel safe.",
        "I have access to transportation.",
        "I have the appropriate assistance when I travel.",
        "I want to take more trips.",
        "I want to travel more/less.",
        "Transportation is available to me when I need it.",
        "Transportation services I utilize are punctual so that I can make my destinations on time."
      ],
    },
    "Health Intervention": {
      "Essential Oils": [
        "Anise",
        "Basil",
        "Believe",
        "Bergamot",
        "Cassia",
        "Cedarwood",
        "Cinnamon",
        "Cistus",
        "Citronella",
        "Citrus Fresh",
        "Clary Sage",
        "Clove",
        "Copaiba",
        "Coriander",
        "Cypress",
        "Digize",
        "Dorado Azul",
        "Fennel",
        "Frankincense",
        "Geranium",
        "Grounding",
        "Helichrysum",
        "Hyssop",
        "Idaho Balsam Fir",
        "Idaho Tansy",
        "Immupower",
        "Joy",
        "Juniper",
        "Lauris Nobilis",
        "Lavender",
        "Ledum",
        "Lemon",
        "Lemongrass",
        "Marjoram",
        "Melissa",
        "Melrose",
        "Mountain Savory",
        "Myrtle",
        "Myrrh",
        "Nutmeg",
        "Ocotea",
        "Orange",
        "Oregano",
        "Palmarosa",
        "Palo Santo",
        "Pan Away",
        "Patchouli",
        "Peace and Calming",
        "Peppermint",
        "Pine",
        "Purification",
        "Ravintsara",
        "Roman Chamomile",
        "Rose",
        "Rosemary",
        "Ruta Vala",
        "Sage",
        "Sandalwood",
        "Spearmint",
        "Spikenard",
        "Spruce",
        "Tangerine",
        "Tarragon",
        "Tea Tree (Malaleuca Alternifolia)",
        "Thieves",
        "Thyme",
        "Tsuga",
        "Trauma Life",
        "Valerian",
        "Valor",
        "Vetiver",
        "White Angelica",
        "Wintergreen",
        "Ylang Ylang"
      ],
      "Energy Modality": [
        "Acupuncture",
        "Aromatherapy",
        "Biofeedback",
        "Body Code",
        "Body Talk",
        "Cryo Therapy",
        "Cold Laser",
        "Color Therapy",
        "Cranial Sacral",
        "Emotion Code",
        "Emotional Freedom Technique (EFT)",
        "Energy Field Clearing - Entity Removal",
        "Essential Oils",
        "Falconer",
        "Fascial Unwinding",
        "Flower Essences",
        "Healing Touch",
        "Hydrotherapy",
        "Hyperbarics",
        "Magnetic Therapy",
        "Massage",
        "Medium",
        "Myofascial Therapy",
        "Ozone Therapy",
        "Physical Therapy",
        "Prayer Chain",
        "Psychic",
        "Psych K",
        "Radionics",
        "Raindrop Therapy",
        "Sound Therapy",
        "Theta Healing",
        "Training",
        "Tuning Forks",
        "Need a therapy not listed"
      ]
    }
  }
}