import { SoulPopUps } from './popups';
import { ChartPagesType } from '../types'

export const SoulCharts: ChartPagesType = {
  "Soul": {
    chart: [
      { id: 1, label: 'Issues', value: 1, color: '#F48026' },
      { id: 2, label: 'Triggers', value: 1, color: '#EFB211' },
      { id: 3, label: 'Connections', value: 1, color: '#46AA42' },
      { id: 4, label: 'Actions/Behaviors', value: 1, color: '#009290' },
      { id: 5, label: 'Traits', value: 1, color: '#263692' },
      { id: 6, label: 'Syncing', value: 1, color: '#221E72' },
      { id: 7, label: 'Clearing', value: 1, color: '#692C91' },
      { id: 8, label: 'Origination', value: 1, color: '#862074' },
      { id: 9, label: 'Angels', value: 1, color: '#896531' },
    ],

    parent: "All Modules",
    level: 1,
    subPages: {
      "Issues": {
        chart: [
          { id: 0, label: 'Thought Pattern', value: 1, color: '#F48026' },
          { id: 1, label: 'Belief', value: 1, color: '#FCDDC5' },
          { id: 2, label: 'Emotional', value: 1, color: '#F48026' },
          { id: 3, label: 'Self Worth Issue', value: 1, color: '#FCDDC5', popupContent: SoulPopUps.Soul.Issues.SelfWorth },
          { id: 4, label: 'Perception Issue', value: 1, color: '#F48026', popupContent: SoulPopUps.Soul.Issues.Perception },
          { id: 5, label: 'Self-Sabotage', value: 1, color: '#FCDDC5', popupContent: SoulPopUps.Soul.Issues.SelfSabotage },
          { id: 6, label: 'Boundary Issue', value: 1, color: '#F48026' }
        ],
        parent: "Soul",
        level: 2,
        subPages: {
          "Emotional": {
            chart: [
              { id: 0, label: 'Happy', value: 1, color: '#F48026' },
              { id: 1, label: 'Sad', value: 1, color: '#FCDFC9' },
              { id: 2, label: 'Angry', value: 1, color: '#F48026' },
              { id: 3, label: 'Scared', value: 1, color: '#FCDFC9' },
              { id: 4, label: 'Confused', value: 1, color: '#F48026' },
              { id: 5, label: 'Bored', value: 1, color: '#FCDFC9', popupContent: SoulPopUps.Soul.Issues.Bored},
              { id: 6, label: 'Attn. Issue', value: 1, color: '#F48026', popupContent: SoulPopUps.Soul.Issues.AttnIssue},
              { id: 7, label: 'Need Space', value: 1, color: '#FCDFC9', popupContent: SoulPopUps.Soul.Issues.NeedSpace},
              { id: 8, label: 'Self Esteem', value: 1, color: '#F48026', popupContent: SoulPopUps.Soul.Issues.LowSelfEsteem},
              { id: 9, label: 'Grieving', value: 1, color: '#FCDFC9', popupContent: SoulPopUps.Soul.Issues.Greiving},
              { id: 10, label: 'Worried', value: 1, color: '#F48026', popupContent: SoulPopUps.Soul.Issues.Worried},
              { id: 11, label: 'Jealous', value: 1, color: '#FCDFC9', popupContent: SoulPopUps.Soul.Issues.Jealous},
              { id: 12, label: 'TFG', value: 1, color: '#F48026', popupContent: SoulPopUps.Soul.Issues.TakenForGranded},
              { id: 13, label: 'Shame', value: 1, color: '#FCDFC9', popupContent: SoulPopUps.Soul.Issues.Shame},
              { id: 14, label: 'W/O Direction', value: 1, color: '#F48026', popupContent: SoulPopUps.Soul.Issues.WithoutDirection},
              { id: 15, label: 'Need to Belong', value: 1, color: '#FCDFC9', popupContent: SoulPopUps.Soul.Issues.NeedToBelong},
              { id: 16, label: 'Preoccupied', value: 1, color: '#F48026', popupContent: SoulPopUps.Soul.Issues.Preoccupied},
              { id: 17, label: 'Overwhelmed', value: 1, color: '#FCDFC9', popupContent: SoulPopUps.Soul.Issues.Overwhelmed},
              { id: 18, label: 'Emotional Sponging', value: 1, color: '#F48026', popupContent: SoulPopUps.Soul.Issues.EmotionalSponging},
          ],
            parent: "Issues",
            level: 3,
            subPages: {}
          }
        }
      },
      "Triggers": {
        chart: [

          { id: 0, label: 'Actions', value: 1, color: '#EFB211', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 1, label: 'Behaviors', value: 1, color: '#FAEABF', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 2, label: 'Emotions', value: 1, color: '#EFB211', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 3, label: 'Reactions', value: 1, color: '#FAEABF', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 4, label: 'Admirations', value: 1, color: '#EFB211', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 5, label: 'Hates', value: 1, color: '#FAEABF', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 6, label: 'Loves', value: 1, color: '#EFB211', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 7, label: 'Fears', value: 1, color: '#FAEABF', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 8, label: 'Power', value: 1, color: '#EFB211', popupContent: SoulPopUps.Soul.Triggers.Power },
          { id: 9, label: 'Rules', value: 1, color: '#FAEABF', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 10, label: 'Challenges', value: 1, color: '#EFB211', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 11, label: 'Gifts', value: 1, color: '#FAEABF', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 12, label: 'Abundance', value: 1, color: '#EFB211', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 13, label: 'Money', value: 1, color: '#FAEABF', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 14, label: 'Opportunities', value: 1, color: '#EFB211', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 15, label: 'Situations', value: 1, color: '#FAEABF', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 16, label: 'Sex/Sexuality', value: 1, color: '#EFB211', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },
          { id: 17, label: 'Unresolved Issue', value: 1, color: '#FAEABF', popupContent: SoulPopUps.Soul.Triggers.TriggersNoInfo },

        ],
        parent: "Soul",
        level: 2,
        subPages: {}
      },


      "Connections": {
        chart: [
          { id: 0, label: 'Conception Energy', value: 1, color: '#46AA42', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 1, label: 'Energetic Cords', value: 1, color: '#CDE8CC', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 2, label: 'Energy Matrix', value: 1, color: '#46AA42', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 3, label: 'Unresolved Trauma', value: 1, color: '#CDE8CC', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 4, label: 'Karma', value: 1, color: '#46AA42', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 5, label: 'Genetic Pattern', value: 1, color: '#CDE8CC', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 6, label: 'Nature', value: 1, color: '#46AA42', popupContent: SoulPopUps.Soul.Connections.Nature },
          { id: 7, label: 'Home', value: 1, color: '#CDE8CC', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 8, label: 'Archetypes', value: 1, color: '#46AA42' },
          { id: 9, label: 'Business', value: 1, color: '#CDE8CC', popupContent: SoulPopUps.Soul.Connections.Business },
          { id: 10, label: 'Marriage', value: 1, color: '#46AA42', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 11, label: 'Programming', value: 1, color: '#CDE8CC', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 12, label: 'Masks', value: 1, color: '#46AA42', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 13, label: 'Ancestral Pattern', value: 1, color: '#CDE8CC', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 14, label: 'Soul Contract', value: 1, color: '#46AA42', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 15, label: 'Generational Energy', value: 1, color: '#CDE8CC', popupContent: SoulPopUps.Soul.Connections.Generational },
          { id: 16, label: 'Work/Job/Career', value: 1, color: '#46AA42', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },
          { id: 17, label: 'Past Life Energy', value: 1, color: '#CDE8CC', popupContent: SoulPopUps.Soul.Connections.ConnectionsNoInfo },

        ],
        parent: "Soul",
        level: 2,
        subPages: {}
      },


      "Actions/Behaviors": {
        chart: [
          { id: 0, label: 'Food/Eating Issues', value: 1, color: '#009290', popupContent: SoulPopUps.Soul.ActionsBehaviors.ActionsNoInfo },
          { id: 1, label: 'Body Image Issues', value: 1, color: '#C0E4E3', popupContent: SoulPopUps.Soul.ActionsBehaviors.ActionsNoInfo },
          { id: 2, label: 'Health/Illness', value: 1, color: '#009290', popupContent: SoulPopUps.Soul.ActionsBehaviors.ActionsNoInfo },
          { id: 3, label: 'Allergies', value: 1, color: '#C0E4E3', popupContent: SoulPopUps.Soul.ActionsBehaviors.ActionsNoInfo },
          { id: 4, label: 'Avoidance', value: 1, color: '#009290', popupContent: SoulPopUps.Soul.ActionsBehaviors.ActionsNoInfo },
          { id: 5, label: 'Resistance', value: 1, color: '#C0E4E3', popupContent: SoulPopUps.Soul.ActionsBehaviors.ActionsNoInfo },
          { id: 6, label: 'Addiction', value: 1, color: '#009290', popupContent: SoulPopUps.Soul.ActionsBehaviors.ActionsNoInfo },
          { id: 7, label: 'Weight Issues', value: 1, color: '#C0E4E3', popupContent: SoulPopUps.Soul.ActionsBehaviors.Weight },
          { id: 8, label: 'Self-Sabotage', value: 1, color: '#009290', popupContent: SoulPopUps.Soul.ActionsBehaviors.ActionsNoInfo },
          { id: 9, label: 'Hidden Actions', value: 1, color: '#C0E4E3', popupContent: SoulPopUps.Soul.ActionsBehaviors.HiddenActions },
          { id: 10, label: 'Hidden Patterns', value: 1, color: '#009290', popupContent: SoulPopUps.Soul.ActionsBehaviors.HiddenPatterns },
          { id: 11, label: 'Sexual Dysfunction', value: 1, color: '#C0E4E3', popupContent: SoulPopUps.Soul.ActionsBehaviors.ActionsNoInfo },
          { id: 12, label: 'Money Management', value: 1, color: '#009290', popupContent: SoulPopUps.Soul.ActionsBehaviors.ActionsNoInfo },
          { id: 13, label: 'Power Distortion', value: 1, color: '#C0E4E3', popupContent: SoulPopUps.Soul.ActionsBehaviors.ActionsNoInfo },
          { id: 14, label: 'Comparing', value: 1, color: '#009290', popupContent: SoulPopUps.Soul.ActionsBehaviors.ActionsNoInfo },

        ],
        parent: "Soul",
        level: 2,
        subPages: {}
      },


      "Traits": {
        chart: [
          { id: 0, label: 'Chart A', value: 1, color: '#263692' },
          { id: 1, label: 'Chart B', value: 1, color: '#C9CDE4' },
          { id: 2, label: 'Chart C', value: 1, color: '#263692' },
          { id: 3, label: 'Chart D', value: 1, color: '#C9CDE4' },
          { id: 4, label: 'Chart E', value: 1, color: '#263692' },
          { id: 5, label: 'Chart F', value: 1, color: '#C9CDE4' },
          { id: 6, label: 'Chart G', value: 1, color: '#263692' },
        ],
        parent: "Soul",
        level: 2,
        subPages: {}
      },


      "Syncing": {
        chart: [
          { id: 0, label: 'Group Consc.', value: 1, color: '#221E72', popupContent: SoulPopUps.Soul.Syncing.GroupConsciousness },
          { id: 1, label: 'Animal Companion', value: 1, color: '#C8C7DC', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 2, label: 'Spouse/Partner', value: 1, color: '#221E72', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 3, label: 'Child(ren)', value: 1, color: '#C8C7DC', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 4, label: 'Roommate', value: 1, color: '#221E72', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 5, label: 'Co-worker', value: 1, color: '#C8C7DC', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 6, label: 'Peer', value: 1, color: '#221E72', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 7, label: 'Client', value: 1, color: '#C8C7DC', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 8, label: 'Friend', value: 1, color: '#221E72', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 9, label: 'Parent', value: 1, color: '#C8C7DC', popupContent: SoulPopUps.Soul.Syncing.Parent },
          { id: 10, label: 'Environment', value: 1, color: '#221E72', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 11, label: 'Sibling', value: 1, color: '#C8C7DC', popupContent: SoulPopUps.Soul.Syncing.Sibling },
          { id: 12, label: 'Land', value: 1, color: '#221E72', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 13, label: 'An Idea', value: 1, color: '#C8C7DC', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 14, label: 'Someone Not Listed', value: 1, color: '#221E72', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 15, label: 'Something Not Listed', value: 1, color: '#C8C7DC', popupContent: SoulPopUps.Soul.Syncing.SyncNoInfo },
          { id: 16, label: 'Deceased Loved One', value: 1, color: '#221E72', popupContent: SoulPopUps.Soul.Syncing.Deceased },


        ],
        parent: "Soul",
        level: 2,
        subPages: {}
      },


      "Clearing": {
        chart: [
          { id: 0, label: 'Frequency', value: 1, color: '#692C91', popupContent: SoulPopUps.Soul.Clearing.Frequency },
          { id: 1, label: 'Call In An Angel', value: 1, color: '#D9CAE3', popupContent: SoulPopUps.Soul.Clearing.CallAngel },
          { id: 2, label: 'Animal Message', value: 1, color: '#692C91', popupContent: SoulPopUps.Soul.Clearing.Animal },
          { id: 3, label: 'Spirit Message', value: 1, color: '#D9CAE3', popupContent: SoulPopUps.Soul.Clearing.Spirit },
          { id: 4, label: 'Karma', value: 1, color: '#692C91', popupContent: SoulPopUps.Soul.Clearing.Karma },
          { id: 5, label: 'Clear Energy Field', value: 1, color: '#D9CAE3', popupContent: SoulPopUps.Soul.Clearing.ClearEnergyField },
        ],
        parent: "Soul",
        level: 2,
        subPages: {}
      },
      "Origination": {
        chart: [
          { id: 0, label: 'One', value: 1, color: '#862074', popupContent: SoulPopUps.Soul.Origination.Origination },
          { id: 1, label: 'Two', value: 1, color: '#E1C7DC', popupContent: SoulPopUps.Soul.Origination.Origination },
          { id: 2, label: 'Three', value: 1, color: '#862074', popupContent: SoulPopUps.Soul.Origination.Origination },
          { id: 3, label: 'Four', value: 1, color: '#E1C7DC', popupContent: SoulPopUps.Soul.Origination.Origination },
          { id: 4, label: 'Five', value: 1, color: '#862074', popupContent: SoulPopUps.Soul.Origination.Origination },
          { id: 5, label: 'Six', value: 1, color: '#E1C7DC', popupContent: SoulPopUps.Soul.Origination.Origination },
          { id: 6, label: 'Seven', value: 1, color: '#862074', popupContent: SoulPopUps.Soul.Origination.Origination },
          { id: 7, label: 'Eight', value: 1, color: '#E1C7DC', popupContent: SoulPopUps.Soul.Origination.Origination },
          { id: 8, label: 'Nine', value: 1, color: '#862074', popupContent: SoulPopUps.Soul.Origination.Origination },
          { id: 9, label: 'Ten', value: 1, color: '#E1C7DC', popupContent: SoulPopUps.Soul.Origination.Origination },
          { id: 10, label: 'More Than Ten', value: 1, color: '#862074', popupContent: SoulPopUps.Soul.Origination.Origination },
        ],
        parent: "Soul",
        level: 2,
        subPages: {}
      },



      "Angels": {
        chart: [
          { id: 0, label: 'Arcturians', value: 1, color: '#896531', popupContent: SoulPopUps.Soul.Angels.ArcturianEmmissionaries },
          { id: 1, label: 'Raphael', value: 1, color: '#DFD5C8', popupContent: SoulPopUps.Soul.Angels.Raphael },
          { id: 2, label: 'Gabriel', value: 1, color: '#896531', popupContent: SoulPopUps.Soul.Angels.Gabriel },
          { id: 3, label: 'Michael', value: 1, color: '#DFD5C8', popupContent: SoulPopUps.Soul.Angels.Michael },
          { id: 4, label: 'Jophiel', value: 1, color: '#896531', popupContent: SoulPopUps.Soul.Angels.Jophiel },
          { id: 5, label: 'Chamuel', value: 1, color: '#DFD5C8', popupContent: SoulPopUps.Soul.Angels.Chamuel },
          { id: 6, label: 'Uriel', value: 1, color: '#896531', popupContent: SoulPopUps.Soul.Angels.Uriel },
          { id: 7, label: 'Zadkiel', value: 1, color: '#DFD5C8', popupContent: SoulPopUps.Soul.Angels.Zadkiel },
          { id: 8, label: 'Spirit Guide', value: 1, color: '#896531', popupContent: SoulPopUps.Soul.Angels.SpiritGuide },
          { id: 9, label: 'Metatron', value: 1, color: '#DFD5C8', popupContent: SoulPopUps.Soul.Angels.Metatron },
          { id: 10, label: 'Jesus Christ', value: 1, color: '#896531', popupContent: SoulPopUps.Soul.Angels.JesusChrist },
          { id: 11, label: 'Animal Companion', value: 1, color: '#DFD5C8', popupContent: SoulPopUps.Soul.Angels.AnimalCompanion },
          { id: 12, label: 'Other Helper', value: 1, color: '#896531', popupContent: SoulPopUps.Soul.Angels.OtherHelper },
        ],
        parent: "Soul",
        level: 2,
        subPages: {}
      },


    }
  }
}