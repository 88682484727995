import { NoInfoPopUp, WordsPopUp } from "../popup"
import { popupIdMappingType } from "../types"

export const NutritionPopUps: popupIdMappingType = {
    "Nutrition": {
        //sydney started from the left of the chart so these are all backwards
        "Cravings": {
            //They all have the same popup info
            "Soda": <WordsPopUp words={["Your body is needing Calcium, Eat:", "Mustard, turnip greens, broccoli, kale, legumes, cheese and sesame."]} />,
            "Candy": <WordsPopUp words={["You may be needing a sweet pick-me-up, rewards and entertainment. Your body needs:",
                "Chromium: Eat broccoli, grapes, dried beans, calves liver, organic free range chicken.",
                "Carbon: Eat fresh fruits.",
                " Phosphorus: Eat chicken, beef, liver, poultry, fish eggs, dairy, nuts, legumes and whole grains.",
                "Sulphur: Eat cranberries, horseradish, cruciferous vegetables, kale and cabbage.",
                "Tryptophan: Eat cheese, liver, lamb, raisins, sweet potato and spinach."]} />,

            "Bread": <WordsPopUp words={["You may be wanting some TLC, comfort, calming and stress reduction. Your body is wanting:", "Nitrogen: Eat protein foods such as meat, fatty fish, nuts, beans and chia seeds."]} />,
            "Salty": <WordsPopUp words={["You may be feeling stress, anger and anxiety. Your body is needing:", "Chloride and Silicon: Eat fatty fish, goat milk, cashews, nuts and seeds."]} />,
            "Crunchy": <WordsPopUp words={["You may be feeling tension and intense anger. You are needing:", "Iron: Eat meat, fish, poultry, seaweed, greens, and black cherries."]} />,
            "Fatty": <WordsPopUp words={["You may be trying to fill the emptiness. Your body needs:", "Calcium: Eat organic milk, cheese and greenleafy vegetables."]} />,
            "Spicey": <WordsPopUp words={["You may be feeling that life is all work and no play. You may have a need for more fun and excitement."]} />,
            "CoolDrinks": <WordsPopUp words={["You are needing:", "Manganese: Eat walnuts, almonds, pecans, pineapple and blueberries."]} />,
            "CoffeeTea": <WordsPopUp words={["You may be feeling fear, self-doubt and may be needing motivation and energy. Your body is needing:",
                "Phosphorus: Eat chicken, beef, liver, poultry, fish, eggs, dairy, nuts and legumes.",
                "Sulphur: Eat egg yolks, red peppers, muscle protein, garlic, onion, cruciferous vegetables.",
                "Salt: Eat sea salt or apple cider vinegar.",
                "Iron: Eat meat, fish, poultry, seaweed, greens and black cherries. "]} />,
            "Chocolate": <WordsPopUp words={["Your body needs:", "Magnesium: Eat nuts, seeds, legumes, veggies and fruit."]} />,
            "Alcohol": <WordsPopUp words={["Your body needs:",
                "Protein: Eat meat, poultry, seafood, dairy and nuts.",
                "Avenin: Eat granola and oatmeal.",
                "Calcium: Eat mustard and turnip greens, broccoli, kale, legumes, cheese and sesame.",
                "Glutamine: Supplement glutamine powder for withdrawal, and drink raw cabbage juice.", "Potassium: Eat sun-dried black olives, potato peel broth, seaweed and bitter greens."]} />,
            "Burned": <WordsPopUp words={["Your body needs:", "Carbon: Eat fresh fruits."]} />,

            "Dairy": <WordsPopUp words={["You may be feeling depressed and want soothing, calming and comfort. So nurture yourself in other ways besides eating. Be gentle and do yoga, stretching and take a hot bath."]} />,
            "Tobacco": <WordsPopUp words={["Your body needs:", "Silicon: Eat nuts, seeds and avoid refined starches.", "Tyrosine: Take vitamin C supplements or orange, green and red fruits and vegetables."]} />,
            "PrefLiquid": <WordsPopUp words={["Preference for Liquids vs. Solids", "Your body needs:", "Water: Flavor your water with lemon or lime and drink 8-10 glasses per day."]} />,
            "PrefSolid": <WordsPopUp words={["Preference for Solids vs. Liquids", "Your body needs:", "Water: You have been dehydrated for so long that you have lost your thirst. Flavor your water with lemon or lime and drink 8-10 glasses per day."]} />,
            "Premenstrual": <WordsPopUp words={["Premenstrual Cravings", "You are needing:", "Zinc so eat red meats (especially organ meats) seafood, leafy vegetables and root vegetables."]} />,
            "Overeating": <WordsPopUp words={["You are needing:", "Silicon: Eat nuts, seeds, and avoid refined starches.", "Tryptophan: Eat cheese, liver, lamb, raisins, sweet potato and spinach.", "Tyrosine: Take vitamin C supplements or eat orange, green, red fruits and vegetables."]} />,

            "Lack": <WordsPopUp words={["You are needing:",
                "Vitamin B1: Eat nuts, seeds, beans, liver and other organ meats.",
                "Vitamin B3: Eat tuna, halibut, beef, chicken, turkey, pork, seeds and legumes.",
                "Manganese: Eat walnuts, almonds, pecans, pineapple and blueberries.",
                "Chloride: Eat raw goat milk and refined sea salt."]} />

        },
        "Meals": {
            //They all have the same popup info
            "NoInfo": <NoInfoPopUp />,
        },
        "Emotions": {
            "Bored": <WordsPopUp words={["Need Activity", "Nothing Going On"]} />,
            "AttnIssue": <WordsPopUp words={["Attention Issue"]} />,
            "NeedSpace": <WordsPopUp words={["Emotional", "Physical"]} />,
            "LSE": <WordsPopUp words={["Low Self Esteem"]} />,
            "Grieving": <NoInfoPopUp />,
            "Worried": <NoInfoPopUp />,
            "Jealous": <NoInfoPopUp />,
            "TakenForGranted": <NoInfoPopUp />,
            "Shame": <NoInfoPopUp />,
            "WithoutDirection": <WordsPopUp words={["Without Direction"]} />,
            "NeedToBelong": <WordsPopUp words={["Need to Belong"]} />,
            "Preoccupied": <NoInfoPopUp />,
            "Overwhelmed": <WordsPopUp words={["Overwhelmed", "Stressed"]} />,
            "EmotionalSponging": <NoInfoPopUp />,

        },
        "EatingTriggers": {
            //They all have the same popup info
            "NoInfo": <NoInfoPopUp />,
        },

        "Detoxification": {
            "Colon": <WordsPopUp words={["Colon Cleanse"]} />,
            "Liver": <WordsPopUp words={["Liver Cleanse"]} />,
            "Gallbladder": <WordsPopUp words={["Gallbladder Cleanse"]} />,
            "Kidney": <WordsPopUp words={["Kidney Cleanse"]} />,
            "Lung": <WordsPopUp words={["Lung Cleanse"]} />,
            "Skin": <WordsPopUp words={["Skin Cleanse"]} />,
            "Lymphatic": <WordsPopUp words={["Lymphatic Cleanse"]} />,
            "Parasite": <WordsPopUp words={["Parasite Cleanse"]} />,
            "Candida": <WordsPopUp words={["Candida Cleanse"]} />,
            "Metal": <WordsPopUp words={["Metal Detox"]} />,
            "Master": <WordsPopUp words={["Master Cleanse"]} />,
            "Juicing": <WordsPopUp words={["Juicing Detox"]} />,
            "Raw": <WordsPopUp words={["Raw/Alkaline Food Detox"]} />,
            "Fasting": <WordsPopUp words={["Fasting Cleanse"]} />,
            "Homeopathic": <WordsPopUp words={["Homeopathic Detox"]} />,
            "Intermittent": <WordsPopUp words={["Intermittent Cleansing"]} />,
            "Sugar": <NoInfoPopUp />,
            "Bentonite": <NoInfoPopUp />,
            "Gold": <WordsPopUp words={["Animal Product"]} />,
            "Other": <WordsPopUp words={["Other Area/Method Not Listed"]} />,
        },
    }
}