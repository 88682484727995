import { Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import { NoInfoPopUp, WordsPopUp } from "../popup"
import { popupIdMappingType } from "../types"
import { AngelsPopups } from "../AngelsPopups"



export const CallAngel = () => {
    return (
        <div>
            <Typography>You do this just with your intention and thoughts. Make sure that you ask specifically for what you need and then start your pendulum swinging in a clockwise manner and let it swing on it's own until it is done. You will then know that the angels/guides/helpers have completed your request. Make sure to thank them for the help. Gratitude goes a long way!</Typography>
            <Typography>Consult &nbsp;
                <Link to="?module=Trauma+People&item=Angels">Angels</Link>
                </Typography>
        </div>
    );

}
export const SpiritPopUp = () => {
    return (
        <div>
            <Typography>Someone in spirit has something that they need you to know. If you are not a medium, you can refer to the Trailblazing Communications People Style Module to find out what the message is.</Typography>
            <Typography>Consult &nbsp;
                <Link to="?module=People">People Style</Link>
                </Typography>
        </div>
    );

}
export const AnimalPopUp = () => {
    return (
        <div>
            <Typography>This means that whatever animal comes up with this has something that they need you to know beyond the explanations for the unwanted visitor explanations. If you are not a non-verbal communicator you can refer to Trailblazing communications Animal Style Module to find out what this message may be.</Typography>
            <Typography>Consult &nbsp;
                <Link to="?module=Animal">Animal Style</Link>
                </Typography>
        </div>
    );

}


export const TraumaPeoplePopUps: popupIdMappingType = {
    "TraumaPeople": {
        "Angels": {
            ...AngelsPopups,
        },
        "Frequency": {
            //all of them have the same popup
            "Frequency": <NoInfoPopUp />,
        },


        "Trauma": {
            "ChromosomeAbnormality": <NoInfoPopUp />,
            "Group": <NoInfoPopUp />,
            "Thoughts": <NoInfoPopUp />,
            "Emotions": <NoInfoPopUp />,
            "Experiences": <NoInfoPopUp />,
            "Genetics": <NoInfoPopUp />,
            "Beliefs": <NoInfoPopUp />,
            "Surgery": <NoInfoPopUp />,
            "PastLive": <NoInfoPopUp />,
            "Behaviors": <NoInfoPopUp />,
            "Cording": <NoInfoPopUp />,
            "Syncing": <NoInfoPopUp />,
            "Chemicals": <NoInfoPopUp />,
            "GMOFoods": <NoInfoPopUp />,
            "Relationships": <NoInfoPopUp />,
            "Programming": <NoInfoPopUp />,
            "Electromagnetics": <WordsPopUp words={["Electromagnetic Smog-Stress"]} />,
            "EnvironmentalToxins": <NoInfoPopUp />,
            "PostTraumaticStress": <WordsPopUp words={["Post Traumatic Stress Disorder"]} />,

        },



        "Issues": {
            "RelationalEnvironment": <WordsPopUp words={[ "Belonging","Communication", "Connection"]} />,
            "PhysicalEnvironment": <WordsPopUp words={["Surroundings", "People"]} />,
            "EnergyDistortion": <NoInfoPopUp />,
            "LearnedPatterns": <NoInfoPopUp />,
            "EnergyPatterns": <NoInfoPopUp />,
            "BodyChemistry": <WordsPopUp words={["Body Chemistry"]} />,
            "SoulUnrest": <NoInfoPopUp />,
            "Dietary": <NoInfoPopUp />,
            "RNA": <NoInfoPopUp />,
            "DNA": <NoInfoPopUp />,
            "Chemical": <NoInfoPopUp />,
            "Genetics": <NoInfoPopUp />,
            //Boundries- no popup
            "Chromasomal": <NoInfoPopUp />,
            "CopingSKills": <NoInfoPopUp />,
            "EnergyDiff": <NoInfoPopUp />,
            "Situational": <NoInfoPopUp />,
            "EmotionalEnv": <NoInfoPopUp />,
            "SpiritualEnvironment": <NoInfoPopUp />,


        },

        "Defenses": {
            "WithdrawalPartial": <NoInfoPopUp />,
            "Dissociation": <NoInfoPopUp />,
            "EnergyHooks": <NoInfoPopUp />,
            "Exploding": <NoInfoPopUp />,
            "Denial": <NoInfoPopUp />,
            "Distancing": <WordsPopUp words={["Sharp Aura"]} />,
            "EnergyVaping": <NoInfoPopUp />,
            "TotalWithdrawal": <NoInfoPopUp />,
            "EnergyVampirism": <NoInfoPopUp />,
        },

        "Blocks": {
            "Dissociated": <NoInfoPopUp />,
            "Depletion": <WordsPopUp words={["Energy Pattern"]} />,
            "Compaction": <WordsPopUp words={["Energy Pattern"]} />,
            "Repression": <WordsPopUp words={["Energy Pattern"]} />,
            "Drugs": <NoInfoPopUp />,
            "Mesh": <WordsPopUp words={["Energy Pattern"]} />,
            "Plate": <WordsPopUp words={["Energy Pattern"]} />,
            "Leak": <WordsPopUp words={["Energy Pattern"]} />,
            "Negative": <NoInfoPopUp />,
        },

        "Syncing": {
            "Deceased": <WordsPopUp words={["Human", "Animal"]} />,
            "Spouse": <NoInfoPopUp />,
            "AnimalCompanion": <NoInfoPopUp />,
            "GroupConsc": <WordsPopUp words={["Group Consciousness"]} />,
            "Parent": <WordsPopUp words={["Mother", "Father"]} />,
            "Idea": <NoInfoPopUp />,
            "Friend": <NoInfoPopUp />,
            "Music": <NoInfoPopUp />,
            "Peer": <NoInfoPopUp />,
            "Client": <NoInfoPopUp />,
            "Sibling": <WordsPopUp words={["Sister", "Brother"]} />,
            "Coworker": <NoInfoPopUp />,
            "Roommate": <NoInfoPopUp />,
            "Children": <NoInfoPopUp />,
            "Environment": <NoInfoPopUp />,
            "SomeoneNotListed": <NoInfoPopUp />,
            "SomethingElse": <NoInfoPopUp />,
        },
        "Emotional": {
            "EmotionalSponging": <NoInfoPopUp />,
            "LSE": <WordsPopUp words={["Low Self Esteem"]} />,
            "AttnIssue": <WordsPopUp words={["Attention Issue"]} />,
            "NeedSpace": <WordsPopUp words={["Emotional", "Physical"]} />,
            "Grieving": <NoInfoPopUp />,
            "Jealous": <NoInfoPopUp />,
            "Bored": <WordsPopUp words={["Need Activity", "Nothing Going On"]} />,
            "Shame": <NoInfoPopUp />,
            "Worried": <NoInfoPopUp />,
            "Preoccupied": <NoInfoPopUp />,
            "NeedToBelong": <WordsPopUp words={["Need to Belong"]} />,
            "WithoutDirection": <WordsPopUp words={["Without Direction"]} />,
            "TakenForGranted": <NoInfoPopUp />,
            "Overwhelmed": <WordsPopUp words={["Overwhelmed", "Stressed"]} />,
        },

        "EnergyField": {
            "Etheric": <WordsPopUp words={["1st layer out from body"]} />,
            "Emotional": <WordsPopUp words={["2nd layer out from body"]} />,
            "Mental": <WordsPopUp words={["3rd layer out from body"]} />,
            "Astral": <WordsPopUp words={["4th layer out from body"]} />,
            "ET": <WordsPopUp words={["Has a physical aspect and is the 5th layer out from the body"]} />,
            "C": <WordsPopUp words={["Has an emotional aspect and is the 6th layer out from the body"]} />,
            "K": <WordsPopUp words={["Has a mental aspect and is the 7th layer out from the body"]} />,

        },
        "Chakras": {
            "Root": <WordsPopUp words={["Adrenals"]} />,
            "Sacral": <WordsPopUp words={["Ovaries", "Testes"]} />,
            "Solar": <WordsPopUp words={["Pancreas"]} />,
            "Heart": <WordsPopUp words={["Thymus"]} />,
            "Throat": <WordsPopUp words={["Thyroid"]} />,
            "ThirdEye": <WordsPopUp words={["Pituitary"]} />,
            "Spiritual": <WordsPopUp words={["Pineal Gland"]} />,
            "NoInfo": <NoInfoPopUp />,

        },
        "Meridians": {
            "Spleen": <WordsPopUp words={["Recycle", "Fairness", "Worry", "Feeling Dirty", "Openness", "Depression", "Subconscious"]} />,
            "HeartGovernor": <WordsPopUp words={["Protects the heart from outside disease factors"]} />,
            "TripleHeater": <WordsPopUp words={["Impotence", "Frigidity", "Fascia", "Cellular Memory", "Transition"]} />,
            "Stomach": <WordsPopUp words={["Intellect", "Digesting Life", "Ruminating", "Intolerance", "All Emotions", "Conscious Thinking"]} />,
            "Bladder": <WordsPopUp words={["Annoyed", "Peeved", "Gentleness", "Resentment", "Support", "Control", "Callousness"]} />,
            "Liver": <WordsPopUp words={["Movement", "Female Cycle", "Anger", "Eyes", "Muscle Spasms", "Benevolence"]} />,
            "Lung": <WordsPopUp words={["Letting Go", "Taking Life In Fully", "Skin Courage", "Grief", "Assertiveness", "Unworthiness"]} />,
            "Heart": <WordsPopUp words={["Trust", "Disappointment", "Insomnia", "Joy", "Being In Touch With Self", "Depression", "Shock", "Serenity"]} />,
            "Kidney": <WordsPopUp words={["Bone Marrow", "Stability", "Restriction", "Fear", "Knees", "Ancestors", "Hopelessness", "Coping", "Wisdom"]} />,
            "GallBladder": <WordsPopUp words={["Wi Qi", "Discernment", "Filters", "Synthesizing", "Decision-Making", "Motivation"]} />,
            "Paricardium": <WordsPopUp words={["Spirituality", "Protection", "Deep Clarity", "Eyes", "Psychic Interaction", "Shutting Down", "Honor"]} />,
            "LargeIntestine": <WordsPopUp words={["Vitality", "Letting Go Of Grief", "Hoarding", "Collecting", "Forgiveness", "Compulsiveness", "Anal Retentive"]} />,
            "SmIntestine": <WordsPopUp words={["Intellect", "Discernment", "Confusion", "Overwhelmed", "Clarity"]} />,
            "Multiple": <NoInfoPopUp />,
        },

        "BodyQuadrants": {
            "one": <NoInfoPopUp />,
            "two": <NoInfoPopUp />,
            "three": <NoInfoPopUp />,
            "four": <NoInfoPopUp />,
        },

        "BodyArea": {
            "CirculatorySystem": <NoInfoPopUp />,
            "Tendons": <NoInfoPopUp />,
            "Sinuses": <NoInfoPopUp />,
            "Fascia": <NoInfoPopUp />,
            "Colon": <NoInfoPopUp />,
            "Brain": <NoInfoPopUp />,
            "Skin": <NoInfoPopUp />,
            "Lymph": <NoInfoPopUp />,
            "Ears": <NoInfoPopUp />,
            "Eyes": <NoInfoPopUp />,
            //Organ word list
            "Bones": <NoInfoPopUp />,
            "Muscles": <NoInfoPopUp />,
            //Endocrine word list
            "Neurological": <NoInfoPopUp />,
        },


        "Systems": {
            "Integumentary": <NoInfoPopUp />,
            "Reproductive": <NoInfoPopUp />,
            "Endocrine": <NoInfoPopUp />,
            "Digestive": <NoInfoPopUp />,
            "Nervous": <NoInfoPopUp />,
            "Immune": <NoInfoPopUp />,
            "Skeletal": <NoInfoPopUp />,
            "Muscular": <NoInfoPopUp />,
            "Urinary": <NoInfoPopUp />,
            "Lymphatic": <NoInfoPopUp />,
            "Respiratory": <NoInfoPopUp />,
            "Cardiovascular": <NoInfoPopUp />,

        },
        "Connectors": {
            "Ligaments": <NoInfoPopUp />,
            "Muscles": <NoInfoPopUp />,
            "Tendons": <NoInfoPopUp />,
            "Nerves": <NoInfoPopUp />,
            "Fascia": <NoInfoPopUp />,
            "Bones": <NoInfoPopUp />,
            "Joints": <NoInfoPopUp />,
            "Skin": <NoInfoPopUp />,
            "Cartilage": <NoInfoPopUp />,
        },

        "Cells": {
            "EndoplasmicReticulum": <WordsPopUp words={["Passageway for material moving through the cell"]} />,
            "CellMembrane": <WordsPopUp words={["The outer most boundary of the cell and separates the cell from other cells."]} />,
            "Cytoskeleton": <WordsPopUp words={["Protein filaments that give the shape, support and movement"]} />,
            "Prokaryote": <WordsPopUp words={["Usually unicellular (bacteria)"]} />,
            "Cytoplasm": <WordsPopUp words={["Clear thick fluid "]} />,
            "CellWall": <NoInfoPopUp />,
            "Endoderm": <WordsPopUp words={["One of the three primary germ layers in the early human embryo and is the proximal layer", "Exocrine secretory epithelial cells: They cover outer body surfaces and excrete", "Hormone secreting cells: Cells that secrete horomes"]} />,
            //Mesoderm word list
            "Ectoderm": <WordsPopUp words={["The outer layer of cells in an embryo", "Integumentary system cells: Cells within the skin", "Nervous system cells: Neurons that carry messages through an electrochemical process"]} />,
            "Vacuole": <WordsPopUp words={["Single membrane organelles that are part of the outside that is located within the cell."]} />,
            "Cell": <WordsPopUp words={["The smallest structural and functional unit of an organism"]} />,
            "Vesicle": <WordsPopUp words={["They function in transport within and to the outside of the cell"]} />,
            "Ribosome": <WordsPopUp words={["This is where the RNA is translated into protein"]} />,
            "Organelle": <WordsPopUp words={["Any of a number of organized or specialized structures within a living cell"]} />,
            "Nucleolus": <WordsPopUp words={["Makes ribosomes"]} />,
            "Lysosomes": <WordsPopUp words={["The digestive system of the cell"]} />,
            "Eukaryote": <WordsPopUp words={["Contain membrane-bound organelles such as the nucleus"]} />,
            "FetalCell": <WordsPopUp words={["Left over cells from pregnancy and birth that can free float within mother’s body long after birth"]} />,
            "Mitochondria": <WordsPopUp words={["The main energy source of the cell"]} />,
            "GolgiApparatus": <WordsPopUp words={["Changes molecules and divides them into small membrane contained sacs called vesicles"]} />,
            "NuclearEnvelope": <WordsPopUp words={["Protects the nucleus", "Lets things in and out"]} />,

        },

        "Bones": {
            //no popups in this section
        },

        "Blood": {
            "WhiteCellAntibodies": <NoInfoPopUp />,
            "CarbonDioxide": <NoInfoPopUp />,
            //whitecell
            "RedCells": <NoInfoPopUp />,
            "Nitrogen": <NoInfoPopUp />,
            "Hormones": <NoInfoPopUp />,
            "Proteins": <NoInfoPopUp />,
            "Plasma": <NoInfoPopUp />,
            "Oxygen": <NoInfoPopUp />,
            "StemCells": <NoInfoPopUp />,
            "Platelets": <NoInfoPopUp />,
            "Hemoglobin": <NoInfoPopUp />,
            "Fat": <NoInfoPopUp />,
            "Carbs": <NoInfoPopUp />,
            //blood coponent
            //complete blood count

        },

        ///Actions is a whole word list

        "BodyImbalance": {
            //No Popus in this section
        },


        "Colors": {
            "Red": <WordsPopUp words={["Brings balance to the area"]} />,
            "Orange": <WordsPopUp words={["Brings the energy of celebration to the situation"]} />,
            "Yellow": <WordsPopUp words={["Brings wisdom to the situation"]} />,
            "Green": <WordsPopUp words={["Brings healing and abundance to the situation"]} />,
            "Blue": <WordsPopUp words={["Brings power and protection to the area and situation"]} />,
            "Violet": <WordsPopUp words={["Brings forgiveness to the situation"]} />,
            "Indigo": <WordsPopUp words={["Brings empowerment to the situation"]} />,
            "White": <WordsPopUp words={["Brings purity to the situation"]} />,
            "Silver": <WordsPopUp words={["Brings magnificence to the situation"]} />,
            "Gold": <WordsPopUp words={["Brings manifestation and perfection to the situation"]} />,
            "Black": <WordsPopUp words={["Density and stuck energy"]} />,
            "Rainbow": <WordsPopUp words={["Brings the full spectrum of color and healing"]} />,

        },
        "Clearing": {
            "CallAngel": <CallAngel />,
            "Frequency": <WordsPopUp words={["Raise", "Lower"]} />,
            "Spirit": <SpiritPopUp />,
            "Animal": <AnimalPopUp />,
            "ClearEnergyField": <WordsPopUp words={["With a left spin of your pendulum and an intention to clear any unwanted energies that are not in the highest good of the person that you are clearing ask for it to be cleared and taken to a dimension where it cannot come back again to bother the person that it was attached to. When the pendulum stops spinning on its own, ask if the energies were cleared. If you get a yes, then do a right spin and bring in the protective and healing white energies of the Divine and if you get a no, repeat the left spin process intending to clear any unwanted energies until you get a yes. Always end this process with filling the cleared space with the protective and healing white energies of the Divine."]} />,
        },

        "Reproductive": {
            //no modals here
        },



        "Origination": {
            //all of them have the same popup
            "Origination": <NoInfoPopUp />,
        }
    }
}