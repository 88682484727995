import tinycolor from 'tinycolor2';

const COLOR_THRESHOLD = 200;
export const SUB_TEXT_TYPOGRAPHY_PROPS = { variant: "body2" as const, style: { fontStyle: 'italic' } };

export const getBrightnessAdjustedColor = (color: string | undefined | null) => {
    const isBright = color ? tinycolor(color).getBrightness() > COLOR_THRESHOLD : false;
    const textColor = isBright ? '#767676' : 'white';
    return textColor;
}

export const getPDFUrl = (pdfName: string) => {
    // TODO this should come from env file
    const pdfServerURL = "https://ashy-rock-03f1e7d10.4.azurestaticapps.net"
    const gDocsPdfViewer = "https://docs.google.com/gview?url="
    return `${gDocsPdfViewer}${pdfServerURL}/${pdfName}&embedded=true`
}