import { Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import { NoInfoPopUp, WordsPopUp } from "../popup"
import { popupIdMappingType } from "../types"


const CallAngel = () => {
    return (
        <div>
            <Typography>You do this just with your intention and thoughts. Make sure that you ask specifically for what you need and then start your pendulum swinging in a clockwise manner and let it swing on it's own until it is done. You will then know that the angels/guides/helpers have completed your request. Make sure to thank them for the help. Gratitude goes a long way!</Typography>
            <Typography>Consult &nbsp;
                <Link to="/?module=Soul&item=Angels">Angels</Link>
            </Typography>
        </div>
    );

}
const SpiritPopUp = () => {
    return (
        <div>
            <Typography>Someone in spirit has something that they need you to know. If you are not a medium, you can refer to the Trailblazing Communications People Style Module to find out what the message is.</Typography>
            <Typography>Consult &nbsp;
                <Link to="/?module=People">People Style</Link>
            </Typography>
        </div>
    );

}
const AnimalPopUp = () => {
    return (
        <div>
            <Typography>This means that whatever animal comes up with this has something that they need you to know beyond the explanations for the unwanted visitor explanations. If you are not a non-verbal communicator you can refer to Trailblazing communications Animal Style Module to find out what this message may be.</Typography>
            <Typography>Consult &nbsp;
                <Link to="/?module=Animal">Animal Style</Link>
            </Typography>
        </div>
    );
}

const FreqRaiseLower = () => {
    return (
        <div>
            <Typography>Frequency</Typography>
            <Typography>
                <Link to="/?module=Nature&item=Crop%2C+Plant+%26+Tree+Issues&subitem=Frequency+Change">Raise</Link>
            </Typography>
            <Typography>
                <Link to="/?module=Nature&item=Crop%2C+Plant+%26+Tree+Issues&subitem=Frequency+Change">Lower</Link>
            </Typography>
        </div>
    );
}

const DowseProperty = () => {
    return (
        <div>
            <Typography>Consult&nbsp;
                <Link to="/?module=Home">Home Module</Link>
            </Typography>
        </div>
    );
}

const FarmerPopUp  = () => {
    return (
        <div>
            <Typography>Farmer (current and past)</Typography>
            <Typography>Is a clearing needed?</Typography>
            <Typography>Consult&nbsp;
                <Link to="/?module=People">People Style Module</Link>
            </Typography>
            <Typography>Consult&nbsp;
                <Link to="/?module=Trauma+People">Trauma People Module</Link>
            </Typography>
        </div>
    );
}

const AnimalsPopUp = () => {
    return (
        <div>
            <Typography>Is a clearing needed?</Typography>
            <Typography>Consult&nbsp;
                <Link to="/?module=Animal">Animal Style Module</Link>
            </Typography>
            <Typography>Consult&nbsp;
                <Link to="/?module=Trauma+Animal">Trauma Animal Module</Link>
            </Typography>
        </div>
    );
}



export const NaturePopUps: popupIdMappingType = {
    "Nature": {
        "CropPlantTreeIssues": {
            "NoInfo": <NoInfoPopUp />,//used for most items
            "Water": <WordsPopUp words={["Too much", "Too little"]} />,
            "Stress": <WordsPopUp words={["Environmental", "Chemical"]} />,
            "GroundWater": <WordsPopUp words={["Ground Water"]} />,
            "RootProb": <WordsPopUp words={["Root Problems"]} />,
            "InvSpecies": <WordsPopUp words={["Invasive Species"]} />,
        },


        "SoilIssues": {
            "NoInfo": <NoInfoPopUp />,//used for most items
        },

        "WaterIssues": {
            "NoInfo": <NoInfoPopUp />,
            "NotEnough": <WordsPopUp words={["Not Enough Water"]} />,
            "TooMuch": <WordsPopUp words={["Too Much Water"]} />,
            "Pollution": <WordsPopUp words={["Pollution", "Toxic Substances"]} />,
            "ChlorophylAorB": <WordsPopUp words={["Too much", "Too little"]} />,
        },
        "AirIssues": {
            "NoInfo": <NoInfoPopUp />,
            "Pollution": <WordsPopUp words={["Pollution", "Toxic Substances"]} />,
        },
        "Clearing": {
            "Write": <WordsPopUp words={["This is asking you to write down what you are intending. Writing it down is a powerful manifestation practice."]} />,
            "NoInfo": <NoInfoPopUp />,
            "Call": <CallAngel />,
            "Frequency": <FreqRaiseLower />,
            "LeftSpin": <WordsPopUp words={["Start your pendulum swinging in a counter clockwise manner and let it swing on it's own accord with the intention of clearing whatever it is you are working on until it stops on it's own. You will then know that the adjustment it complete."]} />,
            "RightSpin": <WordsPopUp words={["Start your pendulum swinging in a clock-wise manner and then let it swing on it's own with the intention of adding energy. I suggest adding energy that is the 'Divine white light energy' and the protective blue energy of Archangel Michael or whatever other energy you are called to bring in. Let the pendulum swing on it's own accord until it is done swinging. It will vary in how much time it takes depending on the job that you are doing. When it is done swinging the adjustment has been made."]} />,
            "Spirit": <SpiritPopUp />,
            "Animal": <AnimalPopUp />,
            "NoAction": <WordsPopUp words={[" No action is needed at this time. This may change in time and you can ask yes or no questions."]} />,
            "Detect": <WordsPopUp words={["This is asking to find out where- which may be a direction, parcel or place. You can determine with asking yes or no questions of your pendulum. Make sure questions are clear and concise."]} />,
            "Dowsing": <DowseProperty />,
            "ClearEnergyField": <WordsPopUp words={["Clear Energy Around Property", "With a left spin of your pendulum and an intention to clear any unwanted energies that are not in the highest good of the property that you are clearing ask for it to be cleared and taken to a dimension where it cannot come back again. When the pendulum stops spinning on it’s own, ask if the energies were cleared. If you get a yes, then do a right spin and bring in the energies listed above under right spin. If no, repeat until you get a yes. Always bring in good energy when you clear something. Do not leave an open void to be filled with just anything."]} />,
        },
        "Syncing": {
            "SyncNoInfo": <NoInfoPopUp />,
            "Chemicals": <WordsPopUp words={["Consciousness of Chemicals"]} />,
            "GMO": <WordsPopUp words={["Consciousness of GMOs"]} />,
            "Pollution": <WordsPopUp words={["Pollution", "Toxic Substances"]} />,
            "Smog": <WordsPopUp words={["Electromagnetic smog"]} />,
            "Animals": <AnimalsPopUp />,
            "Farmer": <FarmerPopUp />,
            "Generational": <WordsPopUp words={["Generational Discordant Energy"]} />,
        }
    }
}