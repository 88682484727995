import { NoInfoPopUp, WordsPopUp, } from "../popup"
import { popupIdMappingType } from "../types"

export const OriginPopUps: popupIdMappingType = {
    "Origin": {
        "Toxicity": {
            //All Have no info
            "ToxicityLevelNoInfo": <NoInfoPopUp />,
           
        },

        "NoInfo": {
            "NoInfo": <NoInfoPopUp />,
        },
        "Clearings": {
         //They all have the same popup info
            "Clearings": <WordsPopUp words={["Past", "Present","Future"]} />,
        },
   
    }
}