import { WordListMappingType } from "../types";

export const BusinessWordListMap: WordListMappingType = {
  "Business": {
    "Remedies": {
      "Oils": [
        "Anise",
        "Basil",
        "Believe",
        "Bergamot",
        "Cassia",
        "Cedarwood",
        "Cinnamon",
        "Cistus",
        "Citronella",
        "Citrus Fresh",
        "Clary Sage",
        "Clove",
        "Copaiba",
        "Coriander",
        "Cypress",
        "Digize",
        "Dorado Azul",
        "Fennel",
        "Frankincense",
        "Geranium",
        "Grounding",
        "Helichrysum",
        "Hyssop",
        "Idaho Balsam Fir",
        "Idaho Tansy",
        "Immupower",
        "Joy",
        "Juniper",
        "Lauris Nobilis",
        "Lavender",
        "Ledum",
        "Lemon",
        "Lemongrass",
        "Marjoram",
        "Melissa",
        "Melrose",
        "Mountain Savory",
        "Myrtle",
        "Myrrh",
        "Nutmeg",
        "Ocotea",
        "Orange",
        "Oregano",
        "Palmarosa",
        "Palo Santo",
        "Pan Away",
        "Patchouli",
        "Peace and Calming",
        "Peppermint",
        "Pine",
        "Purification",
        "Ravintsara",
        "Roman Chamomile",
        "Rose",
        "Rosemary",
        "Ruta Vala",
        "Sage",
        "Sandalwood",
        "Spearmint",
        "Spikenard",
        "Spruce",
        "Tangerine",
        "Tarragon",
        "Tea Tree (Melaleuca Alternifolia)",
        "Thieves",
        "Thyme",
        "Tsuga",
        "Trauma Life",
        "Valerian",
        "Valor",
        "Vetiver",
        "White Angelica",
        "Wintergreen",
        "Ylang Ylang",
        "Custom Items"
      ],
      "Crystals": [
        "Amethyst",
        "Quartz",
        "Rose Quartz",
        "Carnelian",
        "Obsidian",
        "Onyx",
        "Smokey Quartz",
        "Jasper",
        "Lapis Lazuli",
        "Moonstone",
        "Turquoise",
        "Tiger's Eye",
        "Ruby",
        "Amber",
        "Malachite",
        "Sodalite",
        "Azurite",
        "Agate",
        "Celestite",
        "Citrine",
        "Red Jasper"
      ],

    },

  }
}