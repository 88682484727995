import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import WordCardCollection from '../WordCardCollection/WordCardCollection';
import ModuleChart from '../ModuleChart/ModuleChart';
import { ChartPageType } from '../../data/types';
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../utils';

interface NatureNutrientsProps {
    height: number;
    width: number;
}

const SeedChartData: ChartPageType = {
    chart: [
        { label: 'With Seed', value: 1, id: 0, color: '#B7022C' },
        { label: 'Away from Seed', labelWithFormatting: "Away from \n Seed", value: 1, id: 1, color: '#E9B3A7' },
        { label: 'Foliar', value: 1, id: 2, color: '#B7022C' }
    ],
    level: 0,
    subPages: {},
    disableClick: true,

}

const LevelChartData: ChartPageType = {
    chart: [
        { label: 'Low', value: 1, id: 0, color: '#B7022C' },
        { label: 'Adequate', value: 1, id: 1, color: '#E9B3A7' },
        { label: 'High', value: 1, id: 2, color: '#B7022C' }
    ],
    level: 0,
    subPages: {},
    disableClick: true,

}

const NatureNutrientsPage: React.FC<NatureNutrientsProps> = ({ height, width }) => {
    const [chartSize, setChartSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    const chart_heights = height / 2;
    const chart_widths = (width / 3) * 2;
    const wordCardRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const updateChartSize = useCallback(() => {
        const chart_height_using_wc = (wordCardRef.current?.offsetHeight ?? 0) / 2;
        const text_height = (textRef.current?.offsetHeight ?? 0);
        if (chart_height_using_wc > chart_heights) {
            setChartSize({ width: chart_widths, height: chart_height_using_wc });
        }
        else if (chart_heights > text_height){
            setChartSize({ width: chart_widths, height: chart_heights - text_height });
        }
        else {
            setChartSize({ width: chart_widths, height: 200 });
        }
    }, [chart_heights, chart_widths, wordCardRef, textRef]);


    useEffect(() => {
        window.addEventListener('resize', updateChartSize);
        return () => {
            window.removeEventListener('resize', updateChartSize);
        };
    }, [updateChartSize]);

    useEffect(() => {
        updateChartSize();
    }, [height, width, updateChartSize]);


    return (
        <>
            <Box style={{ flex: '1 0 auto', justifyContent: 'center', alignItems: 'center' }}>
                <Grid container spacing={2}>
                    <Grid ref={wordCardRef} item xs={4}>
                        <WordCardCollection wordList={["Nitrogen",
                            "Sulfur",
                            'Phosphorus',
                            'Potassium',
                            "Zinc",
                            "Maganese",
                            "Copper",
                            "Magnesium",
                            "Boron",
                            "Molybedenum",
                            "Iron",
                            "Calcium",
                            "Aluminum",
                            "Cadmium"
                        ]} 
                        disablePagination={true}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container direction="column">
                            <Grid item xs={6}>
                                <ModuleChart chartRadius={"60%"} 
                                              height={chartSize.height} 
                                              width={chart_widths} 
                                              chartData={SeedChartData} 
                                              chartModule={"Nature"} 
                                              item={"Crop, Plant & Tree Issues"} 
                                              subitem={"Nutrients"} />
                            </Grid>
                            <Grid item xs={6}>
                                <ModuleChart chartRadius={"60%"} 
                                             height={chartSize.height} 
                                             width={chart_widths} 
                                             chartData={LevelChartData} 
                                             chartModule={"Nature"} 
                                             item={"Crop, Plant & Tree Issues"} 
                                             subitem={"Nutrients"} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} align='center' ref={textRef}>
                For each, ask for Crop or Land.
                Find out which nutrient/s are needing to be addressed. You will then want to find out the level of the nutrient which will determine if you need to add, subtract or leave alone. If needing to add then you will ask if you need to go to the application chart to determine how to add for the best results. You will want to do this with each nutrient that comes up for consideration. Remember that you can ask as many yes or no questions with your pendulum to get clarity on any answer that you receive.
            </Typography>
        </>
    );
}
export default NatureNutrientsPage;
