import React, { useState, useRef } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { DefaultizedPieValueType, PieValueType, PieArcLabelProps, PieSeriesType } from '@mui/x-charts';
import { Box, ImageList, ImageListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChartPageType, ExtendedPieValueType } from '../../data/types';
import { ChartPopUp } from '../ChartPopUp/ChartPopUp';
import { PieArcLabel } from './ArcLabel';
import { getBrightnessAdjustedColor } from '../../utils';
import { MakeOptional } from '@mui/x-charts/models/helpers';

interface MuChartProps {
  height: number;
  width: number;
  chartData: ChartPageType;
  chartModule: string;
  item: string | null;
  subitem: string | null;
  chartRadius?: string | number | undefined;
}

export const ModuleChart: React.FC<MuChartProps> = ({ height, width, chartData, chartModule, item, subitem, chartRadius }) => {
  const navigate = useNavigate();
  const bottomTextRef = useRef<HTMLDivElement>(null);
  const [popUpState, setPopUpState] = useState<{ open: boolean, item: React.ReactElement | null, title: string }>({ open: false, item: null, title: "" });
  var data = chartData.chart as PieValueType[];
  const GetChartDataIndexFromLabel = (label: string, chartData: ChartPageType): number | null => {
    var data = chartData.chart as ExtendedPieValueType[];
    var i;
    for (i = 0; i < data.length; i++) {
      if (data[i].label === label || (data[i].labelWithFormatting && data[i].labelWithFormatting === label)) {
        return i;
      }
    }
    var dataLengthInner = data.length;
    if (chartData.innerChart !== undefined) {
      data = chartData.innerChart as PieValueType[];
      for (i = 0; i < data.length; i++) {
        if (data[i].label === label || (data[i].labelWithFormatting && data[i].labelWithFormatting === label)) {
          return i + dataLengthInner;
        }
      }
    }
    var dataLengthInnerInner = dataLengthInner;
    if (chartData.innerInnerChart !== undefined) {
      data = chartData.innerInnerChart as PieValueType[];
      for (i = 0; i < data.length; i++) {
        if (data[i].label === label || (data[i].labelWithFormatting && data[i].labelWithFormatting === label)) {
          return i + dataLengthInnerInner;
        }
      }
    }

    return null;
  }

  const GetChartDataFromLabel = (label: string, chartData: ChartPageType): ExtendedPieValueType | null => {
    var index = GetChartDataIndexFromLabel(label, chartData);
    if (index !== null) {
      if (index < chartData.chart.length) {
        return chartData.chart[index] as ExtendedPieValueType;
      }
      else if (chartData.innerChart) {
        return chartData.innerChart[index - chartData.chart.length] as ExtendedPieValueType;
      }
    }
    return null;
  }

  const onTextClick = (label: string) => {
    if (chartData.disableClick) {
      return;
    }
    const index: number = GetChartDataIndexFromLabel(label, chartData) as number;
    const d = { dataIndex: index, label: label };
    setItemData(d);

  }
  const CustomPieArcLabel: React.FC<PieArcLabelProps> = ({ id, ...restProps }) => {

    let data: ExtendedPieValueType | null = GetChartDataFromLabel(restProps.formattedArcLabel as string, chartData);
    const textColor = data?.textColor ? data.textColor : getBrightnessAdjustedColor(data?.color)
    const disableTextRotation = data?.disableTextRotation;
    return (
      <PieArcLabel id={id} onItemClick={onTextClick} {...restProps} style={{ fill: textColor }} disableTextRotation={disableTextRotation} />
    );
  }

  const heightToFontSize = (h: number) => {
    return 18;
  }

  const setItemData = (d: any) => {
    if (chartData.disableClick) {
      return;
    }
    if (data) {
      var extraData = data[d.dataIndex] as ExtendedPieValueType
      var page: string = data[d.dataIndex].label as string;
      var popup: React.ReactElement | undefined = extraData.popupContent
      var onClickNav: string | undefined = extraData.onClickNav
      let params = new URLSearchParams();

      if (popup !== undefined) {
        setPopUpState({ open: true, item: popup as React.ReactElement, title: page });
      }
      else if (onClickNav !== undefined) {
        navigate(onClickNav);
      }
      else if (page === "Go To Another Chart") {
        params.append('module', chartModule);
        navigate('/?' + params.toString());
      }
      else if (chartModule === "All Modules") {
        params.append('module', page);
        navigate('/?' + params.toString());
      }
      else {
        if (item === null) {
          params.append('module', chartModule);
          params.append('item', page);
        }
        else if (subitem === null) {
          params.append('module', chartModule);
          params.append('item', item);
          params.append('subitem', page);
        }
        else {
          params.append('module', chartModule);
          params.append('item', item);
          params.append('subitem', subitem);
          params.append('detail', page);
        }
        navigate('/?' + params.toString());
      }
    }
  };
  const onChartPopUpClose = () => {
    setPopUpState({ open: false, item: null, title: "" });
  }

  const getArcLabel = (params: DefaultizedPieValueType) => {
    if ((params as ExtendedPieValueType).labelWithFormatting) {
      return (params as ExtendedPieValueType).labelWithFormatting
    }
    return `${params.label}`;
  };
  if (height === undefined || width === undefined) {
    return null;
  }


  var series = [{
    startAngle: chartData.fullOneEightyChart ? -15 : -90,
    endAngle: chartData.fullOneEightyChart ? 360 : 90,
    paddingAngle: 0.5,
    cornerRadius: 10,
    arcLabelRadius: chartRadius ? chartRadius : "75%",
    data,
    arcLabel: getArcLabel,
    innerRadius: 0
  }] as MakeOptional<PieSeriesType<MakeOptional<PieValueType, 'id'>>, 'type'>[];


  if (chartData.innerChart) {
    series[0]['innerRadius'] = height / 2.45;
    series.push({
      startAngle: -90,
      endAngle: 90,
      paddingAngle: 0.5,
      cornerRadius: 10,
      outerRadius: height / 2.5,
      arcLabel: 'label',
      arcLabelRadius: "37%",
      data: chartData.innerChart,
    } as MakeOptional<PieSeriesType<MakeOptional<PieValueType, 'id'>>, 'type'>)
  }
  const interpolatePct = (start:number,end:number,total:number) => {
    return start/total*100 + (end /total*100 - start/total*100)/2 + 3+ '%';
  }

  if (chartData.innerInnerChart) {
    series[0]['innerRadius'] = height / 3 * 1.9;
    series[1]['innerRadius'] = height / 2.5;
    series[1]['outerRadius'] = height / 3 * 1.9;
    series[1]['arcLabelRadius'] = interpolatePct(height/2.5,height/3*1.9,height);
    series[0]['arcLabelRadius'] = interpolatePct(height/3*1.9,height,height);

    series.push({
      startAngle: -90,
      endAngle: 90,
      paddingAngle: 0.5,
      cornerRadius: 10,
      outerRadius: height / 2.5,
      arcLabel: 'label',
      arcLabelRadius: interpolatePct(0,height/2.5,height),
      data: chartData.innerInnerChart,
    } as MakeOptional<PieSeriesType<MakeOptional<PieValueType, 'id'>>, 'type'>)
  }

  var bottomTextSize = chartData.textChartInstructions ? (bottomTextRef.current?.offsetHeight ?? 0) : 0;
  const chartHeight = height - bottomTextSize
  var divider = 1.05;
  var margin_subtract = 1 - (1 / divider);

  return (
    <Box
      display={chartData.rightOrLeftImage ? "flex" : "block"}
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
    >
      <React.Fragment>
        <PieChart
          margin={{ bottom: chartData.fullOneEightyChart ? 0 : -chartHeight / divider, left: 0, right: 0, top: 0 }}
          series={series}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontSize: chartData.textSizeOverride ? chartData.textSizeOverride : heightToFontSize(chartHeight),
              flex: '1 0 auto', justifyContent: 'center', alignItems: 'center',
              fontWeight: '600',
            },
          }}
          slots={{
            pieArcLabel: CustomPieArcLabel,
          }}
          slotProps={{
            legend: { hidden: true },
            pieArcLabel: {
            }
          }}
          tooltip={{ 'trigger': 'none' }}
          height={chartHeight - height * margin_subtract}
          onItemClick={(event: React.MouseEvent<SVGPathElement, MouseEvent>, d: any) => setItemData(d)}
        />
        {chartData.rightOrLeftImage && (
          <ImageList cols={1}>
            <ImageListItem>
              <img src={chartData.rightOrLeftImage} alt="" />
            </ImageListItem>
          </ImageList>
        )}
        {chartData && chartData.textChartInstructions
          ? <Box ref={bottomTextRef} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: heightToFontSize(height) }}>
            {chartData.textChartInstructions}
          </Box>
          : null
        }
        <ChartPopUp item={popUpState.item} title={popUpState.title} open={popUpState.open} onClose={onChartPopUpClose} />
      </React.Fragment>
    </Box>
  );
};

export default ModuleChart;