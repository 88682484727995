import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';

const sections = [
  { title: 'Wealth & Prosperity', element: '', color: '#692C91', textColor: 'white' ,colorWords:"Purples, Greens, Reds"},
  { title: 'Fame & Reputation', element: 'Fire Element', color: '#F48026', textColor: 'white' , colorWords:"Reds, Oranges"},
  { title: 'Love & Relationships', color: '#F390B0', element: '', textColor: 'white' ,colorWords:"Pinks, Reds, Whites" },
  { title: 'Family & Friends', color: '#00653A', element: 'Wood Element', textColor: 'white' ,colorWords:"Greens" },
  { title: 'Health & Well Being', color: '#FFE616', element: 'Earth Element', textColor: '#454545' ,colorWords:"Yellows, Earth Tones"},
  { title: 'Children & Creativity', color: '#f0f0f0', element: 'Metal element', textColor: '#454545' ,colorWords:"Whites, Pastels"},
  { title: 'Knowledge & Wisdom', color: '#263692', element: '', textColor: 'white' ,colorWords:"Blues, Blacks, Greens"},
  { title: 'Career', color: '#231F20', element: 'Water element' ,textColor: 'white' , colorWords:"Black"},
  { title: 'Travel & Helpful people', color: '#BEBFC2', element: '', textColor: 'white' ,colorWords:"Grays, Silver"}
];
const FengShuiGrid = () => {
  return (
    <Grid container spacing={0.5} sx={{ flexGrow: 1, backgroundColor: 'white', padding: '10px 0' }}>
    {sections.map((section, index) => (
      <Grid item xs={4} sm={4} md={4} lg={4} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Paper sx={{ backgroundColor: section.color, padding: 2, height: 120, width: 120, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', color: section.textColor }}>
        <Typography variant="body1" sx={{ marginBottom: '10px', fontWeight: '600'}}>
            {section.title}
          </Typography>
          <Typography variant="caption">
            {section.colorWords}
          </Typography>
          <Typography variant="caption">
            {section.element}
          </Typography>
        </Paper>
      </Grid>
    ))}
    <Grid item xs={12} sx={{ marginTop: 2 }}>
      <Typography variant="h6" align="center" sx={{  fontWeight: '500'}}>
        Front Door or Entrance
      </Typography>
    </Grid>
  </Grid>
  );
};

export default FengShuiGrid;
