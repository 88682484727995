import { NoInfoPopUp, } from "../popup"
import { popupIdMappingType } from "../types"

export const KarmaPopUps: popupIdMappingType = {
    "Karma": {
        "Emotional": {
            //They all have the same popup info
            "EmotionalNoInfo": <NoInfoPopUp />,

        },
        "Lifetimes": {
            //They all have the same popup info
            "LifetimesNoInfo": <NoInfoPopUp />,

        },
        "Points": {
            //They all have the same popup info
            "PointsNoInfo": <NoInfoPopUp />,

        },
        "Trials": {
            //They all have the same popup info
            "TrialsNoInfo": <NoInfoPopUp />,

        },
        "Lessons": {
            //They all have the same popup info
            "LessonsNoInfo": <NoInfoPopUp />,
            //l=karmic lessings

        }


    }
}