import React, { useEffect, ReactNode, useState } from 'react';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

type AuthFailResponse = {
  appCode: string;
  message: string;
};

interface AuthProps {
  children?: ReactNode;
  redirectUrl: string;
}

const expirationTime = 60 * 1000 * 5; // 5 minutes

export type TokenObject = {
  token: string;
  expiry: number;
  username: string;
};

export const AddTokenToLocalStorage = (token: TokenObject) => {
  localStorage.setItem('token', JSON.stringify(token));
};

export const LocalStorageTokenValid = () => {
  const tokenString = localStorage.getItem('token');
  const tokenObject = JSON.parse(tokenString || '{}');
  return tokenObject.token && Date.now() < tokenObject.expiry;
};

const Auth: React.FC<AuthProps> = ({ children, redirectUrl }) => {
  const [tokenIsChecked, setTokenIsChecked] = useState(false);
  const [tokenValid, setTokenValid] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine); // State to track internet connectivity
  const [subscriptionValid, setSubscriptionValid] = useState(true); 
  const navigate = useNavigate();

  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    const checkToken = async () => {
      if (!isOnline) return; // Skip token check if offline

      const tokenString = localStorage.getItem('token');
      const tokenObject = JSON.parse(tokenString || '{}');

      if (!tokenObject.token || Date.now() > tokenObject.expiry) {
        const fingerprint = await getFingerprint();
        const response = await fetch('/api/check_token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 'token': tokenObject.token, 'fingerprint': fingerprint }),
        });

        if (response.status === 401) {
          const data : AuthFailResponse = await response.json();
          if (data.appCode === 'SUBSCRIPTION_EXPIRED'){
            setSubscriptionValid(false);
            setTokenValid(false);
            localStorage.setItem('token', JSON.stringify({ token: '', expiry: 0 }));
          }
          else if ((data.appCode === 'TOKEN_NOT_FOUND') || (data.appCode === 'FINGERPRINT_MISMATCH'))
          {
            localStorage.setItem('token', JSON.stringify({ token: '', expiry: 0 }));
            navigate(redirectUrl);   
          }
        }
        else if (response.status === 500) {
          const responseBody = await response.text();
          if (responseBody === "Server error finding token!") {
            localStorage.setItem('token', JSON.stringify({ token: '', expiry: 0 }));
            navigate(redirectUrl);   
          }
        }
        else if (response.status === 200) {
          try {
            const data = await response.text();
            const newTokenObject = { token: data, expiry: Date.now() + expirationTime, username: tokenObject.username };
            localStorage.setItem('token', JSON.stringify(newTokenObject));
            setTokenValid(true);
          } catch (err) {
            console.error(err);
          }
        }
      } else {
        setTokenValid(true);
      }
      setTokenIsChecked(true);
    };

    checkToken();

    const interval = setInterval(checkToken, expirationTime);

    return () => clearInterval(interval);
  }, [isOnline, navigate, redirectUrl]);

  return (
    <>
      {!isOnline ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Alert severity="warning">You are offline. Please check your internet connection.</Alert>
        </Box>
      ) : !tokenIsChecked ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress color="secondary" size={100} />
        </Box>
      ) : !subscriptionValid ? ( // Check if subscription is not valid
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
          <Alert severity="error">Your subscription has expired. Please renew to continue using the service.</Alert>
          <Box mt={2}>
            <a href="https://www.trailblazingcommunications.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <Alert severity="info">Click here to renew your subscription</Alert>
            </a>
          </Box>
        </Box>
      ) : tokenValid ? (
        children
      ) : null}
    </>
  );
};

export default Auth;