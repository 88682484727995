import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

interface CardProps {
  content: string[];
  links?: string[];
  cardSize: number;
  title?: string;
}

const getCard = (cardSize: number) => {
  return styled(Card)({
    minWidth: `${cardSize}px`,
    margin: '5px',
    gridColumn: 'span 1',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'none',
    },
  });
}

const renderWord = (word: string) => {
  const regex = /<b>(.*?)<\/b>/g;
  let match;
  const parts = [];

  // Track the end of the last match to slice the string for content outside the tags
  let lastEnd = 0;

  while ((match = regex.exec(word)) !== null) {
    // Push the content before the <br> tag and the content inside the <br> tag
    parts.push(word.slice(lastEnd, match.index), <b key={`bold_word${match[1]}}`} >{match[1]}</b>);
    lastEnd = regex.lastIndex;
  }

  // Push the rest of the string after the last </br> tag
  parts.push(word.slice(lastEnd));

  return parts;
};

const renderWordWithLink = (word: string, link: string) => {
  return <Link key={`word_link${word}_${link}`} to={link}>{renderWord(word)}</Link>;
}

const WordCard = React.forwardRef<HTMLDivElement, CardProps>(({ content, cardSize, title, links }, ref) => {
  let CardComponent = getCard(cardSize);

  return (
    <>
      <CardComponent ref={ref}>
        <CardContent>
        {title && <Typography variant="h6" fontWeight={"bold"} sx={{ marginTop: '-10px'}}>{title}</Typography>}
          {content.slice(0, 5).map((word, index) => (
            <Typography
              key={`word_card_typ${word}_${index}`}
              variant="body1"
              color="text.secondary"
              sx={{
                overflowWrap: 'break-word',
                textIndent: '-1em',
                paddingLeft: '1em',
                '&::before': {
                  content: '"• "',
                },
              }}
            >
              {links && links[index] ? (renderWordWithLink(word, links[index])) : (renderWord(word))}
            </Typography>
          ))}
        </CardContent>
      </CardComponent>
    </>
  );
});
export default WordCard;