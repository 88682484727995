import { WordListMappingType } from "../types";

export const KarmaWordListMap: WordListMappingType = {
  "Karma": {
    "Lessons & Karma": {
      "Karmic Lessons": [
        "<b>Planetary Karma</b>: Karma that is set out at birth by how the planets are aligned for you at that time. (Eg. Astrology)",
        "<b>Life Contract Deviation</b>: Setting out to accomplish, be or do something before your incarnation and then not completing what you set out to do.",
        "<b>Self Karma</b>: Self Karma is a specific challenge that essence desires you to work on and is caused by false, self-limiting beliefs acquired in past experiences, either in past lives or earlier in the lifetime.",
        "<b>Sexual Karma</b>: When one's soul has experienced being the receiver or giver of sexual experiences that may have left some kind of energetic mark that has stayed stuck in their energy field. It could be past-lives or current lives.",
        "<b>Philanthropic Karma</b>: Philanthropic Karma represents a choice on both parties' part: One to provide support, the other is to receive.",
        "<b>Law of Harmony Karma</b>: The purpose of all the Laws is to attain harmony, so this supersedes all of them. You should strive to be a force that brings harmony, not disharmony and disturbance, to the world and to your life, or your disharmonious actions will be coming back upon you, until your own harmony is restored.",
        "<b>Law of Reincarnation Karma</b>: Your purpose here is to fulfill your Dharma (the deeds you must do) and to resolve your Karma; until you do that, you will continue to reincarnate. You create Karma whenever you act through intention, so make your actions (including thoughts, emotions, words and deeds) as harmonious as you can.",
        "<b>Law of Wisdom Karma</b>: Wisdom erases Karma, so if you are wise enough to learn your lessons through love and wisdom, you can mitigate your suffering.",
        "<b>Law of Grace Karma</b>: If you give love, mercy and grace to others, you will experience the same from them.",
        "<b>Law of Soul Evolution Karma</b>: The purpose of our existence in this life and form is to evolve spiritually; we move closer to a state of harmony when we raise our vibrational rate through love, and by rising above all of our fear-based emotions. Even the pain of our disharmonious acts makes us learn.",
        "<b>Law of Bodhisattiva Karma</b>: Bodhisattva means one who has transcended the need of Earthly incarnations but who has chosen to return to the earth to support others in achieving enlightenment.",
        "<b>Law of Vibrational Attainment Karma</b>: The Universe operates on the principle of vibrational energy, which can't be destroyed - it can only transform, so it can only go forward or backwards. Harmony or disharmony that you have created previously determines your physical life rate, and your goal is to attain the highest vibrational rate, the God-like level, by being harmonious for a lifetime.",
        "<b>Law of Free Will Karma</b>: You always have free will in how you respond to any situation - you can choose to give love and mercy, or contempt and unhappiness, and depending on the choice you make you will minimize or maximize your disharmonious experiences.",
        "<b>Law of One Karma</b>: We all are a part of one collective unconscious whose vibration we either increase or decrease by our harmonious or disharmonious actions.",
        "<b>Law of Manifestation Karma</b>: Everything in our reality begins as a thought, an idea; if we want to change our current reality, we have to change our thoughts. The unlimited creative power of our mind can be the wisdom that will allow us to rise above our Karma.",
        "<b>Law of Conscious Attachment Karma</b>: Buddha summed it up as 'It is your resistance to what is that causes your suffering.' We should change what we can change, but have the wisdom to accept unalterable situations as they are, without wasting mental or physical energy attempting to change what we cannot change.",
        "<b>Law of Gratitude Karma</b>: The more we give, the more we will receive; this Law also works in our day-to-day life.",
        "<b>Law of Fellowship Karma</b>: When two or more people of similar vibration are gathered for a shared purpose, their combined energy directed to the attainment of that purpose is doubled, tripled, quadrupled or more.",
        "<b>Law of Resistance Karma</b>: You draw to you that which you resist, and, as resistance is fear, you need to Karmically resolve that issue and let go of the fear by learning conscious detachment.",
        "<b>Law of Attraction Karma</b>: You attract what you are and what you focus your attention to. You can attract only those qualities that you already possess, so if you want to have peace and harmony in your life, you need to become peaceful and harmonious.",

        "<b>Law of Reflection Karma</b>: The traits you respond to in others, you recognize in yourself, be it something you admire in others, or something that you resist and react strongly to. You have chosen to have these experiences because you wanted to learn to rise above the effect of fear.",
        "<b>Law of Unconditional Love Karma</b>: Unconditional love is the acceptance of others as they are, without judgment or expectations. When you express unconditional love, it will eventually result in harmony.",
        "<b>Law of Magnetic Affinities Karma</b>: The nature of effects you will experience in your life was determined by your Astrological choice of the time and place of your birth. Before you were born, you also chose your parents, other souls to interact with, your character, personality, abilities and everything else.",
        "<b>Law of Abundance Karma</b>: We live in a Universe of abundance, and we have within ourselves everything required to make our earthly incarnation a paradise.",
        "<b>Law of Divine Order Karma</b>: Everything is as it should be, there are no accidents. We always have the learning opportunities we require to resolve our Karma. As we are all one, it's not only our own thoughts and emotions, but the collective thoughts and emotions of mankind create the environment for us all.",

      ]
    }


  }
}