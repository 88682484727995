import React from 'react';
import { AnimalWordListMap } from './Animal/wordlist';
import { PeopleWordListMap, PeopleWordListPageTitle } from './People/wordlist';
import { TraumaAnimalWordListMap } from './TraumaAnimal/wordlist';
import { TraumaPeopleWordListMap } from './TraumaPeople/wordlist';
import { SoulWordListMap,SoulWordTitleMap } from './Soul/wordlist';
import { FreedomWordListMap } from './Freedom/wordlist';
import { NatureWordListMap } from './Nature/wordlist';
import { BusinessWordListMap } from './Business/wordlist';
import { KarmaWordListMap } from './Karma/wordlist';
import { HomeWordListMap } from './Home/wordlist';
import { NutritionWordListMap, NutritionWordTitleMap } from './Nutrition/wordlist';
import { WordListMappingType, WordListPageTitle } from './types';

export const WordListPageTitleMap: WordListPageTitle = {
  ...PeopleWordListPageTitle,
  ...SoulWordTitleMap,
  ...NutritionWordTitleMap
}

const WordListMap: WordListMappingType = {
  ...AnimalWordListMap,
  ...PeopleWordListMap,
  ...TraumaAnimalWordListMap,
  ...TraumaPeopleWordListMap,
  ...SoulWordListMap,
  ...FreedomWordListMap,
  ...NatureWordListMap,
  ...BusinessWordListMap,
  ...HomeWordListMap,
  ...NutritionWordListMap,
  ...KarmaWordListMap

}

export const GetItemWordLists = (module: string | null, item: string | null, subitem: string | null) => {
  const moduleItem = WordListMap[module as keyof WordListMappingType]?.[item as keyof WordListMappingType];

  if (Array.isArray(moduleItem)) {
    return WordListMap[module as keyof WordListMappingType];
  } else if (moduleItem && typeof moduleItem === 'object') {
    const subitemItem = moduleItem[subitem as keyof typeof moduleItem];
    if (Array.isArray(subitemItem)) {
      // If subitemItem is an array, return the array
      return moduleItem;
    } else if (subitemItem && typeof subitemItem === 'object') {
      return subitemItem;
    }
  }

  return moduleItem;
}

export const GetWordList = (module: string | null, item: string | null, subitem: string | null, detail: string | null) => {
  const moduleItem = WordListMap[module as keyof WordListMappingType]?.[item as keyof WordListMappingType];

  if (Array.isArray(moduleItem)) {
    // If moduleItem is an array, return the array
    return moduleItem;
  } else if (moduleItem && typeof moduleItem === 'object') {
    const subitemItem = moduleItem[subitem as keyof typeof moduleItem];
    if (Array.isArray(subitemItem)) {
      // If subitemItem is an array, return the array
      return subitemItem;
    } else if (subitemItem && typeof subitemItem === 'object') {
      const detailItem = subitemItem[detail as keyof typeof subitemItem];
      if (Array.isArray(detailItem)) {
        // If detailItem is an array, return the array
        return detailItem;
      }
    }
  }

  return null;
}

export const GetWordListPageTitle = (module: string | null, item: string | null, subitem: string | null) => {
  const moduleItem = WordListPageTitleMap[module as keyof WordListPageTitle]?.[item as keyof WordListPageTitle];
  if (React.isValidElement(moduleItem)) {
    // If moduleItem is an array, return the array
    return moduleItem;
  } else if (moduleItem && typeof moduleItem === 'object') {
    // If moduleItem is an object, return the subitem
    return moduleItem[subitem as keyof typeof moduleItem] || null;
  }
  return null;
}