'use client';
import * as React from 'react';
import clsx from 'clsx';
import { useSlotProps } from '@mui/base/utils';
import composeClasses from '@mui/utils/composeClasses';
import { useRtl } from '@mui/system/RtlProvider';
import { Interpolation } from '@mui/system';
import { createUseThemeProps, styled } from '@mui/material/zero-styled';
import debounce from '@mui/material/utils/debounce';
import tabsClasses, { getTabsUtilityClass } from '@mui/material/Tabs/tabsClasses';
import ScrollbarSize from './ScrollbarSize';
import TabScrollButton from '@mui/material/TabScrollButton';
import useEventCallback from '@mui/material/utils/useEventCallback';
import ownerDocument from '@mui/material/utils/ownerDocument';
import ownerWindow from '@mui/material/utils/ownerWindow';
import { TabsProps as MuiTabsProps } from '@mui/material/Tabs';

interface LockedTabsProps extends MuiTabsProps {
  lockedTabIndices?: number[];
  scrollLeftOnClick?: boolean;
}

interface OwnerState {
  scrollButtonsHideMobile: boolean;
  vertical: boolean;
  centered: boolean;
  fixed: boolean;
  hideScrollbar: boolean;
  scrollableX: boolean;
  scrollableY: boolean;
}

const useThemeProps = createUseThemeProps('MuiTabs');

const nextItem = (list: HTMLElement, item: HTMLElement | null): HTMLInputElement => {
  if (list === item) {
    return list.firstChild as HTMLInputElement;
  }
  if (item && item.nextElementSibling) {
    return item.nextElementSibling as HTMLInputElement;
  }
  return list.firstChild as HTMLInputElement;
};

const previousItem = (list: HTMLElement, item: HTMLElement | null): HTMLInputElement => {
  if (list === item) {
    return list.lastChild as HTMLInputElement;
  }
  if (item && item.previousElementSibling) {
    return item.previousElementSibling as HTMLInputElement;
  }
  return list.lastChild as HTMLInputElement;
};

const moveFocus = (
  list: HTMLElement,
  currentFocus: HTMLElement | null,
  traversalFunction: (list: HTMLElement, item: HTMLElement | null) => HTMLInputElement,
) => {
  let wrappedOnce = false;
  let nextFocus = traversalFunction(list, currentFocus);

  while (nextFocus) {
    if (nextFocus === list.firstChild) {
      if (wrappedOnce) {
        return;
      }
      wrappedOnce = true;
    }

    const nextFocusDisabled = nextFocus.disabled || nextFocus.getAttribute('aria-disabled') === 'true';

    if (!nextFocus.hasAttribute('tabindex') || nextFocusDisabled) {
      nextFocus = traversalFunction(list, nextFocus);
    } else {
      nextFocus.focus();
      return;
    }
  }
};

const useUtilityClasses = (ownerState: any) => {
  const {
    vertical,
    fixed,
    hideScrollbar,
    scrollableX,
    scrollableY,
    centered,
    scrollButtonsHideMobile,
    classes,
  } = ownerState;

  const slots = {
    root: ['root', vertical && 'vertical'],
    scroller: [
      'scroller',
      fixed && 'fixed',
      hideScrollbar && 'hideScrollbar',
      scrollableX && 'scrollableX',
      scrollableY && 'scrollableY',
    ],
    flexContainer: ['flexContainer', vertical && 'flexContainerVertical', centered && 'centered'],
    indicator: ['indicator'],
    scrollButtons: ['scrollButtons', scrollButtonsHideMobile && 'scrollButtonsHideMobile'],
    scrollableX: [scrollableX && 'scrollableX'],
    hideScrollbar: [hideScrollbar && 'hideScrollbar'],
  };

  return composeClasses(slots, getTabsUtilityClass, classes);
};

const TabsRoot = styled('div', {
  name: 'MuiTabs',
  slot: 'Root',
  overridesResolver: (props, styles) => {
    const { ownerState } = props;
    return [
      { [`& .${tabsClasses.scrollButtons}`]: styles.scrollButtons },
      {
        [`& .${tabsClasses.scrollButtons}`]:
          ownerState.scrollButtonsHideMobile && styles.scrollButtonsHideMobile,
      },
      styles.root,
      ownerState.vertical && styles.vertical,
    ];
  },
})<{ ownerState: OwnerState }>(({ theme }) => ({
  overflow: 'hidden',
  minHeight: 48,
  WebkitOverflowScrolling: 'touch',
  display: 'flex',
  variants: [
    {
      props: ({ ownerState }: { ownerState: OwnerState }) => ownerState.vertical,
      style: {
        flexDirection: 'column',
      },
    },
    {
      props: ({ ownerState }: { ownerState: OwnerState }) => ownerState.scrollButtonsHideMobile,
      style: {
        [`& .${tabsClasses.scrollButtons}`]: {
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        },
      },
    },
  ] as Interpolation<OwnerState>,
}));

const LockedTabsContainer = styled('div', {
  name: 'MuiTabs',
  slot: 'Container',
  overridesResolver: (props, styles) => styles.container,
})({
  display: 'flex',
  flexShrink: 0,
});

const TabsScroller = styled('div', {
  name: 'MuiTabs',
  slot: 'Scroller',
  overridesResolver: (props, styles) => {
    const { ownerState } = props;
    return [
      styles.scroller,
      ownerState.fixed && styles.fixed,
      ownerState.hideScrollbar && styles.hideScrollbar,
      ownerState.scrollableX && styles.scrollableX,
      ownerState.scrollableY && styles.scrollableY,
    ];
  },
})<{ ownerState: OwnerState }>((props) => ({
  position: 'relative',
  display: 'inline-block',
  flex: '1 1 auto',
  whiteSpace: 'nowrap',
  variants: [
    {
      props: ({ ownerState }: { ownerState: OwnerState }) => ownerState.fixed,
      style: {
        overflowX: 'hidden',
        width: '100%',
      },
    },
    {
      props: ({ ownerState }: { ownerState: OwnerState }) => ownerState.hideScrollbar,
      style: {
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    {
      props: ({ ownerState }: { ownerState: OwnerState }) => ownerState.scrollableX,
      style: {
        overflowX: 'auto',
        overflowY: 'hidden',
      },
    },
    {
      props: ({ ownerState }: { ownerState: OwnerState }) => ownerState.scrollableY,
      style: {
        overflowY: 'auto',
        overflowX: 'hidden',
      },
    },
  ],
}));

const FlexContainer = styled('div', {
  name: 'MuiTabs',
  slot: 'FlexContainer',
  overridesResolver: (props, styles) => {
    const { ownerState } = props;
    return [
      styles.flexContainer,
      ownerState.vertical && styles.flexContainerVertical,
      ownerState.centered && styles.centered,
    ];
  },
})<{ ownerState: OwnerState }>({
  display: 'flex',
  variants: [
    {
      props: ({ ownerState }: { ownerState: OwnerState }) => ownerState.vertical,
      style: {
        flexDirection: 'column',
      },
    },
    {
      props: ({ ownerState }: { ownerState: OwnerState }) => ownerState.centered,
      style: {
        justifyContent: 'center',
      },
    },
  ],
});

const TabsIndicator = styled('span', {
  name: 'MuiTabs',
  slot: 'Indicator',
  overridesResolver: (props, styles) => styles.indicator,
})<{ ownerState: OwnerState }>(({ theme }) => ({
  position: 'absolute',
  height: 2,
  bottom: 0,
  width: '100%',
  transition: (theme.transitions as any).create(),
  variants: [
    {
      props: {
        indicatorColor: 'primary',
      },
      style: {
        backgroundColor: ((theme as any).vars || theme).palette.primary.main,
      },
    },
    {
      props: {
        indicatorColor: 'secondary',
      },
      style: {
        backgroundColor: ((theme as any).vars || theme).palette.secondary.main,
      },
    },
    {
      props: ({ ownerState }: { ownerState: OwnerState }) => ownerState.vertical,
      style: {
        height: '100%',
        width: 2,
        right: 0,
      },
    },
  ],
}));

const TabsScrollbarSize = styled(ScrollbarSize)({
  overflowX: 'auto',
  overflowY: 'hidden',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const LockedTabs = React.forwardRef<HTMLDivElement, LockedTabsProps>(function Tabs(inProps, ref) {
  const props = useThemeProps({ props: inProps, name: 'MuiTabs' });
  const isRtl = useRtl();
  const {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    action,
    centered = false,
    children: childrenProp,
    className,
    component = 'div',
    allowScrollButtonsMobile = false,
    indicatorColor = 'primary',
    onChange,
    orientation = 'horizontal',
    ScrollButtonComponent = TabScrollButton,
    scrollButtons = 'auto',
    selectionFollowsFocus,
    slots = {},
    slotProps = {},
    TabIndicatorProps = {},
    TabScrollButtonProps = {},
    textColor = 'primary',
    value,
    variant = 'standard',
    visibleScrollbar = false,
    lockedTabIndices = [],
    ...other
  } = props;
  const scrollable = variant === 'scrollable';
  const vertical = orientation === 'vertical';
  const scrollLeftOnClick = props.scrollLeftOnClick ?? false;

  const scrollStart = vertical ? 'scrollTop' : 'scrollLeft';
  const start = vertical ? 'top' : 'left';
  const end = vertical ? 'bottom' : 'right';
  const clientSize = vertical ? 'clientHeight' : 'clientWidth';
  const size = vertical ? 'height' : 'width';

  const ownerState = {
    ...props,
    component,
    allowScrollButtonsMobile,
    indicatorColor,
    orientation,
    vertical,
    scrollButtons,
    textColor,
    variant,
    visibleScrollbar,
    fixed: !scrollable,
    hideScrollbar: scrollable && !visibleScrollbar,
    scrollableX: scrollable && !vertical,
    scrollableY: scrollable && vertical,
    centered: centered && !scrollable,
    scrollButtonsHideMobile: !allowScrollButtonsMobile,
  };

  const classes = useUtilityClasses(ownerState);

  const startScrollButtonIconProps = useSlotProps({
    elementType: slots.StartScrollButtonIcon,
    externalSlotProps: slotProps.startScrollButtonIcon,
    ownerState,
  });

  const endScrollButtonIconProps = useSlotProps({
    elementType: slots.EndScrollButtonIcon,
    externalSlotProps: slotProps.endScrollButtonIcon,
    ownerState,
  });

  if (process.env.NODE_ENV !== 'production') {
    if (centered && scrollable) {
      console.error(
        'MUI: You can not use the `centered={true}` and `variant="scrollable"` properties ' +
        'at the same time on a `Tabs` component.',
      );
    }
  }

  const [mounted, setMounted] = React.useState(false);
  const [indicatorStyle, setIndicatorStyle] = React.useState<Record<string, any>>({});
  const [indicatorChoice, setIndicatorChoice] = React.useState('locked');
  const [displayStartScroll, setDisplayStartScroll] = React.useState(false);
  const [displayEndScroll, setDisplayEndScroll] = React.useState(false);
  const [updateScrollObserver, setUpdateScrollObserver] = React.useState(false);

  const [scrollerStyle, setScrollerStyle] = React.useState({
    overflow: '',
    scrollbarWidth: 0,
  });

  const valueToIndex = new Map<any, number>();
  const tabsRef = React.useRef<HTMLDivElement>(null);
  const tabListRef = React.useRef<HTMLDivElement>(null);
  const lockedTabListRef = React.useRef<HTMLDivElement>(null);
  const leftScrollButtonRef = React.useRef<HTMLButtonElement>(null);

  const getTabsMeta = (includeLockedTabs: boolean) => {
    const tabsNode = tabsRef.current;
    let tabsMeta: any;
    if (tabsNode) {
      const rect = tabsNode.getBoundingClientRect();
      tabsMeta = {
        clientWidth: tabsNode.clientWidth,
        scrollLeft: tabsNode.scrollLeft,
        scrollTop: tabsNode.scrollTop,
        scrollWidth: tabsNode.scrollWidth,
        top: rect.top,
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
      };
    }

    let tabMeta;
    let lockedChildrenLength = 0;
    if (tabsNode && value !== false) {
      let children: Array<HTMLElement> = Array.from(tabListRef.current!.children as HTMLCollectionOf<HTMLElement>);
      if (includeLockedTabs) {
        const lockedChildren: Array<HTMLElement> = Array.from(lockedTabListRef.current!.children as HTMLCollectionOf<HTMLElement>);
        const filteredChildren = lockedChildren.filter((child) => child.localName === 'button');
        children = [...filteredChildren, ...children];
        lockedChildrenLength = filteredChildren.length;
      }

      if (children.length > 0) {
        const tab = children[valueToIndex.get(value)!] as HTMLElement;
        tabMeta = tab ? tab.getBoundingClientRect() : null;
      }

      if (tabMeta && lockedChildrenLength > 0){
        if (valueToIndex.get(value)! < lockedChildrenLength) {
          (tabMeta as any).lockedTab = true;
        }
        else
        {
          (tabMeta as any).lockedTab = false;
        }
      }
    }
    const lockedTabNode = lockedTabListRef.current;
    if ((tabMeta as any).lockedTab && lockedTabNode){
      const rect = lockedTabNode.getBoundingClientRect();
      tabsMeta = {
        clientWidth: lockedTabNode.clientWidth,
        scrollLeft: lockedTabNode.scrollLeft,
        scrollTop: lockedTabNode.scrollTop,
        scrollWidth: lockedTabNode.scrollWidth,
        top: rect.top,
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
      };
    }

    return { tabsMeta, tabMeta };
  };

  const updateIndicatorState = useEventCallback(() => {
    const { tabsMeta, tabMeta } = getTabsMeta(true);
    let scrollBarWidth = 0;
    if ((tabMeta as any).lockedTab) {
      setIndicatorChoice('locked');
      if (conditionalElements.scrollButtonStart && leftScrollButtonRef.current) {
        scrollBarWidth = leftScrollButtonRef.current.offsetWidth;
      }
    }
    else{
      setIndicatorChoice('scrollable');
    }

    let startValue = 0;
    let startIndicator: any;

    if (vertical) {
      startIndicator = 'top';
      if (tabMeta && tabsMeta) {
        startValue = tabMeta.top - tabsMeta.top + tabsMeta.scrollTop;
      }
    } else {
      startIndicator = isRtl ? 'right' : 'left';
      if (tabMeta && tabsMeta) {
        startValue =
          (isRtl ? -1 : 1) *
          ((tabMeta as any)[startIndicator] - tabsMeta[startIndicator] + tabsMeta.scrollLeft + scrollBarWidth);
      }
    }

    const newIndicatorStyle = {
      [startIndicator]: startValue,
      [size]: tabMeta ? tabMeta[size] : 0,
    };

    if (
      typeof (indicatorStyle as any)[startIndicator] !== 'number' ||
      typeof (indicatorStyle as any)[size] !== 'number'
    ) {
      setIndicatorStyle(newIndicatorStyle);
    } else {
      const dStart = Math.abs((indicatorStyle as any)[startIndicator] - newIndicatorStyle[startIndicator]);
      const dSize = Math.abs((indicatorStyle as any)[size] - newIndicatorStyle[size]);

      if (dStart >= 1 || dSize >= 1) {
        setIndicatorStyle(newIndicatorStyle);
      }
    }
  });

  function scrollToPosition(element: HTMLElement, to: number, smooth: boolean = true): void {
    element.scrollTo({
      left: to,
      behavior: smooth ? 'smooth' : 'auto',
    });
  }

  const scroll = (scrollValue: number, { animation = true } = {}) => {
    if (animation) {
      scrollToPosition(tabsRef.current!, scrollValue);
    } else {
      tabsRef.current![scrollStart] = scrollValue;
    }
  };

  const moveTabsScroll = (delta: number) => {
    let scrollValue = tabsRef.current![scrollStart];

    if (vertical) {
      scrollValue += delta;
    } else {
      scrollValue += delta * (isRtl ? -1 : 1);
    }

    scroll(scrollValue);
  };

  const getScrollSize = () => {
    const containerSize = tabsRef.current![clientSize];
    let totalSize = 0;
    const children = Array.from(tabListRef.current!.children);

    for (let i = 0; i < children.length; i += 1) {
      const tab = children[i] as HTMLElement;
      if (totalSize + tab[clientSize] > containerSize) {
        if (i === 0) {
          totalSize = containerSize;
        }
        break;
      }
      totalSize += tab[clientSize];
    }

    return totalSize;
  };

  const handleStartScrollClick = () => {
    moveTabsScroll(-1 * getScrollSize());
  };

  const handleEndScrollClick = () => {
    moveTabsScroll(getScrollSize());
  };

  const handleScrollbarSizeChange = React.useCallback((scrollbarWidth: number) => {
    setScrollerStyle({
      overflow: '',
      scrollbarWidth,
    });
  }, []);

  const getConditionalElements = () => {
    const conditionalElements: any = {};

    conditionalElements.scrollbarSizeListener = scrollable ? (
      <TabsScrollbarSize onChange={handleScrollbarSizeChange} />
    ) : null;

    const scrollButtonsActive = displayStartScroll || displayEndScroll;
    const showScrollButtons =
      scrollable && ((scrollButtons === 'auto' && scrollButtonsActive) || scrollButtons === true);

    conditionalElements.scrollButtonStart = showScrollButtons ? (
      <ScrollButtonComponent
        ref={leftScrollButtonRef}
        slots={{ StartScrollButtonIcon: slots.StartScrollButtonIcon }}
        slotProps={{ startScrollButtonIcon: startScrollButtonIconProps }}
        orientation={orientation}
        direction={isRtl ? 'right' : 'left'}
        onClick={handleStartScrollClick}
        disabled={!displayStartScroll}
        {...TabScrollButtonProps}
        className={clsx(classes.scrollButtons, TabScrollButtonProps.className)}
      />
    ) : null;

    conditionalElements.scrollButtonEnd = showScrollButtons ? (
      <ScrollButtonComponent
        slots={{ EndScrollButtonIcon: slots.EndScrollButtonIcon }}
        slotProps={{ endScrollButtonIcon: endScrollButtonIconProps }}
        orientation={orientation}
        direction={isRtl ? 'left' : 'right'}
        onClick={handleEndScrollClick}
        disabled={!displayEndScroll}
        {...TabScrollButtonProps}
        className={clsx(classes.scrollButtons, TabScrollButtonProps.className)}
      />
    ) : null;

    return conditionalElements;
  };

  const scrollSelectedIntoView = useEventCallback((animation: boolean) => {
    const { tabsMeta, tabMeta } = getTabsMeta(true);

    if (!tabMeta || !tabsMeta) {
      return;
    }

    if ((tabMeta as any).lockedTab ||  scrollLeftOnClick) {
      scroll(0, { animation });
    }
    
    else if (tabMeta[start] < tabsMeta[start]) {
      const nextScrollStart = tabsMeta[scrollStart] + (tabMeta[start] - tabsMeta[start]);
      scroll(nextScrollStart, { animation });
    } else if (tabMeta[end] > tabsMeta[end]) {
      const nextScrollStart = tabsMeta[scrollStart] + (tabMeta[end] - tabsMeta[end]);
      scroll(nextScrollStart, { animation });
    }
  });

  const updateScrollButtonState = useEventCallback(() => {
    if (scrollable && scrollButtons !== false) {
      setUpdateScrollObserver(!updateScrollObserver);
    }
  });

  React.useEffect(() => {
    const handleResize = debounce(() => {
      if (tabsRef.current) {
        updateIndicatorState();
      }
    });

    let resizeObserver: ResizeObserver | undefined;

    const handleMutation: MutationCallback = (records) => {
      records.forEach((record) => {
        record.removedNodes.forEach((item) => {
          resizeObserver?.unobserve(item as HTMLElement);
        });
        record.addedNodes.forEach((item) => {
          resizeObserver?.observe(item as HTMLElement);
        });
      });
      handleResize();
      updateScrollButtonState();
    };

    const win = ownerWindow(tabsRef.current as Node | undefined);
    win.addEventListener('resize', handleResize);

    let mutationObserver: MutationObserver | undefined;

    if (typeof ResizeObserver !== 'undefined') {
      resizeObserver = new ResizeObserver(handleResize);
      Array.from(tabListRef.current!.children).forEach((child) => {
        resizeObserver!.observe(child as HTMLElement);
      });
    }

    if (typeof MutationObserver !== 'undefined') {
      mutationObserver = new MutationObserver(handleMutation);
      mutationObserver.observe(tabListRef.current!, {
        childList: true,
      });
    }

    return () => {
      handleResize.clear();
      win.removeEventListener('resize', handleResize);
      mutationObserver?.disconnect();
      resizeObserver?.disconnect();
    };
  }, [updateIndicatorState, updateScrollButtonState]);

  React.useEffect(() => {
    const tabListChildren = Array.from(tabListRef.current?.children || []);
    const length = tabListChildren.length;

    if (
      typeof IntersectionObserver !== 'undefined' &&
      length > 0 &&
      scrollable &&
      scrollButtons !== false
    ) {
      const firstTab = tabListChildren[0] as HTMLElement;
      const lastTab = tabListChildren[length - 1] as HTMLElement;
      const observerOptions = {
        root: tabsRef.current,
        threshold: 0.99,
      };

      const handleScrollButtonStart = (entries: IntersectionObserverEntry[]) => {
        setDisplayStartScroll(!entries[0].isIntersecting);
      };
      const firstObserver = new IntersectionObserver(handleScrollButtonStart, observerOptions);
      firstObserver.observe(firstTab);

      const handleScrollButtonEnd = (entries: IntersectionObserverEntry[]) => {
        setDisplayEndScroll(!entries[0].isIntersecting);
      };
      const lastObserver = new IntersectionObserver(handleScrollButtonEnd, observerOptions);
      lastObserver.observe(lastTab);

      return () => {
        firstObserver.disconnect();
        lastObserver.disconnect();
      };
    }

    return undefined;
  }, [scrollable, scrollButtons, updateScrollObserver, childrenProp]);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  React.useEffect(() => {
    updateIndicatorState();
  });

  React.useEffect(() => {
    scrollSelectedIntoView(true);
  }, [value,scrollSelectedIntoView]);

  React.useImperativeHandle(
    action,
    () => ({
      updateIndicator: updateIndicatorState,
      updateScrollButtons: updateScrollButtonState,
    }),
    [updateIndicatorState, updateScrollButtonState],
  );

  const indicator = (
    <TabsIndicator
      {...TabIndicatorProps}
      className={clsx(classes.indicator, TabIndicatorProps.className)}
      ownerState={ownerState as OwnerState}
      style={{
        ...indicatorStyle,
        ...TabIndicatorProps.style,
      }}
    />
  );

  let childIndex = 0;
  const lockedChildren = React.Children.map(childrenProp, (child, index) => {
    if (!React.isValidElement(child)) {
      return null;
    }
    if (!lockedTabIndices.includes(index)) {
      return null;
    }
    const childValue = (child as any).props.value === undefined ? childIndex : (child as any).props.value;
    valueToIndex.set(childValue, childIndex);
    const selected = childValue === value;

    childIndex += 1;
    return React.cloneElement(child, {
      fullWidth: ((variant as string) === 'fullWidth') as boolean,
      indicator: selected && !mounted && indicator,
      selected,
      selectionFollowsFocus,
      onChange,
      textColor,
      value: childValue,
      ...(childIndex === 1 && value === false && !(child as any).props.tabIndex ? { tabIndex: 0 } : {}),
    } as any);
  });

  const children = React.Children.map(childrenProp, (child, index) => {
    if (!React.isValidElement(child)) {
      return null;
    }
    if (lockedTabIndices.includes(index)) {
      return null;
    }

    const childValue = (child as any).props.value === undefined ? childIndex : (child as any).props.value;
    valueToIndex.set(childValue, childIndex);
    const selected = childValue === value;

    childIndex += 1;
    return React.cloneElement(child, {
      fullWidth: ((variant as string) === 'fullWidth') as boolean,
      indicator: selected && !mounted && indicator,
      selected,
      selectionFollowsFocus,
      onChange,
      textColor,
      value: childValue,
      ...(childIndex === 1 && value === false && !(child as any).props.tabIndex ? { tabIndex: 0 } : {}),
    } as any);
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const list = tabListRef.current;
    const currentFocus = ownerDocument(list!).activeElement as HTMLElement;
    const role = currentFocus.getAttribute('role');
    if (role !== 'tab') {
      return;
    }

    let previousItemKey = orientation === 'horizontal' ? 'ArrowLeft' : 'ArrowUp';
    let nextItemKey = orientation === 'horizontal' ? 'ArrowRight' : 'ArrowDown';
    if (orientation === 'horizontal' && isRtl) {
      previousItemKey = 'ArrowRight';
      nextItemKey = 'ArrowLeft';
    }

    switch (event.key) {
      case previousItemKey:
        event.preventDefault();
        moveFocus(list!, currentFocus, previousItem);
        break;
      case nextItemKey:
        event.preventDefault();
        moveFocus(list!, currentFocus, nextItem);
        break;
      case 'Home':
        event.preventDefault();
        moveFocus(list!, null, nextItem);
        break;
      case 'End':
        event.preventDefault();
        moveFocus(list!, null, previousItem);
        break;
      default:
        break;
    }
  };

  const conditionalElements = getConditionalElements();

  return (
    <TabsRoot
      className={clsx(classes.root, className)}
      ownerState={ownerState}
      ref={ref}
      as={component}
      {...other}
    >
      {conditionalElements.scrollButtonStart}
      {conditionalElements.scrollbarSizeListener}

      <LockedTabsContainer ref={lockedTabListRef}>
        {lockedChildren}
      {mounted && indicator && indicatorChoice === 'locked' && indicator}

      </LockedTabsContainer>

      <TabsScroller
        className={classes.scroller}
        ownerState={ownerState}
        style={{
          overflow: scrollerStyle.overflow,
          [vertical ? `margin${isRtl ? 'Left' : 'Right'}` : 'marginBottom']: visibleScrollbar
            ? undefined
            : -scrollerStyle.scrollbarWidth,
          // marginLeft: lockedTabListRef.current ? lockedTabListRef.current.clientWidth : 0, // Adjust margin
        }}
        ref={tabsRef}
      >
        <FlexContainer
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          aria-orientation={orientation === 'vertical' ? 'vertical' : undefined}
          className={classes.flexContainer}
          ownerState={ownerState}
          onKeyDown={handleKeyDown}
          ref={tabListRef}
          role="tablist"
        >
          {children}
        </FlexContainer>
        {mounted && indicator && indicatorChoice === 'scrollable' && indicator}
      </TabsScroller>
      {conditionalElements.scrollButtonEnd}
    </TabsRoot>
  );
});

export default LockedTabs;
