import React from 'react';
import { Typography, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const KarmaTextPage: React.FC = () => {

    const margin = 20;
    const textVarient = 'h6';
    return (
        <React.Fragment>
            <Grid style={{ flex: '1 0 auto'}} container sx={{ marginTop: `${margin}px` }} justifyContent="center">
                <Grid item xs={12} md={12} sx={{ mb: '20px' }} >

                    <Card sx={{ mx: '20px' }} >
                        <CardHeader sx={{ paddingBottom: '0px' }} title="Instructions" />
                        <CardContent>

                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                Begin by starting on the first tab <Link to="/?module=Karma&item=Emotional+Lessons">Emotional Lessons</Link>, 
                                you will then find the one answer on this chart that you need, allow your pendulum to swing and clear after this answer and
                                each answer after this. Once this clearing is complete, you will proceed onto the <Link to="/?module=Karma&item=Lifetimes">Lifetimes</Link> tab 
                                and find that answer, allow it to clear. In the  <Link to="/?module=Karma&item=Points+of+Light">Points of Light</Link> tab
                                you will be finding a beginning and ending color and you will be clearing that spectrum of energy between the
                                two colors. You will then continue to the <Link to="/?module=Karma&item=Trials">Trials</Link> tab and then finally to 
                                the <Link to="/?module=Karma&item=Lessons+%26+Karma">Lessons & Karma</Link> tab. You will be clearing after each answer that you get.
                            </Typography>


                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                You will then proceed on by asking if another module is needed? If yes, continue to find out
                                which module that would be and proceed from there. If you get a no, then do I need
                                another module question, you will then ask if you need another answer in the protocol if
                                you are working with the Trailblazing Communications Whole Life System utilizing the
                                Trauma Genius process and worksheet. If you get a yes, then proceed to the protocol. If
                                you get a no, then ask if this session is complete. If yes, you are done.
                            </Typography>

                            <Typography variant={textVarient} sx={{ margin: `${margin}px` }}>
                                If you are not utilizing the Trailblazing Communications Whole Life system and the Trauma
                                Genius process and worksheet, then you will ask is this session complete? If yes, you are
                                done. If no, you will then ask if another module is needed. Continue with whatever
                                information you receive.
                            </Typography>
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>

        </React.Fragment>
    );
}
export default KarmaTextPage;
