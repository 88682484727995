import { AnimalPopUps } from './popups';
import { ChartPagesType} from '../types'

export const AnimalCharts: ChartPagesType = {
    "Animal": {
        chart: [
          { id: 0, label: 'Basic Needs', value: 1, color: '#8F241F' },
          { id: 1, label: 'Nutrition', value: 1, color: '#D52329' },
          { id: 2, label: 'Body Area', value: 1, color: '#FF7B27' },
          { id: 3, label: 'Health Status', value: 1, color: '#FDB02D' },
          { id: 4, label: 'Emotional', value: 1, color: '#4BAD43' },
          { id: 5, label: 'Interrelational', value: 1, color: '#005D38' },
          { id: 6, label: 'Body Chemistry', value: 1, color: '#0D2D94' },
          { id: 7, label: 'Life Enhancements', value: 1, color: '#0F1D6D' },
          { id: 8, label: 'Health Intervention', value: 1, color: '#662C91' },
        ],
        parent: "All Modules",
        level: 1,
        subPages: {
          "Basic Needs": {
            chart: [
              { id: 0, label: 'Sleep', value: 1, color: '#8F241F', popupContent: AnimalPopUps.Animal.BasicNeeds.Sleep },
              { id: 1, label: 'Eat', value: 1, color: '#E3C8C7', popupContent: AnimalPopUps.Animal.BasicNeeds.Eat },
              { id: 2, label: 'Play', value: 1, color: '#8F241F', popupContent: AnimalPopUps.Animal.BasicNeeds.Play },
              { id: 3, label: 'Eliminate', value: 1, color: '#E3C8C7', popupContent: AnimalPopUps.Animal.BasicNeeds.Eliminate },
              { id: 4, label: 'Exercise', value: 1, color: '#8F241F', popupContent: AnimalPopUps.Animal.BasicNeeds.Exercise },
              { id: 5, label: 'Groomed', value: 1, color: '#E3C8C7', popupContent: AnimalPopUps.Animal.BasicNeeds.Groomed },
              { id: 6, label: 'Work', value: 1, color: '#8F241F', popupContent: AnimalPopUps.Animal.BasicNeeds.Work },
              { id: 7, label: 'Learn', value: 1, color: '#E3C8C7', popupContent: AnimalPopUps.Animal.BasicNeeds.Learn},
              { id: 8, label: 'Climate', value: 1, color: '#8F241F', popupContent: AnimalPopUps.Animal.BasicNeeds.Climate },
              { id: 9, label: 'Water', value: 1, color: '#E3C8C7', popupContent: AnimalPopUps.Animal.BasicNeeds.Water },
              { id: 10, label: 'Air', value: 1, color: '#8F241F', popupContent: AnimalPopUps.Animal.BasicNeeds.Air },
              { id: 11, label: 'Medical', value: 1, color: '#E3C8C7', popupContent: AnimalPopUps.Animal.BasicNeeds.Medical },
            ],
            parent: "Animal",
            level: 2,
            subPages: {}
          },
          "Nutrition": {
            chart: [
              { id: 0, label: 'Foods & Treats', value: 1, color: '#D52329' },
              { id: 1, label: 'Allergy & Intolerance', value: 1, color: '#F4C8C9', popupContent: AnimalPopUps.Animal.Nutrition.AllergyIntolerance },
              { id: 2, label: 'Vitamins', value: 1, color: '#D52329' },
              { id: 3, label: 'Supplements', value: 1, color: '#F4C8C9' },
              { id: 4, label: 'Minerals', value: 1, color: '#D52329' },
              { id: 5, label: 'EFA', value: 1, color: '#F4C8C9', popupContent: AnimalPopUps.Animal.Nutrition.EFA },
              { id: 6, label: 'Hydration', value: 1, color: '#D52329', popupContent: AnimalPopUps.Animal.Nutrition.Hydration},
              { id: 7, label: 'Herbs', value: 1, color: '#F4C8C9' },
              { id: 8, label: 'PH Balance', value: 1, color: '#D52329', popupContent: AnimalPopUps.Animal.Nutrition.PHBalance },
              { id: 9, label: 'Additives', value: 1, color: '#F4C8C9' },
              { id: 10, label: 'Amino Acids', value: 1, color: '#D52329' },
              { id: 11, label: 'Medications', value: 1, color: '#F4C8C9', popupContent: AnimalPopUps.Animal.Nutrition.Medications},
            ],
            parent: "Animal",
            level: 2,
            subPages: {}
          },
          "Body Area": {
            chart: [
              { id: 0, label: 'Skin/Fur/Features', value: 1, color: '#F48026', popupContent: AnimalPopUps.Animal.BodyArea.SkinFurFeatures},
              { id: 1, label: 'Neurological', value: 1, color: '#FCDFC9', popupContent: AnimalPopUps.Animal.BodyArea.Neurological},
              { id: 2, label: 'Endocrine', value: 1, color: '#F48026' },
              { id: 3, label: 'Eye(s)', value: 1, color: '#FCDFC9', popupContent: AnimalPopUps.Animal.BodyArea.Eyes},
              { id: 4, label: 'Ear(s)', value: 1, color: '#F48026', popupContent: AnimalPopUps.Animal.BodyArea.Ears},
              { id: 5, label: 'Organs', value: 1, color: '#FCDFC9' },
              { id: 6, label: 'Lymph', value: 1, color: '#F48026', popupContent: AnimalPopUps.Animal.BodyArea.Lymph},
              { id: 7, label: 'Brain', value: 1, color: '#FCDFC9', popupContent: AnimalPopUps.Animal.BodyArea.Brain},
              { id: 8, label: 'Colon', value: 1, color: '#F48026', popupContent: AnimalPopUps.Animal.BodyArea.Colon},
              { id: 9, label: 'Muscles', value: 1, color: '#FCDFC9', popupContent: AnimalPopUps.Animal.BodyArea.Muscles},
              { id: 10, label: 'Fascia', value: 1, color: '#F48026', popupContent: AnimalPopUps.Animal.BodyArea.Fascia},
              { id: 11, label: 'Tendons', value: 1, color: '#FCDFC9', popupContent: AnimalPopUps.Animal.BodyArea.Tendons},
              { id: 12, label: 'Bone(s)', value: 1, color: '#F48026', popupContent: AnimalPopUps.Animal.BodyArea.Bones},
              { id: 13, label: 'Circ. System', value: 1, color: '#FCDFC9', popupContent: AnimalPopUps.Animal.BodyArea.CirculatorySystem},
              { id: 14, label: 'Leg/Arm/Paw/Foot/Hoof', value: 1, color: '#F48026', popupContent: AnimalPopUps.Animal.BodyArea.LegArmPawFootHoof},
            ],
            parent: "Animal",
            level: 2,
            subPages: {}
          },
          "Health Status": {
            chart: [
              { id: 0, label: 'Allergy/Intolerance', value: 1, color: '#FDB02D', popupContent: AnimalPopUps.Animal.HealthStatus.AllergyIntolerance},
              { id: 1, label: 'Weight', value: 1, color: '#FFEBCA', popupContent: AnimalPopUps.Animal.HealthStatus.Weight },
              { id: 2, label: 'Energy', value: 1, color: '#FDB02D', popupContent: AnimalPopUps.Animal.HealthStatus.Energy },
              { id: 3, label: 'Energy Field', value: 1, color: '#FFEBCA', popupContent: AnimalPopUps.Animal.HealthStatus.EnergyField },
              { id: 4, label: 'Life Force', value: 1, color: '#FDB02D', popupContent: AnimalPopUps.Animal.HealthStatus.LifeForce},
              { id: 5, label: 'Pregnant', value: 1, color: '#FFEBCA', popupContent: AnimalPopUps.Animal.HealthStatus.Pregnant },
              { id: 6, label: 'Obstruction', value: 1, color: '#FDB02D', popupContent: AnimalPopUps.Animal.HealthStatus.Obstruction },
              { id: 7, label: 'Pain', value: 1, color: '#FFEBCA', popupContent: AnimalPopUps.Animal.HealthStatus.Pain },
              { id: 8, label: 'Toxicity', value: 1, color: '#FDB02D', popupContent: AnimalPopUps.Animal.HealthStatus.Toxicity },
              { id: 9, label: 'Body/Temp', value: 1, color: '#FFEBCA', popupContent: AnimalPopUps.Animal.HealthStatus.BodyTemp },
              { id: 10, label: 'Cyst', value: 1, color: '#FDB02D', popupContent: AnimalPopUps.Animal.HealthStatus.Cyst },
              { id: 11, label: 'Tumor', value: 1, color: '#FFEBCA', popupContent: AnimalPopUps.Animal.HealthStatus.Tumor },
              { id: 12, label: 'Digest./Elimi.', value: 1, color: '#FDB02D' , popupContent: AnimalPopUps.Animal.HealthStatus.DigestElimi},
              { id: 13, label: 'Inflam.', value: 1, color: '#FFEBCA', popupContent: AnimalPopUps.Animal.HealthStatus.Inflam },
              { id: 14, label: 'Arthritis', value: 1, color: '#FDB02D', popupContent: AnimalPopUps.Animal.HealthStatus.Arthritis },
              { id: 15, label: 'Nutritional Issue', value: 1, color: '#FFEBCA', popupContent: AnimalPopUps.Animal.HealthStatus.NutritionalIssue },
              { id: 16, label: 'Emergency', value: 1, color: '#FDB02D', onClickNav:"/?module=Animal Trauma" }
            ],
            parent: "Animal",
            level: 2,
            subPages: {}
          },
          "Emotional": {
            chart: [
              { id: 0, label: 'Taken for Granted', value: 1, color: '#4BAD43', popupContent: AnimalPopUps.Animal.Emotional.TakenForGranted},
              { id: 1, label: 'Without Direc.', value: 1, color: '#D2EAD0', popupContent: AnimalPopUps.Animal.Emotional.WithoutDirection },
              { id: 2, label: 'Need to Belong', value: 1, color: '#4BAD43', popupContent: AnimalPopUps.Animal.Emotional.NeedToBelong},
              { id: 3, label: 'Scared', value: 1, color: '#D2EAD0' },
              { id: 4, label: 'Confused', value: 1, color: '#4BAD43' },
              { id: 5, label: 'Bored', value: 1, color: '#D2EAD0', popupContent: AnimalPopUps.Animal.Emotional.Bored },
              { id: 6, label: 'Attn. Issue', value: 1, color: '#4BAD43', popupContent: AnimalPopUps.Animal.Emotional.AttnIssue},
              { id: 7, label: 'Need Space', value: 1, color: '#D2EAD0', popupContent: AnimalPopUps.Animal.Emotional.NeedSpace},
              { id: 8, label: 'LSE', value: 1, color: '#4BAD43', popupContent: AnimalPopUps.Animal.Emotional.LSE},
              { id: 9, label: 'Grieving', value: 1, color: '#D2EAD0', popupContent: AnimalPopUps.Animal.Emotional.Grieving},
              { id: 10, label: 'Worried', value: 1, color: '#4BAD43', popupContent: AnimalPopUps.Animal.Emotional.Worried},
              { id: 11, label: 'Jealous', value: 1, color: '#D2EAD0', popupContent: AnimalPopUps.Animal.Emotional.Jealous},
              { id: 12, label: 'Happy', value: 1, color: '#4BAD43' },
              { id: 13, label: 'Shame', value: 1, color: '#D2EAD0', popupContent: AnimalPopUps.Animal.Emotional.Shame},
              { id: 14, label: 'Sad', value: 1, color: '#4BAD43' },
              { id: 15, label: 'Angry', value: 1, color: '#D2EAD0' },
              { id: 16, label: 'Preoccupied', value: 1, color: '#4BAD43', popupContent: AnimalPopUps.Animal.Emotional.Preoccupied},
              { id: 17, label: 'Overwhelmed', value: 1, color: '#D2EAD0', popupContent: AnimalPopUps.Animal.Emotional.Overwhelmed},
              { id: 18, label: 'Emotional Sponging', value: 1, color: '#4BAD43', popupContent: AnimalPopUps.Animal.Emotional.EmotionalSponging},
            ],
            parent: "Animal",
            level: 2,
            subPages: {}
          },
          "Interrelational": {
            chart: [
              { 'id': 0, 'label': 'People in my life', 'value': 1, 'color': '#005D38', 'popupContent': AnimalPopUps.Animal.Interrelational.PeopleInMyLife },
              { 'id': 1, 'label': 'Do not feel good', 'value': 1, 'color': '#BFD6CD', 'popupContent': AnimalPopUps.Animal.Interrelational.DoNotFeelGood },
              { 'id': 2, 'label': 'Need to tell you', 'value': 1, 'color': '#005D38', 'popupContent': AnimalPopUps.Animal.Interrelational.NeedToTellYou },
              { 'id': 3, 'label': 'Overwhelmed', 'value': 1, 'color': '#BFD6CD', 'popupContent': AnimalPopUps.Animal.Interrelational.Overwhelmed },
              { 'id': 4, 'label': 'Energetic', 'value': 1, 'color': '#005D38', 'popupContent': AnimalPopUps.Animal.Interrelational.Energetic},
              { 'id': 5, 'label': 'Safety', 'value': 1, 'color': '#BFD6CD', 'popupContent': AnimalPopUps.Animal.Interrelational.Safety },
              { 'id': 6, 'label': 'Love', 'value': 1, 'color': '#005D38', 'popupContent': AnimalPopUps.Animal.Interrelational.Love },
              { 'id': 7, 'label': 'Animals', 'value': 1, 'color': '#BFD6CD', 'popupContent': AnimalPopUps.Animal.Interrelational.Animals },
              { 'id': 8, 'label': 'Respect Issue', 'value': 1, 'color': '#005D38', 'popupContent': AnimalPopUps.Animal.Interrelational.RespectIssue},
              { 'id': 9, 'label': 'Life Purpose', 'value': 1, 'color': '#BFD6CD', 'popupContent': AnimalPopUps.Animal.Interrelational.LifePurpose},
              { 'id': 10, 'label': 'Boundaries', 'value': 1, 'color': '#005D38', 'popupContent': AnimalPopUps.Animal.Interrelational.Boundaries},
              { 'id': 11, 'label': 'Taken Advantage Of', 'value': 1, 'color': '#BFD6CD', 'popupContent': AnimalPopUps.Animal.Interrelational.TakenAdvantageOf},
              { 'id': 12, 'label': 'Poor Self Care', 'value': 1, 'color': '#005D38', 'popupContent': AnimalPopUps.Animal.Interrelational.PoorSelfCare},
              { 'id': 13, 'label': 'Lonesome', 'value': 1, 'color': '#BFD6CD', 'popupContent': AnimalPopUps.Animal.Interrelational.Lonesome},
            ],
            parent: "Animal",
            level: 2,
            subPages: {}
          },
          "Body Chemistry": {
            chart: [
              { "id": 0, "label": "Parasites", "value": 1, "color": "#0D2D94" },
              { "id": 1, "label": "Fungus", "value": 1, "color": "#C2CAE4" },
              { "id": 2, "label": "Bacteria", "value": 1, "color": "#0D2D94" },
              { "id": 3, "label": "Viruses", "value": 1, "color": "#C2CAE4" },
              { "id": 4, "label": "Mold", "value": 1, "color": "#0D2D94" },
              { "id": 5, "label": "Toxins", "value": 1, "color": "#C2CAE4" },
              { "id": 6, "label": "Vaccine", "value": 1, "color": "#0D2D94" },
              { "id": 7, "label": "Nutrition", "value": 1, "color": "#C2CAE4", onClickNav:"/?module=Animal&item=Nutrition" },
              { "id": 8, "label": "Heavy Metal", "value": 1, "color": "#0D2D94", popupContent: AnimalPopUps.Animal.BodyChemistry.HeavyMetal},
              { "id": 9, "label": "Additive", "value": 1, "color": "#C2CAE4" },
              { "id": 10, "label": "PH Imbalance", "value": 1, "color": "#0D2D94" , popupContent: AnimalPopUps.Animal.BodyChemistry.PHImbalance},
              { "id": 11, "label": "Medication Issue", "value": 1, "color": "#C2CAE4", popupContent: AnimalPopUps.Animal.BodyChemistry.MedicationIssue},
            ],
            parent: "Animal",
            level: 2,
            subPages: {}
          },
          "Life Enhancements": {
            chart: [
              { "id": 0, "label": "Walk/Ride/Exercise", "value": 1, "color": "#0F1D6D", popupContent: AnimalPopUps.Animal.LifeEnhancements.WalkRideExercise },
              { "id": 1, "label": "Have Adventures", "value": 1, "color": "#C2C6DA", popupContent: AnimalPopUps.Animal.LifeEnhancements.HaveAdventures},
              { "id": 2, "label": "Make me priority", "value": 1, "color": "#0F1D6D", popupContent: AnimalPopUps.Animal.LifeEnhancements.MakeMePriority},
              { "id": 3, "label": "Love Experiences", "value": 1, "color": "#C2C6DA", popupContent: AnimalPopUps.Animal.LifeEnhancements.LoveExperiences},
              { "id": 4, "label": "Be of Service", "value": 1, "color": "#0F1D6D", popupContent: AnimalPopUps.Animal.LifeEnhancements.BeOfService},
              { "id": 5, "label": "Want a Job", "value": 1, "color": "#C2C6DA", popupContent: AnimalPopUps.Animal.LifeEnhancements.WantAJob},
              { "id": 6, "label": "New/Diff. Job", "value": 1, "color": "#0F1D6D", popupContent: AnimalPopUps.Animal.LifeEnhancements.NewDiffJob},
              { "id": 7, "label": "Home/Family", "value": 1, "color": "#C2C6DA", popupContent: AnimalPopUps.Animal.LifeEnhancements.HomeFamily},
              { "id": 8, "label": "Equipment", "value": 1, "color": "#0F1D6D", popupContent: AnimalPopUps.Animal.LifeEnhancements.Equipment},
              { "id": 9, "label": "Part of the family", "value": 1, "color": "#C2C6DA", popupContent: AnimalPopUps.Animal.LifeEnhancements.PartOfTheFamily},
              { "id": 10, "label": "End of Life", "value": 1, "color": "#0F1D6D", popupContent: AnimalPopUps.Animal.LifeEnhancements.EndOfLife},
              { "id": 11, "label": "Play Dates", "value": 1, "color": "#C2C6DA", popupContent: AnimalPopUps.Animal.LifeEnhancements.PlayDates},
            ],
            parent: "Animal",
            level: 2,
            subPages: {}
          },
          "Health Intervention": {
            chart: [
              { "id": 0, "label": "Veterinarian", "value": 1, "color": "#662C91", popupContent: AnimalPopUps.Animal.HealthIntervention.Veterinarian },
              { "id": 1, "label": "Chiropractor", "value": 1, "color": "#D3C8E4", popupContent: AnimalPopUps.Animal.HealthIntervention.Chiropractor },
              { "id": 2, "label": "Acupuncture", "value": 1, "color": "#662C91", popupContent: AnimalPopUps.Animal.HealthIntervention.Acupuncture },
              { "id": 3, "label": "Homeopathy", "value": 1, "color": "#D3C8E4", popupContent: AnimalPopUps.Animal.HealthIntervention.Homeopathy },
              { "id": 4, "label": "Non-verbal Comm.", "value": 1, "color": "#662C91", popupContent: AnimalPopUps.Animal.HealthIntervention.NonVerbalComm},
              { "id": 5, "label": "Medication", "value": 1, "color": "#D3C8E4", popupContent: AnimalPopUps.Animal.HealthIntervention.Medication },
              { "id": 6, "label": "Dentist", "value": 1, "color": "#662C91", popupContent: AnimalPopUps.Animal.HealthIntervention.Dentist },
              { "id": 7, "label": "Flower Essences", "value": 1, "color": "#D3C8E4", popupContent: AnimalPopUps.Animal.HealthIntervention.FlowerEssences },
              { "id": 8, "label": "Dowsing", "value": 1, "color": "#662C91", popupContent: AnimalPopUps.Animal.HealthIntervention.Dowsing },
              { "id": 9, "label": "Grooming", "value": 1, "color": "#D3C8E4", popupContent: AnimalPopUps.Animal.HealthIntervention.Grooming },
              { "id": 10, "label": "Farrier", "value": 1, "color": "#662C91", popupContent: AnimalPopUps.Animal.HealthIntervention.Farrier },
              { "id": 11, "label": "Essential Oils", "value": 1, "color": "#D3C8E4"},
              { "id": 12, "label": "Detoxification", "value": 1, "color": "#662C91", popupContent: AnimalPopUps.Animal.HealthIntervention.Detoxification},
              { "id": 13, "label": "Energy Field Cleanse", "value": 1, "color": "#D3C8E4", popupContent: AnimalPopUps.Animal.HealthIntervention.EnergyFieldCleanse},
              { "id": 14, "label": "Energy Modality", "value": 1, "color": "#662C91" },
            ],
            parent: "Animal",
            level: 2,
            subPages: {}
          }
        }
      }
    }