import { NoInfoPopUp, WordsPopUp, NutritionalIssuePopUp } from "../popup"
import { popupIdMappingType } from "../types"

export const AnimalPopUps: popupIdMappingType = {
    "Animal": {
        "BasicNeeds": {
            "Sleep": <NoInfoPopUp />,
            "Eat": <NoInfoPopUp />,
            "Play": <NoInfoPopUp />,
            "Eliminate": <NoInfoPopUp />,
            "Exercise": <NoInfoPopUp />,
            "Groomed": <NoInfoPopUp />,
            "Work": <NoInfoPopUp />,
            "Learn": <NoInfoPopUp />,
            "Climate": <WordsPopUp words={["Physical", "Emotional"]} />,
            "Water": <NoInfoPopUp />,
            "Air": <NoInfoPopUp />,
            "Medical": <NoInfoPopUp />,
        },
        "Nutrition": {
            "AllergyIntolerance": <WordsPopUp words={["Physical", "Environmental", "Emotional", "Chemical"]} />,
            "EFA": <WordsPopUp words={["Essential Fatty Acids", "Add", "Adjust", "Eliminate"]} />,
            "Hydration": <WordsPopUp words={["Dehydration", "Water Quality"]} />,
            "PHBalance": <WordsPopUp words={["Acidic", "Alkaline"]} />,
            "Medications": <WordsPopUp words={["Add", "Adjust", "Eliminate"]} />,
        },
        "BodyArea": {
            "SkinFurFeatures": <NoInfoPopUp />,
            "Neurological": <NoInfoPopUp />,
            "Eyes": <NoInfoPopUp />,
            "Ears": <NoInfoPopUp />,
            "Lymph": <NoInfoPopUp />,
            "Brain": <NoInfoPopUp />,
            "Colon": <NoInfoPopUp />,
            "Muscles": <NoInfoPopUp />,
            "Fascia": <NoInfoPopUp />,
            "Tendons": <NoInfoPopUp />,
            "Bones": <NoInfoPopUp />,
            "CirculatorySystem": <WordsPopUp words={["Circulatory System"]} />,
            "LegArmPawFootHoof": <NoInfoPopUp />
        },
        "HealthStatus": {
            "AllergyIntolerance": <NoInfoPopUp />,
            "Weight": <WordsPopUp words={["Over", "Under"]} />,
            "Energy": <WordsPopUp words={["High", "Low"]} />,
            "EnergyField": <WordsPopUp words={["Energy Field Compromise"]} />,
            "LifeForce": <WordsPopUp words={["Dying", "Healing"]} />,
            "Pregnant": <NoInfoPopUp />,
            "Obstruction": <WordsPopUp words={["Bowels", "Throat"]} />,
            "Pain": <WordsPopUp words={["Slight", "Intense"]} />,
            "Toxicity": <WordsPopUp words={["Physical", "Emotional", "Sensory"]} />,
            "BodyTemp": <WordsPopUp words={["High", "Low"]} />,
            "Cyst": <WordsPopUp words={["Actual", "Energetic", "Literal", "None Literal"]} />,
            "Tumor": <NoInfoPopUp />,
            "DigestElimi": <WordsPopUp words={["Digestion", "Elimination"]} />,
            "Inflam": <WordsPopUp words={["Inflammation"]} />,
            "Arthritis": <NoInfoPopUp />,
            "NutritionalIssue": <NutritionalIssuePopUp />,
        },
        "Emotional": {
            "TakenForGranted": <NoInfoPopUp />,
            "WithoutDirection": <WordsPopUp words={["Without Direction"]} />,
            "NeedToBelong": <WordsPopUp words={["Need to Belong"]} />,
            "Bored": <WordsPopUp words={["Need Activity", "Nothing Going On"]} />,
            "AttnIssue": <WordsPopUp words={["Attention Issue"]} />,
            "NeedSpace": <WordsPopUp words={["Emotional", "Physical"]} />,
            "LSE": <WordsPopUp words={["Low Self Esteem"]} />,
            "Grieving": <NoInfoPopUp />,
            "Worried": <NoInfoPopUp />,
            "Jealous": <NoInfoPopUp />,
            "Shame": <NoInfoPopUp />,
            "Preoccupied": <NoInfoPopUp />,
            "Overwhelmed": <WordsPopUp words={["Overwhelmed", "Stressed"]} />,
            "EmotionalSponging": <NoInfoPopUp />
        },
        "Interrelational": {
            "PeopleInMyLife": <WordsPopUp words={["Good", "Bad"]} />,
            "DoNotFeelGood": <WordsPopUp words={["Don't feel good about:", "Something", "Self", "Someone Else"]} />,
            "NeedToTellYou": <WordsPopUp words={["I have something to tell you."]} />,
            "Overwhelmed": <NoInfoPopUp />,
            "Energetic": <NoInfoPopUp />,
            "Safety": <WordsPopUp words={["Need to Feel"]} />,
            "Love": <WordsPopUp words={["Need to Feel", "Don't Feel"]} />,
            "Animals": <WordsPopUp words={["Affected by the animals in my life."]} />,
            "RespectIssue": <WordsPopUp words={["Do Feel", "Don't Feel", "Me", "You", "Someone Else"]} />,
            "LifePurpose": <WordsPopUp words={["Life Purpose Issue"]} />,
            "Boundaries": <WordsPopUp words={["Boundary Issue"]} />,
            "TakenAdvantageOf": <WordsPopUp words={["Me", "You", "Someone Else"]} />,
            "PoorSelfCare": <WordsPopUp words={["Me", "People", "Animal"]} />,
            "Lonesome": <WordsPopUp words={["For something or someone"]} />,
        },
        "BodyChemistry": {
            "HeavyMetal": <NoInfoPopUp />,
            "PHImbalance": <WordsPopUp words={["Acidic", "Alkaline"]} />,
            "MedicationIssue": <NoInfoPopUp />,
        },
        "LifeEnhancements":{
            "WalkRideExercise": <NoInfoPopUp />,
            "HaveAdventures": <NoInfoPopUp />,
            "MakeMePriority": <NoInfoPopUp />,
            "LoveExperiences": <NoInfoPopUp />,
            "BeOfService": <NoInfoPopUp />,
            "WantAJob": <NoInfoPopUp />,
            "NewDiffJob": <WordsPopUp words={["New or Different Job"]}/>,
            "HomeFamily" : <WordsPopUp words={["Want a different home or family."]}/>,
            "Equipment": <WordsPopUp words={["Need to change a piece of equipment."]} />,
            "PartOfTheFamily":<WordsPopUp words={["Feel more a part of the family."]} />,
            "EndOfLife": <WordsPopUp words={["End of Life Assistance"]} />,
            "PlayDates": <WordsPopUp words={["With an animal or human friend."]} />
        }, 
        "HealthIntervention" : {
            "Veterinarian": <NoInfoPopUp />,
            "Chiropractor": <NoInfoPopUp />,
            "Acupuncture": <NoInfoPopUp />,
            "Homeopathy": <NoInfoPopUp />,
            "NonVerbalComm": <WordsPopUp words={["Non-verbal Communicator"]} />,
            "Medication": <WordsPopUp words={["Add","Remove"]} />,
            "Dentist": <NoInfoPopUp />,
            "FlowerEssences":<WordsPopUp words={["Bach Flower"]} />,
            "Dowsing": <NoInfoPopUp />,
            "Grooming": <NoInfoPopUp />,
            "Farrier": <NoInfoPopUp />,
            "Detoxification": <NoInfoPopUp />,
            "EnergyFieldCleanse": <NoInfoPopUp />,
        }
    }
}