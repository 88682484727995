import React from 'react';
import { SpiritPopUps } from './popups';
import { ChartPagesType } from '../types'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../utils';

const MarkingLocationsText = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>If yes, which category needs to be addressed.</Typography>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>After going through this process with your first selection, you will come back and ask Are any birthmarks in the other category?
        If yes, continue through the same process.</Typography>
    </Box>
  );
}


const NumOfBirthmarksText = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>Allow for a clearing after the answer you receive.</Typography>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>After the answer that came up is cleared, continue to the <Link to="/?module=Spirit&item=Type+of+Marking">Type Of Marking Chart</Link>.</Typography>
    </Box>
  );
}

const TypeOfMarkingText = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>Allow for a clearing after each answer.</Typography>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>After all the answers that came up are cleared, continue to <Link to="/?module=Spirit&item=Origination">Origination Chart</Link>.</Typography>
    </Box>
  );
}

const OriginationText = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>Find origination from this chart and the <Link to="/?module=Spirit&item=Origination&subitem=Origination+Information">Origination Information Chart</Link>. Allow for a clearing after each answer.</Typography>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>After all the answers that came up are cleared, continue to <Link to="/?module=Spirit&item=Life+Lessons">Life Lessons Chart</Link>.</Typography>
    </Box>
  );
}


const LifeLessonsText = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>Allow for a clearing after each answer.</Typography>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>After all the answers that came up are cleared, continue on to the <Link to="/?module=Spirit&item=Stages+of+Learning">Stages Of Learning Chart</Link>.</Typography>
    </Box>
  );
}

const StagesOfLearningText = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>Allow for a clearing after each answer.</Typography>
      <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS}>Next check to see if there needs to be clearing in the other birthmark category on the <Link to="/?module=Spirit&item=Marking+Locations">Marking Locations Chart</Link>.</Typography>
    </Box>
  );
};

export const SpiritCharts: ChartPagesType = {
  "Spirit": {
    chart: [
      { id: 0, label: 'Marking Locations', value: 1, color: '#FF7B27' },
      { id: 1, label: 'Number of Birthmarks', value: 1, color: '#EFB211' },
      { id: 3, label: 'Type of Marking', value: 1, color: '#46AA42' },
      { id: 4, label: 'Origination', value: 1, color: '#0D2D94' },
      { id: 6, label: 'Life Lessons', value: 1, color: '#692C91' },
      { id: 7, label: 'Stages of Learning', value: 1, color: '#862074' },

    ],

    parent: "All Modules",
    level: 1,
    subPages: {

      "Marking Locations": {
        chart: [
          { id: 0, label: 'Internal', value: 1, color: '#FF7B27', onClickNav: '/?module=Spirit&item=Number+of+Birthmarks' },
          { id: 1, label: 'External', value: 1, color: '#FFDBC5', onClickNav: '/?module=Spirit&item=Number+of+Birthmarks' },
        ],
        parent: "Spirit",
        level: 2,
        textSizeOverride : 24,
        subPages: {},
        textChartInstructions: <MarkingLocationsText />
      },
      "Number of Birthmarks": {
        chart: [

          { id: 0, label: '1', value: 1, color: '#EFB211', popupContent: SpiritPopUps.Spirit.Number.Number },
          { id: 1, label: '2', value: 1, color: '#FAEABF', popupContent: SpiritPopUps.Spirit.Number.Number },
          { id: 2, label: '3', value: 1, color: '#EFB211', popupContent: SpiritPopUps.Spirit.Number.Number },
          { id: 3, label: '4', value: 1, color: '#FAEABF', popupContent: SpiritPopUps.Spirit.Number.Number },
          { id: 4, label: '5', value: 1, color: '#EFB211', popupContent: SpiritPopUps.Spirit.Number.Number },
          { id: 5, label: '6', value: 1, color: '#FAEABF', popupContent: SpiritPopUps.Spirit.Number.Number },
          { id: 6, label: '7', value: 1, color: '#EFB211', popupContent: SpiritPopUps.Spirit.Number.Number },
          { id: 7, label: '8', value: 1, color: '#FAEABF', popupContent: SpiritPopUps.Spirit.Number.Number },
          { id: 8, label: '9', value: 1, color: '#EFB211', popupContent: SpiritPopUps.Spirit.Number.Number },
          { id: 9, label: 'More Than 9', value: 1, color: '#FAEABF', popupContent: SpiritPopUps.Spirit.Number.Number },

        ],
        parent: "Spirit",
        level: 2,
        subPages: {},
        textChartInstructions: <NumOfBirthmarksText />
      },
      "Type of Marking": {
        chart: [

          { id: 0, label: 'Heart', value: 1, color: '#46AA42', popupContent: SpiritPopUps.Spirit.Type.Type },
          { id: 1, label: 'Brain', value: 1, color: '#CDE8CC', popupContent: SpiritPopUps.Spirit.Type.Type },
          { id: 2, label: 'Cells', value: 1, color: '#46AA42', popupContent: SpiritPopUps.Spirit.Type.Cells },
          { id: 3, label: 'Blood', value: 1, color: '#CDE8CC', popupContent: SpiritPopUps.Spirit.Type.Type },
          { id: 4, label: 'Nature', value: 1, color: '#46AA42', popupContent: SpiritPopUps.Spirit.Type.Type },
          { id: 5, label: 'Bones', value: 1, color: '#CDE8CC', popupContent: SpiritPopUps.Spirit.Type.Type },
          { id: 6, label: 'Nerves', value: 1, color: '#46AA42', popupContent: SpiritPopUps.Spirit.Type.Type },
          { id: 7, label: 'Skin', value: 1, color: '#CDE8CC', popupContent: SpiritPopUps.Spirit.Type.Type },
          { id: 8, label: 'Vagus Nerve', value: 1, color: '#46AA42', popupContent: SpiritPopUps.Spirit.Type.Type },
          { id: 9, label: 'Soul Signature', value: 1, color: '#CDE8CC', popupContent: SpiritPopUps.Spirit.Type.Type },

        ],
        parent: "Spirit",
        level: 2,
        subPages: {},
        textChartInstructions: <TypeOfMarkingText />
      },
      "Origination": {
        chart: [
          { id: 0, label: 'Heredity', value: 1, color: '#0D2D94', onClickNav: '/?module=Spirit&item=Origination&subitem=Origination+Information' },
          { id: 1, label: 'Past Life', value: 1, color: '#BEC6E2', onClickNav: '/?module=Spirit&item=Origination&subitem=Origination+Information' },
          { id: 2, label: 'Current Life', value: 1, color: '#0D2D94', onClickNav: '/?module=Spirit&item=Origination&subitem=Origination+Information' },
          { id: 3, label: 'Energetic Programming', labelWithFormatting: 'Energetic \n Programming', value: 1, color: '#BEC6E2', onClickNav: '/?module=Spirit&item=Origination&subitem=Origination+Information' },
        ],
        parent: "Spirit",
        level: 2,
        subPages: {
          "Origination Information": {
            chart: [
              { id: 0, label: 'Emotional', value: 1, color: '#C5C9E1', popupContent: SpiritPopUps.Spirit.OriginationInfo.OriginationInfo },
              { id: 1, label: 'Physical', value: 1, color: '#263692', popupContent: SpiritPopUps.Spirit.OriginationInfo.OriginationInfo },
              { id: 2, label: 'Spiritual', value: 1, color: '#C5C9E1', popupContent: SpiritPopUps.Spirit.OriginationInfo.OriginationInfo },
              { id: 3, label: 'Experiences', value: 1, color: '#263692', popupContent: SpiritPopUps.Spirit.OriginationInfo.OriginationInfo },
              { id: 4, label: 'Relational', value: 1, color: '#C5C9E1', popupContent: SpiritPopUps.Spirit.OriginationInfo.OriginationInfo },
              { id: 5, label: 'Illness', value: 1, color: '#263692', popupContent: SpiritPopUps.Spirit.OriginationInfo.OriginationInfo },
              { id: 6, label: 'Surgery', value: 1, color: '#C5C9E1', popupContent: SpiritPopUps.Spirit.OriginationInfo.OriginationInfo },
              { id: 7, label: 'Injury', value: 1, color: '#263692', popupContent: SpiritPopUps.Spirit.OriginationInfo.OriginationInfo },
              { id: 8, label: 'Branding', value: 1, color: '#C5C9E1', popupContent: SpiritPopUps.Spirit.OriginationInfo.OriginationInfo },
              { id: 9, label: 'Abuse', value: 1, color: '#263692', popupContent: SpiritPopUps.Spirit.OriginationInfo.OriginationInfo },
              { id: 10, label: 'Group Consciousness',labelWithFormatting: 'Group \n Consciousness' ,value: 1, color: '#C5C9E1', popupContent: SpiritPopUps.Spirit.OriginationInfo.OriginationInfo },
            ],
            parent: "Origination",
            level: 3,
            subPages: {}
          }
        },
        textChartInstructions: <OriginationText />
      },



      "Life Lessons": {
        chart: [

          { id: 0, label: 'Fulfillment', value: 1, color: '#692C91', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 1, label: 'Appearance', value: 1, color: '#D7C6E1', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 2, label: 'Attachment', value: 1, color: '#692C91', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 3, label: 'Duality', value: 1, color: '#D7C6E1', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 4, label: 'Egoism', value: 1, color: '#692C91', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 5, label: 'Fame', value: 1, color: '#D7C6E1', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 6, label: 'Fear', value: 1, color: '#692C91', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 7, label: 'Anger', value: 1, color: '#D7C6E1', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 8, label: 'Greed', value: 1, color: '#692C91', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 9, label: 'Jealousy', value: 1, color: '#D7C6E1', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 10, label: 'Power', value: 1, color: '#692C91', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 11, label: 'Pride', value: 1, color: '#D7C6E1', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 12, label: 'Wealth', value: 1, color: '#692C91', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 13, label: 'Sexuality', value: 1, color: '#D7C6E1', popupContent: SpiritPopUps.Spirit.Life.Life },
          { id: 14, label: 'Selfishness', value: 1, color: '#692C91', popupContent: SpiritPopUps.Spirit.Life.Life },

        ],
        parent: "Spirit",
        level: 2,
        subPages: {},
        textChartInstructions: <LifeLessonsText />
      },

      "Stages of Learning": {
        chart: [
          { id: 0, label: 'Purification And Releasing', labelWithFormatting: 'Purification \nAnd Releasing', value: 1, color: '#862074', popupContent: SpiritPopUps.Spirit.Stages.Stages },
          { id: 1, label: 'Invitation/Calling', value: 1, color: '#DEC3D9', popupContent: SpiritPopUps.Spirit.Stages.Stages },
          { id: 2, label: 'Nirvana', value: 1, color: '#862074', popupContent: SpiritPopUps.Spirit.Stages.Stages },
          { id: 3, label: 'Rebirth', value: 1, color: '#DEC3D9', popupContent: SpiritPopUps.Spirit.Stages.Stages },
          { id: 4, label: 'Contact', value: 1, color: '#862074', popupContent: SpiritPopUps.Spirit.Stages.Stages },
          { id: 5, label: 'Tests/Challenges', value: 1, color: '#DEC3D9', popupContent: SpiritPopUps.Spirit.Stages.Stages },
          { id: 6, label: 'Learning Lessons And Judgement', labelWithFormatting: 'Learning Lessons And \n Judgement', value: 1, color: '#862074', popupContent: SpiritPopUps.Spirit.Stages.Stages },
        ],
        parent: "Spirit",
        level: 2,
        subPages: {},
        textChartInstructions: <StagesOfLearningText />,
      },
    }
  }
}