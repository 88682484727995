import React, { useState, useRef, useEffect } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ModuleChart from '../components/ModuleChart/ModuleChart';
import BottomTabs from '../components/BottomTabs/BottomTabs';
import { Box } from '@mui/material';
import WordCardCollection from '../components/WordCardCollection/WordCardCollection';
import ModuleTitle from '../components/ModuleTitle/ModuleTitle';
import ModuleBreadcrumbs from '../components/ModuleBreadcrumbs/ModuleBreadcrumbs';
import { useLocation } from 'react-router-dom';
import { ChartPages } from '../data/charts';
import { ChartPageType, ChartPagesType, WordListType } from '../data/types';
import { GetWordList, GetWordListPageTitle } from '../data/wordlists';
import { GetCustomPage } from '../data/CustomPageMap';
import { useWindowSize, useVisibilityChange } from '@uidotdev/usehooks';

const GetChart = (module: string | null, item: string | null, subitem: string | null) => {
    // if no module is selected then we should show all modules
    if (!module) {
        return ChartPages["All Modules"] as ChartPageType;
    }

    // select the current module
    const currentChartModule: ChartPageType = ChartPages[module as keyof ChartPagesType] as ChartPageType;

    // are we in a chart within a module
    if (item) {
        // we encode with %5C but that means / not \ so we need to replace
        const itemSelector = item?.replace("\\", "/") as string;
        if (subitem) {
            const subitemSelector = subitem?.replace("\\", "/") as string;
            return currentChartModule.subPages[itemSelector as keyof ChartPagesType].subPages[subitemSelector as keyof ChartPagesType] as ChartPageType;
        } else {
            return currentChartModule.subPages[itemSelector as keyof ChartPagesType] as ChartPageType;
        }
    }

    return currentChartModule;
}


const Home: React.FC = () => {
    const [chartSize, setChartSize] = useState<{ height: number, width: number }>({ height: 0, width: 0 })
    const location = useLocation();
    const documentVisible = useVisibilityChange();
    const [tabAwayCount, setTabAwayCount] = React.useState(0);
    const queryParams = new URLSearchParams(location.search);
    const module = queryParams.get('module');
    const item = queryParams.get('item');
    const subitem = queryParams.get('subitem');
    const detail = queryParams.get('detail');

    const headerRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const labelRef = useRef<HTMLDivElement>(null);
    const breadcrumbsAndLabelRef = useRef<HTMLDivElement>(null);
    const tagRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);
    const wordPageTitleRef = useRef<HTMLDivElement>(null);

    var chartData: ChartPageType | undefined | null = null;
    var words: WordListType[] | null = null;
    var wordPageTitle: React.ReactElement | null = null;
    var chartModule: string = "All Modules";
    chartModule = module ? module : "All Modules";
    var specialWordPage = chartModule === "Soul" && item === "Relationships" && subitem === null;



    const CustomComponent = GetCustomPage(module, item, subitem, detail) as React.FC<any> | null;
    const isCustomPage = CustomComponent ? true : false;
    const size = useWindowSize();

    if (isCustomPage) {
        wordPageTitle = GetWordListPageTitle(module, item, subitem);
    }

    if ((!detail) && (!isCustomPage)) {
        chartData = GetChart(module, item, subitem);
    }

    useEffect(() => {
        if (documentVisible === false) {
            setTabAwayCount((c) => c + 1);
        }
    }, [documentVisible]);

    useEffect(() => {
        let topOffset = (wordPageTitle || chartData || words || specialWordPage) ? (labelRef.current?.offsetHeight ?? 0) : (breadcrumbsAndLabelRef.current?.offsetHeight ?? 0)
        // although we allow pagination and title to overflow we need to account for them
        // when calculating the number of word cards to display
        if (words || specialWordPage) {
            topOffset = (labelRef.current?.offsetHeight ?? 0) + (titleRef.current?.offsetHeight ?? 0)
        }
        const chartHeight = (size?.height ?? 0) - (headerRef.current?.offsetHeight ?? 0) - (footerRef.current?.offsetHeight ?? 0) - (tagRef.current?.offsetHeight ?? 0)
            - topOffset
        const chartWidth = size?.width ?? 0;
        setChartSize({ height: chartHeight, width: chartWidth });
    }, [words, module, item, subitem, detail, size, wordPageTitle, chartData, specialWordPage]);



    if ((!chartData) && (!isCustomPage)) {
        words = GetWordList(module, item, subitem, detail);
        wordPageTitle = GetWordListPageTitle(module, item, subitem);

        // very unique case where we have a detail from within a word list
        if (module === "Freedom" && subitem === "Emotion" && detail) {
            words = GetWordList(module, item, detail, null);
            wordPageTitle = GetWordListPageTitle(module, item, detail);
        }
    }


    const getBreadcrumbsTitle = () => {
        if (wordPageTitle || chartData || words || specialWordPage) {
            return (
                <>
                    <Box ref={labelRef}>
                        <ModuleBreadcrumbs module={chartModule} item={item} subItem={subitem} detail={detail} />
                    </Box>
                    <Box style={{ position: 'absolute', top: 'desiredValue' }} ref={titleRef}>
                        <ModuleTitle module={chartModule} />
                    </Box>
                </>
            )
        }
        else {
            return (
                <>
                    <Box ref={breadcrumbsAndLabelRef}>
                        <ModuleBreadcrumbs module={chartModule} item={item} subItem={subitem} detail={detail} />
                        <ModuleTitle module={chartModule} />
                    </Box>
                </>
            )
        }
    }



    const customComponenentProps = {
        height: chartSize.height, width: chartSize.width,
        titleHeight: titleRef.current?.offsetHeight,
        wordPageTitleHeight: wordPageTitleRef.current?.offsetHeight
    }
    return (
        <Box style={{ display: 'flex', flexDirection: 'column', minHeight: '100svh' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', minHeight: '100svh', overflow: 'hidden' }}>
                <Box ref={headerRef}>
                    <Header />
                </Box>
                <Box style={{ position: 'relative', flexGrow: 1 }}>
                    {getBreadcrumbsTitle()}

                    {chartData && chartSize.height > 0 && (
                        <ModuleChart height={chartSize.height} width={chartSize.width} chartData={chartData} chartModule={chartModule} item={item} subitem={subitem} />
                    )}

                    {wordPageTitle &&
                        <Box ref={wordPageTitleRef}>
                            {wordPageTitle}
                        </Box>
                    }
                    {CustomComponent ? (
                        <CustomComponent {...customComponenentProps} />
                    ) : null}

                    {words && chartSize.height > 0 && chartSize.width > 0 && (
                        <WordCardCollection wordList={words} width={chartSize.width}
                            height={chartSize.height}
                            titleHeight={titleRef.current?.offsetHeight}
                            wordPageTitleHeight={wordPageTitleRef.current?.offsetHeight}
                        />
                    )}
                    {!chartData && !words && !CustomComponent && chartSize.height > 0 && chartSize.width && (
                        <Box style={{ flex: '1 0 auto', justifyContent: 'center', alignItems: 'center' }}>
                        </Box>
                    )}
                </Box>
                <Box style={{ position: 'sticky', bottom: 0 }} ref={tagRef}>
                    <BottomTabs key={tabAwayCount} currentModule={chartModule} item={item} subitem={subitem} detail={detail} />
                </Box>
                <Box style={{ position: 'sticky', bottom: 0 }} ref={footerRef}>
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Home;
