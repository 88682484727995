import React from 'react';
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Button, Drawer, IconButton, List, ListItem, Paper, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import './Header.css';
import { Link } from '@mui/material';


const Header: React.FC = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const modulesClick = () => {
    var page: string = "All Modules";
    let params = new URLSearchParams();
    params.append('module', page);
    navigate(`/?${params.toString()}`);
  }

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };


  const drawer = (
    <Drawer
      variant="temporary"
      anchor="left"
      open={drawerOpen}
      onClose={handleDrawerToggle}
      PaperProps={{ style: { width: '70%' } }} // Adjust this to your preferred width
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <IconButton>
            <img src="/logo.png" alt="Logo" className="logo" />
          </IconButton>
        </Box>
        <List>
          <ListItem onClick={modulesClick}>Modules</ListItem>
          <ListItem component={Link} href="https://www.trailblazingcommunications.com/"
            target="_blank" rel="noopener noreferrer"
            sx={{ color: 'inherit' }}>All Trainings</ListItem>
        </List>
      </Box>
    </Drawer>
  );

  return (
    <AppBar position="static" className="appBar" style={{ backgroundColor: 'transparent' }}>
      <Paper elevation={0}>
        <Toolbar>
          {isSmallScreen ? (
            <>
              <IconButton >
                <img src="/logo.png" alt="Logo" className="logo" />
              </IconButton>
              <IconButton edge="end" color="inherit" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
              {drawer}
            </>
          ) : (
            <>
              <IconButton sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}>
                <img src="/logo.png" alt="Logo" className="logo" />
              </IconButton>
              <Typography variant="h6" color="textPrimary" className="title">
                Trailblazing Communications
              </Typography>
              <Button color="inherit" className="button" onClick={modulesClick} sx={{ textTransform: 'none' }}>
                Modules
              </Button>
              <Button sx={{ textTransform: 'none' }} component={Link} href="https://www.trailblazingcommunications.com/" target="_blank" rel="noopener noreferrer" color="inherit" className="button">All Trainings</Button>
            </>
          )}
        </Toolbar>
      </Paper>
    </AppBar>
  );
};

export default Header;