import { TraumaPeoplePopUps } from './popups';
import { ChartPagesType } from '../types'

export const TraumaPeopleCharts: ChartPagesType = {
  "Trauma People": {
    chart: [
      { id: 0, label: 'Angels', value: 1, color: '#380004' },
      { id: 1, label: 'Frequency', value: 1, color: '#571B1E' },
      { id: 2, label: 'Trauma', value: 1, color: '#8A2528' },
      { id: 3, label: 'Issues', value: 1, color: '#C3102F' },
      { id: 4, label: 'Defenses', value: 1, color: '#E51A23' },
      { id: 5, label: 'Blocks', value: 1, color: '#F25B21' },
      { id: 6, label: 'Syncing', value: 1, color: '#F7971D' },
      { id: 7, label: 'Emotional', value: 1, color: '#EFB211' },
      { id: 8, label: 'Energy Fields', value: 1, color: '#FFE616' },
      { id: 9, label: 'Chakras', value: 1, color: '#C1D72F' },
      { id: 10, label: 'Meridians', value: 1, color: '#8BC63E' },
      { id: 11, label: 'Body Quadrant', value: 1, color: '#46AA42' },
      { id: 12, label: 'Body Area', value: 1, color: '#357B2B' },
      { id: 13, label: 'Systems', value: 1, color: '#00653A' },
      { id: 14, label: 'Connectors', value: 1, color: '#006B6E' },
      { id: 15, label: 'Cells', value: 1, color: '#0083A9' },
      { id: 16, label: 'Bones', value: 1, color: '#0077C0' },
      { id: 17, label: 'Blood', value: 1, color: '#135CAB' },
      { id: 18, label: 'Actions', value: 1, color: '#263692' },
      { id: 19, label: 'Colors', value: 1, color: '#8C63AA' },
      { id: 20, label: 'Clearing', value: 1, color: '#692C91' },
      { id: 21, label: 'Reproductive', value: 1, color: '#6C1F7D' },
      { id: 22, label: 'Origination', value: 1, color: '#896531' },
      { id: 23, label: 'Other Modules', value: 1, color: '#535249', onClickNav: "/?module=All Modules" },
    ],

    parent: "All Modules",
    level: 1,
    subPages: {
      "Angels": {
        chart: [
          { id: 0, label: 'Arcturians', value: 1, color: '#380004', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.ArcturianEmmissionaries },
          { id: 1, label: 'Raphael', value: 1, color: '#bd9395', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.Raphael },
          { id: 2, label: 'Gabriel', value: 1, color: '#380004', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.Gabriel },
          { id: 3, label: 'Michael', value: 1, color: '#bd9395', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.Michael },
          { id: 4, label: 'Jophiel', value: 1, color: '#380004', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.Jophiel },
          { id: 5, label: 'Chamuel', value: 1, color: '#bd9395', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.Chamuel },
          { id: 6, label: 'Uriel', value: 1, color: '#380004', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.Uriel },
          { id: 7, label: 'Zadkiel', value: 1, color: '#bd9395', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.Zadkiel },
          { id: 8, label: 'Spirit Guide', value: 1, color: '#380004', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.SpiritGuide },
          { id: 9, label: 'Metatron', value: 1, color: '#bd9395', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.Metatron },
          { id: 10, label: 'Jesus Christ', value: 1, color: '#380004', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.JesusChrist },
          { id: 11, label: 'Animal Companion', value: 1, color: '#bd9395', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.AnimalCompanion },
          { id: 12, label: 'Other Helper', value: 1, color: '#380004', popupContent: TraumaPeoplePopUps.TraumaPeople.Angels.OtherHelper },
        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      "Frequency": {
        chart: [
          { id: 0, label: '-100', value: 1, color: '#571B1E', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 1, label: '-90', value: 1, color: '#683234', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 2, label: '-80', value: 1, color: '#78484B', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 3, label: '-70', value: 1, color: '#895F61', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 4, label: '-60', value: 1, color: '#9A7678', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 5, label: '-50', value: 1, color: '#AB8D8E', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 6, label: '-40', value: 1, color: '#BCA4A5', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 7, label: '-30', value: 1, color: '#CBB', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 8, label: '-20', value: 1, color: '#DED2D2', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 9, label: '-10', value: 1, color: '#EEE8E8', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 10, label: '0', value: 1, color: '#EEE8E8', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 11, label: '10', value: 1, color: '#EEE8E8', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 12, label: '20', value: 1, color: '#DED2D2', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 13, label: '30', value: 1, color: '#CBB', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 14, label: '40', value: 1, color: '#BCA4A5', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 15, label: '50', value: 1, color: '#AB8D8E', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 16, label: '60', value: 1, color: '#9A7678', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 17, label: '70', value: 1, color: '#895F61', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 18, label: '80', value: 1, color: '#78484B', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 19, label: '90', value: 1, color: '#683234', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },
          { id: 20, label: '100', value: 1, color: '#571B1E', popupContent: TraumaPeoplePopUps.TraumaPeople.Frequency.Frequency },

        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      "Trauma": {
        chart: [
          { id: 0, label: 'Chromosome Abn.', value: 1, color: '#8A2528', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.ChromosomeAbnormality },
          { id: 1, label: 'Group Consc.', value: 1, color: '#DFC4C5', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Group },
          { id: 2, label: 'Thoughts', value: 1, color: '#8A2528', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Thoughts },
          { id: 3, label: 'Emotions', value: 1, color: '#DFC4C5', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Emotions },
          { id: 4, label: 'Experiences', value: 1, color: '#8A2528', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Experiences },
          { id: 5, label: 'Genetics', value: 1, color: '#DFC4C5', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Genetics },
          { id: 6, label: 'Beliefs', value: 1, color: '#8A2528', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Beliefs },
          { id: 7, label: 'Surgery', value: 1, color: '#DFC4C5', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Surgery },
          { id: 8, label: 'Past Life', value: 1, color: '#8A2528', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.PastLive },
          { id: 9, label: 'Behaviors', value: 1, color: '#DFC4C5', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Behaviors },
          { id: 10, label: 'Cording', value: 1, color: '#8A2528', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Cording },
          { id: 11, label: 'Syncing', value: 1, color: '#DFC4C5', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Syncing },
          { id: 12, label: 'Chemicals', value: 1, color: '#8A2528', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Chemicals },
          { id: 13, label: 'GMO Foods', value: 1, color: '#DFC4C5', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.GMOFoods },
          { id: 14, label: 'Relationships', value: 1, color: '#8A2528', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Experiences },
          { id: 15, label: 'Programming', value: 1, color: '#DFC4C5', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Relationships },
          { id: 16, label: 'Electromagnetic', value: 1, color: '#8A2528', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.Electromagnetics },
          { id: 17, label: 'Env. Toxins', value: 1, color: '#DFC4C5', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.EnvironmentalToxins },
          { id: 18, label: 'Post Traumatic Stress', value: 1, color: '#8A2528', popupContent: TraumaPeoplePopUps.TraumaPeople.Trauma.PostTraumaticStress },
        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },
      "Issues": {
        chart: [

          { id: 0, label: 'Relational Env.', value: 1, color: '#C3102F', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.RelationalEnvironment },
          { id: 1, label: 'Physical Env.', value: 1, color: '#EFBFC7', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.PhysicalEnvironment },
          { id: 2, label: 'Energy Distortion', value: 1, color: '#C3102F', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.EnergyDistortion },
          { id: 3, label: 'Learned Patterns', value: 1, color: '#EFBFC7', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.LearnedPatterns },
          { id: 4, label: 'Energy Patterns', value: 1, color: '#C3102F', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.EnergyPatterns },
          { id: 5, label: 'Body Chem.', value: 1, color: '#EFBFC7', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.BodyChemistry },
          { id: 6, label: 'Soul Unrest', value: 1, color: '#C3102F', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.SoulUnrest },
          { id: 7, label: 'Dietary', value: 1, color: '#EFBFC7', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.Dietary },
          { id: 8, label: 'RNA', value: 1, color: '#C3102F', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.RNA },
          { id: 9, label: 'DNA', value: 1, color: '#EFBFC7', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.DNA },
          { id: 10, label: 'Chemical', value: 1, color: '#C3102F', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.Chemical },
          { id: 11, label: 'Genetics', value: 1, color: '#EFBFC7', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.Genetics },
          { id: 12, label: 'Boundaries', value: 1, color: '#C3102F' },
          { id: 13, label: 'Chromosomal', value: 1, color: '#EFBFC7', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.Chromasomal },
          { id: 14, label: 'Coping skills', value: 1, color: '#C3102F', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.CopingSKills },
          { id: 15, label: 'Energy Diff.', value: 1, color: '#EFBFC7', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.EnergyDiff },
          { id: 16, label: 'Situational Choices', value: 1, color: '#C3102F', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.Situational },
          { id: 17, label: 'Emotional Env.', value: 1, color: '#EFBFC7', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.EmotionalEnv },
          { id: 18, label: 'Spiritual Env.', value: 1, color: '#C3102F', popupContent: TraumaPeoplePopUps.TraumaPeople.Issues.SpiritualEnvironment },

        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },
      "Defenses": {
        chart: [
          { id: 0, label: 'Withdrawal - Partial', value: 1, color: '#E51A23', popupContent: TraumaPeoplePopUps.TraumaPeople.Defenses.WithdrawalPartial },
          { id: 1, label: 'Dissociation', value: 1, color: '#F8C1C4', popupContent: TraumaPeoplePopUps.TraumaPeople.Defenses.Dissociation },
          { id: 2, label: 'Energy Hooks', value: 1, color: '#E51A23', popupContent: TraumaPeoplePopUps.TraumaPeople.Defenses.EnergyHooks },
          { id: 3, label: 'Exploding', value: 1, color: '#F8C1C4', popupContent: TraumaPeoplePopUps.TraumaPeople.Defenses.Exploding },
          { id: 4, label: 'Denial', value: 1, color: '#E51A23', popupContent: TraumaPeoplePopUps.TraumaPeople.Defenses.Denial },
          { id: 5, label: 'Distancing', value: 1, color: '#F8C1C4', popupContent: TraumaPeoplePopUps.TraumaPeople.Defenses.Distancing },
          { id: 6, label: 'Energy Vaping', value: 1, color: '#E51A23', popupContent: TraumaPeoplePopUps.TraumaPeople.Defenses.EnergyVaping },
          { id: 7, label: 'Total Withdrawal', value: 1, color: '#F8C1C4', popupContent: TraumaPeoplePopUps.TraumaPeople.Defenses.TotalWithdrawal },
          { id: 8, label: 'Energy Vampirism', value: 1, color: '#E51A23', popupContent: TraumaPeoplePopUps.TraumaPeople.Defenses.EnergyVampirism },

        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      "Blocks": {
        chart: [
          { id: 0, label: 'Diss. Thought Forms', value: 1, color: '#F25B21', popupContent: TraumaPeoplePopUps.TraumaPeople.Blocks.Dissociated },
          { id: 1, label: 'Depletion', value: 1, color: '#FBD3C3', popupContent: TraumaPeoplePopUps.TraumaPeople.Blocks.Depletion },
          { id: 2, label: 'Compaction', value: 1, color: '#F25B21', popupContent: TraumaPeoplePopUps.TraumaPeople.Blocks.Compaction },
          { id: 3, label: 'Repression', value: 1, color: '#FBD3C3', popupContent: TraumaPeoplePopUps.TraumaPeople.Blocks.Repression },
          { id: 4, label: 'Drugs', value: 1, color: '#F25B21', popupContent: TraumaPeoplePopUps.TraumaPeople.Blocks.Drugs },
          { id: 5, label: 'Mesh Armor', value: 1, color: '#FBD3C3', popupContent: TraumaPeoplePopUps.TraumaPeople.Blocks.Mesh },
          { id: 6, label: 'Plate Armor', value: 1, color: '#F25B21', popupContent: TraumaPeoplePopUps.TraumaPeople.Blocks.Plate },
          { id: 7, label: 'Energy Leak', value: 1, color: '#FBD3C3', popupContent: TraumaPeoplePopUps.TraumaPeople.Blocks.Leak },
          { id: 8, label: 'Negative Emotions', value: 1, color: '#F25B21', popupContent: TraumaPeoplePopUps.TraumaPeople.Blocks.Negative },
        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },
      "Syncing": {
        chart: [
          { id: 0, label: 'Deceased Loved One', value: 1, color: '#F7971D', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Deceased },
          { id: 1, label: 'Spouse/Partner', value: 1, color: '#FCE3C2', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Spouse },
          { id: 2, label: 'Animal Companion', value: 1, color: '#F7971D', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.AnimalCompanion },
          { id: 3, label: 'Group Consc.', value: 1, color: '#FCE3C2', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.GroupConsc },
          { id: 4, label: 'Parent', value: 1, color: '#F7971D', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Parent },
          { id: 5, label: 'An Idea', value: 1, color: '#FCE3C2', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Idea },
          { id: 6, label: 'Friend', value: 1, color: '#F7971D', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Friend },
          { id: 7, label: 'Music', value: 1, color: '#FCE3C2', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Music },
          { id: 8, label: 'Peer', value: 1, color: '#F7971D', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Peer },
          { id: 9, label: 'Client', value: 1, color: '#FCE3C2', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Client },
          { id: 10, label: 'Sibling', value: 1, color: '#F7971D', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Sibling },
          { id: 11, label: 'Co-worker', value: 1, color: '#FCE3C2', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Coworker },
          { id: 12, label: 'Room Mate', value: 1, color: '#F7971D', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Roommate },
          { id: 13, label: 'Child(ren)', value: 1, color: '#FCE3C2', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Children },
          { id: 14, label: 'Environment', value: 1, color: '#F7971D', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.Environment },
          { id: 15, label: 'Someone Not Listed', value: 1, color: '#FCE3C2', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.SomeoneNotListed },
          { id: 16, label: 'Something Not Listed', value: 1, color: '#F7971D', popupContent: TraumaPeoplePopUps.TraumaPeople.Syncing.SomethingElse },

        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      "Emotional": {
        chart: [
          // { id: 0, label: 'Go To Another Chart', value: 1, color: '#EFB211' },
          { id: 1, label: 'Emotional Sponging', value: 1, color: '#FAEABF', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.EmotionalSponging },
          { id: 2, label: 'Low Self Esteem', value: 1, color: '#EFB211', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.LSE },
          { id: 3, label: 'Attn. Issue', value: 1, color: '#FAEABF', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.AttnIssue },
          { id: 4, label: 'Need Space', value: 1, color: '#EFB211', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.NeedSpace },
          { id: 5, label: 'Grieving', value: 1, color: '#FAEABF', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.Grieving },
          { id: 6, label: 'Jealous', value: 1, color: '#EFB211', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.Jealous },
          { id: 7, label: 'Scared', value: 1, color: '#FAEABF' },
          { id: 8, label: 'Angry', value: 1, color: '#EFB211' },
          { id: 9, label: 'Sad', value: 1, color: '#FAEABF' },
          { id: 10, label: 'Happy', value: 1, color: '#EFB211' },
          { id: 11, label: 'Bored', value: 1, color: '#FAEABF', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.Bored },
          { id: 12, label: 'Shame', value: 1, color: '#EFB211', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.Shame },
          { id: 13, label: 'Worried', value: 1, color: '#FAEABF', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.Worried },
          { id: 14, label: 'Confused', value: 1, color: '#EFB211' },
          { id: 15, label: 'Preoccupied', value: 1, color: '#FAEABF', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.Preoccupied },
          { id: 16, label: 'Need To Belong', value: 1, color: '#EFB211', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.NeedToBelong },
          { id: 17, label: 'W/out Direction', value: 1, color: '#FAEABF', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.WithoutDirection },
          { id: 18, label: 'Taken For Granted', value: 1, color: '#EFB211', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.TakenForGranted },
          { id: 19, label: 'Overwhelmed', value: 1, color: '#FAEABF', popupContent: TraumaPeoplePopUps.TraumaPeople.Emotional.Overwhelmed },
        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      "Energy Fields": {
        chart: [
          { id: 0, label: 'Etheric Body', value: 1, color: '#FFE616', popupContent: TraumaPeoplePopUps.TraumaPeople.EnergyField.Etheric },
          { id: 1, label: 'Emotional Body', value: 1, color: '#FFF8C0', popupContent: TraumaPeoplePopUps.TraumaPeople.EnergyField.Emotional },
          { id: 2, label: 'Mental Body', value: 1, color: '#FFE616', popupContent: TraumaPeoplePopUps.TraumaPeople.EnergyField.Mental },
          { id: 3, label: 'Astral Body', value: 1, color: '#FFF8C0', popupContent: TraumaPeoplePopUps.TraumaPeople.EnergyField.Astral },
          { id: 4, label: 'Etheric Template', value: 1, color: '#FFE616', popupContent: TraumaPeoplePopUps.TraumaPeople.EnergyField.ET },
          { id: 5, label: 'Celestial Body', value: 1, color: '#FFF8C0', popupContent: TraumaPeoplePopUps.TraumaPeople.EnergyField.C },
          { id: 6, label: 'Ketheric Body', value: 1, color: '#FFE616', popupContent: TraumaPeoplePopUps.TraumaPeople.EnergyField.K },

        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      "Chakras": {
        chart: [
          { id: 0, label: 'Root Chakra', value: 1, color: '#C3102F', popupContent: TraumaPeoplePopUps.TraumaPeople.Chakras.Root },
          { id: 1, label: 'Sacral Chakra', value: 1, color: '#F48026', popupContent: TraumaPeoplePopUps.TraumaPeople.Chakras.Sacral },
          { id: 2, label: 'Solar Plexus Chakra', value: 1, color: '#EFB211', popupContent: TraumaPeoplePopUps.TraumaPeople.Chakras.Solar },
          { id: 3, label: 'Heart Chakra', value: 1, color: '#46AA42', popupContent: TraumaPeoplePopUps.TraumaPeople.Chakras.Heart },
          { id: 4, label: 'Throat Chakra', value: 1, color: '#263692', popupContent: TraumaPeoplePopUps.TraumaPeople.Chakras.Throat },
          { id: 5, label: 'Third Eye Chakra', value: 1, color: '#221E72', popupContent: TraumaPeoplePopUps.TraumaPeople.Chakras.ThirdEye },
          { id: 6, label: 'Spiritual Chakra', value: 1, color: '#692C91', popupContent: TraumaPeoplePopUps.TraumaPeople.Chakras.Spiritual },
          { id: 7, label: 'Chakra Not Listed', value: 1, color: '#221E72', popupContent: TraumaPeoplePopUps.TraumaPeople.Chakras.NoInfo },


        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },




      "Meridians": {
        chart: [
          { id: 0, label: 'Spleen (Pancreas)', value: 1, color: '#8BC63E', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.Spleen },
          { id: 1, label: 'Heart Governor', value: 1, color: '#E0EFCB', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.HeartGovernor },
          { id: 2, label: 'Triple Heater', value: 1, color: '#8BC63E', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.TripleHeater },
          { id: 3, label: 'Stomach', value: 1, color: '#E0EFCB', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.Stomach },
          { id: 4, label: 'Bladder', value: 1, color: '#8BC63E', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.Bladder },
          { id: 5, label: 'Liver', value: 1, color: '#E0EFCB', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.Liver },
          { id: 6, label: 'Lung', value: 1, color: '#8BC63E', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.Lung },
          { id: 7, label: 'Heart', value: 1, color: '#E0EFCB', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.Heart },
          { id: 8, label: 'Kidney', value: 1, color: '#8BC63E', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.Kidney },
          { id: 9, label: 'Gallbladder', value: 1, color: '#E0EFCB', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.GallBladder },
          { id: 10, label: 'Pericardium', value: 1, color: '#8BC63E', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.Paricardium },
          { id: 11, label: 'Large Intestine', value: 1, color: '#E0EFCB', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.LargeIntestine },
          { id: 12, label: 'Small Intestine', value: 1, color: '#8BC63E', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.SmIntestine },
          { id: 13, label: 'Multiple Meridians', value: 1, color: '#E0EFCB', popupContent: TraumaPeoplePopUps.TraumaPeople.Meridians.Multiple },


        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },
      "Body Quadrant": {
        chart: [
          { id: 0, label: '1', value: 1, color: '#46AA42', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyQuadrants.one  },
          { id: 1, label: '2', value: 1, color: '#74BF71', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyQuadrants.two  },
          { id: 2, label: '3', value: 1, color: '#A2D4A0', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyQuadrants.three },
          { id: 3, label: '4', value: 1, color: '#D1EAD0', textColor: "white",  popupContent: TraumaPeoplePopUps.TraumaPeople.BodyQuadrants.four },
        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {},
        textSizeOverride: 32,
        rightOrLeftImage: "bodyquadrants.png"
      },
      "Body Area": {
        chart: [
          { id: 0, label: 'Circulatory System', value: 1, color: '#357B2B', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.CirculatorySystem },
          { id: 2, label: 'Tendons', value: 1, color: '#C9DBC6', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.Tendons },
          { id: 3, label: 'Sinuses', value: 1, color: '#357B2B', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.Sinuses },
          { id: 4, label: 'Fascia', value: 1, color: '#C9DBC6', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.Fascia },
          { id: 5, label: 'Colon', value: 1, color: '#357B2B', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.Colon },
          { id: 6, label: 'Brain', value: 1, color: '#C9DBC6', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.Brain },
          { id: 7, label: 'Skin', value: 1, color: '#357B2B', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.Skin },
          { id: 8, label: 'Lymph', value: 1, color: '#C9DBC6', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.Lymph },
          { id: 9, label: 'Ear(s)', value: 1, color: '#357B2B', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.Ears },
          { id: 10, label: 'Eye(s)', value: 1, color: '#C9DBC6', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.Eyes },
          { id: 11, label: 'Organs', value: 1, color: '#357B2B' },
          { id: 12, label: 'Bone(s)', value: 1, color: '#C9DBC6', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.Bones },
          { id: 13, label: 'Muscles', value: 1, color: '#357B2B', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.Muscles },
          { id: 14, label: 'Endocrine', value: 1, color: '#C9DBC6' },
          { id: 15, label: 'Neurological', value: 1, color: '#357B2B', popupContent: TraumaPeoplePopUps.TraumaPeople.BodyArea.Neurological },
          { id: 16, label: 'Hormones', value: 1, color: '#C9DBC6' },

        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      "Systems": {
        chart: [
          { id: 0, label: 'Integumentary', value: 1, color: '#00653A', popupContent: TraumaPeoplePopUps.TraumaPeople.Systems.Integumentary },
          { id: 1, label: 'Reproductive', value: 1, color: '#BBD5CA', popupContent: TraumaPeoplePopUps.TraumaPeople.Systems.Reproductive },
          { id: 2, label: 'Endocrine', value: 1, color: '#00653A', popupContent: TraumaPeoplePopUps.TraumaPeople.Systems.Endocrine },
          { id: 3, label: 'Digestive', value: 1, color: '#BBD5CA', popupContent: TraumaPeoplePopUps.TraumaPeople.Systems.Digestive },
          { id: 4, label: 'Nervous', value: 1, color: '#00653A', popupContent: TraumaPeoplePopUps.TraumaPeople.Systems.Nervous },
          { id: 5, label: 'Immune', value: 1, color: '#BBD5CA', popupContent: TraumaPeoplePopUps.TraumaPeople.Systems.Immune },
          { id: 6, label: 'Skeletal', value: 1, color: '#00653A', popupContent: TraumaPeoplePopUps.TraumaPeople.Systems.Skeletal },
          { id: 7, label: 'Muscular', value: 1, color: '#BBD5CA', popupContent: TraumaPeoplePopUps.TraumaPeople.Systems.Muscular },
          { id: 8, label: 'Urinary', value: 1, color: '#00653A', popupContent: TraumaPeoplePopUps.TraumaPeople.Systems.Urinary },
          { id: 9, label: 'Lymphatic', value: 1, color: '#BBD5CA', popupContent: TraumaPeoplePopUps.TraumaPeople.Systems.Lymphatic },
          { id: 10, label: 'Respiratory', value: 1, color: '#00653A', popupContent: TraumaPeoplePopUps.TraumaPeople.Systems.Respiratory },
          { id: 11, label: 'Cardiovascular', value: 1, color: '#BBD5CA', popupContent: TraumaPeoplePopUps.TraumaPeople.Systems.Cardiovascular },

        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      "Connectors": {
        chart: [
          { id: 0, label: 'Ligaments', value: 1, color: '#006B6E', popupContent: TraumaPeoplePopUps.TraumaPeople.Connectors.Ligaments },
          { id: 1, label: 'Muscles', value: 1, color: '#BBD7D8', popupContent: TraumaPeoplePopUps.TraumaPeople.Connectors.Muscles },
          { id: 2, label: 'Tendons', value: 1, color: '#006B6E', popupContent: TraumaPeoplePopUps.TraumaPeople.Connectors.Tendons },
          { id: 3, label: 'Nerves', value: 1, color: '#BBD7D8', popupContent: TraumaPeoplePopUps.TraumaPeople.Connectors.Nerves },
          { id: 4, label: 'Fascia', value: 1, color: '#006B6E', popupContent: TraumaPeoplePopUps.TraumaPeople.Connectors.Fascia },
          { id: 5, label: 'Bones', value: 1, color: '#BBD7D8', popupContent: TraumaPeoplePopUps.TraumaPeople.Connectors.Bones },
          { id: 6, label: 'Joints', value: 1, color: '#006B6E', popupContent: TraumaPeoplePopUps.TraumaPeople.Connectors.Joints },
          { id: 7, label: 'Skin', value: 1, color: '#BBD7D8', popupContent: TraumaPeoplePopUps.TraumaPeople.Connectors.Skin },
          { id: 8, label: 'Cartilage', value: 1, color: '#006B6E', popupContent: TraumaPeoplePopUps.TraumaPeople.Connectors.Cartilage },
        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      "Cells": {
        chart: [
          { id: 0, label: 'Endoplasmic Ret.', value: 1, color: '#0083A9', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.EndoplasmicReticulum },
          { id: 1, label: 'Cell Membrane', value: 1, color: '#BBDDE8', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.CellMembrane },
          { id: 2, label: 'Cytoskeleton', value: 1, color: '#0083A9', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Cytoskeleton },
          { id: 3, label: 'Prokaryote', value: 1, color: '#BBDDE8', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Prokaryote },
          { id: 4, label: 'Cytoplasm', value: 1, color: '#0083A9', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Cytoplasm },
          { id: 5, label: 'Cell Wall', value: 1, color: '#BBDDE8', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.CellWall },
          { id: 6, label: 'Endoderm', value: 1, color: '#0083A9', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Endoderm },
          { id: 7, label: 'Mesoderm', value: 1, color: '#BBDDE8' },
          { id: 8, label: 'Ectoderm', value: 1, color: '#0083A9', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Ectoderm },
          { id: 9, label: 'Vacuole', value: 1, color: '#BBDDE8', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Vacuole },
          { id: 10, label: 'Cell', value: 1, color: '#0083A9', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Cell },
          { id: 11, label: 'Vesicle', value: 1, color: '#BBDDE8', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Vesicle },
          { id: 12, label: 'Ribosome', value: 1, color: '#0083A9', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Ribosome },
          { id: 13, label: 'Organelle', value: 1, color: '#BBDDE8', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Organelle },
          { id: 14, label: 'Nucleolus', value: 1, color: '#0083A9', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Nucleolus },
          { id: 15, label: 'Lysosomes', value: 1, color: '#BBDDE8', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Lysosomes },
          { id: 16, label: 'Eukaryote', value: 1, color: '#0083A9', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Eukaryote },
          { id: 17, label: 'Fetal Cells', value: 1, color: '#BBDDE8', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.FetalCell },
          { id: 18, label: 'Mitochondria', value: 1, color: '#0083A9', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.Mitochondria },
          { id: 19, label: 'Golgi Apparatus', value: 1, color: '#BBDDE8', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.GolgiApparatus },
          { id: 20, label: 'Nuclear Envelope', value: 1, color: '#0083A9', popupContent: TraumaPeoplePopUps.TraumaPeople.Cells.NuclearEnvelope },
        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      "Bones": {
        chart: [

          { id: 0, label: 'Long Bones', value: 1, color: '#0077C0' },
          { id: 1, label: 'Short Bones', value: 1, color: '#BBDAEE' },
          { id: 2, label: 'Flat Bones', value: 1, color: '#0077C0' },
          { id: 3, label: 'Irregular Bones', value: 1, color: '#BBDAEE' },
          { id: 4, label: 'Sesamoid Bones', value: 1, color: '#0077C0' },
          { id: 5, label: 'Pneumatic Bones', value: 1, color: '#BBDAEE' },

        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      "Blood": {
        chart: [
          { id: 0, label: 'White Cell Antibodies', value: 1, color: '#135CAB', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.WhiteCellAntibodies },
          { id: 1, label: 'Carbon Dioxide', value: 1, color: '#C0D3E8', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.CarbonDioxide },
          { id: 2, label: 'White Cells', value: 1, color: '#135CAB' },
          { id: 3, label: 'Red Cells', value: 1, color: '#C0D3E8', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.RedCells },
          { id: 4, label: 'Nitrogen', value: 1, color: '#135CAB', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.Nitrogen },
          { id: 5, label: 'Hormones', value: 1, color: '#C0D3E8', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.Hormones },
          { id: 6, label: 'Proteins', value: 1, color: '#135CAB', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.Proteins },
          { id: 7, label: 'Plasma', value: 1, color: '#C0D3E8', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.Plasma },
          { id: 8, label: 'Oxygen', value: 1, color: '#135CAB', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.Oxygen },
          { id: 9, label: 'Stem Cells', value: 1, color: '#C0D3E8', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.StemCells },
          { id: 10, label: 'Platelets', value: 1, color: '#135CAB', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.Platelets },
          { id: 11, label: 'Hemoglobin', value: 1, color: '#C0D3E8', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.Hemoglobin },
          { id: 12, label: 'Fat Globules', value: 1, color: '#135CAB', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.Fat },
          { id: 13, label: 'Carbohydrates', value: 1, color: '#C0D3E8', popupContent: TraumaPeoplePopUps.TraumaPeople.Blood.Carbs },
          { id: 14, label: 'Blood Comp.', value: 1, color: '#135CAB' },
          { id: 15, label: 'Comp Blood Cnt', value: 1, color: '#C0D3E8' },

        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      /// Actions is a whole word list

      "Colors": {
        chart: [
          { id: 0, label: 'Red', value: 1, color: '#C3102F', popupContent: TraumaPeoplePopUps.TraumaPeople.Colors.Red },
          { id: 1, label: 'Orange', value: 1, color: '#F48026', popupContent: TraumaPeoplePopUps.TraumaPeople.Colors.Orange },
          { id: 2, label: 'Yellow', value: 1, color: '#F9EC33', popupContent: TraumaPeoplePopUps.TraumaPeople.Colors.Yellow },
          { id: 3, label: 'Green', value: 1, color: '#46AA42', popupContent: TraumaPeoplePopUps.TraumaPeople.Colors.Green },
          { id: 4, label: 'Blue', value: 1, color: '#263692', popupContent: TraumaPeoplePopUps.TraumaPeople.Colors.Blue },
          { id: 5, label: 'Violet', value: 1, color: '#77278D', popupContent: TraumaPeoplePopUps.TraumaPeople.Colors.Violet },
          { id: 6, label: 'Indigo', value: 1, color: '#4E2582', popupContent: TraumaPeoplePopUps.TraumaPeople.Colors.Indigo },
          { id: 7, label: 'White', value: 1, color: '#f0f0f0', popupContent: TraumaPeoplePopUps.TraumaPeople.Colors.White },
          { id: 8, label: 'Silver', value: 1, color: '#A8A9AD', popupContent: TraumaPeoplePopUps.TraumaPeople.Colors.Silver },
          { id: 9, label: 'Gold', value: 1, color: '#D3881B', popupContent: TraumaPeoplePopUps.TraumaPeople.Colors.Gold },
          { id: 10, label: 'Black', value: 1, color: '#231F20', popupContent: TraumaPeoplePopUps.TraumaPeople.Colors.Black },
          { id: 11, label: 'Rainbow', value: 1, color: '#ffd700', popupContent: TraumaPeoplePopUps.TraumaPeople.Colors.Rainbow },

        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },


      "Clearing": {
        chart: [
          { id: 2, label: 'Call In An Angel', value: 1, color: '#692C91', popupContent: TraumaPeoplePopUps.TraumaPeople.Clearing.CallAngel },
          { id: 3, label: 'Frequency', value: 1, color: '#D7C6E1', popupContent: TraumaPeoplePopUps.TraumaPeople.Clearing.Frequency },
          { id: 6, label: 'Spirit Message', value: 1, color: '#692C91', popupContent: TraumaPeoplePopUps.TraumaPeople.Clearing.Spirit },
          { id: 7, label: 'Animal Message', value: 1, color: '#D7C6E1', popupContent: TraumaPeoplePopUps.TraumaPeople.Clearing.Animal },
          { id: 9, label: 'Clear Energy Field', value: 1, color: '#692C91', popupContent: TraumaPeoplePopUps.TraumaPeople.Clearing.ClearEnergyField },
        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      "Reproductive": {
        chart: [

          { id: 0, label: 'Female', value: 1, color: '#6C1F7D' },
          { id: 1, label: 'Male', value: 1, color: '#D7C3DC' }
        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },

      ///// START HERE//////
      "Body Imbalance": {
        chart: [
          { id: 0, label: 'Virus', value: 1, color: '#F25B21' },
          { id: 1, label: 'Bacteria', value: 1, color: '#FBD3C3' },
          { id: 2, label: 'Parasites', value: 1, color: '#F25B21' },
          { id: 3, label: 'Fungus', value: 1, color: '#FBD3C3' },
          { id: 4, label: 'Disease', value: 1, color: '#F25B21' },

        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },



      "Origination": {
        chart: [
          { id: 0, label: '1', value: 1, color: '#896531', popupContent: TraumaPeoplePopUps.TraumaPeople.Origination.Origination },
          { id: 1, label: '2', value: 1, color: '#DFD5C8', popupContent: TraumaPeoplePopUps.TraumaPeople.Origination.Origination },
          { id: 2, label: '3', value: 1, color: '#896531', popupContent: TraumaPeoplePopUps.TraumaPeople.Origination.Origination },
          { id: 3, label: '4', value: 1, color: '#DFD5C8', popupContent: TraumaPeoplePopUps.TraumaPeople.Origination.Origination },
          { id: 4, label: '5', value: 1, color: '#896531', popupContent: TraumaPeoplePopUps.TraumaPeople.Origination.Origination },
          { id: 5, label: '6', value: 1, color: '#DFD5C8', popupContent: TraumaPeoplePopUps.TraumaPeople.Origination.Origination },
          { id: 6, label: '7', value: 1, color: '#896531', popupContent: TraumaPeoplePopUps.TraumaPeople.Origination.Origination },
          { id: 7, label: '8', value: 1, color: '#DFD5C8', popupContent: TraumaPeoplePopUps.TraumaPeople.Origination.Origination },
          { id: 8, label: '9', value: 1, color: '#896531', popupContent: TraumaPeoplePopUps.TraumaPeople.Origination.Origination },
          { id: 9, label: '10', value: 1, color: '#DFD5C8', popupContent: TraumaPeoplePopUps.TraumaPeople.Origination.Origination },
          { id: 10, label: 'More Than 10', value: 1, color: '#896531', popupContent: TraumaPeoplePopUps.TraumaPeople.Origination.Origination },

        ],
        parent: "TraumaPeople",
        level: 2,
        subPages: {}
      },


    }
  }
}